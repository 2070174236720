<script lang="ts">
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { popErrorToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import AccessFormField from "../collaborators/AccessFormField.svelte";
  import {
    convertUIPermissionsToDBPermissions,
    type UIPermissions,
  } from "../collaborators/collaborators";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import GiftIcon from "../icons/GiftBig.svelte";
  import UserIcon from "../icons/UserBig.svelte";
  import AddUpdateBeneficiaryCharityForm from "./AddUpdateBeneficiaryCharityForm.svelte";
  import type { PersonFormData } from "./AddUpdateBeneficiaryPersonForm.svelte";
  import AddUpdateBeneficiaryPersonForm from "./AddUpdateBeneficiaryPersonForm.svelte";
  import {
    createTaskToDesignateBeneficiaryAssets,
    createTaskToInviteBeneficiaryToAccess,
  } from "./tasks";
  import { ProgressRadial, modalStore } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";

  type Step = "Select Type" | "Form" | "Invite" | "Success";
  let step: Step = "Select Type";

  export let beneficiaryType: "person" | "charity" = null;

  // Used in the Success step only
  let beneficiaryName = "";

  // --- Beneficiary Person
  let permissions: UIPermissions;
  let addPersonLoading = false;

  // Preserve the form data in case the user goes back to the form
  let person: PersonFormData = null;

  async function addPerson(personFormData: PersonFormData) {
    const { inviteUser, ...person } = personFormData;

    const {
      data: { id },
      error,
    } = await supabase
      .from("beneficiary_person")
      .insert({
        ...person,
        estate_id: $currentEstate.id,
      })
      .select("id")
      .single();

    if (error) throw error;

    if (!inviteUser) {
      createTaskToInviteBeneficiaryToAccess({
        ...person,
        id,
      });
    }

    createTaskToDesignateBeneficiaryAssets(
      id,
      "person",
      `${person.first_name} ${person.last_name}`
    );

    return id;
  }

  // Handler functions
  const selectBeneficiaryType = (type: "person" | "charity") => {
    beneficiaryType = type;
    step = "Form";
  };

  onMount(() => {
    if (beneficiaryType) selectBeneficiaryType(beneficiaryType);
  });

  async function handleAddPerson({
    detail: personFormData,
  }: {
    detail: PersonFormData;
  }) {
    if (personFormData.inviteUser) {
      ref.parentElement.scrollTo(0, 0);
      step = "Invite";
      person = personFormData;
      return;
    }

    try {
      addPersonLoading = true;

      const id = await addPerson(personFormData);
      beneficiaryName = `${personFormData.first_name} ${personFormData.last_name}`;
      $modalStore[0].response({ person: { ...personFormData, id } });

      step = "Success";
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      addPersonLoading = false;
    }
  }

  async function handleAddPermissions() {
    try {
      addPersonLoading = true;

      // 1. add person beneficiary (keep id for linking to user from step 3)
      const id = await addPerson(person);

      // 2. invite beneficiary-collaborator
      const dbPermissions = convertUIPermissionsToDBPermissions(permissions);
      const { first_name, last_name, email } = person;
      const { data, error } = await supabase.functions.invoke(
        "invite-collaborator",
        {
          body: {
            first_name,
            last_name,
            email,
            invited_as: "beneficiary",
            permissions: dbPermissions,
          },
        }
      );

      if (error ?? data.error) throw new Error(error ?? data.error);

      // 3. link beneficiary to collaborator
      const { error: linkErr } = await supabase
        .from("beneficiary_person")
        .update({ user_id: data.user.id })
        .eq("id", id)
        .select("user_id");
      if (linkErr) throw linkErr;

      // 4. update local state and proceed
      beneficiaryName = `${person.first_name} ${person.last_name}`;
      $modalStore[0].response({
        fullRefresh: true,
      });

      step = "Success";
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      addPersonLoading = false;
    }
  }

  function handleAddCharityDone({ detail: charity }) {
    $modalStore[0].response({ charity });

    beneficiaryName = charity?.name;
    step = "Success";
  }

  function addAnotherBeneficiary() {
    beneficiaryName = "";
    beneficiaryType = null;
    person = null;
    step = "Select Type";
  }

  let ref: HTMLElement;
</script>

<Modal>
  <div class="mobile-content w-[750px]" bind:this={ref}>
    {#if step === "Select Type"}
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title mb-[30px]">
          Add <ins class="max-md:hidden">a </ins>New Beneficiary
        </h1>

        <p class="text-body/large text-text/dark max-md:hidden">
          What type of beneficiary would you like to add?
        </p>
      </div>

      <div
        class="mobile-content-body border-b border-t border-ui/border-light p-[50px] md:shadow-forms"
      >
        <p class="mobile-content-description md:hidden">
          What type of beneficiary would you like to add?
        </p>

        <div class="flex gap-[30px] max-sm:flex-col max-sm:items-stretch">
          <button
            type="button"
            class="variant-ringed-primary btn flex flex-1 flex-col !gap-[15px] bg-white !py-[30px] text-state/link"
            on:click={() => selectBeneficiaryType("person")}
          >
            <span class="icon">
              <UserIcon />
            </span>

            <span class="text-[22px] leading-[28px] max-md:text-[20px]">
              Person
            </span>
          </button>

          <button
            type="button"
            class="variant-ringed-primary btn flex flex-1 flex-col !gap-[15px] bg-white !py-[30px] text-state/link"
            on:click={() => selectBeneficiaryType("charity")}
          >
            <span class="icon">
              <GiftIcon />
            </span>

            <span class="text-[22px] leading-[28px] max-md:text-[20px]">
              Charity
            </span>
          </button>
        </div>
      </div>
    {:else if step === "Form"}
      {#if beneficiaryType === "person"}
        <AddUpdateBeneficiaryPersonForm
          {person}
          loading={addPersonLoading}
          on:submit={handleAddPerson}
          on:cancel={() => (step = "Select Type")}
        />
      {:else if beneficiaryType === "charity"}
        <AddUpdateBeneficiaryCharityForm
          on:done={handleAddCharityDone}
          on:cancel={() => (step = "Select Type")}
        />
      {/if}
    {:else if step === "Invite"}
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title pb-[30px]">
          Add <ins class="max-md:hidden">a </ins>New Beneficiary
        </h1>

        <p class="m-0 p-0 text-body/large max-md:hidden">
          What should {person.first_name} have access to in your account?
        </p>
      </div>

      <div class="mobile-content-body">
        <AccessFormField bind:permissions />
      </div>

      <div
        class="mobile-content-footer flex items-baseline justify-between px-[50px] py-[40px]"
      >
        <button
          type="button"
          class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
          disabled={addPersonLoading}
          on:click={() => (step = "Form")}
        >
          <ArrowLeftIcon />

          Back
        </button>

        <div class="max-md:w-[100%]">
          <button
            type="submit"
            class="btn-filled btn-success btn-lg flex gap-[10px]"
            disabled={addPersonLoading}
            on:click={handleAddPermissions}
          >
            {#if addPersonLoading}
              Adding Beneficiary

              <ProgressRadial
                value={undefined}
                width="w-6"
                meter="stroke-white"
              />
            {:else}
              Add Beneficiary

              <ArrowRightIcon />
            {/if}
          </button>

          <p
            class="max-md:text-text/small mt-[12px] text-center text-base text-text/medium md:mt-4"
          >
            An invite email will be sent
          </p>
        </div>
      </div>
    {:else if step === "Success"}
      <div class="text-center">
        <div class=" px-[50px] py-[30px]">
          <span class="icon mb-[30px] flex justify-center">
            {#if beneficiaryType === "person"}
              <UserIcon />
            {:else if beneficiaryType === "charity"}
              <GiftIcon />
            {/if}
          </span>

          <h1>{beneficiaryName} Added!</h1>
        </div>

        <div class="w-full border-t border-ui/border p-[50px] shadow">
          <p class="mb-10 text-[22px] text-text/dark">
            What would you like to do now?
          </p>

          <button
            type="button"
            class="variant-ringed-primary btn-lg btn mb-[22px] w-[350px] text-state/link"
            on:click={addAnotherBeneficiary}
          >
            Add Another Beneficiary
          </button>

          <button
            type="button"
            class="btn-filled btn-lg btn w-[350px] gap-[5px]"
            on:click={modalStore.close}
          >
            Close Window
          </button>
        </div>
      </div>
    {/if}
  </div>
</Modal>

<style lang="postcss">
  .icon :global(svg) {
    height: 60px;
    width: 60px;
  }
</style>
