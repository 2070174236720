<script lang="ts">
  import type { View } from "../../pages/Auth.svelte";
  import { supabase } from "../../supabaseClient";
  import { Routes } from "../../util/routes";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import SendIcon from "../icons/Send.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let view: View;

  let submitClicked = false;
  let emailSent = false;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        email: null,
      },
      validationSchema: object().shape({
        email: string().required("Required").email("Must be a valid email"),
      }),
      onSubmit: async () => {
        await sendEmail();

        emailSent = true;
      },
    }
  );

  async function sendEmail() {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail($form.email, {
        redirectTo: `${location.origin}${Routes.UpdatePassword}`,
      });

      if (error) throw error;
      popSuccessToast("Email sent");
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    }
  }
</script>

{#if emailSent}
  <div class="mobile-content">
    <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
      <div class="mobile-header">
        <h1 class="mobile-header-title mb-[20px] text-text/dark">
          Check Your Email
        </h1>
      </div>

      <p
        class="text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        We’ve sent a message to <span class="underline">{$form.email}</span> with
        instructions on how to set a new password for your account.
      </p>
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
    >
      <button
        type="submit"
        class="btn-filled btn-lg flex gap-[10px]"
        disabled={$isSubmitting}
        on:click={() => (view = "login")}
      >
        <ArrowLeftIcon />

        Account Login
      </button>

      <button
        type="button"
        class="variant-ringed-primary btn flex gap-[5px] text-state/link"
        on:click={sendEmail}
      >
        {#if $isSubmitting}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
        {:else}
          <SendIcon />
        {/if}

        Resend Email
      </button>
    </div>
  </div>
{:else}
  <form class="mobile-content" on:submit={handleSubmit} novalidate>
    <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
      <div class="mobile-header">
        <h1 class="mobile-header-title mb-5 text-text/dark">Forgot Password</h1>
      </div>

      <p
        class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        Please enter your account’s email address and we will send instructions
        on how you can reset your password.
      </p>

      <div class="input-wrap">
        <label for="email">
          Your Email Address

          <span class="text-state/negative">*</span>
        </label>

        <input
          id="email"
          type="email"
          bind:value={$form.email}
          on:change={handleChange}
        />

        {#if $errors.email && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.email}</small>
        {/if}
      </div>
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
    >
      <button
        type="button"
        class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
        on:click={() => (view = "login")}
      >
        <ArrowLeftIcon />

        Back
      </button>

      <button
        type="submit"
        class="btn-filled btn-lg flex gap-[10px]"
        disabled={$isSubmitting}
        on:click={() => (submitClicked = true)}
      >
        {#if $isSubmitting}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
        {:else}
          <SendIcon />
        {/if}

        Send Instructions
      </button>
    </div>
  </form>
{/if}
