<script lang="ts">
  import {
    loanAccountTypesMap,
    type Debt,
    creditAccountTypesMap,
    getDebtTypeIcon,
    getDebtTypeName,
  } from "../../pages/Debts.svelte";
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import {
    openPlaidLinkModal,
    type OnSuccessMetaData,
    type PlaidError,
    type LinkHandler,
  } from "../../util/plaid";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import Modal from "../Modal.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import WifiIcon from "../icons/Wifi.svelte";
  import NoWifiIcon from "../icons/WifiNo.svelte";
  import AddUpdateDebtForm from "./AddUpdateDebtForm.svelte";
  import type { DebtType } from "./debts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { onDestroy } from "svelte";

  export let debtType: DebtType;
  type Step = "Select Type" | "Select Flow" | "Form" | "Success";
  let step: Step = debtType ? "Select Flow" : "Select Type";

  let createConnectedLoading = false;

  let plaidHandler: LinkHandler;

  async function openPlaidLink() {
    try {
      createConnectedLoading = true;

      await openPlaidLinkModal({
        onSuccess: (public_token: string, metadata: OnSuccessMetaData) => {
          if (public_token) {
            // There's only a single account possible for now
            const account = metadata.accounts[0];

            supabase.functions
              .invoke("create-debt", {
                body: {
                  // debt data
                  estate_id: $currentEstate.id,
                  debt_type: debtType,
                  institution_name: metadata.institution.name,
                  account_number: account.mask,

                  // connected_account data
                  plaid_account_id: account.id,
                  institution_id: metadata.institution.institution_id,
                  public_token,
                },
              })
              .then(({ data, error }) => {
                if (error) throw error;
                $modalStore[0].response(data.debt);
                step = "Success";
              })
              .catch((error) => {
                console.error(error);
                popErrorToast("Error adding debt. Please try again later.");
              })
              .finally(() => {
                createConnectedLoading = false;
              });
          }
        },
        onExit: (error: PlaidError | null, metadata) => {
          if (error) {
            console.error(
              `Plaid error: ${error.error_type} ${error.error_message}`
            );
          }
          createConnectedLoading = false;
        },
      }).then((handler) => {
        plaidHandler = handler;
      });
    } catch (e) {
      popErrorToast("There was an error connecting your account.");
      console.error(e);

      createConnectedLoading = false;
    }
  }

  function selectDebtType(type: DebtType | any) {
    debtType = type;
    step = "Select Flow";
  }

  function selectFlow(flow: "Connected" | "Offline") {
    if (flow === "Connected") {
      openPlaidLink();
    } else {
      step = "Form";
    }
  }

  function handleDone({ detail: debt }: { detail: Debt }) {
    step = "Success";

    $modalStore[0].response(debt);
  }

  onDestroy(() => {
    if (plaidHandler) {
      plaidHandler.exit();
    }
  });
</script>

<Modal>
  <div class="mobile-content w-[750px] max-w-[100%]">
    {#if step === "Select Type"}
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title mb-[30px]">
          Add <ins class="max-md:hidden">a </ins>New Debt
        </h1>
        <p class="text-body/large text-text/dark max-md:hidden">
          What type of debt would you like to add?
        </p>
      </div>

      <div
        class="mobile-content-body bg-bg/white border-t border-ui/border-light px-[50px] pb-[40px] pt-[50px] md:shadow-forms"
      >
        <p class="mobile-content-description md:hidden">
          What type of debt would you like to add?
        </p>

        <h3
          class="pb-[15px] text-[20px] leading-[30px] max-md:text-[16px] max-md:leading-[24px]"
        >
          Credit Accounts
        </h3>

        <div
          class="grid grid-cols-[repeat(auto-fit,minmax(290px,1fr))] gap-[20px] max-md:gap-[15px]"
        >
          {#each Object.entries(creditAccountTypesMap) as [type, { name, icon, note }] (type)}
            <div class="gap-[20px]">
              <button
                type="button"
                class="btn-main btn w-full !justify-start !p-[20px] max-md:gap-[5px] max-md:!p-[16px]"
                on:click={() => selectDebtType(type)}
              >
                {#if icon}
                  <svelte:component this={icon} />
                {/if}
                <span class="text-[22px] max-md:text-[20px]">{name}</span>
              </button>

              {#if note}
                <p
                  class="input-hint !mt-[10px] ml-[25px] w-[100%] max-md:ml-[22px]"
                >
                  {note}
                </p>
              {/if}
            </div>
          {/each}
        </div>

        <h3
          class="mt-11 pb-[15px] text-[20px] leading-[30px] max-md:text-[16px] max-md:leading-[24px]"
        >
          Loans & Mortgages
        </h3>

        <div
          class="grid grid-cols-[repeat(auto-fit,minmax(290px,1fr))] gap-[20px] max-md:gap-[15px]"
        >
          {#each Object.entries(loanAccountTypesMap) as [type, { name, icon }] (type)}
            <div class="flex flex-wrap items-center gap-[20px]">
              <button
                type="button"
                class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                on:click={() => selectDebtType(type)}
              >
                {#if icon}
                  <span><svelte:component this={icon} /></span>
                {/if}
                <span class="text-[22px] max-md:text-[20px]">{name}</span>
              </button>
            </div>
          {/each}
        </div>
      </div>
    {:else if step === "Select Flow"}
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title pb-[30px]">
          Add a New {getDebtTypeName(debtType)}
        </h1>
        <p class="m-0 p-0 text-body/large max-md:hidden">
          How would you like to add this account to LegaSEE?
        </p>
      </div>

      <div
        class="mobile-content-body relative border-b border-t border-ui/border-light p-[50px] md:shadow-forms"
      >
        <p class="mobile-content-description md:hidden">
          How would you like to add this account to LegaSEE?
        </p>

        <div class="flex gap-[30px] max-sm:flex-col max-sm:items-stretch">
          <button
            class="btn-wifi btn bg-white"
            disabled={createConnectedLoading}
            on:click={() => selectFlow("Connected")}
          >
            <span class="btn-wifi-svg"><WifiIcon /></span>
            <span
              class="mt-[10px] block text-[22px] leading-[28px] max-md:mt-[20px] max-md:text-[20px]"
            >
              Connected Account
            </span>

            <span
              class="mt-[20px] block whitespace-normal text-[16px] font-normal leading-[24px] text-text/dark max-md:mt-[10px]"
            >
              Find your financial institution and link your account for
              automatic updates.
            </span>
          </button>

          <button
            class="btn-wifi btn bg-white"
            disabled={createConnectedLoading}
            on:click={() => selectFlow("Offline")}
          >
            <span class="btn-wifi-svg"><NoWifiIcon /></span>
            <span
              class="mt-[10px] block text-[22px] leading-[28px] max-md:mt-[20px] max-md:text-[20px]"
            >
              Offline Debt
            </span>

            <span
              class="mt-[20px] block whitespace-normal text-[16px] font-normal leading-[24px] text-text/dark max-md:mt-[10px]"
            >
              If you can't link to an account, give us the best details you
              have.
            </span>
          </button>
        </div>
        {#if createConnectedLoading}
          <span class="absolute bottom-0 left-0 right-0">
            <BarLoader />
          </span>
        {/if}
      </div>

      <div class="md:px-[50px] md:py-[40px]">
        <button
          class="btn-main mobile-back-button btn"
          disabled={createConnectedLoading}
          on:click={() => (step = "Select Type")}
        >
          <ArrowLeftIcon />
          Back
        </button>
      </div>
    {:else if step === "Form"}
      <AddUpdateDebtForm
        {debtType}
        on:done={handleDone}
        on:cancel={() => (step = "Select Flow")}
      />
    {:else if step === "Success"}
      <div class="text-center">
        <div class=" px-[50px] py-[30px]">
          <span class="icon mb-[30px] flex justify-center">
            <svelte:component this={getDebtTypeIcon(debtType)} />
          </span>

          <h1>{getDebtTypeName(debtType)} Added!</h1>
        </div>

        <div class="w-full border-t border-ui/border p-[50px] shadow">
          <p class="mb-10 text-[22px] text-text/dark">
            What would you like to do now?
          </p>

          <button
            type="button"
            class="variant-ringed-primary btn-lg btn mb-[22px] w-[350px] text-state/link"
            on:click={() => (step = "Select Type")}
          >
            Add Another Debt
          </button>

          <button
            type="button"
            class="btn-filled btn-lg btn w-[350px] gap-[5px]"
            on:click={modalStore.close}
          >
            Close Window
          </button>
        </div>
      </div>
    {/if}
  </div>
</Modal>

<style lang="postcss">
  .btn-wifi {
    @apply flex flex-col items-center justify-center gap-0 rounded border border-state/link px-[25px] py-[30px] text-state/link sm:w-[50%];
  }

  .icon :global(svg) {
    height: 80px;
    width: 80px;
  }
</style>
