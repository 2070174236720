<script lang="ts">
  import {
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money.js";
  import { handleDollarInput } from "../../util/validation.ts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { startCase } from "lodash";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { boolean, number, object, string } from "yup";

  export let multipartFormData;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goBack: () => void = getContext("back");
  const goForward: () => void = getContext("forward");

  const vehicleTypes = [
    "automobile",
    "boat",
    "motorcycle",
    "snowmobile",
    "bicycle",
    "commercialVehicle",
    "other",
  ];

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        vehicleType: $multipartFormData.vehicle_type,
        automobileSelected: $multipartFormData.vehicle_type === "automobile",
        make: $multipartFormData.make,
        model: $multipartFormData.model,
        year: $multipartFormData.year,
        estimatedValue: convertCentsToInputDollars(
          $multipartFormData.estimated_value
        ),
        vin: $multipartFormData.vin,
        usage: $multipartFormData.usage,
        usageType: $multipartFormData.usage_type,
      },
      validationSchema: object().shape({
        automobileSelected: boolean(),
        vehicleType: string().required("Required"),
        make: string().when("automobileSelected", {
          is: true,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        model: string().required("Required"),
        year: number().when("automobileSelected", {
          is: true,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        estimatedValue: string().required("Required"),
        vin: string().nullable(),
        usage: number().nullable(),
        usageType: string().nullable(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.vehicle_type = formData.vehicleType;
        $multipartFormData.make = formData.make;
        $multipartFormData.model = formData.model;
        $multipartFormData.year = formData.year;
        $multipartFormData.estimated_value = convertDollarsStringToCents(
          formData.estimatedValue
        );
        $multipartFormData.vin = formData.vin;
        $multipartFormData.usage = formData.usage;
        $multipartFormData.usage_type = formData.usageType;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );
</script>

<form
  class="mobile-content {!!onSubmitted ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if !!onSubmitted}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Asset</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {!!$multipartFormData.year
          ? $multipartFormData.year + " "
          : ""}{$multipartFormData.model}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">Add Vehicle</h1>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <label for="vehicleType">
          Vehicle Type <span class="text-state/negative">*</span>
        </label>

        <select
          name="vehicleType"
          id="vehicleType"
          bind:value={$form.vehicleType}
          on:change={handleChange}
        >
          <option value="" disabled selected>Choose...</option>
          {#each vehicleTypes as vehicleType}
            <option value={vehicleType}>{startCase(vehicleType)}</option>
          {/each}
        </select>

        {#if $errors.vehicleType}
          <small class="input-error"><WarnIcon /> {$errors.vehicleType}</small>
        {/if}
      </div>

      {#if $form.vehicleType}
        {#if $form.vehicleType === "automobile"}
          <div class="input-wrap">
            <label for="make">
              Make <span class="text-state/negative">*</span>
            </label>

            <input
              type="text"
              name="make"
              id="make"
              bind:value={$form.make}
              on:change={handleChange}
            />
          </div>

          <div class="input-wrap">
            <label for="model">
              Model <span class="text-state/negative">*</span>
            </label>

            <input
              type="text"
              name="model"
              id="model"
              bind:value={$form.model}
              on:change={handleChange}
            />
          </div>

          <div class="input-wrap">
            <label for="year">
              Year <span class="text-state/negative">*</span>
            </label>

            <input
              type="number"
              name="year"
              id="year"
              bind:value={$form.year}
              on:change={handleChange}
            />
          </div>

          <div class="input-wrap pb-[35px] md:pb-[45px]">
            <label for="estimatedValue">
              Estimated Value <span class="text-state/negative">*</span>
            </label>

            <div class="input-group w-full grid-cols-[42px_auto]">
              <label
                for="estimatedValue"
                class="m-0 flex items-center pl-[18px] text-text/medium"
              >
                <DollarIcon />
              </label>

              <input
                type="text"
                name="estimatedValue"
                id="estimatedValue"
                bind:value={$form.estimatedValue}
                class="!pl-0"
                on:input={(e) => handleDollarInput(e, form, "estimatedValue")}
              />
            </div>
          </div>
        {:else}
          <div class="input-wrap">
            <label for="model">
              Model/Description <span class="text-state/negative">*</span>
            </label>

            <input
              type="text"
              name="model"
              id="model"
              bind:value={$form.model}
              on:change={handleChange}
            />
          </div>

          <div class="input-wrap pb-[35px] md:pb-[45px]">
            <label for="estimatedValue">
              Estimated Value <span class="text-state/negative">*</span>
            </label>

            <div class="input-group w-full grid-cols-[42px_auto]">
              <label
                for="estimatedValue"
                class="m-0 flex items-center pl-[18px] text-text/medium"
              >
                <DollarIcon />
              </label>

              <input
                type="text"
                name="estimatedValue"
                id="estimatedValue"
                class="!pl-0"
                bind:value={$form.estimatedValue}
                on:input={(e) => handleDollarInput(e, form, "estimatedValue")}
              />
            </div>
          </div>
        {/if}
      {/if}
    </div>

    {#if $form.vehicleType}
      <div
        class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] border-ui/border-light max-md:gap-y-[35px] md:gap-y-[45px] md:border-t md:pr-[50px] md:pt-[45px]"
      >
        <div class="input-wrap">
          <label for="vin">
            {$form.vehicleType === "automobile" ? "VIN" : "VIN/Serial Number"}
          </label>

          <input
            type="text"
            name="vin"
            id="vin"
            bind:value={$form.vin}
            on:change={handleChange}
          />
        </div>
        <div class="input-wrap">
          <label for="usage">
            {$form.vehicleType === "automobile" ? "Miles" : "Miles/Hours"}
          </label>

          <div class="flex items-center justify-start gap-[15px]">
            <input
              type="number"
              name="usage"
              id="usage"
              bind:value={$form.usage}
              on:change={handleChange}
            />

            {#if $form.vehicleType !== "automobile"}
              <select
                name="usageType"
                bind:value={$form.usageType}
                on:change={handleChange}
              >
                <option value="miles">Miles</option>
                <option value="hours">Hours</option>
              </select>
            {/if}
          </div>
        </div>
      </div>
    {/if}
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button btn-main"
      on:click|preventDefault={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
