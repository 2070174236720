<script lang="ts">
  export let icon: any;
  export let color = "";
</script>

<button
  type="button"
  class="flex w-full items-center gap-[7px] py-[9px] pl-[12px] pr-[30px] text-base hover:opacity-70 {color}"
  on:click
>
  <div class="flex h-6 w-6 items-center">
    <svelte:component this={icon} />
  </div>

  <div
    class={color === "text-state/negative" || color === "text-state/positive"
      ? "font-semibold"
      : ""}
  >
    <slot />
  </div>
</button>
