<script lang="ts">
  import type { ClientEstate } from "../../pages/AdvisorClients.svelte";
  import { currentEstate, userPermissions } from "../../stores/user";
  import { floatString, timeAgo } from "../../util/format";
  import AccessIcon from "../icons/Access.svelte";
  import SearchIcon from "../icons/Search.svelte";
  import ReadinessMeter from "../readiness/ReadinessMeter.svelte";
  import {
    taskList,
    calculateCompletionPercentage,
  } from "../readiness/readiness";
  import { orderBy } from "lodash";

  export let clients: ClientEstate[] = [];

  let searchVal = "";

  $: matchingClients = searchVal
    ? clients.filter(({ user }) =>
        [user.first_name, user.last_name, user.email].some((str) =>
          str.includes(searchVal)
        )
      )
    : clients;

  $: sortedMatchingClients = orderBy(
    clients,
    (client) => new Date(client.user.created_at).getTime(),
    "desc"
  );
</script>

{#if clients.length}
  <div class="mb-[40px] flex items-center justify-between max-md:mb-[25px]">
    <p class="text-body/medium max-md:hidden">
      {clients.length} active client{clients.length !== 1 ? "s" : ""}
    </p>

    <div
      class="input-group max-w-[250px] grid-cols-[42px_auto] max-md:max-w-none"
    >
      <label
        for="search"
        class="search-icon m-0 flex items-center pl-[14px] text-text/medium max-md:!bg-bg/lightest"
      >
        <SearchIcon />
      </label>

      <input
        class="max-md:!bg-bg/white py-[14px] !pl-0 pr-[14px] !text-body/medium max-md:text-[16px]"
        type="search"
        id="search"
        name="search"
        placeholder="Search..."
        bind:value={searchVal}
      />
    </div>
  </div>
{/if}

{#if matchingClients.length}
  <div class="table-container">
    <table class="table max-md:border-t">
      <thead class="!bg-bg/lighter">
        <th class="rounded-tl-[5px]">Name</th>
        <th>Readiness</th>
        <th colspan="2" class="rounded-tr-[5px]">Last Login</th>
      </thead>
      <tbody>
        {#each sortedMatchingClients as { user, ...estate }}
          {@const clientTasks = $taskList.filter(
            (task) => task.estate_id === estate.id
          )}
          {@const readinessPercent = calculateCompletionPercentage(clientTasks)}
          {@const lastLogin = timeAgo(user.last_sign_in_at)}
          <tr>
            <td class="w-[33%] max-md:!text-[16px]">
              {user.first_name}
              {user.last_name}
              <span class="table-meta max-md:!hidden">{user.email}</span>
              <span class="mt-[13px] flex items-center gap-[10px] md:hidden">
                <ReadinessMeter
                  percent={readinessPercent}
                  thickness={15}
                  class="max-w-[150px]"
                />
                <span class="text-body/x-small"
                  >{floatString(readinessPercent, 0)}%</span
                >
              </span>
            </td>
            <td class="w-[33%] !align-middle max-md:hidden">
              <span class="flex items-center gap-[10px]">
                <ReadinessMeter
                  percent={readinessPercent}
                  thickness={15}
                  class="max-w-[150px]"
                />
                <span class="text-body/x-small"
                  >{floatString(readinessPercent, 0)}%</span
                >
              </span>
            </td>
            <td class="w-[24%] !align-middle max-md:hidden">{lastLogin}</td>
            <td class="text-right !align-middle">
              <button
                class="btn-main mobile-icon-button btn-sm"
                on:click={() => {
                  $userPermissions = null; //! important: prevents reactive routing from detecting "pending client selection" permissions
                  $currentEstate = estate;
                }}
              >
                <span>Access</span>
                <span class="btn-access"><AccessIcon /></span>
              </button>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{:else if clients.length}
  <div
    class="flex flex-col items-center justify-center border-t border-ui/border pt-[100px] text-body/large"
  >
    <h2 class="mb-[30px] font-normal text-text/medium">
      No clients found for those search terms.
    </h2>
  </div>
{:else}
  <div
    class="flex flex-col items-center justify-center pt-[100px] text-body/large"
  >
    <h2 class="font-normal text-text/medium">
      You don't have any clients yet.
    </h2>
  </div>
{/if}

<style>
  .search-icon :global(svg) {
    width: 18px;
    height: 18px;
  }
  .btn-access :global(svg) {
    width: 24px;
  }
</style>
