import { supabase } from "../../supabaseClient";
import { createDynamicTask } from "../readiness/readiness";
import { getAssetDisplayName } from "./assets";

async function fetchAsset(assetId: number) {
  const { data } = await supabase
    .from("asset")
    .select(
      `
        id,
        distribution_method,
        financial_account (institution_name, account_number, label, current_value),
        real_estate (address, estimated_value),
        property (description, unique_id, estimated_value),
        loan_asset (debtor, account_number, current_balance),
        insurance (insurer, insured, policy_limit),
        inheritance (description, asset_type, estimated_value),
        legal_entity (name, role, value),
        vehicle (year, model, estimated_value)
      `
    )
    .eq("id", assetId)
    .maybeSingle();

  return data;
}

export async function createTaskToSpecifyDistribution(assetId: number) {
  const asset = await fetchAsset(assetId);
  if (!asset) return;

  const assetName = getAssetDisplayName(asset);
  createDynamicTask({
    category: "assets",
    name: `Specify distribution method for ${assetName}`,
    complete: false,
    description: "How will this asset be distributed to beneficiaries?",
    repeatable: false,
    repeatable_on_table: null,
    repeatable_on_type: null,
    repetitions: null,
    action_label: "Specify",
    action_hook: {
      openModal: "editDistribution",
      assetId,
    },
    dismiss_label: null,
    completion_type: "trigger",
  });
}

export async function createTaskToSpecifyBeneficiaries(assetId: number) {
  const asset = await fetchAsset(assetId);
  if (!asset) return;

  const assetName = getAssetDisplayName(asset);
  createDynamicTask({
    category: "assets",
    name: `Specify beneficiaries for ${assetName}`,
    complete: false,
    description: "Which beneficiaries will receive this asset?",
    repeatable: false,
    repeatable_on_table: null,
    repeatable_on_type: null,
    repetitions: null,
    action_label: "Specify",
    action_hook: {
      openModal: "editDistribution",
      assetId,
    },
    dismiss_label: null,
    completion_type: "trigger",
  });
}
