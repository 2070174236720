<script lang="ts">
  import ChevronDown from "./icons/ChevronDown.svelte";
  import { Accordion, AccordionItem } from "@skeletonlabs/skeleton";

  let className = "";
  export { className as class };

  let collapsed = false;

  function toggleCollapsed() {
    collapsed = !collapsed;
  }
</script>

<div class="table-container {className}">
  <Accordion
    caretOpen="-scale-y-100"
    padding="p-0"
    regionCaret="hidden"
    hover="bg-inherit"
    rounded="rounded-none"
  >
    <AccordionItem open on:toggle={toggleCollapsed}>
      <svelte:fragment slot="summary">
        <div
          class="mobile-list-header flex items-center justify-between rounded-tl-[5px] rounded-tr-[5px] border-b border-ui/border bg-bg/light px-[15px] py-[10px] hover:opacity-70"
        >
          <span
            class="mobile-list-header-text flex items-center justify-start gap-[10px] text-table/title"
          >
            <slot name="titleBar" />
          </span>
          <button
            class="mobile-list-header-toggle relative -right-2 p-2 transition hover:opacity-70 active:scale-90"
            class:-scale-y-100={collapsed}
          >
            <ChevronDown />
          </button>
        </div>
      </svelte:fragment>
      <svelte:fragment slot="content">
        <table
          class="table origin-top !rounded-t-none"
          class:-scale-y-0={collapsed}
        >
          <thead class="!bg-bg/lightest">
            <slot name="thead" />
          </thead>

          <tbody>
            <slot name="tbody" />
          </tbody>
        </table>
      </svelte:fragment>
    </AccordionItem>
  </Accordion>
</div>
