import { modalStore } from "@skeletonlabs/skeleton";
import { navigate } from "svelte-navigator";

/**
 * Navigate back to the origin page where a modal has been launched across pages
 * via history state
 * ! (Requires setting a "returnTo" route on the state object)
 *
 * OR
 * Clear the history state from said modal
 */
let initial = true;
modalStore.subscribe((val) => {
  //? when no value, this works essentially as something like an "onClose" event
  if (!val.length) {
    //? except that it would fire on page load as the modalStore is initialized
    if (initial) {
      initial = false;
      return;
    }

    // ? so with that case out of the way, here is our "onClose callback"
    if (history.state?.returnTo) {
      navigate(history.state.returnTo);
    } else {
      history.replaceState({}, "");
    }
  }
});

/**
 * Make sure the current modal closes when using the back/fwd button
 * (unless explicitly controlled via state obj "openModal" key)
 */
addEventListener("popstate", (e) => {
  if (!e.state?.openModal) modalStore.close();
});
