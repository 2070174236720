<script lang="ts">
  import ArrowLeftIcon from "./icons/ArrowLeft.svelte";
  import CrossIcon from "./icons/Cross.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let mobileExit: "close" | "back" | "hide" = "close";
</script>

<div
  class="mobile-modal flex min-h-[100px] overflow-hidden rounded-lg bg-white"
>
  <div
    class="bg-gradient-left text-center text-text/light md:w-[80px] md:border-r md:border-ui/border md:py-[25px]"
  >
    <button
      class="mobile-{mobileExit}-button modal-close hover:opacity-70 active:scale-90"
      tabindex="-1"
      on:click={modalStore.close}
    >
      {#if mobileExit === "back"}
        <span class="md:hidden">
          <ArrowLeftIcon />
        </span>
        <span class="max-md:hidden">
          <CrossIcon />
        </span>
      {:else if mobileExit === "hide"}
        <span class="max-md:hidden">
          <CrossIcon />
        </span>
      {:else}
        <CrossIcon />
      {/if}
    </button>
  </div>
  <div class="max-h-[calc(100vh-10px)] overflow-y-auto overflow-x-hidden">
    <slot />
  </div>
</div>

<style lang="postcss">
  .modal-close :global svg {
    @apply h-[50px] w-[50px];
  }

  @media (max-width: 768px) {
    .mobile-modal {
      @apply fixed bottom-0 left-0 right-0 top-0 rounded-none;
    }
  }
</style>
