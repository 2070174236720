<script lang="ts">
  import { popErrorToast } from "../util/toasts";
  import Cross from "./icons/CrossClip.svelte";
  import { uniqueId } from "lodash";

  // If needed, max size and file type can be passed as props

  export let id = uniqueId("file-upload-");
  export let file: File;
  export let removable = false;
  export let labelVisible = true;

  const MAX_SIZE = 50; // MB

  function validate() {
    const fileSize = file.size / 1024 / 1024; // Convert to MB

    if (fileSize > MAX_SIZE) {
      popErrorToast(`File size exceeds the limit of ${MAX_SIZE}MB.`);
      file = undefined;
    }
  }

  function handleChange(event) {
    file = event.target.files?.[0];

    validate();
  }
</script>

<div class="flex items-center gap-4">
  {#if removable && file}
    <div
      class="flex items-center gap-[15px] rounded-[6px] border border-ui/border-dark bg-bg/lightest pb-[13px] pl-[15px] pr-[13px] pt-[12px] shadow"
    >
      {file?.name}

      <button
        type="button"
        class="remove-btn rounded-[4px] border border-state/negative bg-white p-[5px] text-state/negative shadow-heavy transition hover:bg-state/negative hover:text-white"
        on:click={() => (file = undefined)}
      >
        <Cross />
      </button>
    </div>
  {:else}
    <label
      class="variant-ringed-primary btn m-0 flex cursor-pointer gap-[5px] bg-white text-state/link"
    >
      Choose File...

      <input
        {id}
        type="file"
        accept=".pdf,.jpg,.png,.tiff"
        class="hidden"
        on:change={handleChange}
      />
    </label>
  {/if}

  {#if labelVisible}
    <span class="text-lg text-text/medium">
      {file ? file.name : "No file selected"}
    </span>
  {/if}
</div>

<style>
  .remove-btn :global(svg) {
    width: 12px;
    height: 12px;
  }
</style>
