<script lang="ts">
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { accountTypes } from "./assets";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { number, object, string } from "yup";

  export let multipartFormData;
  export let selectedAssetType;
  const goBack: () => void = getContext("back");
  const goForward: () => void = getContext("forward");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        label: $multipartFormData.label,
      },
      validationSchema: object().shape({
        label: string().required("Required"),
      }),
      onSubmit: (formData) => {
        $multipartFormData.label = formData.label;
        goForward();
      },
    }
  );
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title pb-[30px]">
      Add <span class="capitalize">{selectedAssetType}</span> Account
    </h1>
    <p class="m-0 p-0 text-body/large max-md:hidden">
      Since you didn’t provide us with an account number, please provide a
      short, unique description that can be used to identify this account in
      lists.
    </p>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      Since you didn’t provide us with an account number, please provide a
      short, unique description that can be used to identify this account in
      lists.
    </p>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <label for="label">
          Descriptive Label <span class="text-state/negative">*</span>
        </label>

        <input
          name="label"
          id="label"
          type="text"
          bind:value={$form.label}
          on:change={handleChange}
        />

        {#if $errors.label}
          <small class="input-error"><WarnIcon /> {$errors.label}</small>
        {/if}
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button btn-main"
      on:click|preventDefault={goBack}
    >
      <ArrowLeftIcon />
      <span>Back</span>
    </button>
    <button class="btn-filled btn-lg" type="submit" disabled={$isSubmitting}>
      <span>Continue</span>
      <ArrowRightIcon />
    </button>
  </div>
</form>
