<script lang="ts">
  import type { OnboardingData } from "../../types/Onboarding";
  import DateInput from "../DateInput.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CalendarIcon from "../icons/Calendar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { imask } from "@imask/svelte";
  import { popup } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import { date, object, string } from "yup";

  export let data: OnboardingData;

  export let goBack: () => void;
  export let goForward: () => void;

  let submitClicked = false;

  let ssn = data.ssn;

  const handleAccept = ({ detail: { _unmaskedValue } }) =>
    updateValidateField("ssn", _unmaskedValue);

  const { form, errors, handleChange, handleSubmit, updateValidateField } =
    createForm({
      initialValues: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        ssn,
      },
      validationSchema: object().shape({
        firstName: string().required("Required"),
        lastName: string().required("Required"),
        dateOfBirth: date()
          .typeError("Must be a valid date")
          .required("Required"),
        ssn: string().length(9, "Must be a valid SSN").nullable(),
      }),
      onSubmit: (formData) => {
        data = {
          ...data,
          ...formData,
        };

        goForward();
      },
    });
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
    <p
      class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
    >
      Next, tell us a little bit about yourself.
    </p>

    <div class="flex flex-col gap-x-[40px] gap-y-[40px] md:grid md:grid-cols-2">
      <div class="input-wrap">
        <label for="firstName">
          First Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="firstName"
          bind:value={$form.firstName}
          on:change={handleChange}
        />

        {#if $errors.firstName && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.firstName}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="lastName">
          Last Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="lastName"
          bind:value={$form.lastName}
          on:change={handleChange}
        />

        {#if $errors.lastName && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.lastName}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="dateOfBirth">
          Date of Birth <span class="text-state/negative">*</span>
        </label>
        <DateInput bind:value={$form.dateOfBirth} />

        {#if $errors.dateOfBirth && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.dateOfBirth}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="ssn"> Social Security Number </label>
        <input
          type="text"
          id="ssn"
          value={ssn}
          use:imask={{ mask: "000-00-0000" }}
          on:accept={handleAccept}
        />

        {#if $errors.ssn && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.ssn}</small>
        {/if}

        <div class="input-hint">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="cursor-help [&>*]:pointer-events-none"
            use:popup={{
              event: "hover",
              target: `ssn-tooltip`,
              placement: "bottom",
              middleware: {
                offset: {
                  mainAxis: 10,
                  crossAxis: 100,
                },
              },
            }}>Why is this needed?</a
          >
        </div>

        <div
          class="rounded-[6px] border border-black bg-text/dark px-[14px] py-[12px] text-white shadow-heavy"
          data-popup="ssn-tooltip"
        >
          <p class="max-w-[300px] text-body/small leading-[24px]">
            Your social security number is vital for both identity verification
            and accessing information from the credit reporting agencies. This
            action will will not affect your credit score and will only be used
            to populate certain assets and liabilities within the the LegaSEE
            platform.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg flex gap-[10px]"
      on:click={() => (submitClicked = true)}
    >
      Next Step

      <ArrowRightIcon />
    </button>
  </div>
</form>
