<script lang="ts">
  import ContextMenuItem from "./ContextMenuItem.svelte";
  import ThreeDotsIcon from "./icons/ThreeDots.svelte";
  import { popup, type PopupSettings } from "@skeletonlabs/skeleton";
  import { uniqueId } from "lodash";

  type Item = {
    text: string;
    icon: any;
    color?: string;
    onClick: () => void;
  };

  export let items: Item[];
  export let classes: string = "hover:opacity-70";
  export let placement: PopupSettings["placement"] = "left-start";
  export let offsetMain: number = -35;
  export let offsetCross: number = 0;
  export let menuClasses: string = "";

  const id = uniqueId();

  const settings: PopupSettings = {
    event: "click",
    target: id,
    placement: placement,
    middleware: {
      offset: {
        mainAxis: offsetMain,
        crossAxis: offsetCross,
      },
    },
  };
</script>

<button type="button" class={classes} use:popup={settings}>
  <slot>
    <span class="overflow-icon"><ThreeDotsIcon /></span>
  </slot>
</button>

<div
  class="{menuClasses} relative z-50 mt-[-7px] min-w-[176px] rounded-[6px] border border-ui/border bg-white py-[5px] shadow-heavy"
  data-popup={id}
>
  {#each items as { text, icon, color, onClick }}
    <ContextMenuItem {icon} {color} on:click={onClick}>
      {text}
    </ContextMenuItem>
  {/each}
</div>

<style type="text/css">
  .overflow-icon :global(svg) {
    color: #848b9f;
  }
</style>
