<script lang="ts">
  import type { OnboardingCharityBeneficiary } from "../../types/Onboarding";
  import { states } from "../../util/place";
  import { charityLegalStructures } from "../beneficiaries/beneficiaries";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { imask } from "@imask/svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let beneficiary: OnboardingCharityBeneficiary;

  export let goBack: () => void;
  export let goForward: () => void;

  let submitClicked = false;
  let detailsVisible = false;

  let ein = beneficiary.ein;

  const handleAccept = ({ detail: { _unmaskedValue } }) =>
    updateValidateField("ein", _unmaskedValue);

  const { form, errors, handleChange, handleSubmit, updateValidateField } =
    createForm({
      initialValues: {
        name: beneficiary.name,
        state: beneficiary.state,
        legalStructure: beneficiary.legalStructure,
        ein,
        email: beneficiary.email,
      },
      validationSchema: object().shape({
        name: string().required("Required"),
        state: string().nullable(),
        legalStructure: string().nullable(),
        ein: string().length(9, "Must be a valid EIN").nullable(),
        email: string().email().nullable(),
      }),
      onSubmit: (formData) => {
        beneficiary = {
          ...formData,
          type: "charity",
        };

        goForward();
      },
    });
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
    <p
      class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
    >
      Tell us about the charity you would like to add as a beneficiary.
    </p>

    <div class="flex flex-col gap-x-[40px] gap-y-[40px] md:grid md:grid-cols-2">
      <div class="input-wrap col-span-2">
        <label for="name">
          Charity Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="name"
          bind:value={$form.name}
          on:change={handleChange}
        />

        {#if $errors.name && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.name}</small>
        {/if}
      </div>

      {#if detailsVisible}
        <div class="input-wrap">
          <label for="state"> State of Formation </label>

          <select id="state" bind:value={$form.state} on:change={handleChange}>
            <option value="">Choose...</option>
            {#each Object.entries(states) as [abbreviation, name]}
              <option value={abbreviation}>{name}</option>
            {/each}
          </select>

          {#if $errors.state && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.state}</small>
          {/if}
        </div>

        <div class="input-wrap">
          <label for="legalStructure"> Legal Structure </label>

          <select
            id="legalStructure"
            bind:value={$form.legalStructure}
            on:change={handleChange}
          >
            <option value="">Choose...</option>

            {#each charityLegalStructures as legalStructure}
              <option value={legalStructure}>{legalStructure}</option>
            {/each}
          </select>

          {#if $errors.legalStructure && submitClicked}
            <small class="input-error"
              ><WarnIcon /> {$errors.legalStructure}</small
            >
          {/if}
        </div>

        <div class="input-wrap">
          <label for="ein"> EIN </label>

          <input
            type="text"
            id="ein"
            value={ein}
            use:imask={{ mask: "00-0000000" }}
            on:accept={handleAccept}
          />

          {#if $errors.ein && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.ein}</small>
          {/if}
        </div>

        <div class="input-wrap">
          <label for="email"> Contact Email Address </label>

          <input
            type="text"
            id="email"
            bind:value={$form.email}
            on:change={handleChange}
          />

          {#if $errors.email && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.email}</small>
          {/if}
        </div>
      {:else}
        <button
          type="button"
          class="col-span-2 text-left text-body/medium text-text/medium underline transition hover:text-state/link"
          on:click={() => (detailsVisible = !detailsVisible)}
        >
          Enter additional details...
        </button>
      {/if}
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg flex gap-[10px]"
      on:click={() => (submitClicked = true)}
    >
      Continue

      <ArrowRightIcon />
    </button>
  </div>
</form>
