<script>
  import { capitalize, startCase } from "lodash";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { date, number, object, string } from "yup";
  import { accountTypes } from "./assets";

  export let multipartFormData;
  const goForward = getContext("forward");
  const goBack = getContext("back");

  const { form, handleSubmit } = createForm({
    initialValues: {
      assetType: $multipartFormData.financial_account_type,
      accountType: $multipartFormData.financial_account_subtype,
    },
    validationSchema: object().shape({
      assetType: string().required("Required"),
      accountType: string().required("Required"),
    }),
    onSubmit: (formData) => {
      $multipartFormData.financial_account_type = formData.assetType;
      $multipartFormData.financial_account_subtype = formData.accountType;
      goForward();
    },
  });

  function assetChanged() {
    $form.accountType = undefined;
  }
</script>

<form class="mobile-content" on:submit|preventDefault={handleSubmit}>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1
      class="mobile-header-title items-center justify-start gap-[15px] pb-[30px] md:flex"
    >
      <span class="header-icon text-state/positive max-md:hidden">
        <CheckIcon />
      </span>
      Account Connected
    </h1>
    <p class="text-body/large max-md:hidden">
      We were able to successfully connect your account at {$multipartFormData.institution_name}.
      Please confirm the categorization for the account below before continuing.
    </p>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      We were able to successfully connect your account at {$multipartFormData.institution_name}.
      Please confirm the categorization for the account below before continuing.
    </p>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <span class="form-label">
          Asset Type <span class="text-state/negative">*</span>
        </span>

        <div
          class="flex justify-start gap-[30px] max-md:flex-col max-md:gap-[14px] md:items-center"
        >
          <label class="input-option">
            <input
              type="radio"
              id="banking"
              name="assetType"
              value="banking"
              on:change={assetChanged}
              bind:group={$form.assetType}
            />
            Banking
          </label>
          <label class="input-option">
            <input
              type="radio"
              id="investment"
              name="assetType"
              value="investment"
              on:change={assetChanged}
              bind:group={$form.assetType}
            />
            Investment
          </label>
          <label class="input-option">
            <input
              type="radio"
              id="retirement"
              name="assetType"
              value="retirement"
              on:change={assetChanged}
              bind:group={$form.assetType}
            />
            Retirement
          </label>
        </div>
      </div>

      <div class="input-wrap col-span-full">
        <div class="form-label">
          {capitalize($form.assetType)} Account Type
          <span class="text-state/negative">*</span>
        </div>

        <select bind:value={$form.accountType} class="w-full">
          {#each accountTypes[$form.assetType] as type}
            <option value={type}>{startCase(type)}</option>
          {/each}
        </select>
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      class="mobile-back-button btn-main"
      on:click|preventDefault={goBack}
    >
      <ArrowLeftIcon />
      Back
    </button>
    <button class="btn-filled btn-lg" type="submit">
      Continue <ArrowRightIcon /></button
    >
  </div>
</form>

<style type="text/css">
  .header-icon :global(svg) {
    width: 28px;
    height: 18px;
  }
</style>
