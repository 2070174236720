import App from "./App.svelte";
import "./assets/styles/app.postcss";
import "./util/modal";
import "./util/realtime-messages";

if (import.meta.env.MODE === "production") {
  (async () => {
    const Sentry = await import("@sentry/svelte");

    Sentry.init({
      dsn: "https://0c19c0ab57b87ce5afb514d625a23647@o4505643171708928.ingest.sentry.io/4505643174264832",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost", "https://legasee.cloud"],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  })();
}

const app = new App({
  target: document.getElementById("app"),
});

export default app;
