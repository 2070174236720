<script lang="ts">
  import {
    LegalEntityRoles,
    convertCentsToDollarString,
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money.js";
  import { states } from "../../util/place.js";
  import { handleDollarInput } from "../../util/validation.ts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { number, object, string } from "yup";

  export let multipartFormData;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        entityName: $multipartFormData.name,
        value: convertCentsToInputDollars($multipartFormData.value),
        stateRegistered: $multipartFormData.state_registered,
        role: $multipartFormData.role,
        committedCapital: convertCentsToInputDollars(
          $multipartFormData.committed_capital
        ),
        uncalledCapital: convertCentsToInputDollars(
          $multipartFormData.uncalled_capital
        ),
        primaryContact: $multipartFormData.primary_contact,
      },
      validationSchema: object().shape({
        entityName: string().required("Required"),
        value: string().required("Required"),
        stateRegistered: string().nullable(),
        role: string().nullable(),
        committedCapital: string().nullable(),
        uncalledCapital: string().nullable(),
        primaryContact: string().nullable(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.name = formData.entityName;
        $multipartFormData.value = convertDollarsStringToCents(formData.value);
        $multipartFormData.state_registered = formData.stateRegistered;
        $multipartFormData.role = formData.role;
        $multipartFormData.committed_capital = convertDollarsStringToCents(
          formData.committedCapital
        );
        $multipartFormData.uncalled_capital = convertDollarsStringToCents(
          formData.uncalledCapital
        );
        $multipartFormData.primary_contact = formData.primaryContact;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );
</script>

<form
  class="mobile-content {!!onSubmitted ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if !!onSubmitted}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Asset</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {$multipartFormData.name}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">Add Legal Entity</h1>
    </div>
  {/if}
  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="entityName">
          Entity Name <span class="text-state/negative">*</span>
        </label>

        <input
          name="entityName"
          id="entityName"
          type="text"
          bind:value={$form.entityName}
          on:change={handleChange}
        />

        {#if $errors.entityName}
          <small class="input-error"><WarnIcon /> {$errors.entityName}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="value">
          Value <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="value"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            name="value"
            id="value"
            class="!pl-0"
            bind:value={$form.value}
            on:input={(e) => handleDollarInput(e, form, "value")}
          />
        </div>

        {#if $errors.value}
          <small class="input-error"><WarnIcon /> {$errors.value}</small>
        {/if}
      </div>
    </div>
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] border-ui/border-light pt-[35px] max-md:gap-y-[35px] md:gap-y-[45px] md:border-t md:pr-[50px] md:pt-[45px]"
    >
      <div class="input-wrap">
        <label for="stateRegistered"> State Registered </label>

        <select
          name="stateRegistered"
          id="stateRegistered"
          bind:value={$form.stateRegistered}
          on:change={handleChange}
          required
        >
          <option value="" disabled selected>Choose...</option>
          {#each Object.entries(states) as [abbr, state]}
            <option value={abbr}>{state}</option>
          {/each}
        </select>
      </div>

      <div class="input-wrap">
        <label for="role"> Role </label>

        <select
          name="role"
          id="role"
          bind:value={$form.role}
          on:change={handleChange}
          required
        >
          <option value="" disabled selected>Choose...</option>
          {#each LegalEntityRoles as role}
            <option value={role}>{role}</option>
          {/each}
        </select>
      </div>

      <div class="input-wrap">
        <label for="committedCapital"> Committed Capital </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="committedCapital"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            name="committedCapital"
            id="committedCapital"
            class="!pl-0"
            bind:value={$form.committedCapital}
            on:input={(e) => handleDollarInput(e, form, "committedCapital")}
          />
        </div>
      </div>

      <div class="input-wrap">
        <label for="uncalledCapital"> Uncalled Capital </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="uncalledCapital"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            name="uncalledCapital"
            id="uncalledCapital"
            class="!pl-0"
            bind:value={$form.uncalledCapital}
            on:input={(e) => handleDollarInput(e, form, "uncalledCapital")}
          />
        </div>
      </div>

      <div class="col-span-full">
        <label for="primaryContact"> Primary Contact </label>

        <input
          name="primaryContact"
          id="primaryContact"
          type="text"
          bind:value={$form.primaryContact}
          on:change={handleChange}
        />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="btn-main mobile-back-button"
      on:click|preventDefault={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
