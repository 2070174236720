<script lang="ts">
  import type { ClientEstate } from "../../pages/AdvisorClients.svelte";
  import { supabase } from "../../supabaseClient";
  import { timeAgo } from "../../util/format";
  import { popSuccessToast, popErrorToast } from "../../util/toasts";
  import ContextMenu from "../ContextMenu.svelte";
  import CancelIcon from "../icons/Cancel.svelte";
  import SendIcon from "../icons/Send.svelte";
  import { orderBy } from "lodash";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let clients: ClientEstate[] = [];

  $: sortedClients = orderBy(
    clients,
    (client) => new Date(client.user.created_at).getTime(),
    "desc"
  );

  async function resendOrCancelInvite(
    email: string,
    action: "resend" | "cancel"
  ) {
    try {
      const { data, error } = await supabase.functions.invoke(
        `invite-client-${action}`,
        {
          body: {
            email,
          },
        }
      );
      if (error ?? data.error) throw new Error(error ?? data.error);

      popSuccessToast(
        `Success!\n Invitation for ${email} has been ${
          action === "resend" ? "re-sent" : "cancelled"
        }.`
      );
      if (action === "cancel") {
        // notify parent of cancellation (parent will re-fetch clients)
        dispatch("cancel");
      }
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    }
  }
</script>

{#if sortedClients.length}
  <div class="table-container">
    <table class="table max-md:border-t">
      <thead class="!bg-bg/lighter">
        <th class="rounded-tl-[5px]">Name</th>
        <th colspan="2" class="rounded-tr-[5px]">Invite Sent</th>
      </thead>
      <tbody>
        {#each sortedClients as { user }}
          {@const lastLogin = timeAgo(user.created_at)}
          <tr>
            <td class="w-[33%]">
              {user.first_name}
              {user.last_name}
              <span class="table-meta">{user.email}</span>
            </td>
            <td class="w-[24%] !align-middle max-md:hidden">{lastLogin}</td>
            <td class="text-right !align-middle max-md:!pr-[10px]">
              <ContextMenu
                items={[
                  {
                    text: "Resend Invite",
                    icon: SendIcon,
                    color: "text-text/dark",
                    onClick: () => resendOrCancelInvite(user.email, "resend"),
                  },
                  {
                    text: "Cancel Invite",
                    icon: CancelIcon,
                    color: "text-state/negative",
                    onClick: () => resendOrCancelInvite(user.email, "cancel"),
                  },
                ]}
              />
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{:else}
  <p>No active invitations.</p>
{/if}
