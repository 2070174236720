<script lang="ts" context="module">
  export type PersonFormData = {
    first_name: string;
    last_name: string;
    relationship: string;
    email: string;
    dob: string;
    ssn: string;

    inviteUser?: boolean;
  };
</script>

<script lang="ts">
  import { userStore } from "../../stores/user";
  import DateInput from "../DateInput.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import TooltipIcon from "../icons/Tooltip.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { beneficiaryRelationships } from "./beneficiaries";
  import { imask } from "@imask/svelte";
  import { ProgressRadial, popup } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { fade, slide } from "svelte/transition";
  import { object, string, date, boolean } from "yup";

  export let person: PersonFormData = null;
  export let loading: boolean;
  export let isUpdate = false;

  const dispatch = createEventDispatcher();

  let submitClicked = false;

  let ssn = person?.ssn;
  const handleSSN = ({ detail: { _unmaskedValue } }) =>
    updateValidateField("ssn", _unmaskedValue);

  const { form, errors, handleChange, handleSubmit, updateValidateField } =
    createForm({
      initialValues: {
        first_name: person?.first_name ?? "",
        last_name: person?.last_name ?? "",
        relationship: person?.relationship ?? "",
        email: person?.email ?? "",
        dob: person?.dob ?? null,
        ssn: person?.ssn ?? "",
        inviteUser: person?.inviteUser ?? false,
      },
      validationSchema: object().shape({
        first_name: string().required("Required"),
        last_name: string().required("Required"),
        relationship: string().required("Required"),
        email: string()
          .email("Invalid email")
          .nullable()
          .when("inviteUser", {
            is: true,
            then: () => string().email("Invalid email").required("Required"),
          }),
        dob: date().typeError("Must be a valid date").nullable(),
        ssn: string().test("use-ssn", "Must be a valid SSN", (ssn: string) =>
          [0, 9].includes(ssn.length)
        ),
        inviteUser: boolean(),
      }),
      onSubmit: (formData) => {
        // There's a bug in svelte-forms-lib where it creates a field with a key `undefined`.
        dispatch("submit", {
          first_name: formData.first_name,
          last_name: formData.last_name,
          relationship: formData.relationship,
          email: formData.email,
          dob: formData.dob,
          ssn: formData.ssn,
          inviteUser: formData.inviteUser,
        });
      },
    });
</script>

<form
  class="mobile-content {isUpdate ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  <div
    class="mobile-header mb-[30px] px-[50px] pt-[30px] {isUpdate
      ? 'max-md:block max-md:!h-[85px]'
      : ''}"
  >
    <h1 class="mobile-header-title-fixed{isUpdate ? ' max-md:!text-left' : ''}">
      {#if isUpdate}
        Edit Beneficiary Details
      {:else}
        Add <ins class="max-md:hidden">a </ins>New Beneficiary
      {/if}
    </h1>
    {#if isUpdate}
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {person.first_name}
        {person.last_name}
      </p>
    {/if}
  </div>

  <div
    class="mobile-content-body border-ui/border-light py-[50px] pb-0 pl-[50px] md:border-b md:border-t md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pb-[45px] max-md:gap-y-[35px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="first_name">
          First Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="first_name"
          bind:value={$form.first_name}
          on:change={handleChange}
        />

        {#if $errors.first_name && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.first_name}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="last_name">
          Last Name <span class="text-state/negative">*</span>
        </label>
        <input
          type="text"
          id="last_name"
          bind:value={$form.last_name}
          on:change={handleChange}
        />
        {#if $errors.last_name && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.last_name}</small>
        {/if}
      </div>

      <div class="input-wrap col-span-full">
        <label for="relationship">
          Relationship <span class="text-state/negative">*</span>
        </label>

        <select
          id="relationship"
          bind:value={$form.relationship}
          on:change={handleChange}
          required
        >
          <option selected disabled value="">Choose...</option>
          {#each beneficiaryRelationships as relationship}
            <option value={relationship}>{relationship}</option>
          {/each}
        </select>

        {#if $errors.relationship && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.relationship}</small>
        {/if}
      </div>

      <div class="input-wrap col-span-full">
        {#if $userStore.user_type === "owner" && !isUpdate}
          <label class="input-option flex justify-start gap-[10px] pb-[40px]">
            <input
              class="mt-[2px]"
              type="checkbox"
              bind:checked={$form.inviteUser}
              on:change={handleChange}
            />

            <div class="!mt-0 flex flex-col gap-2 max-md:!mt-[3px]">
              <span class="!mt-[-3px] max-w-[484px] max-md:!mt-[-6px]">
                Invite this beneficiary to collaborate <span
                  class="relative top-[5px] ml-[5px] inline-block cursor-help text-state/link transition hover:opacity-70 [&>*]:pointer-events-none"
                  use:popup={{
                    event: "hover",
                    target: `perm-tooltip`,
                    placement: "left-start",
                    middleware: {
                      offset: {
                        mainAxis: -25,
                        crossAxis: 35,
                      },
                    },
                  }}
                >
                  <TooltipIcon />
                </span>
              </span>
              <div
                class="rounded-[6px] border border-black bg-text/dark px-[14px] py-[12px] text-white shadow-heavy"
                data-popup="perm-tooltip"
              >
                <p class="max-w-[300px] text-body/small leading-[24px]">
                  This option grants the beneficiary full or limited access to
                  your account based on the permissions you define, and adds
                  them to the collaborators page.
                </p>
              </div>

              <span
                class="text-base font-normal text-text/medium max-md:text-[14px] max-md:text-text/dark"
              >
                You will specify permissions in the next step
              </span>
            </div>
          </label>
        {/if}

        {#if !$form.inviteUser}
          <hr
            class="mb-[45px] w-[calc(100%+50px)] max-md:hidden"
            transition:slide|local={{ duration: 250 }}
          />
        {/if}

        <div class="input-wrap">
          <label for="email">
            Email Address

            {#if $form.inviteUser}
              <span
                class="text-state/negative"
                transition:fade|local={{ duration: 200 }}>*</span
              >
            {/if}
          </label>

          <input
            type="email"
            id="email"
            bind:value={$form.email}
            on:change={handleChange}
          />

          {#if $errors.email && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.email}</small>
          {/if}

          {#if $form.inviteUser}
            <div class="input-hint">
              This is where we will send the invite to join your account
            </div>
          {/if}
        </div>

        {#if $form.inviteUser}
          <hr
            class="mt-[45px] max-md:hidden"
            transition:slide|local={{ duration: 250 }}
          />
        {/if}
      </div>

      <!-- svelte-ignore a11y-label-has-associated-control -->
      <div class="input-wrap">
        <label> Date of Birth </label>

        <DateInput bind:value={$form.dob} />

        {#if $errors.dob && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.dob}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="ssn"> Social Security Number </label>
        <input
          type="text"
          id="ssn"
          value={ssn}
          use:imask={{ mask: "000-00-0000" }}
          on:accept={handleSSN}
        />
        {#if $errors.ssn && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.ssn}</small>
        {/if}
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
      disabled={loading}
      on:click={() => dispatch("cancel")}
    >
      {#if isUpdate}
        <span class="md:hidden">
          <ArrowLeftIcon />
        </span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg flex gap-[10px]"
      class:btn-success={!$form.inviteUser}
      disabled={loading}
      on:click={() => (submitClicked = true)}
    >
      {#if isUpdate}
        {#if loading}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />

          Saving
        {:else}
          <CheckIcon />

          Save Changes
        {/if}
      {:else if loading}
        Adding Beneficiary

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else if $form.inviteUser}
        Continue

        <ArrowRightIcon />
      {:else}
        Add Beneficiary

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
