<script lang="ts">
  import BeneficiariesDash from "../lib/dashboard/BeneficiariesDash.svelte";
  import ExpendituresDash from "../lib/dashboard/ExpendituresDash.svelte";
  import NetWorthDash from "../lib/dashboard/NetWorthDash.svelte";
  import ReadinessDash from "../lib/dashboard/ReadinessDash.svelte";
  import ServicesDash from "../lib/dashboard/ServicesDash.svelte";
  import VaultDash from "../lib/dashboard/VaultDash.svelte";
  import { userCan } from "../stores/user";
  import { supabase } from "../supabaseClient";

  // Whenever the user hits the dashboard page we look to update connected account balances async
  if (userCan("assets_write") && userCan("debts_write")) {
    supabase.functions.invoke("update-connected-balances");
  }
</script>

<h1 class="mobile-header-title-fixed">Estate Readiness</h1>

<div class="mobile-content">
  <div class="mobile-content-body">
    <div class="py-[45px] max-md:pt-[25px]">
      <div class="grid gap-x-[60px] gap-y-10 xl:grid-cols-2">
        <ReadinessDash />
        {#if userCan("assets_read") && userCan("debts_read")}
          <NetWorthDash />
        {/if}
        {#if userCan("assets_read")}
          <ExpendituresDash />
        {/if}
        {#if userCan("services_read")}
          <ServicesDash />
        {/if}
        {#if userCan("beneficiaries_read")}
          <BeneficiariesDash />
        {/if}
        {#if userCan("vault_read")}
          <VaultDash />
        {/if}
      </div>
    </div>
  </div>
</div>
