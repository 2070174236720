<script lang="ts" context="module">
  /**
   * Confettery: The Confetti Factory!
   */
  import { Confetti } from "svelte-confetti";
  import { writable, type Writable } from "svelte/store";

  const duration = 1200,
    amount = 25,
    size = 8,
    fallDistance = "30px";

  type Confetti = {
    loc: {
      x: number;
      y: number;
    };
    id: string;
    markedForDestroy?: boolean;
  };

  export const confettery: Writable<Confetti[]> = writable([]);

  export function popConfettiAt(x: number, y: number) {
    confettery.update((list) => {
      list.push({
        loc: { x, y },
        id: crypto.randomUUID(),
      });
      return list;
    });
  }

  confettery.subscribe((list) => {
    const fresh = list.find((confetti) => !confetti.markedForDestroy) ?? null;

    if (!fresh) return;

    fresh.markedForDestroy = true;

    setTimeout(() => {
      confettery.update((list) => list.filter(({ id }) => id !== fresh.id));
    }, duration);
  });
</script>

{#each $confettery as confetti (confetti.id)}
  <div style="left: {confetti.loc.x}px; top: {confetti.loc.y}px;">
    <Confetti
      y={[-0.5, 0.5]}
      x={[-0.5, 0.5]}
      {amount}
      {duration}
      {size}
      {fallDistance}
    />
  </div>
{/each}

<style>
  div {
    position: fixed;
    z-index: 99;
  }
</style>
