<script lang="ts">
  import type { Debt } from "../../pages/Debts.svelte";
  import Modal from "../Modal.svelte";
  import DeleteDebtForm from "./DeleteDebtForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let debt: Debt;

  function handleDone() {
    $modalStore[0].response(debt.id);
    modalStore.close();
  }
</script>

<Modal mobileExit="back">
  <DeleteDebtForm
    {debt}
    on:cancel={() => modalStore.close()}
    on:done={handleDone}
  />
</Modal>
