<script lang="ts">
  import { helpNotificationHTML } from "../emails/helpNoticiation.ts";
  import SendIcon from "../lib/icons/Send.svelte";
  import WarnIcon from "../lib/icons/WarnFilled.svelte";
  import { supabase } from "../supabaseClient.ts";
  import { popErrorToast, popSuccessToast } from "../util/toasts";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  let submitClicked = false;

  const {
    form,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleReset,
  } = createForm({
    initialValues: {
      subject: null,
      body: null,
      email: null,
    },
    validationSchema: object().shape({
      subject: string().required("Required"),
      body: string().required("Required"),
      email: string().required("Required").email("Must be a valid email"),
    }),
    onSubmit: async ({ subject, body, email }) => {
      const recipients =
        import.meta.env.VITE_SUPPORT_EMAILS?.split(",") ?? "ops@armyofbees.com";
      const response = await supabase.functions.invoke("send-email", {
        body: {
          from: "no-reply@legasee.cloud",
          to: recipients,
          subject: "Help Form Submitted",
          html: helpNotificationHTML(email, subject, body),
        },
      });

      if (response.error) {
        console.error(response.error);
        popErrorToast("Failed to send message");
      } else {
        handleReset();
        scrollTo(0, 0);
        popSuccessToast("Message sent successfully");
      }
    },
  });
</script>

<h1 class="mobile-header-title-fixed mb-[30px]">Contact Us</h1>

<p class="text-body/medium text-text/dark">
  Fill out the form below with your question or concern and our support team
  will reach out to you as soon as possible.
</p>

<hr class="mb-[50px] mt-[30px] max-md:hidden" />

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body">
    <p class="mobile-content-description md:hidden">
      Fill out the form below with your question or concern and our support team
      will reach out to you as soon as possible.
    </p>

    <div class="flex flex-col gap-[50px] md:max-w-[775px]">
      <div class="input-wrap">
        <label for="subject">
          Subject <span class="text-state/negative">*</span>
        </label>

        <input
          id="subject"
          type="text"
          bind:value={$form.subject}
          on:change={handleChange}
        />

        {#if $errors.subject && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.subject}</small>
        {/if}

        <div class="input-hint">
          A short description of your question or concern
        </div>
      </div>

      <div class="input-wrap">
        <label for="body">
          Additional Details <span class="text-state/negative">*</span>
        </label>

        <textarea
          id="body"
          class="h-40"
          bind:value={$form.body}
          on:change={handleChange}
        />

        {#if $errors.body && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.body}</small>
        {/if}

        <div class="input-hint">
          Any additional information we might need to help you
        </div>
      </div>

      <div class="input-wrap">
        <label for="email">
          Contact Email Address <span class="text-state/negative">*</span>
        </label>

        <input
          id="email"
          type="email"
          bind:value={$form.email}
          on:change={handleChange}
        />

        {#if $errors.email && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.email}</small>
        {/if}

        <div class="input-hint">
          The email address you would like us to send our reply to
        </div>
      </div>
    </div>
  </div>

  <div class="mobile-content-footer md:pt-[50px]">
    <button
      type="submit"
      class="btn-filled btn-lg btn-success flex gap-[10px] self-baseline md:mt-[10px]"
      disabled={$isSubmitting}
      on:click={() => (submitClicked = true)}
    >
      <SendIcon />

      {$isSubmitting ? "Sending ..." : "Send Message"}
    </button>
  </div>
</form>
