<script lang="ts">
  import type { OnboardingTrust } from "../../types/Onboarding";
  import { getOrdinalWord } from "../../util/format";
  import FileUpload from "../FileUpload.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let trust: OnboardingTrust;
  export let index: number;

  export let goBack: () => void;
  export let goForward: () => void;

  let submitClicked = false;

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      name: trust.name,
    },
    validationSchema: object().shape({
      name: string().required("Required"),
    }),
    onSubmit: async (formData) => {
      trust = {
        ...formData,
        legalDocument: trust.legalDocument,
      };

      goForward();
    },
  });
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
    <p
      class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
    >
      Please tell us about your {getOrdinalWord(index)} trust.
    </p>

    <div class="grid gap-y-[40px]">
      <div class="input-wrap">
        <label for="name">
          Trust Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="name"
          bind:value={$form.name}
          on:change={handleChange}
        />

        {#if $errors.name && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.name}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label>Legal Document</label>

        <FileUpload bind:file={trust.legalDocument} />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg flex gap-[10px]"
      on:click={() => (submitClicked = true)}
    >
      Continue

      <ArrowRightIcon />
    </button>
  </div>
</form>
