<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import { deleteWillDocuments } from "../../util/storage";
  import { popSuccessToast } from "../../util/toasts";
  import DeleteForm from "../DeleteForm.svelte";
  import Modal from "../Modal.svelte";
  import { createDynamicTask } from "../readiness/readiness";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let hasAssetDesignations: boolean;

  const requirements = [
    "Your will is going to be completely removed from the LegaSEE application.",
    "The legal document you uploaded for your will is going to be deleted.",
    ...(hasAssetDesignations
      ? [
          "All asset designations using your will as the distribution method will have their method updated as “to be determined”.",
        ]
      : []),
  ];

  async function deleteAction() {
    const { error: storageError } = await deleteWillDocuments();

    const { error: dbError } = await supabase
      .from("asset")
      .update({ distribution_method: "tbd" })
      .eq("distribution_method", "will");

    if (dbError || storageError) throw dbError ?? storageError;

    // this task generated on estate creation, but since we're deleting the will
    // here, need to re-create this task
    createDynamicTask({
      category: "vault",
      name: "Upload the legal document for your will",
      complete: false,
      description: null,
      repeatable: false,
      repeatable_on_table: null,
      repeatable_on_type: null,
      repetitions: null,
      action_label: "Upload",
      action_hook: {
        openModal: "uploadWillDoc",
      },
      dismiss_label: "Skip",
      completion_type: "trigger",
    });
  }

  function handleDone() {
    $modalStore[0].response(true);
    modalStore.close();
    popSuccessToast("Your will has been deleted.");
  }
</script>

<Modal mobileExit="back">
  <DeleteForm
    {requirements}
    {deleteAction}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  >
    Delete Your Will
  </DeleteForm>
</Modal>
