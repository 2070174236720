<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import { navigateHome } from "../../util/navigateHome.ts";
  import { popErrorToast } from "../../util/toasts";
  import { passwordValidationSchema } from "../../util/validation.ts";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import EyeIcon from "../icons/Eye.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import { object } from "yup";

  let submitClicked = false;
  let passwordVisible = false;
  let passwordReset = false;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        password: null,
      },
      validationSchema: object().shape({
        password: passwordValidationSchema,
      }),
      onSubmit: async () => {
        await updatePassword();

        passwordReset = true;
      },
    }
  );

  async function updatePassword() {
    try {
      await supabase.auth.updateUser({
        password: $form.password,
      });
    } catch (e) {
      if (e instanceof Error) {
        popErrorToast(e.message);
      }
    }
  }
</script>

<div
  class="flex h-full flex-col items-center justify-center bg-gradient-to-br from-[#3A86FF] to-[#06BA63]"
>
  <div
    class="card w-full max-w-[650px] shadow-onboarding max-md:rounded-none md:max-w-[650px]"
  >
    {#if passwordReset}
      <div class="mobile-content">
        <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
          <div class="mobile-header">
            <h1 class="mobile-header-title mb-[20px] text-text/dark">
              Password Reset
            </h1>
          </div>

          <p
            class="text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
          >
            Your account password was successfully reset. You may now continue
            to the LegaSEE application.
          </p>
        </div>

        <div
          class="mobile-content-footer flex border-t border-ui/border px-[50px] py-[40px]"
        >
          <button
            on:click={navigateHome}
            class="btn-filled btn-lg ml-auto flex gap-[10px]"
          >
            Continue to App

            <ArrowRightIcon />
          </button>
        </div>
      </div>
    {:else}
      <form class="mobile-content" on:submit={handleSubmit} novalidate>
        <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
          <div class="mobile-header">
            <h1 class="mobile-header-title mb-[20px] text-text/dark">
              Set New Password
            </h1>
          </div>

          <p
            class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
          >
            Please enter your desired account password below.
          </p>

          <div class="input-wrap">
            <label for="password">
              New Password <span class="text-state/negative">*</span>
            </label>

            <div class="input-group w-full grid-cols-[auto_50px]">
              <!-- Can't change `type` dynamically  -->
              {#if passwordVisible}
                <input
                  id="password"
                  type="text"
                  placeholder="******"
                  bind:value={$form.password}
                  on:change={handleChange}
                />
              {:else}
                <input
                  id="password"
                  type="password"
                  placeholder="******"
                  bind:value={$form.password}
                  on:change={handleChange}
                />
              {/if}

              <button
                type="button"
                class="justify-self-end text-text/medium"
                on:click={() => (passwordVisible = !passwordVisible)}
              >
                <EyeIcon />
              </button>
            </div>

            {#if $errors.password && submitClicked}
              <small class="input-error"><WarnIcon /> {$errors.password}</small>
            {/if}

            <div class="input-hint">
              <ul class="ml-[18px] mt-[10px] list-disc">
                <li class="mt-[3px]">8 characters minimum</li>
                <li class="mt-[3px]">One lowercase letter</li>
                <li class="mt-[3px]">One uppercase letter</li>
                <li class="mt-[3px]">One special character</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="mobile-content-footer flex border-t border-ui/border px-[50px] py-[40px]"
        >
          <button
            type="submit"
            class="btn-filled btn-lg ml-auto flex gap-[10px]"
            disabled={$isSubmitting}
            on:click={() => (submitClicked = true)}
          >
            {#if $isSubmitting}
              <ProgressRadial
                value={undefined}
                width="w-6"
                meter="stroke-white"
              />
            {:else}
              <CheckIcon />
            {/if}

            Reset Password
          </button>
        </div>
      </form>
    {/if}
  </div>
</div>
