<script lang="ts">
  import { currentEstate } from "../../stores/user.js";
  import { supabase } from "../../supabaseClient";
  import type { CharityBeneficiary, PersonBeneficiary } from "../../types";
  import { states } from "../../util/place.js";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import DateInput from "../DateInput.svelte";
  import { beneficiaryRelationships } from "../beneficiaries/beneficiaries";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CalendarIcon from "../icons/Calendar.svelte";
  import PlusIcon from "../icons/Plus.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { createEventDispatcher, getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import type { Writable } from "svelte/store";
  import { date, object, string } from "yup";

  export let personBeneficiaries: Writable<PersonBeneficiary[]>;
  export let charityBeneficiaries: Writable<CharityBeneficiary[]>;
  const goBack: () => void = getContext("back");

  const dispatch = createEventDispatcher();

  let showAdditionalFields = false;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        beneficiaryType: "person",
        firstName: "",
        lastName: "",
        name: "",
        relationship: "",
        dateOfBirth: "",
        ssn: "",
        stateOfFormation: "",
        legalStructure: "",
        ein: "",
        contactEmail: "",
      },
      validationSchema: object().shape({
        beneficiaryType: string().required("Required"),
        firstName: string().when("beneficiaryType", {
          is: "person",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        lastName: string().when("beneficiaryType", {
          is: "person",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        name: string().when("beneficiaryType", {
          is: "charity",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        relationship: string().when("beneficiaryType", {
          is: "person",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        dateOfBirth: string().nullable(),
        ssn: string().nullable(),
        stateOfFormation: string().nullable(),
        legalStructure: string().nullable(),
        ein: string().nullable(),
        contactEmail: string().email("Invalid email address"),
      }),
      onSubmit: (formData) => {
        const type = formData.beneficiaryType;
        const table =
          type === "person" ? "beneficiary_person" : "beneficiary_charity";
        const insertData =
          type === "person"
            ? {
                estate_id: $currentEstate.id,
                first_name: formData.firstName,
                last_name: formData.lastName,
                relationship: formData.relationship,
                dob: formData.dateOfBirth ? formData.dateOfBirth : null,
                ssn: formData.ssn,
              }
            : {
                estate_id: $currentEstate.id,
                name: formData.name,
                state_of_formation: formData.stateOfFormation,
                legal_structure: formData.legalStructure,
                ein: formData.ein,
                contact_email: formData.contactEmail,
              };
        supabase
          .from(table)
          .insert(insertData)
          .select("*")
          .then(({ data, error }) => {
            if (error) {
              popErrorToast(error.message);
            } else {
              if (type === "person") {
                $personBeneficiaries = [
                  ...$personBeneficiaries,
                  data[0],
                ] as PersonBeneficiary[];
              } else {
                $charityBeneficiaries = [
                  ...$charityBeneficiaries,
                  data[0],
                ] as CharityBeneficiary[];
              }
              goBack();
              dispatch("beneficiaryAdded");
            }
          });
      },
    }
  );
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title pb-[30px]">Add New Beneficiary</h1>
    <p class="m-0 p-0 text-body/large max-md:hidden">
      Tell us about the beneficiary you’d like to add.
    </p>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      Tell us about the beneficiary you’d like to add.
    </p>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <label for="person">
          Beneficiary Type <span class="text-state/negative">*</span>
        </label>

        <div
          class="flex items-start gap-[25px] max-md:flex-col max-md:gap-[12px]"
        >
          <label class="input-option">
            <input
              type="radio"
              id="person"
              name="beneficiaryType"
              value="person"
              bind:group={$form.beneficiaryType}
              on:change={handleChange}
            />
            Person
          </label>
          <label class="input-option">
            <input
              type="radio"
              id="charity"
              name="beneficiaryType"
              value="charity"
              bind:group={$form.beneficiaryType}
              on:change={handleChange}
            />
            Charity
          </label>
        </div>

        {#if $errors.beneficiaryType}
          <small class="input-error"
            ><WarnIcon /> {$errors.beneficiaryType}</small
          >
        {/if}
      </div>

      {#if $form.beneficiaryType === "person"}
        <div class="input-wrap">
          <label for="firstName">
            First Name <span class="text-state/negative">*</span>
          </label>

          <input
            type="text"
            name="firstName"
            id="firstName"
            bind:value={$form.firstName}
            on:change={handleChange}
          />
        </div>

        <div class="input-wrap">
          <label for="lastName">
            Last Name <span class="text-state/negative">*</span>
          </label>

          <input
            type="text"
            name="lastName"
            id="lastName"
            bind:value={$form.lastName}
            on:change={handleChange}
          />
        </div>

        <div class="input-wrap col-span-full">
          <label for="relationship">
            Relationship <span class="text-state/negative">*</span>
          </label>

          <select
            name="relationship"
            id="relationship"
            bind:value={$form.relationship}
            on:change={handleChange}
            required
          >
            <option value="" selected disabled>Choose...</option>
            {#each beneficiaryRelationships as relationship}
              <option value={relationship}>{relationship}</option>
            {/each}
          </select>
        </div>
      {:else}
        <div class="input-wrap col-span-full">
          <label for="name">
            Charity Name <span class="text-state/negative">*</span>
          </label>

          <input
            type="text"
            name="name"
            id="name"
            bind:value={$form.name}
            on:change={handleChange}
          />
        </div>
      {/if}

      {#if !showAdditionalFields}
        <div class="col-span-full">
          <span
            class="cursor-pointer text-body/medium text-text/medium underline transition hover:text-state/link"
            on:click={() => (showAdditionalFields = true)}
            on:keyup={() => (showAdditionalFields = true)}
            >Enter additional details...</span
          >
        </div>
      {/if}
      {#if showAdditionalFields}
        {#if $form.beneficiaryType === "person"}
          <div>
            <label for="dateOfBirth"> Date of Birth </label>
            <DateInput bind:value={$form.dateOfBirth} />
          </div>

          <div>
            <label for="ssn"> Social Security Number </label>

            <input
              type="text"
              name="ssn"
              id="ssn"
              bind:value={$form.ssn}
              on:change={handleChange}
            />
          </div>
        {:else}
          <div>
            <label for="stateOfFormation"> State of Formation </label>

            <select
              name="stateOfFormation"
              id="stateOfFormation"
              bind:value={$form.stateOfFormation}
              on:change={handleChange}
              required
            >
              <option value="" disabled selected>Choose...</option>
              {#each Object.entries(states) as [abbreviation, name]}
                <option value={abbreviation}>{name}</option>
              {/each}
            </select>
          </div>

          <div>
            <label for="legalStructure"> Legal Structure </label>

            <select
              name="legalStructure"
              id="legalStructure"
              bind:value={$form.legalStructure}
              on:change={handleChange}
              required
            >
              <option value="" disabled selected>Choose...</option>
              <option value="llc">LLC</option>
              <option value="llp">LLP</option>
              <option value="lp">LP</option>
              <option value="cCorp">C Corp</option>
              <option value="sCorp">S Corp</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label for="ein"> EIN </label>

            <input
              type="text"
              name="ein"
              id="ein"
              bind:value={$form.ein}
              on:change={handleChange}
            />
          </div>

          <div>
            <label for="ein"> Contact Email </label>

            <input
              type="email"
              name="contactEmail"
              id="contactEmail"
              bind:value={$form.contactEmail}
              on:change={handleChange}
            />
          </div>
        {/if}
      {/if}
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button btn-main"
      on:click|preventDefault={goBack}
    >
      <ArrowLeftIcon />
      <span>Back</span>
    </button>
    <button class="btn-filled btn-lg" type="submit" disabled={$isSubmitting}>
      <span>Continue</span>
      <ArrowRightIcon />
    </button>
  </div>
</form>
