<script lang="ts">
  import Navigation from "../lib/Navigation.svelte";
  import ArrowLeftIcon from "../lib/icons/ArrowLeftFramed.svelte";
  import CloseIcon from "../lib/icons/Cross.svelte";
  import ExpandRightIcon from "../lib/icons/ExpandRight.svelte";
  import HamburgerIcon from "../lib/icons/Hamburger.svelte";
  import TasksSidebar from "../lib/readiness/TasksSidebar.svelte";
  import { tasksSidebarRoutes, showTasks } from "../lib/readiness/readiness";
  import { taskListOpen } from "../lib/readiness/readiness";
  import { userStore } from "../stores/user";
  import { onNavigate } from "../util/routes";
  import { AppShell } from "@skeletonlabs/skeleton";
  import { useLocation } from "svelte-navigator";
  import { quintOut } from "svelte/easing";
  import { fade, fly } from "svelte/transition";

  const location = useLocation();

  /**
   * We have two sidebars, navigation on the left and readiness/tasks on the right.
   * Only one can be expanded at a time.
   */
  let expandedSidebar: "left" | "right" = "left";
  let mobileNavOpen: boolean = false;
  $: sidebarLeftCollapsed = expandedSidebar === "right";
  $: sidebarRightCollapsed = !sidebarLeftCollapsed;
  $: mobileNavCollapsed = !mobileNavOpen;

  function toggleSidebars(): void {
    expandedSidebar = expandedSidebar === "left" ? "right" : "left";
    $taskListOpen = expandedSidebar === "right";
  }
  function toggleMobileNav(): void {
    mobileNavOpen = !mobileNavOpen;
  }

  onNavigate((location) => {
    mobileNavOpen = false;

    if (location.state?.openTasks && sidebarRightCollapsed) {
      toggleSidebars();
    } else if (location.state?.closeTasks && !sidebarRightCollapsed) {
      toggleSidebars();
    }
  });

  /**
   * Special UI case for tablets
   */
  let isTablet = false;
  function checkScreenSize() {
    isTablet = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px)"
    ).matches;
  }
  checkScreenSize();
</script>

<svelte:window on:resize={checkScreenSize} />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<AppShell>
  <!-- mobile header -->
  <div
    class="fixed left-0 right-0 z-10 flex h-[64px] items-center justify-center bg-white px-[54px] md:hidden
    {$location.pathname !== '/vault' ? 'shadow-mobileheader' : ''}
    {$userStore?.user_type === 'advisor' ? 'top-[51px]' : 'top-0'}
    "
  >
    <button
      class="absolute left-[16px] top-[17px] transition hover:opacity-70"
      on:click={() => toggleMobileNav()}
    >
      <HamburgerIcon />
    </button>
  </div>

  <!-- left sidebar -->
  <svelte:fragment slot="sidebarLeft">
    {#if !isTablet}
      <div class="bg-gradient-left min-h-full">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class:hover:opacity-70={sidebarLeftCollapsed}
          class="bg-gradient-left relative h-full border-r border-ui/border transition-[width] duration-200 ease-in-out max-md:hidden {sidebarLeftCollapsed
            ? 'w-[64px]'
            : 'w-[240px]'}"
          on:click={() => (sidebarLeftCollapsed ? toggleSidebars() : null)}
        >
          {#if sidebarLeftCollapsed}
            <div
              class="absolute bottom-0 left-0 right-0 top-0 cursor-pointer"
            />
          {/if}

          <!-- logo -->
          <figure
            class="flex flex-col gap-2.5 p-5 text-base font-bold tracking-[3px]"
            class:items-center={sidebarLeftCollapsed}
          >
            {sidebarLeftCollapsed ? "LS" : "LEGASEE"}

            {#if sidebarLeftCollapsed}
              <button class="anchor hover:opacity-70 active:scale-95">
                <ExpandRightIcon />
              </button>
            {/if}
          </figure>

          <!-- navigation -->
          <Navigation collapsed={sidebarLeftCollapsed} />
        </div>

        <!-- mobile flyout -->
        <div
          class="mobile-nav min-h-[100vh] md:hidden"
          class:is-open={!mobileNavCollapsed}
        >
          <figure
            class="relative flex justify-between bg-white py-[18px] pl-[16px] pr-[68px] shadow-mobileheader"
          >
            <span class="text-[20px] font-bold leading-[28px]">LegaSEE</span>
            <button
              class="mobile-nav-close anchor absolute right-[5px] top-[14px] transition hover:opacity-70 active:scale-95"
              on:click={() => toggleMobileNav()}
            >
              <CloseIcon />
            </button>
          </figure>

          <Navigation collapsed={false} />
        </div>
      </div>
    {/if}
  </svelte:fragment>

  <!-- main -->
  <div
    class="min-h-full px-[50px] py-[30px] max-md:overflow-scroll max-md:p-[16px] md:max-lg:pl-[100px]"
  >
    <slot />
  </div>

  <!-- right sidebar -->
  <svelte:fragment slot="sidebarRight">
    {#if !isTablet}
      <div
        class="md:bg-gradient-right hide-scrollbars tasks-sidebar relative min-h-full border-l border-ui/border bg-bg/light p-0"
        class:is-open={!sidebarRightCollapsed}
      >
        {#if tasksSidebarRoutes.includes($location.pathname) && $showTasks[$location.pathname.slice(1)]}
          <button
            class="fixed left-[11px] top-[18px] transition hover:opacity-70 md:hidden"
            class:hidden={sidebarRightCollapsed}
            on:click={() => toggleSidebars()}
          >
            <ArrowLeftIcon />
          </button>
          <TasksSidebar
            on:toggle={toggleSidebars}
            collapsed={sidebarRightCollapsed}
          />
        {/if}
      </div>
    {/if}
  </svelte:fragment>

  <!-- tablet only versions of nav and tasks sidebar -->
  {#if isTablet}
    <!-- nav menu button -->
    <button
      class="btn-main tablet-hamburger absolute left-[16px]"
      style="top: {$userStore?.user_type === 'advisor' ? '81px' : '30px'};"
      on:click={() => toggleMobileNav()}
    >
      <HamburgerIcon />
    </button>
    <!-- nav flyout -->
    {#if !mobileNavCollapsed}
      <figure
        class="backdrop"
        transition:fade|local={{ duration: 150 }}
        on:click={toggleMobileNav}
      />
      <div
        class="fixed left-0 top-0 z-10 min-h-[100vh] w-60 bg-[#F0F2F5] shadow-modal"
        transition:fly|local={{ duration: 150, x: "-100%", easing: quintOut }}
      >
        <figure
          class="relative flex justify-between bg-white py-[18px] pl-[16px] pr-[68px] shadow-mobileheader"
        >
          <span class="text-[20px] font-bold leading-[28px]">LegaSEE</span>
          <button
            class="mobile-nav-close anchor absolute right-[5px] top-[14px] transition hover:opacity-70 active:scale-95"
            on:click={() => toggleMobileNav()}
          >
            <CloseIcon />
          </button>
        </figure>

        <Navigation collapsed={false} />
      </div>
    {/if}

    <!-- tasks flyout -->
    {#if tasksSidebarRoutes.includes($location.pathname) && $showTasks[$location.pathname.slice(1)] && !sidebarRightCollapsed}
      <figure
        class="backdrop"
        transition:fade|local={{ duration: 150 }}
        on:click={toggleSidebars}
      />

      <div
        class="fixed right-0 top-0 z-10 min-h-[100vh] w-[300px] bg-[#F0F2F5] shadow-modal"
        transition:fly|local={{ duration: 150, x: "100%", easing: quintOut }}
      >
        <button
          class="mobile-nav-close anchor absolute right-[5px] top-[14px] transition hover:opacity-70 active:scale-95"
          on:click={toggleSidebars}
        >
          <CloseIcon />
        </button>
        <TasksSidebar collapsed={false} noslide />
      </div>
    {/if}
  {/if}
</AppShell>

<style lang="postcss">
  .mobile-nav-close :global(svg) {
    @apply h-[36px] w-[36px] text-text/dark;
  }

  .tablet-hamburger :global(svg) {
    @apply h-[20px] w-[20px];
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
