<script lang="ts">
  import type { View } from "../../pages/Auth.svelte";
  import { supabase } from "../../supabaseClient";
  import { popErrorToast } from "../../util/toasts";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import EyeIcon from "../icons/Eye.svelte";
  import EyeSlashIcon from "../icons/EyeSlash.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import { link } from "svelte-navigator";
  import { object, string } from "yup";

  export let view: View;

  let submitClicked = false;
  let passwordVisible = false;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        email: null,
        password: null,
      },
      validationSchema: object().shape({
        email: string().required("Required").email("Must be a valid email"),
        password: string().required("Required"),
      }),
      onSubmit: async ({ email, password }) => {
        try {
          const { error } = await supabase.auth.signInWithPassword({
            email,
            password,
          });

          if (error) throw error;
        } catch (error) {
          if (error instanceof Error) {
            popErrorToast(error.message);
          }
        }
      },
    }
  );
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
    <div class="mobile-header">
      <h1 class="mobile-header-title mb-[60px] text-text/dark">
        Login to LegaSEE
      </h1>
    </div>

    <div class="input-wrap mb-[40px]">
      <label for="email">
        Email Address

        <span class="text-state/negative">*</span>
      </label>

      <input
        id="email"
        type="email"
        bind:value={$form.email}
        on:change={handleChange}
      />

      {#if $errors.email && submitClicked}
        <small class="input-error"><WarnIcon /> {$errors.email}</small>
      {/if}
    </div>

    <div class="input-wrap">
      <label for="password">
        Password <span class="text-state/negative">*</span>
      </label>

      <div class="input-group w-[350px] grid-cols-[auto_50px]">
        <!-- Can't change `type` dynamically  -->
        {#if passwordVisible}
          <input
            id="password"
            type="text"
            bind:value={$form.password}
            on:change={handleChange}
          />
        {:else}
          <input
            id="password"
            type="password"
            bind:value={$form.password}
            on:change={handleChange}
          />
        {/if}

        <button
          type="button"
          class="justify-self-end text-text/medium"
          on:click={() => (passwordVisible = !passwordVisible)}
        >
          {#if passwordVisible}
            <EyeSlashIcon />
          {:else}
            <EyeIcon />
          {/if}
        </button>
      </div>

      {#if $errors.password && submitClicked}
        <small class="input-error"><WarnIcon /> {$errors.password}</small>
      {/if}

      <button
        type="button"
        disabled={$isSubmitting}
        class="mt-[14px] text-state/link"
        on:click={() => (view = "forgot-password")}
      >
        Forgot password?
      </button>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center md:justify-end md:border-t md:border-ui/border md:px-[50px] md:py-[40px]"
  >
    <button
      type="submit"
      class="btn-filled btn-lg flex"
      disabled={$isSubmitting}
      on:click={() => (submitClicked = true)}
    >
      Continue

      {#if $isSubmitting}
        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
