<script lang="ts">
  import type {
    OnboardingBeneficiary,
    OnboardingBeneficiaryType,
  } from "../../types/Onboarding";
  import { getOrdinalWord } from "../../util/format";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import GiftIcon from "../icons/Gift.svelte";
  import UserIcon from "../icons/User.svelte";

  export let beneficiary: OnboardingBeneficiary;
  export let index: number;

  export let goBack: () => void;
  export let goForward: () => void;

  function select(type: OnboardingBeneficiaryType) {
    beneficiary = {
      ...(beneficiary.type === type ? beneficiary : null),
      type,
    };

    goForward();
  }
</script>

<div class="mobile-content">
  <div
    class="mobile-content-body max-md:flex max-md:items-center max-md:justify-center"
  >
    <div
      class="flex flex-col items-center justify-center md:px-[50px] md:py-[100px] md:shadow"
    >
      <p
        class="mb-[40px] w-[350px] text-center text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        What type of beneficiary would you like to add {getOrdinalWord(index)}?
      </p>

      <div class="flex w-[350px] flex-col gap-[25px]">
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          on:click={() => select("person")}
        >
          <span class="button-icon"><UserIcon /></span>

          A Person
        </button>
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          on:click={() => select("charity")}
        >
          <span class="button-icon relative top-[-1px]">
            <GiftIcon />
          </span>

          A Charity
        </button>
      </div>
    </div>

    <div
      class="flex items-center border-t border-ui/border md:px-[50px] md:py-[40px]"
    >
      <button
        class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
        on:click={goBack}
      >
        <ArrowLeftIcon />

        Back
      </button>
    </div>
  </div>
</div>

<style type="text/css">
  .button-icon :global(svg) {
    width: 28px;
    height: 28px;
  }
</style>
