<script lang="ts">
  import type { Service } from "../../pages/Services.svelte";
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import {
    PaymentFrequencies,
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money";
  import { convertCentsToDollars } from "../../util/money.js";
  import { popErrorToast } from "../../util/toasts";
  import { handleDollarInput } from "../../util/validation.ts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getServiceTypeName } from "./AddServiceModal.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string, number } from "yup";

  export let serviceType: Service["type"];
  export let service: Service = null;

  const dispatch = createEventDispatcher();

  type SubmitData = {
    name: string;
    description: string;
    cost: number;
    frequency: string;
  };

  let submitClicked = false;

  function addService(formData: SubmitData) {
    return supabase
      .from("service")
      .insert({
        ...formData,
        estate_id: $currentEstate.id,
        type: serviceType,
      })
      .select("name, description, cost, frequency, type, id");
  }

  function updateService(formData: SubmitData) {
    return supabase
      .from("service")
      .update({
        ...formData,
        type: serviceType,
      })
      .eq("id", service.id)
      .select("name, description, cost, frequency, type, id");
  }

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        name: service?.name,
        description: service?.description,
        cost: convertCentsToInputDollars(service?.cost),
        frequency: service?.frequency || "",
      },
      validationSchema: object().shape({
        name: string().required("Required"),
        description: string().required("Required"),
        cost: string().required("Required"),
        frequency: string().required("Required"),
      }),
      onSubmit: async (formData) => {
        const addOrUpdateData = {
          ...formData,
          cost: convertDollarsStringToCents(formData.cost),
        };
        try {
          const { data, error } = isUpdate
            ? await updateService(addOrUpdateData)
            : await addService(addOrUpdateData);

          if (error) throw error;

          dispatch("done", data[0]);
        } catch (error) {
          popErrorToast(error.message);
        }
      },
    }
  );

  $: isUpdate = !!service;
</script>

<form
  class="mobile-content {isUpdate ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  <div
    class="mobile-header px-[50px] py-[30px] {isUpdate
      ? 'max-md:block max-md:!h-[85px]'
      : ''}"
  >
    <h1 class="mobile-header-title-fixed {isUpdate ? 'max-md:!text-left' : ''}">
      {#if isUpdate}
        Edit Service Details
      {:else}
        New {getServiceTypeName(serviceType)}
      {/if}
    </h1>

    {#if isUpdate}
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {service.name}
      </p>
    {/if}
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pb-0 pl-[50px] shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pb-[35px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="name">
          Service Provider <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="name"
          bind:value={$form.name}
          on:change={handleChange}
        />

        {#if $errors.name && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.name}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="description">
          Description <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="description"
          bind:value={$form.description}
          on:change={handleChange}
        />

        {#if $errors.description && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.description}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="cost">
          Payment Cost <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="cost"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            id="cost"
            class="!pl-0"
            bind:value={$form.cost}
            on:input={(e) => handleDollarInput(e, form, "cost")}
          />
        </div>

        {#if $errors.cost && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.cost}</small>
        {/if}

        <div class="input-hint">If the cost changes, use an average</div>
      </div>

      <div class="input-wrap">
        <label for="frequency">
          Payment Frequency <span class="text-state/negative">*</span>
        </label>

        <select
          id="frequency"
          bind:value={$form.frequency}
          on:change={handleChange}
          required
        >
          <option value="" selected disabled>Choose...</option>

          {#each PaymentFrequencies as paymentFrequency}
            <option value={paymentFrequency}>
              {paymentFrequency}
            </option>
          {/each}
        </select>

        {#if $errors.frequency && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.frequency}</small>
        {/if}
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="variant-ringed-primary mobile-back-button priority btn flex gap-[5px] text-state/link"
      disabled={$isSubmitting}
      on:click={() => dispatch("cancel")}
    >
      {#if isUpdate}
        <span class="md:hidden">
          <ArrowLeftIcon />
        </span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg btn-success flex gap-[10px]"
      disabled={$isSubmitting}
      on:click={() => (submitClicked = true)}
    >
      {#if isUpdate}
        {#if $isSubmitting}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />

          Saving
        {:else}
          <CheckIcon />

          Save Changes
        {/if}
      {:else if $isSubmitting}
        Adding Service

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        Add Service

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
