<script lang="ts">
  import type { Person } from "../../pages/Beneficiaries.svelte";
  import Modal from "../Modal.svelte";
  import DeleteBeneficiaryPersonForm from "./DeleteBeneficiaryPersonForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let person: Person;

  function handleDone() {
    $modalStore[0].response(person.id);
    modalStore.close();
  }
</script>

<Modal mobileExit="back">
  <DeleteBeneficiaryPersonForm
    {person}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  />
</Modal>
