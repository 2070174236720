<script lang="ts">
  export let group: any;
  export let value: any;

  $: selected = group === value;
</script>

<li
  class="mobile-tab mb-[-1px] border border-r-0 border-ui/border bg-bg/lighter text-text/medium first:rounded-tl last:rounded-tr last:border-r"
  class:selected
  style={!selected
    ? "background: linear-gradient(0deg, rgba(234,236,239,1) 0%, rgba(240,242,245,1) 20%);"
    : ""}
>
  <button
    class="mobile-tab-button flex items-center gap-[14px] px-[25px] py-[18px] text-body/medium font-semibold"
    type="button"
    on:click={() => (group = value)}
  >
    <slot />
  </button>
</li>

<style lang="postcss">
  .selected {
    @apply border-b-0 bg-white text-text/dark;
  }

  button {
    @apply transition;
  }

  li:not(.selected):hover button,
  li:not(.selected):focus button {
    opacity: 0.7;
  }
</style>
