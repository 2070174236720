<script lang="ts">
  import type { Document } from "../../types";
  import { deleteDocument } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import DeleteForm from "../DeleteForm.svelte";
  import Modal from "../Modal.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let document: Document;

  const requirements = [
    "This document and the file associated with it will be completely removed from the LegaSEE application.",
  ];

  async function deleteAction() {
    try {
      await deleteDocument(document);
    } catch (error) {
      console.error(error);
      popErrorToast("There was a problem deleting your document.");
    }
  }

  function handleDone() {
    $modalStore[0].response(true);
    modalStore.close();
    popSuccessToast("Your document has been deleted.");
  }
</script>

<Modal mobileExit="back">
  <DeleteForm
    {requirements}
    {deleteAction}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  >
    Delete {document?.description}
  </DeleteForm>
</Modal>
