<script lang="ts">
  import CheckIcon from "../icons/CheckSmall.svelte";

  export let step: number;

  type ParentStep = {
    title: string;
    step: number;
  };

  function getParentStep(step: number): ParentStep {
    if (step === 0) {
      return {
        title: "Account Setup",
        step: 0,
      };
    } else if (step === 1) {
      return {
        title: "Personal Information",
        step: 1,
      };
    } else if (step === 2) {
      return {
        title: "Tax Return",
        step: 2,
      };
    } else if (step === 3 || step === 4) {
      return {
        title: "Will",
        step: 3,
      };
    } else if (step >= 5 && step < 100) {
      return {
        title: "Trusts",
        step: 4,
      };
    } else if (step >= 100) {
      return {
        title: "Beneficiaries",
        step: 5,
      };
    }
  }

  $: parentStep = getParentStep(step);
</script>

<div
  class="mobile-ob-header flex items-center justify-between rounded-t-lg border-b border-ui/border bg-bg/lighter p-[20px]"
>
  <span
    class="mobile-ob-header-title text-body/large font-semibold text-text/medium"
  >
    {parentStep?.title}
  </span>

  <ol class="mobile-ob-stepper flex items-center">
    {#each { length: 6 } as _, i}
      <li
        class:active={parentStep.step === i}
        class:completed={parentStep.step > i}
        class="mobile-ob-step flex w-full items-center transition after:inline-block after:h-1 after:w-[15px] after:border-b after:border-ui/border-dark after:shadow after:content-[''] last:w-fit last:after:hidden"
      >
        <span
          class="flex h-[30px] w-[30px] shrink-0 items-center justify-center rounded-full border border-ui/border-dark bg-white text-sm text-text/light shadow max-md:hidden"
        >
          {#if parentStep.step > i}
            <CheckIcon />
          {:else}
            {i + 1}
          {/if}
        </span>
      </li>
    {/each}
  </ol>
</div>

<style lang="postcss">
  .active span {
    @apply border-state/link font-semibold text-text/dark;
  }

  .completed {
    @apply after:border-state/positive;
  }

  .completed span {
    @apply border-state/positive text-state/positive;
  }
</style>
