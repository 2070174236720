<script lang="ts">
  import {
    getNormalizedAsset,
    type Charity,
    selectedAssetDistributionData,
    assetSelectedForEdit,
  } from "../../pages/Beneficiaries.svelte";
  import { userCan } from "../../stores/user";
  import { convertCentsToDollarString } from "../../util/money";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import EditDistribution from "../assets/EditDistribution.svelte";
  import { updateAssetDistribution } from "../assets/assets";
  import DocumentIcon from "../icons/Document.svelte";
  import PenIcon from "../icons/Pen.svelte";
  import TrashIcon from "../icons/Trash.svelte";
  import AddUpdateBeneficiaryCharityForm from "./AddUpdateBeneficiaryCharityForm.svelte";
  import DeleteBeneficiaryCharityForm from "./DeleteBeneficiaryCharityForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let charity: Charity;

  type View = "Details" | "Edit" | "Delete" | "Distribution";
  let view: View = "Details";

  function handleDoneUpdating({ detail }) {
    view = "Details";

    charity = { ...charity, ...detail };
    $modalStore[0].response(charity);
  }

  function handleDoneDeleting() {
    $modalStore[0].response(charity.id);
    modalStore.close();
  }

  async function handleDoneEditingAssetDistribution() {
    try {
      const {
        id,
        distribution_method,
        distribution_trust_id,
        beneficiary_person,
        beneficiary_charity,
      } = $selectedAssetDistributionData;

      await updateAssetDistribution({
        id,
        distribution_method,
        distribution_trust_id,
        beneficiary_person,
        beneficiary_charity,
      });

      // Update the view modal w/ the updated asset
      charity = {
        ...charity,
        asset: charity.asset
          .map(
            (asset) =>
              asset.id === id
                ? {
                    ...asset,
                    distribution_method,
                    distribution_trust_id,
                    beneficiary_person,
                    beneficiary_charity,
                  }
                : asset
            // The currently viewed beneficiary may be unselected from the asset during
            // this process, and if so we need to filter it out
          )
          .filter((asset) =>
            asset.beneficiary_charity.find((b) => b.id === charity.id)
          ),
      };

      view = "Details";
      popSuccessToast("Asset distribution updated successfully");

      // Notify the parent page that it needs to reload the data
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast("Failed to update asset distribution");
    } finally {
      selectedAssetDistributionData.set(null);
    }
  }
</script>

<Modal mobileExit="back">
  {#if view === "Details"}
    <div class="mobile-content w-[996px] max-w-[100%]">
      <div class="mobile-header mb-[40px] px-[50px] pt-[30px] max-md:block">
        <h1 class="mobile-header-title-fixed">
          {charity.name}
        </h1>
      </div>

      <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
        <div
          class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
        >
          <div
            class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] bg-bg/lighter p-[6px] pl-[15px]"
          >
            <div
              class="mobile-list-header-text text-body/large font-semibold text-text/dark"
            >
              Beneficiary Details
            </div>

            <button
              type="button"
              class="btn-main mobile-list-header-link btn-sm shadow-heavy"
              on:click={() => (view = "Edit")}
            >
              <PenIcon />
              Edit
            </button>
          </div>

          <div class="flex flex-wrap rounded-bl-[5px] rounded-br-[5px]">
            <div
              class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
            >
              <div>
                <div class="mobile-list-item-label mb-[8px] text-text/medium">
                  State of Formation
                </div>
                <div
                  class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                >
                  {charity.state_of_formation || "-"}
                </div>
              </div>
            </div>

            <div
              class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
            >
              <div>
                <div class="mobile-list-item-label mb-[8px] text-text/medium">
                  Legal Structure
                </div>
                <div
                  class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                >
                  {charity.legal_structure || "-"}
                </div>
              </div>
            </div>

            <div
              class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
            >
              <div>
                <div class="mobile-list-item-label mb-[8px] text-text/medium">
                  EIN
                </div>
                <div
                  class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                >
                  {charity.ein || "-"}
                </div>
              </div>
            </div>

            <div
              class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
            >
              <div>
                <div class="mobile-list-item-label mb-[8px] text-text/medium">
                  Contact Email Address
                </div>
                <div
                  class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                >
                  {charity.contact_email || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>

        {#if (userCan("assets_read") || userCan("assets_read_designated")) && charity.asset?.length}
          <div
            class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
          >
            <div
              class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] border-b border-ui/border bg-bg/lighter p-[6px] pl-[15px]"
            >
              <div
                class="mobile-list-header-text text-body/large font-semibold text-text/dark"
              >
                Asset Distribution
              </div>
            </div>

            <table class="table !rounded-t-none">
              <thead class="text-text/medium">
                <th>Asset</th>
                <th>Type</th>
                <th>Method</th>
                <th />
              </thead>

              <tbody>
                {#each charity.asset as asset (asset.id)}
                  {@const normalizedAsset = getNormalizedAsset(asset)}

                  <tr>
                    <td>
                      {normalizedAsset.description}

                      <span class="table-meta">
                        {normalizedAsset.descriptionSubtext}
                      </span>
                    </td>
                    <td class="max-md:hidden">
                      {normalizedAsset.title}

                      <span class="table-meta">
                        {#if userCan("show_dollar_values")}
                          <sup>$</sup>{convertCentsToDollarString(
                            normalizedAsset.value
                          )}
                        {:else}
                          &mdash;
                        {/if}
                      </span>
                    </td>
                    <td class="max-md:hidden">
                      <span
                        class="flex items-center gap-[7px] font-semibold capitalize text-state/link"
                      >
                        <DocumentIcon />

                        {normalizedAsset.distributionMethod === "tbd"
                          ? "TBD"
                          : normalizedAsset.distributionMethod}
                      </span>
                    </td>
                    <td class="text-right">
                      <button
                        type="button"
                        class="variant-ringed-primary mobile-icon-button btn btn-sm bg-white text-state/link"
                        on:click={() => {
                          assetSelectedForEdit(normalizedAsset);
                          view = "Distribution";
                        }}
                      >
                        <PenIcon />

                        Edit
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        {/if}

        <button
          class="mobile-delete-btn variant-ringed-error btn border-state/negative bg-white text-state/negative"
          on:click={() => (view = "Delete")}
        >
          <TrashIcon />

          Delete Beneficiary
        </button>
      </div>
    </div>
  {:else if view === "Edit"}
    <div class="w-[750px] max-w-[100%]">
      <AddUpdateBeneficiaryCharityForm
        {charity}
        on:done={handleDoneUpdating}
        on:cancel={() => (view = "Details")}
      />
    </div>
  {:else if view === "Distribution"}
    <div class="w-[750px] max-w-[100%]">
      <EditDistribution
        multipartFormData={selectedAssetDistributionData}
        selectedAssetType={$selectedAssetDistributionData.assetType}
        name={$selectedAssetDistributionData.name}
        onSubmitted={handleDoneEditingAssetDistribution}
        onCancelled={() => (view = "Details")}
      />
    </div>
  {:else}
    <DeleteBeneficiaryCharityForm
      {charity}
      on:done={handleDoneDeleting}
      on:cancel={() => (view = "Details")}
    />
  {/if}
</Modal>
