<script lang="ts">
  import img from "../../assets/images/onboarding/welcome.png";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
</script>

<div class="flex gap-24 px-[50px] pb-[50px] pt-[40px]">
  <div class="mobile-content">
    <div class="mobile-content-body">
      <div class="mobile-header">
        <h1 class="mobile-header-title">Welcome to LegaSEE</h1>
      </div>

      <img
        src={img}
        alt="Welcome"
        class="mx-auto mb-[65px] max-w-[250px] md:hidden"
      />

      <p
        class="mb-[30px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px] md:mt-[30px]"
      >
        We’re excited that you’ve chosen our platform to help get your estate in
        order.
      </p>

      <p
        class="mt-[30px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        We have a few questions we need to ask straight away to finish creating
        your account, and then you’ll be off to the races.
      </p>
    </div>

    <div class="mobile-content-footer">
      <button
        class="btn-filled btn-lg flex gap-[10px] md:mt-[50px]"
        on:click={() => dispatch("start")}
      >
        Get Started

        <ArrowRightIcon />
      </button>
    </div>
  </div>
  <div class="shrink-0 basis-[275px]">
    <img src={img} alt="Welcome" />
  </div>
</div>
