<script lang="ts">
  import { slide } from "svelte/transition";

  let className = "";
  export { className as class };

  export let percent: number;
</script>

<div class="{className} relative flex h-5 w-full gap-[5px] rounded-none">
  {#if percent > 0}
    <figure
      style="max-width: {percent}%"
      class="h-full w-full min-w-[4px] rounded-bl-[3px] rounded-tl-[3px] border border-[#07AE5E] bg-gradient-to-b from-[#53CE93] to-[#06BA63] transition-all duration-500"
      class:full={percent === 100}
      in:slide|local={{ axis: "x" }}
    />
  {/if}
  {#if percent < 100}
    <figure
      style="max-width: {100 - percent}%"
      class="h-full w-full min-w-[4px] origin-left rounded-br-[3px] rounded-tr-[3px] border border-[#E5063E] bg-gradient-to-b from-[#EE6084] to-[#E5063E] transition-all duration-500"
      class:full={percent === 0}
      in:slide|local={{ axis: "x" }}
    />
  {/if}
</div>

<style lang="postcss">
  .full {
    @apply rounded-[3px];
  }
</style>
