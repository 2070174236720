<script lang="ts">
  import { navigate } from "svelte-navigator";

  export let linkTo: string = null;

  function handleLink() {
    if (!!linkTo) {
      navigate(linkTo);
    }
  }
</script>

<div class="flex flex-col rounded border border-ui/border bg-white shadow">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <header
    class="flex items-center justify-between border-b border-ui/border p-[15px] text-table/title md:bg-bg/light"
    class:hover:opacity-70={!!linkTo}
    class:cursor-pointer={!!linkTo}
    on:click={handleLink}
  >
    <slot name="header" />
  </header>
  <slot name="body" />
</div>
