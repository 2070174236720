<script lang="ts" context="module">
  export type View = "Main" | "Edit Details" | "Edit Distribution" | "Delete";
  export type TabView = "Details" | "Transactions";
</script>

<script lang="ts">
  import { currentEstate, userCan, userStore } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { formatDBDate, maskAccountNumber } from "../../util/format";
  import { isVisible } from "../../util/isVisible";
  import { convertCentsToDollarString } from "../../util/money";
  import { Routes } from "../../util/routes";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import Modal from "../Modal.svelte";
  import Tab from "../Tab.svelte";
  import Tabs from "../Tabs.svelte";
  import { getInitials } from "../beneficiaries/beneficiaries";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import DocumentIcon from "../icons/Document.svelte";
  import EyeIcon from "../icons/EyeClip.svelte";
  import EyeSlashIcon from "../icons/EyeSlashClip.svelte";
  import PenIcon from "../icons/Pencil.svelte";
  import TransactionsIcon from "../icons/Transactions.svelte";
  import TrashIcon from "../icons/TrashClip.svelte";
  import ViewIcon from "../icons/View.svelte";
  import TablePlaceholder from "../placeholders/ServicesTable.svelte";
  import AddInheritance from "./AddInheritance.svelte";
  import AddInsurance from "./AddInsurance.svelte";
  import AddLegalEntity from "./AddLegalEntity.svelte";
  import AddLoanAsset from "./AddLoanAsset.svelte";
  import AddManualAccount from "./AddManualAccount.svelte";
  import AddOtherProperty from "./AddOtherProperty.svelte";
  import AddRealEstate from "./AddRealEstate.svelte";
  import AddVehicle from "./AddVehicle.svelte";
  import DeleteAssetForm from "./DeleteAssetForm.svelte";
  import EditDistribution from "./EditDistribution.svelte";
  import { updateAssetDistribution } from "./assets";
  import {
    createTaskToSpecifyBeneficiaries,
    createTaskToSpecifyDistribution,
  } from "./tasks";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { omit, startCase } from "lodash";
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { writable } from "svelte/store";

  export let asset: any;
  export let assetGroupTitle: string;

  export let view: View = "Main";
  export let tabView: TabView = "Details";
  export let transactionId: string = null;

  let transactions = [];
  let transactionsLoading = false;

  const multipartFormData = writable<{ [key: string]: any }>({
    estate_id: $currentEstate.id,
  });

  const editableFieldsMap = {
    financial_account: [
      "financial_account_subtype",
      "current_value",
      "account_number",
      "institution_name",
      "payment_frequency",
      "plaid_account_id",
    ],
    inheritance: [
      "description",
      "estimated_value",
      "asset_type",
      "expected_inheritance_date",
    ],
    insurance: [
      "insured",
      "insurer",
      "policy_number",
      "maturity",
      "policy_limit",
      "premium",
      "payment_frequency",
      "payment_source",
    ],
    real_estate: ["address", "estimated_value", "location_of_deed"],
    property: [
      "description",
      "estimated_value",
      "unique_id",
      "physical_location",
    ],
    legal_entity: [
      "name",
      "value",
      "state_registered",
      "role",
      "committed_capital",
      "uncalled_capital",
      "primary_contact",
    ],
    vehicle: [
      "vehicle_type",
      "make",
      "model",
      "year",
      "estimated_value",
      "vin",
      "usage",
      "usage_type",
    ],
    loan_asset: [
      "debtor",
      "amount",
      "current_balance",
      "account_number",
      "payment_frequency",
      "maturity_date",
    ],
  } as const;

  const editAssetFormsMap = {
    financial_account: AddManualAccount,
    inheritance: AddInheritance,
    insurance: AddInsurance,
    real_estate: AddRealEstate,
    property: AddOtherProperty,
    legal_entity: AddLegalEntity,
    vehicle: AddVehicle,
    loan_asset: AddLoanAsset,
  } as const;

  async function updateAsset() {
    try {
      // Remove fields that are stored on the asset table
      const updateFields = omit($multipartFormData, [
        "distribution_method",
        "distribution_trust_id",
        "beneficiary_person",
        "beneficiary_charity",
      ]);
      const { data, error } = await supabase
        .from(asset.type)
        .update(updateFields)
        .eq("id", asset.raw.id)
        .select("*");

      if (error) throw error;

      // Update the raw asset with the new data
      asset = {
        ...asset,
        raw: {
          ...asset.raw,
          ...data?.[0],
        },
      };

      view = "Main";
      popSuccessToast("Asset updated successfully");

      // Notify the parent page that it needs to reload the data
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast(error.message);
    }
  }

  async function handleDoneEditingAssetDistribution() {
    try {
      const {
        distribution_method,
        distribution_trust_id,
        beneficiary_person,
        beneficiary_charity,
      } = $multipartFormData;

      await updateAssetDistribution({
        id: asset.raw?.asset?.id,
        distribution_method,
        distribution_trust_id,
        beneficiary_person,
        beneficiary_charity,
      });

      // Update the data in the modal
      asset = {
        ...asset,
        distributionMethod: distribution_method,
        raw: {
          ...asset.raw,
          asset: {
            ...asset.raw.asset,
            distribution_method,
            distribution_trust_id,
            beneficiary_person,
            beneficiary_charity,
          },
        },
      };
      assignDistributionsFields();

      // maybe update tasks
      const noBeneficiary =
          !asset.raw.asset.beneficiary_charity?.length &&
          !asset.raw.asset.beneficiary_person?.length,
        tbdMethod = asset.raw.asset.distribution_method === "tbd",
        assetId = asset.raw.asset.id;
      if (noBeneficiary) createTaskToSpecifyBeneficiaries(assetId);
      if (tbdMethod) createTaskToSpecifyDistribution(assetId);

      view = "Main";
      popSuccessToast("Asset distribution updated successfully");

      // Notify the parent page that it needs to reload the data
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast("Failed to update asset distribution");
    }
  }

  async function getTransactions(offset = 0) {
    try {
      transactionsLoading = true;

      const { data, error } = await supabase.functions.invoke(
        "fetch-transactions",
        {
          body: {
            plaidAccountIds: [rawAsset.plaid_account_id],
            offset,
          },
        }
      );

      if (error) throw error;

      transactions = [...transactions, ...data];
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      transactionsLoading = false;

      if (transactionId) {
        setTimeout(() => {
          attemptScrollToTransaction(transactionId);
        }, 50);
      }
    }
  }

  function loadMoreTransactions() {
    const transactionsCount = transactions.length;
    const hasMoreTransactions =
      transactionsCount > 0 && transactionsCount % 100 === 0;

    if (hasMoreTransactions) {
      getTransactions(transactionsCount);
    }
  }

  function handleDoneDeleting() {
    view = "Main";

    // Notify the parent page that it needs to reload the data
    $modalStore[0].response(true);
  }

  function assignEditableFields() {
    const obj = editableFieldsMap[asset.type].reduce((acc, key: string) => {
      acc[key] = asset.raw?.[key];
      return acc;
    }, {});

    $multipartFormData = { ...obj };
  }

  function assignDistributionsFields() {
    $multipartFormData = {
      ...$multipartFormData,
      distribution_method: asset.raw?.asset?.distribution_method,
      distribution_trust_id: asset.raw?.asset?.distribution_trust_id,
      beneficiary_person: asset.raw?.asset?.beneficiary_person,
      beneficiary_charity: asset.raw?.asset?.beneficiary_charity,
    };
  }

  async function fetchTrustName(trustId: number): Promise<string> {
    const {
      data: { name },
    } = await supabase
      .from("trust")
      .select("name")
      .eq("id", trustId)
      .maybeSingle();
    return name ?? "";
  }

  $: rawAsset = asset.raw;
  $: isConnected = !!rawAsset.plaid_account_id;
  $: selectedAssetType =
    asset.type === "financial_account"
      ? rawAsset.financial_account_type
      : asset.type;

  $: beneficiaries = [
    ...rawAsset.asset.beneficiary_charity.map((c) => {
      return {
        id: c.id,
        fullName: c.name,
        type: "charity",
      };
    }),
    ...rawAsset.asset.beneficiary_person.map((p) => {
      return {
        id: p.id,
        fullName: `${p.first_name} ${p.last_name}`,
        type: "person",
      };
    }),
  ];

  $: fieldsMap = {
    financial_account: [
      {
        title: "Institution",
        value: rawAsset.institution_name,
      },
      {
        title: "Account Type",
        value: startCase(rawAsset.financial_account_subtype),
      },
      {
        title: "Account Number",
        value: maskAccountNumber(rawAsset.account_number),
        rawValue: rawAsset.account_number,
        rawValueVisible: false,
      },
      {
        title: "Current Value",
        value: convertCentsToDollarString(rawAsset.current_value),
        type: "currency",
      },
    ],
    real_estate: [
      {
        title: "Address",
        value: `${rawAsset.address}`,
      },
      {
        title: "Estimated Value",
        value: convertCentsToDollarString(rawAsset.estimated_value),
        type: "currency",
      },
    ],
    property: [
      {
        title: "Description",
        value: rawAsset.description,
      },
      {
        title: "Unique ID",
        value: rawAsset.unique_id,
      },
      {
        title: "Estimated Value",
        value: convertCentsToDollarString(rawAsset.estimated_value),
      },
    ],
    loan_asset: [
      {
        title: "Debtor",
        value: rawAsset.debtor,
      },
      {
        title: "Account Number",
        value: maskAccountNumber(rawAsset.account_number),
        rawValue: rawAsset.account_number,
        rawValueVisible: false,
      },
      {
        title: "Current Balance",
        value: convertCentsToDollarString(rawAsset.current_balance),
        type: "currency",
      },
    ],
    insurance: [
      {
        title: "Insurer",
        value: rawAsset.insurer,
      },
      {
        title: "Insured",
        value: rawAsset.insured,
      },
      {
        title: "Policy Limit",
        value: convertCentsToDollarString(rawAsset.policy_limit),
        type: "currency",
      },
    ],
    inheritance: [
      {
        title: "Description",
        value: rawAsset.description,
      },
      {
        title: "Type",
        value: rawAsset.asset_type,
      },
      {
        title: "Estimated Value",
        value: convertCentsToDollarString(rawAsset.estimated_value),
        type: "currency",
      },
    ],
    legal_entity: [
      {
        title: "Name",
        value: rawAsset.name,
      },
      {
        title: "Role",
        value: rawAsset.role,
      },
      {
        title: "Value",
        value: convertCentsToDollarString(rawAsset.value),
        type: "currency",
      },
    ],
    vehicle: [
      {
        title: "Make",
        value: rawAsset.make,
      },
      {
        title: "Model",
        value: rawAsset.model,
      },
      {
        title: "Year",
        value: rawAsset.year,
      },
      {
        title: "Estimated Value",
        value: convertCentsToDollarString(rawAsset.estimated_value),
        type: "currency",
      },
      {
        title: "Mileage",
        value: rawAsset.year,
      },
    ],
  };

  function attemptScrollToTransaction(transactionId: string): void {
    const transactionRow = document.querySelector(
      `[data-transaction="${transactionId}"]`
    );
    if (transactionRow)
      transactionRow.scrollIntoView({
        behavior: "smooth",
      });
  }

  onMount(() => {
    assignEditableFields();
    assignDistributionsFields();

    if (isConnected) {
      getTransactions();
    }
  });
</script>

<Modal mobileExit="hide">
  {#if view === "Main"}
    <button class="mobile-back-button md:hidden" on:click={modalStore.close}>
      <ArrowLeftIcon />
    </button>

    <div
      class="mobile-content max-w-[100%] {isConnected
        ? 'max-md:!top-[161px]'
        : 'max-md:!top-[85px]'}"
      class:w-[750px]={!isConnected}
      class:w-[950px]={isConnected}
    >
      <div
        class="mobile-header mb-[40px] px-[50px] pt-[30px] max-md:block max-md:!h-[85px] {isConnected
          ? '!shadow-none'
          : ''}"
      >
        <h1 class="mobile-header-title-fixed max-md:!text-left">
          {asset.description}
        </h1>
        {#if asset.descriptionSubtext}
          <p
            class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
          >
            {asset.descriptionSubtext}
          </p>
        {/if}
      </div>

      {#if isConnected}
        <Tabs mobileSubtitle>
          <Tab bind:group={tabView} value="Details">
            <ViewIcon /> <span class="mobile-tab-text">Details</span>
          </Tab>
          <Tab bind:group={tabView} value="Transactions">
            <TransactionsIcon />
            <span class="mobile-tab-text">Transactions</span>
          </Tab>
        </Tabs>
      {/if}

      {#if tabView === "Details"}
        <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
          <div
            class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
          >
            <div
              class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] bg-bg/lighter p-[6px] pl-[15px]"
            >
              <div
                class="mobile-list-header-text text-body/large font-semibold text-text/dark"
              >
                Asset Details
              </div>

              <button
                type="button"
                class="btn-main mobile-list-header-link btn-sm shadow-heavy"
                on:click={() => (view = "Edit Details")}
              >
                <PenIcon />
                Edit
              </button>
            </div>

            <div class="flex flex-wrap rounded-bl-[5px] rounded-br-[5px]">
              {#each fieldsMap[asset.type] as { title, value, rawValue, rawValueVisible, type }, i}
                {#if value}
                  <div
                    class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
                  >
                    <div>
                      <div
                        class="mobile-list-item-label mb-[8px] text-text/medium"
                      >
                        {title}
                      </div>
                      <div
                        class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                      >
                        {#if type === "currency"}
                          <sup>$</sup>
                        {/if}
                        {rawValueVisible ? rawValue : value}
                      </div>
                    </div>

                    {#if rawValue}
                      <button
                        type="button"
                        class="btn-main btn-sm"
                        on:click={() =>
                          (fieldsMap[asset.type][i].rawValueVisible =
                            !fieldsMap[asset.type][i].rawValueVisible)}
                      >
                        {#if rawValueVisible}
                          <EyeSlashIcon /> Hide
                        {:else}
                          <EyeIcon /> View
                        {/if}
                      </button>
                    {/if}
                  </div>
                {/if}
              {/each}
            </div>
          </div>

          {#if userCan("beneficiaries_read")}
            <div
              class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
            >
              <div
                class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] border-b border-ui/border bg-bg/lighter p-[6px] pl-[15px]"
              >
                <div
                  class="mobile-list-header-text text-body/large font-semibold text-text/dark"
                >
                  Asset Distribution
                </div>

                <button
                  type="button"
                  class="mobile-list-header-link variant-ringed-primary btn btn-sm bg-white text-state/link"
                  on:click={() => (view = "Edit Distribution")}
                >
                  <PenIcon />

                  Edit
                </button>
              </div>

              <div class="flex flex-wrap rounded-bl-[5px] rounded-br-[5px]">
                <div
                  class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full"
                >
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Method
                  </div>
                  <div
                    class="mobile-list-item-value text-body/medium text-text/dark"
                  >
                    {#if $userStore.user_type === "owner" && ["will", "trust"].includes(asset.distributionMethod)}
                      <button
                        class="anchor inline-flex items-center gap-2 font-semibold no-underline"
                        on:click={() => {
                          modalStore.close();
                          navigate(Routes.Vault);
                        }}
                      >
                        <DocumentIcon />
                        {#if asset.trust?.name}
                          {asset.trust.name}
                        {:else if asset.raw?.asset?.distribution_trust_id}
                          {#await fetchTrustName(asset.raw.asset.distribution_trust_id) then name}
                            {name}
                          {/await}
                        {:else}
                          {startCase(asset.distributionMethod)}
                        {/if}
                      </button>
                    {:else}
                      {asset.distributionMethod === "tbd"
                        ? "TBD"
                        : startCase(asset.distributionMethod) || "-"}
                    {/if}
                  </div>
                </div>
                <div
                  class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full"
                >
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Beneficiaries
                  </div>
                  <div
                    class="mobile-list-item-value text-body/medium text-text/dark"
                  >
                    {#if beneficiaries.length}
                      {#each beneficiaries as beneficiary}
                        <button
                          class="chip ml-[4px] !px-[5px] !pb-[3px] !pt-[4px] uppercase"
                          on:click={() => {
                            modalStore.close();
                            navigate(Routes.Beneficiaries, {
                              state: {
                                openModal: "viewAssetBeneficiary",
                                returnTo: location.pathname,
                                type: beneficiary.type,
                                id: beneficiary.id,
                              },
                            });
                          }}
                        >
                          {getInitials(beneficiary.fullName, beneficiary.type)}
                        </button>
                      {/each}
                    {:else}
                      &mdash;
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          {/if}

          <button
            class="mobile-delete-btn variant-ringed-error btn border-state/negative bg-white text-state/negative"
            on:click={() => (view = "Delete")}
          >
            <TrashIcon />

            Delete Asset
          </button>
        </div>
      {:else}
        <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
          {#if transactionsLoading && !transactions?.length}
            <TablePlaceholder />
          {:else}
            <div class="mobile-list-container table-container">
              <table class="table max-md:border-t">
                <thead class="!bg-bg/lighter">
                  <th class="rounded-tl-[6px]">Date</th>
                  <th>Description</th>
                  <th>Category</th>
                  <th class="rounded-tr-[6px] text-right">Amount</th>
                </thead>

                <tbody>
                  {#each transactions as { transaction_id, date, name, category, amount } (transaction_id)}
                    {@const isCredit = amount < 0}

                    <tr data-transaction={transaction_id}>
                      <td>
                        <span class="mobile-list-item-value md:hidden">
                          {formatDBDate(date, "M/d/yy")}
                        </span>
                        <span class="max-md:hidden">
                          {formatDBDate(date, "MMM dd, yyyy")}
                        </span>
                      </td>
                      <td>
                        <span class="mobile-list-item-value">{name}</span>
                      </td>
                      <td class="max-md:hidden">{category?.[0]}</td>
                      <td
                        class="text-right {isCredit
                          ? 'text-state/positive'
                          : ''}"
                      >
                        <span class="mobile-list-item-value">
                          {!isCredit ? "-" : ""}
                          <sup>$</sup>{convertCentsToDollarString(
                            Math.abs(amount * 100),
                            true,
                            false
                          )}
                        </span>
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>

            <div class="mt-4" use:isVisible={loadMoreTransactions}>
              {#if transactionsLoading}
                <BarLoader />
              {/if}
            </div>
          {/if}
        </div>
      {/if}
    </div>
  {:else if view === "Edit Details"}
    <div class="w-[750px] max-w-[100%]">
      <svelte:component
        this={editAssetFormsMap[asset.type]}
        onSubmitted={updateAsset}
        onCancelled={() => (view = "Main")}
        {multipartFormData}
        {selectedAssetType}
      />
    </div>
  {:else if view === "Edit Distribution"}
    <div class="w-[750px] max-w-[100%]">
      <EditDistribution
        {multipartFormData}
        {selectedAssetType}
        name={asset.description}
        onSubmitted={handleDoneEditingAssetDistribution}
        onCancelled={() => (view = "Main")}
      />
    </div>
  {:else if view === "Delete"}
    <DeleteAssetForm
      {asset}
      {assetGroupTitle}
      on:done={handleDoneDeleting}
      on:cancel={() => (view = "Main")}
    />
  {/if}
</Modal>
