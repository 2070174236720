<script lang="ts">
  import Modal from "../Modal.svelte";
  import type { PersonToInvite } from "./InviteToAccessForm.svelte";
  import InviteToAccessForm from "./InviteToAccessForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let person: PersonToInvite;

  function handleDone() {
    $modalStore[0].response(person);
    modalStore.close();
  }
</script>

<Modal mobileExit="back">
  <InviteToAccessForm
    {person}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  />
</Modal>
