<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import { popSuccessToast, popErrorToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import ModalStep from "../ModalStep.svelte";
  import ModalStepper from "../ModalStepper.svelte";
  import StepOneForm from "./StepOneForm.svelte";
  import StepTwoForm from "./StepTwoForm.svelte";
  import type { InviteCollaboratorFields } from "./collaborators";
  import { convertUIPermissionsToDBPermissions } from "./collaborators";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { writable, type Writable } from "svelte/store";

  let processing = false;

  const multipartFormData: Writable<InviteCollaboratorFields> = writable({
    firstName: "",
    lastName: "",
    email: "",
    dashboard: "none",
    assets: "none",
    debts: "none",
    services: "none",
    beneficiaries: "none",
    showDollarValues: false,
  });

  async function inviteCollaborator() {
    try {
      processing = true;

      const { firstName, lastName, email, ...uiPermissions } =
        $multipartFormData;
      const dbPermissions = convertUIPermissionsToDBPermissions(uiPermissions);

      const { data, error } = await supabase.functions.invoke(
        "invite-collaborator",
        {
          body: {
            first_name: firstName,
            last_name: lastName,
            email,
            invited_as: "collaborator",
            permissions: dbPermissions,
          },
        }
      );

      if (error ?? data.error) throw new Error(error ?? data.error);

      popSuccessToast(
        `Success!\n You have invited ${$multipartFormData.firstName} ${$multipartFormData.lastName} to collaborate`
      );
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast(error.message);
      $modalStore[0].response(false);
    } finally {
      modalStore.close();
    }
  }
</script>

<Modal mobileExit="back">
  <div class="w-[750px] max-w-[100%]">
    <ModalStepper>
      <ModalStep step={0}>
        <svelte:fragment let:goForward>
          <div class="mobile-content">
            <div class="mobile-header px-[50px] py-[30px]">
              <h1 class="mobile-header-title-fixed pb-[30px]">
                Invite Collaborator
              </h1>
              <p class="m-0 p-0 text-body/large max-md:hidden">
                Tell us who you would like to invite to your account.<br /><br
                />
                You will be able to define what they have access to in the next step.
              </p>
            </div>
            <StepOneForm {multipartFormData} {goForward} />
          </div>
        </svelte:fragment>
      </ModalStep>
      <ModalStep step={1}>
        <svelte:fragment let:goBack>
          <div class="mobile-content">
            <div class="mobile-header px-[50px] py-[30px]">
              <h1 class="mobile-header-title-fixed pb-[30px]">
                Specify Access
              </h1>
              <p class="m-0 p-0 text-body/large max-md:hidden">
                What should {$multipartFormData.firstName}
                {$multipartFormData.lastName} have access to in your account?
              </p>
            </div>
            <StepTwoForm
              {multipartFormData}
              {goBack}
              {inviteCollaborator}
              {processing}
            />
          </div>
        </svelte:fragment>
      </ModalStep>
    </ModalStepper>
  </div>
</Modal>
