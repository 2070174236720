<script lang="ts">
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CrossIcon from "../icons/Cross.svelte";

  export let i: number;
  export let next: () => void;
  export let exitAndForget: () => void;
  export let last: boolean = false;
  export let show: boolean = false;
</script>

<div class="absolute z-[888] md:w-[442px]" data-tour={i} class:hidden={!show}>
  <slot name="arrow" />
  <div class="overflow-hidden rounded-[10px] bg-white shadow-tooltips">
    <button
      class="mobile-close-button !z-50 md:hidden"
      on:click={exitAndForget}
    >
      <CrossIcon />
    </button>
    <slot name="header" />
    <div class="mobile-content">
      <slot name="body" />
      <div
        class="mobile-content-footer flex gap-[20px] rounded-bl-[10px] rounded-br-[10px] p-[17px]"
      >
        {#if last}
          <button
            class="btn-filled w-full !py-[15px] px-[25px] !text-[20px] !leading-[30px]"
            on:click={exitAndForget}>Finish Tour</button
          >
        {:else}
          <button
            class="btn-filled w-[64%] !py-[15px] px-[25px] !text-[20px] !leading-[30px]"
            on:click={next}
          >
            Next <span class="btn-arrow"><ArrowRightIcon /></span>
          </button>
          <button
            class="btn-main grow !py-[15px] px-[25px] !text-[20px] !leading-[30px] max-md:!hidden"
            on:click={exitAndForget}
          >
            Exit Tour
          </button>
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .btn-arrow :global(svg) {
    @apply h-[12px] w-auto;
  }
</style>
