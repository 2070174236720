<script lang="ts">
  import { fetchServices, type Service } from "../../pages/Services.svelte";
  import {
    convertCentsToDollarString,
    type PaymentFrequencies,
  } from "../../util/money";
  import { Routes } from "../../util/routes";
  import { popErrorToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import Plus from "../icons/Plus.svelte";
  import Bar from "./Bar.svelte";
  import Card from "./Card.svelte";
  import Empty from "./Empty.svelte";
  import { sumBy, sortBy, maxBy } from "lodash";
  import { onMount } from "svelte";
  import { navigate, Link } from "svelte-navigator";

  let loading = true;
  let empty = false;
  let services: Array<Service>;

  $: servicesPerMonth = services?.map(({ name, cost, frequency, id }) => {
    switch (frequency as (typeof PaymentFrequencies)[number]) {
      case "Weekly":
        cost *= 52 / 12;
        break;
      case "Bi-Monthly":
        cost *= 2;
        break;
      case "Quarterly":
        cost /= 3;
        break;
      case "Annually":
        cost /= 12;
        break;
    }
    return { name, cost, id };
  });
  $: sorted = sortBy(servicesPerMonth, "cost").reverse();
  $: topSixServices =
    sorted.length > 6
      ? [
          ...sorted.slice(0, 5),
          {
            name: "Others",
            cost: sumBy(sorted.slice(5), "cost"),
            id: null,
          },
        ]
      : sorted;
  $: largestCost = maxBy(topSixServices, "cost")?.cost ?? undefined;
  $: withPercentages = largestCost
    ? topSixServices.map(({ name, cost, id }) => ({
        name,
        cost,
        id,
        percentage: Math.round((cost / largestCost) * 100),
      }))
    : [];

  $: monthlyTotal = servicesPerMonth
    ? sumBy(servicesPerMonth, "cost")
    : undefined;

  async function getServices() {
    try {
      const { data, error } = await fetchServices();

      if (error) throw error;
      services = data;

      if (services.length === 0) empty = true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        popErrorToast("Something went wrong. Please try again.");
      }
    } finally {
      loading = false;
    }
  }

  function goTo(service: (typeof withPercentages)[number]) {
    const fullService = services.find(({ id }) => service.id === id);

    if (fullService)
      navigate(Routes.Services, {
        state: {
          openModal: "viewService",
          service: fullService,
          returnTo: location.pathname,
        },
      });
    else navigate(Routes.Services);
  }

  onMount(getServices);
</script>

<Card linkTo={Routes.Services}>
  <svelte:fragment slot="header">
    Services
    <span class="text-body/x-small text-text/medium">Last 30 Days</span>
  </svelte:fragment>
  <svelte:fragment slot="body">
    {#if loading}
      <BarLoader />
    {/if}
    <div class="p-5">
      {#if !loading && typeof monthlyTotal !== "undefined"}
        {#if !empty}
          <div class="flex flex-wrap items-start justify-evenly gap-6">
            <div
              class="w-min rounded-[3px] border border-ui/border px-6 pb-4 pt-3 text-center shadow"
            >
              <div class="flex flex-col">
                <strong
                  class="whitespace-nowrap text-[30px] font-bold leading-normal"
                >
                  <sup class="-top-2 -mr-1">$</sup>
                  <span>
                    {convertCentsToDollarString(monthlyTotal)}
                  </span>
                </strong>
                <span class="text-body/small text-text/medium">monthly</span>
              </div>
            </div>
            <div
              class="grid min-w-[320px] flex-grow grid-cols-[auto_1fr_auto] gap-x-2 gap-y-4"
            >
              {#each withPercentages as service, i (i)}
                <button class="group contents" on:click={() => goTo(service)}>
                  <span class="text-right group-hover:opacity-70">
                    {service.name}
                  </span>
                  <span class="relative group-hover:opacity-70">
                    <Bar percent={service.percentage} />
                    <span
                      style="left: calc({service.percentage}% + 8px)"
                      class="absolute top-1/2 -translate-y-1/2 whitespace-nowrap text-text/medium"
                    >
                      <sup>$</sup>
                      {convertCentsToDollarString(service.cost)}
                    </span>
                  </span>

                  <!-- sentinel -->
                  <span class="invisible">
                    <sup>$</sup>
                    {convertCentsToDollarString(service.cost)}
                  </span>
                </button>
              {/each}
            </div>
          </div>
        {:else}
          <Empty>
            <span slot="text">No services added yet</span>

            <svelte:fragment slot="buttons">
              <Link
                class="btn-main"
                to={Routes.Services}
                state={{ openModal: "addService", returnTo: location.pathname }}
              >
                <Plus />
                Add First Service
              </Link>
            </svelte:fragment>
          </Empty>
        {/if}
      {/if}
    </div>
  </svelte:fragment>
</Card>
