<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import { popSuccessToast, popErrorToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import SendIcon from "../icons/Send.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  let processing = false;

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: object().shape({
      firstName: string().required("Required"),
      lastName: string().required("Required"),
      email: string().email("Must be a valid email").required("Required"),
    }),
    onSubmit: inviteClient,
  });

  async function inviteClient(formData: ClientFormData) {
    try {
      processing = true;

      const { data, error } = await supabase.functions.invoke("invite-client", {
        body: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
        },
      });

      if (error ?? data.error) throw new Error(error ?? data.error);

      popSuccessToast(
        `Success!\n You have invited ${formData.firstName} ${formData.lastName} to the platform.`
      );
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast(error.message);
      $modalStore[0].response(false);
    } finally {
      modalStore.close();
    }
  }

  type ClientFormData = {
    firstName: string;
    lastName: string;
    email: string;
  };
</script>

<Modal mobileExit="back">
  <form
    class="mobile-content w-[750px] max-w-[100%]"
    on:submit={handleSubmit}
    novalidate
  >
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title-fixed">Invite Client</h1>
    </div>

    <div
      class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] shadow-forms"
    >
      <div
        class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] md:pr-[50px]"
      >
        <div class="input-wrap">
          <label for="firstName">
            First Name <span class="text-state/negative">*</span>
          </label>

          <input
            name="firstName"
            id="firstName"
            type="text"
            bind:value={$form.firstName}
            on:change={handleChange}
          />

          {#if $errors.firstName}
            <small class="input-error">
              <WarnIcon />
              {$errors.firstName}
            </small>
          {/if}
        </div>

        <div class="input-wrap">
          <label for="lastName">
            Last Name <span class="text-state/negative">*</span>
          </label>

          <input
            name="lastName"
            id="lastName"
            type="text"
            bind:value={$form.lastName}
            on:change={handleChange}
          />

          {#if $errors.lastName}
            <small class="input-error"><WarnIcon /> {$errors.lastName}</small>
          {/if}
        </div>

        <div class="input-wrap col-span-full">
          <label for="email">
            Email Address <span class="text-state/negative">*</span>
          </label>

          <input
            name="email"
            id="email"
            type="email"
            bind:value={$form.email}
            on:change={handleChange}
          />

          {#if $errors.email}
            <small class="input-error"><WarnIcon /> {$errors.email}</small>
          {/if}
        </div>
      </div>
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
    >
      <button
        class="btn-main mobile-back-button"
        type="button"
        on:click|preventDefault={modalStore.close}
      >
        Cancel
      </button>
      <button
        type="submit"
        disabled={processing}
        class="btn-lg btn variant-filled bg-state/positive"
      >
        <SendIcon />
        <span>{processing ? "Inviting..." : "Send Invite"}</span>
      </button>
    </div>
  </form>
</Modal>
