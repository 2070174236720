import { Routes } from "../../util/routes";
import { createDynamicTask } from "../readiness/readiness";
import type { PersonToInvite } from "./InviteToAccessForm.svelte";

export async function createTaskToInviteBeneficiaryToAccess(
  person: PersonToInvite
) {
  createDynamicTask({
    category: "beneficiaries",
    name: `Invite beneficiary ${person.first_name} ${person.last_name} to the platform`,
    complete: false,
    description:
      "Allow this beneficiary to access information about their benefits",
    repeatable: false,
    repeatable_on_table: null,
    repeatable_on_type: null,
    repetitions: null,
    action_label: "Invite to Access",
    action_hook: {
      openModal: "inviteToAccess",
      person,
    },
    dismiss_label: "Skip",
    completion_type: "trigger",
  });
}

export async function createTaskToDesignateBeneficiaryAssets(
  id: number,
  type: "person"|"charity",
  name: string
) {
  createDynamicTask({
    category: "beneficiaries",
    name: `Add assets for ${name} to receive`,
    complete: false,
    description:
      "Designate the assets you want to leave to this beneficiary",
    repeatable: false,
    repeatable_on_table: null,
    repeatable_on_type: null,
    repetitions: null,
    action_label: "Manage Assets",
    action_hook: {openModal: false, linkTo: Routes.Assets, id, type, name},
    dismiss_label: "Skip",
    completion_type: "trigger",
  });
}
