<script lang="ts" context="module">
  import { currentEstate } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import { get } from "svelte/store";

  export function fetchServices() {
    return supabase
      .from("service")
      .select("name, description, cost, frequency, type, id")
      .eq("estate_id", get(currentEstate).id);
  }

  export type Service = Awaited<
    ReturnType<typeof fetchServices>
  >["data"][number];
</script>

<script lang="ts">
  import CollapsibleTable from "../lib/CollapsibleTable.svelte";
  import ContextMenu from "../lib/ContextMenu.svelte";
  import TasksToggleButton from "../lib/TasksToggleButton.svelte";
  import BulbIcon from "../lib/icons/Bulb.svelte";
  import CalendarIcon from "../lib/icons/CalendarTime.svelte";
  import CheckIcon from "../lib/icons/CheckCircle.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import RecurringIcon from "../lib/icons/Recurring.svelte";
  import TrashIcon from "../lib/icons/Trash.svelte";
  import ViewIcon from "../lib/icons/View.svelte";
  import TablePlaceholder from "../lib/placeholders/ServicesTable.svelte";
  import AddServiceModal from "../lib/services/AddServiceModal.svelte";
  import DeleteServiceModal from "../lib/services/DeleteServiceModal.svelte";
  import ViewServiceModal from "../lib/services/ViewServiceModal.svelte";
  import { userCan } from "../stores/user";
  import {
    PaymentFrequencies,
    convertCentsToDollarString,
    paymentFrequencySuffix,
  } from "../util/money";
  import { onNavigate } from "../util/routes";
  import { popErrorToast } from "../util/toasts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";

  let loading = false;

  type Service = Awaited<ReturnType<typeof fetchServices>>["data"][number];

  let servicesMap = {
    utility: [],
    subscription: [],
    expense: [],
  };

  onMount(getServices);

  function updateServices(
    type: Service["type"],
    callback: (services: Service[]) => Service[]
  ) {
    servicesMap = {
      ...servicesMap,
      [type]: callback(servicesMap[type]),
    };
  }

  async function getServices() {
    try {
      loading = true;

      const { data, error, status } = await fetchServices();

      if (error && status !== 406) throw error;

      servicesMap = data.reduce((map, service) => {
        map[service.type].push(service);
        return map;
      }, servicesMap);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        popErrorToast("Something went wrong. Please try again.");
      }
    } finally {
      loading = false;
    }
  }

  function showViewModal(service: Service) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: ViewServiceModal,
        props: {
          service,
        },
      },
      response: (data: Service | number) => {
        if (data) {
          const isDeleted = typeof data === "number";

          if (isDeleted) {
            updateServices(service.type, (services) =>
              services.filter((s) => s.id !== data)
            );
          } else {
            updateServices(service.type, (services) =>
              services.map((s) => (s.id === service.id ? data : s))
            );
          }
        }
      },
    });
  }

  function showAddModal(preSelectedType: string = null) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: AddServiceModal,
      },
      meta: {
        ...(preSelectedType ? { serviceType: preSelectedType } : {}),
      },
      response: (service: Service) => {
        if (service) {
          updateServices(service.type, (services) => [...services, service]);
        }
      },
    });
  }

  function showDeleteModal(service: Service) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: DeleteServiceModal,
        props: {
          service,
        },
      },
      response: (id: number) => {
        if (id) {
          updateServices(service.type, (services) =>
            services.filter((service) => service.id !== id)
          );
        }
      },
    });
  }

  onNavigate(({ state }) => {
    if (state?.openModal === "addService") {
      showAddModal(state?.serviceType ?? null);
    }
    if (state?.openModal === "viewService" && state?.service) {
      showViewModal(state.service);
    }
  });

  $: perYearTotal = Object.values(servicesMap).reduce(
    (total, services) =>
      total +
      services.reduce((total, service) => {
        let serviceYearlyCost = service.cost;
        switch (service.frequency as (typeof PaymentFrequencies)[number]) {
          case "Weekly":
            serviceYearlyCost *= 52;
            break;
          case "Monthly":
            serviceYearlyCost *= 12;
            break;
          case "Bi-Monthly":
            serviceYearlyCost *= 24;
            break;
          case "Quarterly":
            serviceYearlyCost *= 4;
            break;
        }
        return total + serviceYearlyCost;
      }, 0),
    0
  );
</script>

<header class="flex items-center justify-between">
  <div>
    <h1 class="mobile-header-title-fixed">Services</h1>
    {#if userCan("show_dollar_values")}
      {#if loading}
        <p
          class="mt-[-2px] pt-[15px] text-[18px] text-text/medium max-md:hidden"
        >
          <sup>$</sup><span
            class="placeholder relative left-[2px] top-[6px] inline-block w-[60px] animate-pulse bg-text/light"
          />
        </p>
      {:else if perYearTotal !== 0}
        <p class="pt-[15px] text-[18px] text-text/medium max-md:hidden">
          <sup>$</sup>{convertCentsToDollarString(perYearTotal, true)} per year
        </p>
      {/if}
    {/if}
  </div>
  <div class="flex items-center">
    {#if userCan("services_write")}
      <button
        type="button"
        class="btn-main mobile-fab"
        on:click={() => showAddModal()}
      >
        <PlusIcon />
        <span>New Service</span>
      </button>
    {/if}
    {#if userCan("services_write") && userCan("dashboard_read")}
      <TasksToggleButton class="ml-[15px]" />
    {/if}
  </div>
</header>

<main class="mobile-content">
  <div class="mobile-content-body">
    {#if loading}
      <TablePlaceholder />
    {:else if !servicesMap.expense.length && !servicesMap.subscription.length && !servicesMap.utility.length}
      <div
        class="mt-[30px] flex flex-col items-center justify-center border-t border-ui/border pt-[150px] text-body/large"
      >
        {#if userCan("services_write")}
          <h2 class="mb-[50px] font-normal text-text/medium">
            You don't have any services yet.
          </h2>

          <button class="btn-main btn-lg" on:click={() => showAddModal()}>
            <span><PlusIcon /></span>
            <span>Add First Service</span>
          </button>
        {:else}
          <h2 class="font-normal text-text/medium">
            No services have been added yet.
          </h2>
        {/if}
      </div>
    {:else}
      {#each Object.entries(servicesMap) as [type, services]}
        {#if services.length}
          <CollapsibleTable
            class="mt-[50px] max-md:mt-[32px] first:max-md:mt-[0]"
          >
            <svelte:fragment slot="titleBar">
              {#if type === "expense"}
                <span class="max-md:hidden"><CalendarIcon /></span>
                <span>Recurring Expenses</span>
              {/if}
              {#if type === "subscription"}
                <span class="max-md:hidden"><RecurringIcon /></span>
                <span>Subscriptions</span>
              {/if}
              {#if type === "utility"}
                <span class="max-md:hidden"><BulbIcon /></span>
                <span>Utilities</span>
              {/if}
            </svelte:fragment>
            <svelte:fragment slot="thead">
              <tr>
                <th>Service</th>
                <th class="max-md:hidden">Cost</th>
                <th class="max-md:hidden">Credentials</th>
                <th />
              </tr>
            </svelte:fragment>
            <svelte:fragment slot="tbody">
              {#each services as service}
                <tr>
                  <td class="w-[33%]">
                    <div class="max-md:text-[16px] max-md:leading-[24px]">
                      {service.name}
                      <span class="table-meta">{service.description}</span>
                    </div>
                  </td>
                  <td class="w-[33%] max-md:hidden">
                    {#if userCan("show_dollar_values")}
                      <sup>$</sup>{convertCentsToDollarString(
                        service.cost,
                        true
                      )}
                    {:else}
                      &mdash;
                    {/if}
                    <span class="table-meta"
                      >{paymentFrequencySuffix(service.frequency)}</span
                    >
                  </td>
                  <td class="w-[33%] text-state/positive max-md:hidden">
                    <CheckIcon />
                  </td>
                  <td
                    class="relative w-[1%] max-md:!pr-[10px] max-md:text-right"
                  >
                    <ContextMenu
                      items={[
                        {
                          text: "View Details",
                          icon: ViewIcon,
                          color: "text-text/dark",
                          onClick: () => showViewModal(service),
                        },
                        {
                          text: "Delete Service",
                          icon: TrashIcon,
                          color: "text-state/negative",
                          onClick: () => showDeleteModal(service),
                        },
                      ]}
                    />
                  </td>
                </tr>
              {/each}
            </svelte:fragment>
          </CollapsibleTable>
        {/if}
      {/each}
    {/if}
  </div>
</main>
