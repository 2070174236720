import { userPermissions, userCan } from "../stores/user";
import { once } from "./once";
import { Routes } from "./routes";
import { navigate } from "svelte-navigator";
import { get } from "svelte/store";

/**
 * Find the first route available for the current user and navigate there
 * (since users may not have a dashboard available)
 *
 * Useful for programmatic routing on login,
 * user landing on "/" route,
 * or when choosing an estate context to view when multiple estates are available
 */

export async function navigateHome() {
  const permissions = get(userPermissions) ?? (await once(userPermissions));

  if (permissions === "PENDING_ESTATE_SELECTION") {
    navigate(Routes.Clients);
    return;
  }

  let fallback = "profile";

  for (const route of [
    Routes.Dashboard,
    Routes.Assets,
    Routes.Debts,
    Routes.Services,
    Routes.Beneficiaries,
    Routes.Vault,
  ] as const) {
    const view = route.slice(1); //? trim preceeding slash
    if (
      // @ts-ignore: string manipulation above killed our type information
      userCan(`${view}_read`) ||
      (view === "assets" && userCan("assets_read_designated"))
    ) {
      fallback = null;
      navigate(view);
      break;
    }
  }

  if (fallback) {
    navigate(fallback);
  }
}
