<script lang="ts">
  import ClipboardIcon from "./icons/ClipboardClip.svelte";
  import CrossIcon from "./icons/CrossTasks.svelte";
  import { taskListOpen } from "./readiness/readiness";
  import { Link } from "svelte-navigator";

  let className = "";
  export { className as class };
</script>

<Link
  class="btn-main mobile-tasks-button {className} {$taskListOpen
    ? '!bg-[#F1F6FF] !shadow-inset'
    : ''}"
  style={$taskListOpen
    ? "--tw-brighness:none !important; filter: none !important;"
    : ""}
  to={location.pathname}
  state={$taskListOpen ? { closeTasks: true } : { openTasks: true }}
  replace={true}
>
  {#if $taskListOpen}
    <CrossIcon />
  {:else}
    <ClipboardIcon />
  {/if}
  <span>Tasks</span>
</Link>
