export const beneficiaryRelationships = [
  "Child",
  "Parent",
  "Sibling",
  "Spouse",
  "Grandchild",
  "Other",
];

export const charityLegalStructures = [
  "LLC",
  "LLP",
  "LP",
  "C Corp",
  "S Corp",
  "Other",
];

export function getInitials(
  fullName: string,
  type: "person" | "charity"
): string {
  //? reduce whitespace to single space
  fullName = fullName.replace(/\s+/g, " ");
  if (type === "person") {
    return (
      fullName.split(" ")[0][0] + fullName.split(" ")[1][0]
    ).toUpperCase();
  } else {
    return fullName.slice(0, 2)?.toUpperCase();
  }
}
