export function isVisible(
    element: HTMLElement,
    callback: () => void
) {
    const observer = new IntersectionObserver(
        ([entry]: IntersectionObserverEntry[]) => {
            if (entry.intersectionRatio > 0 && entry.isIntersecting) {
                callback();
            }
        }
    );

    observer.observe(element);

    return {
        destroy() {
            observer?.disconnect();
        },
    };
}