<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import { popSuccessToast, popErrorToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import type { CollaboratorProfile } from "./collaborators";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let collaborator: CollaboratorProfile["user_data"];

  let processing = false;
  let dirty = false;
  let acknowledgements = [
    false,
    ...(collaborator.user_type === "beneficiary" ? [false] : []),
  ];

  $: isValid = acknowledgements.every((val) => val === true);

  function validate(): boolean {
    dirty = true;
    return isValid;
  }

  async function removeCollaborator() {
    if (!validate()) return;

    try {
      processing = true;

      const { data, error } = await supabase.functions.invoke(
        "delete-collaborator",
        {
          body: {
            collaborator_id: collaborator.user_id,
          },
        }
      );

      if (error ?? data.error) throw new Error(error ?? data.error);

      popSuccessToast(
        `Success!\n Revoked access for ${collaborator.first_name}`
      );
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast(error.message);
      $modalStore[0].response(false);
    } finally {
      modalStore.close();
    }
  }
</script>

<Modal mobileExit="back">
  <form on:submit|preventDefault={removeCollaborator}>
    <div class="mobile-content max-w-[100%] md:w-[620px]">
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title max-md:!pl-[34px] max-md:!text-left">
          Remove {collaborator.first_name}'s Access
        </h1>
      </div>

      <div class="mobile-content-body px-[50px] pb-[20px] shadow">
        <p
          class="mb-[30px] text-[20px] font-semibold leading-[25px] text-state/negative max-md:text-[18px] max-md:font-bold max-md:leading-[28px]"
        >
          By continuing, you understand that:
        </p>

        <label class="input-option !mb-[30px] flex items-start gap-[8px]">
          <input
            type="checkbox"
            class="relative !top-[2px]"
            bind:checked={acknowledgements[0]}
          />
          <span class="relative !mt-0 block leading-[30px]">
            This collaborator will no longer have any access to your LegaSEE
            account.
          </span>
        </label>

        {#if collaborator.user_type === "beneficiary"}
          <label class="input-option !mb-[30px] flex items-start gap-[8px]">
            <input
              type="checkbox"
              class="relative !top-[2px]"
              bind:checked={acknowledgements[1]}
            />
            <span class="relative !mt-0 block leading-[30px]">
              This person’s beneficiary profile and asset designations will be
              unaffected.
            </span>
          </label>
        {/if}

        {#if dirty && !isValid}
          <small class="input-error">
            <WarnIcon />
            You must acknowledge before continuing.
          </small>
        {/if}
      </div>

      <div
        class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
      >
        <button
          type="button"
          class="mobile-back-button btn-main"
          on:click|preventDefault={modalStore.close}
        >
          <span>Cancel</span>
        </button>
        <button
          type="submit"
          disabled={processing}
          class="btn-lg btn variant-filled bg-state/negative"
        >
          <CheckIcon />
          <span>{processing ? "Removing..." : "Remove Access"}</span>
        </button>
      </div>
    </div>
  </form>
</Modal>
