<script lang="ts">
  /**
   * A thin wrapper around the library date input
   * https://date-picker-svelte.kasper.space/docs
   *
   * Our DateInput will function just like a native date input, where value
   * is a string formatted yyyy-MM-dd
   *
   * The library datepicker deals with js Date objects, so we are converting
   * between the formats on initialization and on changes.
   *
   * Additionally, we have some styling and configuration defined on the library
   * component
   */
  import { format } from "date-fns";
  import { DateInput } from "date-picker-svelte";

  export let value: string = null;

  let date = value ? new Date(`${value}T00:00:00`) : null;

  $: {
    value = date ? format(date, "yyyy-MM-dd") : null;
  }
</script>

<DateInput
  format="MM/dd/yyyy"
  placeholder="mm/dd/yyyy"
  min={new Date("1900-01-01T00:00:00")}
  closeOnSelection
  dynamicPositioning
  bind:value={date}
/>

<style>
  :root {
    --theme-font-family-base: "Source Sans", sans-serif;
    --theme-font-family-heading: "Source Sans", sans-serif;
    --date-input-width: 100%;
    --date-picker-highlight-border: #3a86ff;
  }
  :global(.date-time-field input) {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.375 0.75V4.5' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.625 0.75V4.5' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.625 11.375H6.375' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.625 15.75H6.375' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.625 11.375H11.375' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.625 15.75H11.375' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.625 11.375H16.375' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.625 15.75H16.375' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.75' y='2.625' width='22.5' height='20.625' rx='3' stroke='%23848B9F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
    background-repeat: no-repeat !important;
    background-position: right 16px center !important;
    padding: 18px !important;
    border-radius: var(--theme-rounded-base) !important;
  }
</style>
