<script lang="ts">
  import BeneficiariesImage from "../../assets/images/onboarding/screen-beneficiaries.png";
  import CollaboratorsImage from "../../assets/images/onboarding/screen-collaborators.png";
  import FinancesImage from "../../assets/images/onboarding/screen-finances.png";
  import ReadinessImage from "../../assets/images/onboarding/screen-readiness.png";
  import VaultImage from "../../assets/images/onboarding/screen-vault.png";
  import SupportImage from "../../assets/images/onboarding/support.png";
  import WelcomeImage from "../../assets/images/onboarding/welcome.png";
  import { myAdvisor } from "../collaborators/collaborators";
  import ChecklistIcon from "../icons/Checklist.svelte";
  import CoinsIcon from "../icons/Coins.svelte";
  import DataTreeIcon from "../icons/DataTree.svelte";
  import HelpIcon from "../icons/Help.svelte";
  import UserGroupIcon from "../icons/UserGroup.svelte";
  import VaultIcon from "../icons/Vault.svelte";
  import TourItem from "./TourItem.svelte";
  import { activeTourStep } from "./tour";
  import type { ComponentType } from "svelte";

  let firstTimeTour = history.state?.tour ?? false;
  let preTourModal = true;

  type TourTip = {
    header: string;
    icon?: ComponentType;
    mobileImage?: string;
    mobileImageStyling?: string;
    arrowDirection?: string;
    content: string; //? html allowed
  };

  let tourContent: TourTip[] = [
    {
      header: "Readiness Meters",
      icon: ChecklistIcon,
      mobileImage: ReadinessImage,
      mobileImageStyling: "boxed",
      arrowDirection: "up",
      content: `
        <p>
          Completing tasks on LegaSEE helps contribute to your overall estate readiness meter. Each section comes with its own set of tasks to help you through the planning process.
        </p>
        <p>
          Your goal should be to complete all tasks and get your estate to 100% readiness!
        </p>
      `,
    },
    {
      header: "Finances",
      icon: CoinsIcon,
      mobileImage: FinancesImage,
      mobileImageStyling: "boxed",
      arrowDirection: "left",
      content: `
        <p>
          The finances section of LegaSEE is where you'll add your assets, debts, and any services you have expenses for.
        </p>
        <p>
          Within assets you'll also be able to specify how your estate gets distributed to your beneficiaries.
        </p>
      `,
    },
    {
      header: "Beneficiaries",
      icon: DataTreeIcon,
      mobileImage: BeneficiariesImage,
      mobileImageStyling: "boxed",
      arrowDirection: "left",
      content: `
        <p>
          The beneficiaries section is where you can see and manage people or charitable organizations that are receiving inheritances from your estate.
        </p>
      `,
    },
    {
      header: "Collaborators",
      icon: UserGroupIcon,
      mobileImage: CollaboratorsImage,
      mobileImageStyling: "boxed",
      arrowDirection: "left",
      content: `
        <p>
          You can add collaborators to share some part of your account with different advisors or beneficiaries.
        </p>
      `,
    },
    {
      header: "Digital Vault",
      icon: VaultIcon,
      mobileImage: VaultImage,
      mobileImageStyling: "boxed",
      arrowDirection: "left",
      content: `
        <p>
          The digital vault is a place where you can upload and manage your will as well as any trusts you might have.
        </p>
        <p>
          You can also upload important documents here that will help the people managing your estate after you pass.
        </p>
      `,
    },
    {
      header: "Get Help",
      icon: HelpIcon,
      mobileImage: SupportImage,
      mobileImageStyling: "simple",
      arrowDirection: "left",
      content: `
        <p>
          If you ever have any questions along the way, contact LegaSEE's support team and we'll be happy to help.
        </p>
        <p>
          Now let's start planning!
        </p>
      `,
    },
  ];

  myAdvisor().then((advisor) => {
    if (!advisor) return;

    const collaboratorsI = tourContent.findIndex(
      (content) => content.header === "Collaborators"
    );
    if (!collaboratorsI) return;

    tourContent[collaboratorsI].content += `
        <p>
          Your financial advisor, ${advisor.first_name} ${advisor.last_name}, has already been added as a collaborator.
        </p>
      `;

    //? svelte assignment hack
    tourContent = tourContent;
  });

  $: activeTourContent =
    $activeTourStep !== null ? tourContent[$activeTourStep] : null;

  function startTour() {
    preTourModal = false;
  }

  function next() {
    $activeTourStep = $activeTourStep + 1;
  }

  function exitAndForget() {
    firstTimeTour = false;
    $activeTourStep = null;
    history.replaceState({}, "", location.href);
  }
</script>

{#if firstTimeTour}
  {#if preTourModal}
    <div
      class="tour-overlay-heavy fixed bottom-0 left-0 right-0 top-0 z-[888] flex items-center justify-center"
      style="background-color: rgba(0,0,0,.8);"
    >
      <div
        class="mobile-content w-[100%] max-w-[835px] gap-[100px] md:flex md:rounded-[15px] md:bg-white md:px-[50px] md:pb-[50px] md:pt-[40px] md:shadow-onboarding"
      >
        <div class="mobile-content-body">
          <div class="mobile-header">
            <h1 class="mobile-header-title">Let's Get Planning</h1>
          </div>

          <img
            src={WelcomeImage}
            alt="Welcome"
            class="mx-auto mb-[65px] max-w-[250px] md:hidden"
          />

          <p
            class="mb-[30px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px] md:mt-[30px]"
          >
            Your account is setup and ready for you to start planning your
            estate!
          </p>
          <p
            class="mt-[30px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
          >
            We recommend taking a short, 5-minute tour of LegaSEE's features to
            familiarize yourself with the app.
          </p>
          <div class="mobile-content-footer mt-[50px] flex flex-col gap-[22px]">
            <button class="btn-filled btn-lg" on:click={startTour}>
              Get Started
            </button>
            <button class="btn-main btn-lg" on:click={exitAndForget}>
              Skip the Tour
            </button>
          </div>
        </div>
        <div class="max-md:hidden">
          <img src={WelcomeImage} alt="Welcome" class="max-w-[275px]" />
        </div>
      </div>
    </div>
  {/if}

  <div
    class="tour-overlay absolute bottom-0 left-0 right-0 top-0 z-[888]"
    style="background-color: rgba(0,0,0,0.3)"
    class:hidden={preTourModal || !activeTourContent}
  >
    {#each tourContent as step, i (i)}
      <TourItem
        {i}
        {next}
        {exitAndForget}
        last={i === tourContent.length - 1}
        show={i === $activeTourStep}
      >
        <span slot="arrow">
          {#if step["arrowDirection"]}
            <span
              class="tt-arrow tt-arrow-{step['arrowDirection']} max-md:hidden"
            />
          {/if}
        </span>
        <header
          slot="header"
          class="mobile-header relative z-10 flex justify-start gap-[7px] rounded-tl-[10px] rounded-tr-[10px] border-b border-ui/border bg-bg/lighter px-[17px] pb-[15px] pt-[17px] text-text/dark md:shadow"
        >
          {#if step["icon"]}
            <span class="tt-icon max-md:hidden">
              <svelte:component this={step["icon"]} />
            </span>
          {/if}
          <h3 class="mobile-header-title md:text-[20px] md:leading-[28px]">
            {step["header"]}
          </h3>
        </header>
        <div
          slot="body"
          class="mobile-content-body flex flex-col gap-[20px] bg-white p-[17px] text-[18px] leading-[30px]"
        >
          {@html step["content"]}
          {#if step["mobileImage"]}
            <!-- svelte-ignore a11y-missing-attribute -->
            <img
              src={step["mobileImage"]}
              class="mx-auto {step['mobileImageStyling'] === 'boxed'
                ? 'mt-[15px] w-full max-w-[500px] rounded-[6px] border border-ui/border-dark shadow'
                : 'mt-[60px] max-w-[250px]'} md:hidden"
            />
          {/if}
        </div>
      </TourItem>
    {/each}
  </div>
{/if}

<style lang="postcss">
  .tt-icon :global(svg) {
    @apply h-[28px] w-[28px];
  }

  .tt-arrow {
    @apply absolute h-[20px] w-[20px] rotate-45 bg-bg/lighter shadow-tooltips;
  }

  .tt-arrow-up {
    @apply left-[48px] top-[-10px];
  }

  .tt-arrow-left {
    @apply left-[-10px] top-[22px];
  }
</style>
