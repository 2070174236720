/**
 * Format a database amount into a currency string for display
 * @param {number} amount The database amount, assumed to be stored as cents
 * @param {boolean} [displayCents=false] Option to display cents
 * @param {boolean} [addDollarSign=false] Option to include dollar sign
 * @returns {string} The formatted currency string
 */
export function convertCentsToDollarString(
  amount: number,
  displayCents: boolean = false,
  addDollarSign: boolean = false
): string {
  if (!amount) amount = 0;

  const opts = {
    maximumFractionDigits: 0,
  };

  if (displayCents) {
    opts["maximumFractionDigits"] = 2;
    opts["minimumFractionDigits"] = 2;
  }

  const numberString = Intl.NumberFormat("en-US", opts).format(amount / 100);

  return (addDollarSign ? "$" : "") + numberString;
}

/**
 * This version returns undefined in case of zero amounts to not pre-populate
 * the input with a zero value
 * @param amount The db amount, assumed to be stored as cents
 * @returns A string formatted currency string if the amount is not zero, otherwise undefined
 */
export function convertCentsToInputDollars(amount: number): string | undefined {
  if (!amount) return undefined;
  return convertCentsToDollarString(amount, true);
}

/**
 * Converts a database cents amount into a dollars number for use in fields
 * @param amount - The database amount, assumed to be stored as cents
 * @returns The amount in dollars
 */
export function convertCentsToDollars(amount: number): number {
  if (!amount || amount === 0) return 0;
  return amount / 100;
}

/**
 * Take an entry in a field in dollars and convert to cents for database storage
 * @param amount - The amount in dollars
 * @returns The amount in cents
 */
export function convertDollarsToCents(amount: number): number {
  return Math.round(amount * 100);
}

/**
 * Takes a string representing a dollar amount and converts it to cents for database storage
 * @param amount The amount in dollars represented as a string
 * @returns
 */
export function convertDollarsStringToCents(
  amount: string
): number | undefined {
  if (!amount) return undefined;
  return convertDollarsToCents(parseFloat(amount.replace(/[^0-9.]/g, "")));
}

export const PaymentFrequencies = [
  "Weekly",
  "Bi-Monthly",
  "Monthly",
  "Quarterly",
  "Annually",
] as const;

export function paymentFrequencySuffix(
  frequency: (typeof PaymentFrequencies)[number]
): string {
  switch (frequency) {
    case "Weekly":
      return "per week";
    case "Bi-Monthly":
      return "twice a month";
    case "Monthly":
      return "per month";
    case "Quarterly":
      return "per quarter";
    case "Annually":
      return "per year";
    default:
      return "";
  }
}

export const LegalEntityRoles = ["Member", "Manager", "LP", "GP"] as const;
