import { toastStore } from "@skeletonlabs/skeleton";

/**
 * Pop a success message toast
 * @param {string} message
 */
export function popSuccessToast(message: string): void {
  toastStore.trigger({
    message,
    background: "variant-filled-success",
  });
}

/**
 * Pop an error toast
 * @param {string} message
 */
export function popErrorToast(message: string): void {
  toastStore.trigger({
    message,
    background: "variant-filled-error",
  });
}
