<script lang="ts">
  import { popErrorToast } from "../util/toasts";
  import ArrowLeftIcon from "./icons/ArrowLeft.svelte";
  import TrashIcon from "./icons/Trash.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";

  export let requirements: string[];
  export let deleteAction: () => Promise<void>;

  const dispatch = createEventDispatcher();

  let loading = false;

  async function handleDelete() {
    try {
      loading = true;

      await deleteAction();

      dispatch("done");
    } catch (error) {
      popErrorToast(error?.message);
    } finally {
      loading = false;
    }
  }

  const handleChange = (index: number, e: any) =>
    (confirmations[index] = e.target.checked);

  $: confirmations = requirements.map(() => false);
  $: confirmed = confirmations.every((confirmation) => confirmation);
</script>

<div class="mobile-content max-w-[100%] md:w-[620px]">
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title max-md:!pl-[34px] max-md:!text-left">
      <slot />
    </h1>
  </div>

  <div class="mobile-content-body px-[50px] pb-[20px] shadow">
    <p
      class="mb-[30px] text-[20px] font-semibold leading-[25px] text-state/negative max-md:text-[18px] max-md:font-bold max-md:leading-[28px]"
    >
      By continuing, you understand that:
    </p>

    {#each requirements as requirement, i (requirement)}
      <label class="input-option !mb-[30px] flex items-start gap-[8px]">
        <input
          type="checkbox"
          value={confirmations[i]}
          on:change={(e) => handleChange(i, e)}
        />

        <span class="relative top-[-4px] !mt-0 block leading-[30px]"
          >{requirement}</span
        >
      </label>
    {/each}
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      disabled={loading}
      on:click={() => dispatch("cancel")}
    >
      <span class="md:hidden"><ArrowLeftIcon /></span>

      Cancel
    </button>

    <button
      type="button"
      class="btn-filled btn-lg flex gap-[10px] !bg-state/negative"
      disabled={!confirmed || loading}
      on:click={handleDelete}
    >
      {#if loading}
        Deleting

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        <TrashIcon />

        Delete Forever
      {/if}
    </button>
  </div>
</div>
