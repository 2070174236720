<script lang="ts">
  import ContextMenu from "../lib/ContextMenu.svelte";
  import NavigationAdvisor from "../lib/NavigationAdvisor.svelte";
  import ArrowLeftIcon from "../lib/icons/ArrowLeft.svelte";
  import ClientsIcon from "../lib/icons/Clients.svelte";
  import CloseIcon from "../lib/icons/Cross.svelte";
  import EyeIcon from "../lib/icons/Eye.svelte";
  import GearIcon from "../lib/icons/Gear.svelte";
  import HamburgerIcon from "../lib/icons/Hamburger.svelte";
  import HelpIcon from "../lib/icons/Help.svelte";
  import KeyIcon from "../lib/icons/Key.svelte";
  import LogoutIcon from "../lib/icons/Logout.svelte";
  import ProfileIcon from "../lib/icons/Profile.svelte";
  import { currentEstate, userCan, userPermissions } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import { navigateHome } from "../util/navigateHome";
  import { Routes, onNavigate } from "../util/routes";
  import { popErrorToast } from "../util/toasts";
  import StandardLayout from "./StandardLayout.svelte";
  import { link, useLocation, navigate } from "svelte-navigator";

  let mobileNavOpen: boolean = false;

  function toggleMobileNav(): void {
    mobileNavOpen = !mobileNavOpen;
  }

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) popErrorToast(error.message);
    else navigate("/");
  }

  /**
   * Advisors will be programmatically routed between the client-selection route
   * and their "home" route for a given estate view (based on permissions),
   * depending on whether or not they have selected an estate to view.
   */
  const location = useLocation();
  const advisorRoutes: Array<string> = [
    Routes.Clients,
    Routes.Profile,
    Routes.Help,
    Routes.LoginDetails,
  ];
  currentEstate.subscribe((estate) => {
    if (!estate && !advisorRoutes.includes($location.pathname)) {
      /**
       * ! warning
       * for some reason, when an advisor has "manage" permissions on the current
       * route, clientside routing back to clients page is not working properly.
       * (but it works fine with "view" permissions...)
       *
       * I couldn't figure out why this is the case in a reasonable timeframe,
       * so I am hacking around this by triggering an actual page chage to the
       * clients page url 😕
       */
      const useHack =
        $userPermissions?.[$location.pathname.replace("/", "") + "_write"];
      if (useHack) {
        window.location.href = Routes.Clients;
      } else {
        navigate(Routes.Clients);
      }
    }

    if (estate && $location.pathname === Routes.Clients) {
      navigateHome();
    }
  });

  /**
   * Fetch and show details about the Estate Owner when current estate changes
   */
  let ownerName: string = "";
  $: {
    if (!$currentEstate) {
      ownerName = "";
    }

    if ($currentEstate) {
      fetchOwner().then(({ data, error }) => {
        if (error) popErrorToast(error.message);
        else ownerName = `${data.first_name} ${data.last_name}`;
      });
    }
  }
  async function fetchOwner() {
    if ($currentEstate.owner_id)
      return supabase
        .from("user")
        .select("first_name, last_name")
        .eq("user_id", $currentEstate?.owner_id)
        .limit(1)
        .single();
  }

  onNavigate(() => {
    mobileNavOpen = false;
  });
</script>

{#if $currentEstate?.id && $userPermissions !== "PENDING_ESTATE_SELECTION"}
  <header
    class="dark-header relative flex justify-between px-5 py-[14px] text-body/medium text-text/lightest shadow-heavy"
    id="advisor-chrome"
  >
    <button
      class="anchor inline-flex items-center gap-[5px] !text-text/lightest transition hover:opacity-70"
      on:click={() => {
        $currentEstate = null;
        // $userPermissions = "PENDING_ESTATE_SELECTION";
      }}
    >
      <ArrowLeftIcon />
      <span class="text-body/medium font-semibold underline">
        Back to Clients
      </span>
    </button>

    {#if ownerName}
      <span
        class="inline-flex items-center gap-2 md:absolute md:left-1/2 md:-translate-x-1/2"
      >
        <EyeIcon />
        <span class="text-body/medium">
          Viewing {ownerName}
        </span>
      </span>
    {/if}
  </header>
  <StandardLayout>
    <slot />
  </StandardLayout>
{:else}
  <!-- mobile header -->
  <div
    class="fixed left-0 right-0 top-0 z-10 flex h-[64px] items-center justify-center bg-white px-[54px] md:hidden"
  >
    <button
      class="absolute left-[16px] top-[17px] transition hover:opacity-70"
      on:click={() => toggleMobileNav()}
    >
      <HamburgerIcon />
    </button>
  </div>

  <!-- mobile flyout -->
  <div class="mobile-nav min-h-[100vh] md:hidden" class:is-open={mobileNavOpen}>
    <figure
      class="relative flex justify-between bg-white py-[18px] pl-[16px] pr-[68px] shadow-mobileheader"
    >
      <span class="text-[20px] font-bold leading-[28px]">LegaSEE</span>
      <button
        class="mobile-nav-close anchor absolute right-[5px] top-[14px] text-text/medium transition hover:opacity-70 active:scale-95"
        on:click={() => toggleMobileNav()}
      >
        <CloseIcon />
      </button>
    </figure>

    <NavigationAdvisor />
  </div>

  <!-- dekstop header -->
  <div
    class="flex h-[100vh] flex-col items-stretch justify-start overflow-auto"
  >
    <header
      class="light-header flex border-b border-ui/border px-[30px] py-[18px]"
    >
      <a href={Routes.Clients} class="hover:opacity-70" use:link>
        <figure
          class="inline-flex items-center text-[20px] font-bold leading-[24px] tracking-[3px] text-text/dark"
        >
          LEGASEE
        </figure>
      </a>
      <a
        class="ml-auto inline-flex items-center gap-[5px] text-body/medium text-text/medium no-underline hover:opacity-70"
        href={Routes.Clients}
        use:link
      >
        <span class="nav-icon"><ClientsIcon /></span>
        <span>Clients</span>
      </a>

      <ContextMenu
        classes="ml-[30px] inline-flex items-center gap-[5px] text-body/medium text-text/medium no-underline hover:opacity-70"
        placement="bottom"
        offsetMain={-30}
        items={[
          {
            text: "Your Profile",
            icon: ProfileIcon,
            color: "text-text/dark",
            onClick: () => navigate(Routes.Profile),
          },
          {
            text: "Login Details",
            icon: KeyIcon,
            color: "text-text/dark",
            onClick: () => navigate(Routes.LoginDetails),
          },
          {
            text: "Log Out",
            icon: LogoutIcon,
            color: "text-text/dark",
            onClick: signOut,
          },
        ]}
      >
        <span class="nav-icon"><GearIcon /></span>
        <span>Your Account</span>
      </ContextMenu>

      <a
        class="ml-[30px] inline-flex items-center gap-[5px] text-body/medium text-text/medium no-underline hover:opacity-70"
        href={Routes.Help}
        use:link
      >
        <span class="nav-icon"><HelpIcon /></span>
        <span>Help</span>
      </a>
    </header>
    <main class="mx-auto w-[100%] max-w-[1160px] px-[30px] pb-[88px] pt-[44px]">
      <slot />
    </main>
  </div>
{/if}

<style lang="postcss">
  .nav-icon :global(svg) {
    width: 24px;
    height: 24px;
  }

  .light-header {
    background: linear-gradient(180deg, #f4f6f9 52.08%, #e9ecf1 100%);
  }

  .dark-header {
    background: linear-gradient(180deg, #222324 0%, #0d0d0e 100%);
  }

  header a :global(svg) {
    height: 24px;
    width: 24px;
  }

  .mobile-nav-close :global(svg) {
    @apply h-[36px] w-[36px] text-text/dark;
  }
</style>
