<script lang="ts">
  import { popSuccessToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import DeleteAssetForm from "./DeleteAssetForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let asset: { raw: { asset: { id: number } } };
  export let assetGroupTitle: string;

  function handleDone() {
    $modalStore[0].response(true);
    modalStore.close();
    popSuccessToast("Your asset has been deleted.");
  }
</script>

<Modal mobileExit="back">
  <DeleteAssetForm
    {asset}
    {assetGroupTitle}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  />
</Modal>
