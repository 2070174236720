import type { Writable } from "svelte/store";
import { string } from "yup";

export const passwordValidationSchema = string()
  .min(8, "Must be at least 8 characters")
  .matches(/[A-Z]/, "Must include at least one uppercase letter")
  .matches(/[a-z]/, "Must include at least one lowercase letter")
  .matches(/\d/, "Must include at least one number")
  .matches(
    /[\^\]\-\\$*.[{}()?"!@#%&/,><':;|_~`+=]/,
    "Must include at least one special character"
  )
  .matches(/^[\S]+$/, "Cannot contain empty spaces")
  .required("Required");

/**
 * This function is used to handle dollar inputs.
 * It will remove non-numeric values and format the input as a currency string
 * @param event - The input event
 * @param form - The form store the field is on
 * @param field - The field to update
 */
export function handleDollarInput(
  event: Event,
  form: Writable<any>,
  field: string
) {
  const target = event.target as HTMLInputElement;
  let value = target.value.replace(/[^0-9.]/g, "");

  const [dollars, cents] = value.split(".");
  const formattedDollars = dollars
    ? parseInt(dollars, 10).toLocaleString("en-US")
    : "";

  if (cents) {
    value = `${formattedDollars}.${cents.slice(0, 2)}`;
  } else if (value.includes(".")) {
    value = `${formattedDollars}.`;
  } else {
    value = formattedDollars;
  }

  target.value = value;

  form.update((f) => {
    f[field] = target.value;
    return f;
  });
}
