<script lang="ts" context="module">
  export type View = "Details" | "Edit" | "Delete";
  export type TabView = "Details" | "Transactions";
</script>

<script lang="ts">
  import { getDebtTypeName, type Debt } from "../../pages/Debts.svelte";
  import { supabase } from "../../supabaseClient.ts";
  import { formatDBDate, maskAccountNumber } from "../../util/format";
  import { isVisible } from "../../util/isVisible";
  import { convertCentsToDollarString } from "../../util/money";
  import { popErrorToast } from "../../util/toasts.ts";
  import BarLoader from "../BarLoader.svelte";
  import Modal from "../Modal.svelte";
  import Tab from "../Tab.svelte";
  import Tabs from "../Tabs.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import EyeIcon from "../icons/EyeClip.svelte";
  import EyeSlashIcon from "../icons/EyeSlashClip.svelte";
  import PenIcon from "../icons/Pencil.svelte";
  import TransactionsIcon from "../icons/Transactions.svelte";
  import TrashIcon from "../icons/TrashClip.svelte";
  import ViewIcon from "../icons/View.svelte";
  import TablePlaceholder from "../placeholders/ServicesTable.svelte";
  import AddUpdateDebtForm from "./AddUpdateDebtForm.svelte";
  import DeleteDebtForm from "./DeleteDebtForm.svelte";
  import { getDebtDisplayName, type DebtType } from "./debts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";

  export let debt: Debt;

  type View = "Details" | "Edit" | "Delete";
  export let view: View = "Details";
  export let tabView: TabView = "Details";
  export let transactionId: string = null;

  let transactions = [];
  let transactionsLoading = false;

  let accountNumberVisible = false;

  function handleDoneUpdating({ detail }: { detail: Debt }) {
    view = "Details";

    debt = { ...detail };
    $modalStore[0].response(debt);
  }

  function handleDoneDeleting({ detail }: { detail: number }) {
    $modalStore[0].response(detail);
    modalStore.close();
  }

  async function getTransactions(offset = 0) {
    try {
      transactionsLoading = true;

      const { data, error } = await supabase.functions.invoke(
        "fetch-transactions",
        {
          body: {
            plaidAccountIds: [debt.plaid_account_id],
            offset,
          },
        }
      );

      if (error) throw error;

      transactions = [...transactions, ...data];
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      transactionsLoading = false;

      if (transactionId) {
        setTimeout(() => {
          attemptScrollToTransaction(transactionId);
        }, 50);
      }
    }
  }

  function loadMoreTransactions() {
    const transactionsCount = transactions.length;
    const hasMoreTransactions =
      transactionsCount > 0 && transactionsCount % 100 === 0;

    if (hasMoreTransactions) {
      getTransactions(transactionsCount);
    }
  }

  function attemptScrollToTransaction(transactionId: string): void {
    const transactionRow = document.querySelector(
      `[data-transaction="${transactionId}"]`
    );
    if (transactionRow)
      transactionRow.scrollIntoView({
        behavior: "smooth",
      });
  }

  $: debtType = debt.debt_type as DebtType;
  $: isConnected = !!debt.plaid_account_id;

  onMount(() => {
    if (isConnected) {
      getTransactions();
    }
  });
</script>

<Modal mobileExit="hide">
  {#if view === "Details"}
    <button class="mobile-back-button md:hidden" on:click={modalStore.close}>
      <ArrowLeftIcon />
    </button>

    <div class="mobile-content w-[750px] max-w-[100%] max-md:!top-[85px]">
      <div
        class="mobile-header mb-[40px] px-[50px] pt-[30px] max-md:block max-md:!h-[85px]"
      >
        <h1 class="mobile-header-title-fixed max-md:!text-left">
          {getDebtTypeName(debtType)}
        </h1>

        <p
          class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
        >
          {getDebtDisplayName(debt)}
        </p>
      </div>

      {#if isConnected}
        <Tabs mobileSubtitle>
          <Tab bind:group={tabView} value="Details">
            <ViewIcon /> <span class="mobile-tab-text">Details</span>
          </Tab>
          <Tab bind:group={tabView} value="Transactions">
            <TransactionsIcon />
            <span class="mobile-tab-text">Transactions</span>
          </Tab>
        </Tabs>
      {/if}

      {#if tabView === "Details"}
        <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
          <div
            class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
          >
            <div
              class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] bg-bg/lighter p-[6px] pl-[15px]"
            >
              <div
                class="mobile-list-header-text text-body/large font-semibold text-text/dark"
              >
                Debt Details
              </div>

              {#if !isConnected}
                <button
                  type="button"
                  class="mobile-list-header-link btn-main btn-sm"
                  on:click={() => (view = "Edit")}
                >
                  <PenIcon />

                  Edit
                </button>
              {/if}
            </div>

            <div class="flex flex-wrap rounded-bl-[5px] rounded-br-[5px]">
              <div
                class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
              >
                <div>
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Institution
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    {debt.institution_name}
                  </div>
                </div>
              </div>

              {#if debt.account_number}
                <div
                  class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
                >
                  <div>
                    <div
                      class="mobile-list-item-label mb-[8px] text-text/medium"
                    >
                      Account #
                    </div>
                    <div
                      class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                    >
                      {accountNumberVisible
                        ? debt.account_number
                        : maskAccountNumber(debt.account_number)}
                    </div>
                  </div>

                  <button
                    type="button"
                    class="btn-main btn-sm"
                    on:click={() =>
                      (accountNumberVisible = !accountNumberVisible)}
                  >
                    {#if accountNumberVisible}
                      <EyeSlashIcon /> Hide
                    {:else}
                      <EyeIcon /> View
                    {/if}
                  </button>
                </div>
              {/if}

              <div
                class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
              >
                <div class="mobile-list-item-label mb-[8px] text-text/medium">
                  Current Balance
                </div>
                <div
                  class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                >
                  <sup>$</sup>
                  {convertCentsToDollarString(debt.current_balance)}
                </div>
              </div>

              {#if debt.credit_limit}
                <div
                  class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
                >
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Credit Limit
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    <sup>$</sup>
                    {convertCentsToDollarString(debt.credit_limit)}
                  </div>
                </div>
              {/if}

              {#if debt.original_loan_amount}
                <div
                  class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
                >
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Original Loan Amount
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    <sup>$</sup>
                    {convertCentsToDollarString(debt.original_loan_amount)}
                  </div>
                </div>
              {/if}

              {#if debt.payment_frequency}
                <div
                  class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
                >
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Payment Frequency
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    {debt.payment_frequency}
                  </div>
                </div>
              {/if}
            </div>
          </div>

          <button
            class="mobile-delete-btn variant-ringed-error btn border-state/negative bg-white text-state/negative"
            on:click={() => (view = "Delete")}
          >
            <TrashIcon />

            Delete Debt
          </button>
        </div>
      {:else if tabView === "Transactions"}
        <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
          {#if transactionsLoading && !transactions?.length}
            <TablePlaceholder />
          {:else}
            <div class="mobile-list-container table-container">
              <table class="table max-md:border-t">
                <thead class="!bg-bg/lighter">
                  <th class="rounded-tl-[6px]">Date</th>
                  <th>Description</th>
                  <th>Category</th>
                  <th class="rounded-tr-[6px] text-right">Amount</th>
                </thead>

                <tbody>
                  {#each transactions as { transaction_id, date, name, category, amount } (transaction_id)}
                    {@const isCredit = amount < 0}

                    <tr data-transaction={transaction_id}>
                      <td>
                        <span class="mobile-list-item-value md:hidden">
                          {formatDBDate(date, "M/d/yy")}
                        </span>
                        <span class="max-md:hidden">
                          {formatDBDate(date, "MMM dd, yyyy")}
                        </span>
                      </td>
                      <td>
                        <span class="mobile-list-item-value">{name}</span>
                      </td>
                      <td class="max-md:hidden">{category?.[0]}</td>
                      <td
                        class="text-right {isCredit
                          ? 'text-state/positive'
                          : ''}"
                      >
                        <span class="mobile-list-item-value">
                          {!isCredit ? "-" : ""}
                          <sup>$</sup>{convertCentsToDollarString(
                            Math.abs(amount * 100),
                            true,
                            false
                          )}
                        </span>
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>

            <div class="mt-4" use:isVisible={loadMoreTransactions}>
              {#if transactionsLoading}
                <BarLoader />
              {/if}
            </div>
          {/if}
        </div>
      {/if}
    </div>
  {:else if view === "Edit"}
    <div class="mobile-content w-[750px] max-w-[100%] max-md:!top-[85px]">
      <AddUpdateDebtForm
        {debt}
        {debtType}
        on:done={handleDoneUpdating}
        on:cancel={() => (view = "Details")}
      />
    </div>
  {:else}
    <DeleteDebtForm
      {debt}
      on:done={handleDoneDeleting}
      on:cancel={() => (view = "Details")}
    />
  {/if}
</Modal>
