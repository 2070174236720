import { currentEstate } from "../stores/user.ts";
import { supabase } from "../supabaseClient.ts";
import type { Document } from "../types/index.ts";
import { get } from "svelte/store";

const ESTATE_BUCKET = "estate_documents";
const getCurrentEstateId = () => get(currentEstate).id;

const validFileTypes = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/tiff",
];

async function upsertFile(file: File, path: string, meta: DocumentMeta) {
  if (!validFileTypes.includes(file.type)) {
    return {
      data: null,
      error: new Error("Not a valid file type"),
    };
  }

  // As a side effect we also add it to the document table
  // Supabase upsert only supports primary key which we don't have,
  // so we have to check if it exists and update if so
  const { data, error } = await supabase
    .from("document")
    .select("id")
    .eq("path", path);
  if (error) throw error;
  if (data.length) {
    const { error } = await supabase
      .from("document")
      .update({
        ...meta,
        filename: file.name,
      })
      .eq("path", path);
    if (error) throw error;
  } else {
    const { error } = await supabase.from("document").insert({
      estate_id: getCurrentEstateId(),
      description: meta.description,
      category: meta.category,
      path,
      type: meta.type,
      filename: file.name,
      notes: meta.notes,
    });
    if (error) throw error;
  }
  return supabase.storage.from(ESTATE_BUCKET).upload(path, file, {
    upsert: true,
  });
}

async function deleteFile(path: string) {
  const { error } = await supabase.from("document").delete().eq("path", path);
  if (error) throw error;
  return supabase.storage.from(ESTATE_BUCKET).remove([path]);
}

async function deleteFiles(paths: string[]) {
  const { error } = await supabase.from("document").delete().in("path", paths);
  if (error) throw error;
  return supabase.storage.from(ESTATE_BUCKET).remove(paths);
}

// Will
export async function addOrUpdateWillDocument(file: File) {
  const { data, error } = await supabase
    .from("document")
    .select("path")
    .eq("type", "will")
    .eq("estate_id", getCurrentEstateId());
  if (error) throw error;
  if (data.length) {
    await deleteFiles(data.map((doc) => doc.path));
  }
  return upsertFile(file, `${getCurrentEstateId()}/estate/will/${file.name}`, {
    description: "Will",
    category: "estate",
    type: "will",
    notes: null,
  });
}

export async function deleteWillDocuments() {
  const { data, error } = await supabase
    .from("document")
    .select("path")
    .eq("type", "will")
    .eq("estate_id", getCurrentEstateId());
  if (error) throw error;
  return deleteFile(data[0].path);
}

export function getWillDocuments() {
  return supabase
    .from("document")
    .select("*")
    .eq("type", "will")
    .eq("estate_id", getCurrentEstateId());
}

// Trusts
export async function addOrUpdateTrustDocument(file: File, trustName: string) {
  const { data, error } = await supabase
    .from("document")
    .select("path")
    .eq("type", "trust")
    .eq("description", trustName)
    .eq("estate_id", getCurrentEstateId());
  if (error) throw error;
  if (data.length) {
    deleteFiles(data.map((doc) => doc.path));
  }
  return upsertFile(
    file,
    `${getCurrentEstateId()}/estate/trusts/${trustName}/${file.name}`,
    {
      description: trustName,
      category: "estate",
      type: "trust",
      notes: null,
    }
  );
}

export async function getTrustDocuments(trustName: string) {
  return supabase
    .from("document")
    .select("*")
    .eq("type", "trust")
    .eq("description", trustName)
    .eq("estate_id", getCurrentEstateId());
}

export async function deleteTrustDocuments(trustName: string) {
  const { data, error } = await supabase
    .from("document")
    .select("path")
    .eq("type", "trust")
    .eq("description", trustName)
    .eq("estate_id", getCurrentEstateId());
  if (error) throw error;
  const paths = data.map((doc) => doc.path);
  return deleteFiles(paths);
}

// Documents
export async function addDocument(
  file: File,
  category: DocumentCategory,
  description: string,
  notes: Record<string, any> = null
) {
  const path = `${getCurrentEstateId()}/${category}/${description}/${
    file.name
  }`;
  return upsertFile(file, path, {
    description,
    category,
    type: "general",
    notes,
  });
}

export async function updateDocument(
  document: Document,
  updateFields: {
    description: string;
    category: DocumentCategory;
  },
  file?: File
) {
  const { id, path, filename } = document;
  if (file) {
    if (file.name !== document.filename) {
      await deleteFile(path);
    }
    await addDocument(file, updateFields.category, updateFields.description);
  } else {
    const newPath = `${getCurrentEstateId()}/${updateFields.category}/${
      updateFields.description
    }/${filename}`;
    if (path !== newPath) {
      await supabase.storage.from(ESTATE_BUCKET).move(path, newPath);
    }
    return await supabase
      .from("document")
      .update({
        ...updateFields,
        path: newPath,
      })
      .eq("id", id);
  }
}

export async function deleteDocument(document: Document) {
  return deleteFile(document.path);
}

export async function getSignedUrl(path: string) {
  const { data, error } = await supabase.storage
    .from(ESTATE_BUCKET)
    .createSignedUrl(path, 3_600);

  if (error) throw error;

  return data.signedUrl;
}

export type DocumentMeta = Pick<
  Document,
  "description" | "category" | "type" | "notes"
>;

export const documentCategories = [
  "estate",
  "family",
  "financial",
  "health",
  "identity",
  "legal",
] as const;

export type DocumentCategory = (typeof documentCategories)[number];
