<script lang="ts">
  import { addOrUpdateWillDocument } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import FileUpload from "../FileUpload.svelte";
  import Modal from "../Modal.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial, modalStore } from "@skeletonlabs/skeleton";

  export let replace = false;

  let submitClicked = false;
  let loading = false;

  let file: File;

  async function uploadFile() {
    try {
      loading = true;

      const { error } = await addOrUpdateWillDocument(file);

      if (error) throw error;

      $modalStore[0].response(true);
      modalStore.close();
      popSuccessToast("Your will has been uploaded successfully.");
    } catch (error) {
      popErrorToast(
        error?.message || "An error occurred while uploading your will."
      );
    } finally {
      loading = false;
    }
  }

  function handleSave() {
    submitClicked = true;

    if (!file) return;

    uploadFile();
  }
</script>

<Modal mobileExit="back">
  <div class="mobile-content w-[630px] max-w-[100%]">
    <div
      class="mobile-header border-b border-b-ui/border-light px-[50px] py-[30px]"
    >
      <h1 class="mobile-header-title">
        {replace ? "Update" : "Upload"} Your Will
      </h1>
    </div>

    <div class="mobile-content-body px-[50px] py-[50px] shadow-forms">
      <div class="input-wrap">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label>Legal Document <span class="text-state/negative">*</span></label>

        <FileUpload bind:file />

        {#if !file && submitClicked}
          <small class="input-error"><WarnIcon /> Required</small>
        {/if}
      </div>
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between border-t border-ui/border-light px-[50px] py-[40px]"
    >
      <button
        type="button"
        class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
        on:click={() => modalStore.close()}
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn-filled btn-lg btn-success flex gap-[10px]"
        disabled={!file || loading}
        on:click={handleSave}
      >
        {#if loading}
          Saving

          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
        {:else}
          <CheckIcon />

          Save
        {/if}
      </button>
    </div>
  </div>
</Modal>
