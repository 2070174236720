<script lang="ts">
  import { Routes } from "../../util/routes";
  import ReadinessMeter from "../readiness/ReadinessMeter.svelte";
  import {
    calculateCompletionPercentage,
    showTasks,
    taskList,
  } from "../readiness/readiness";
  import Card from "./Card.svelte";
  import Meter from "./Meter.svelte";
  import { capitalize } from "lodash";
  import { navigate } from "svelte-navigator";

  $: overallPercentage = calculateCompletionPercentage($taskList);
  $: categories = Object.keys($showTasks);

  function handleClick(taskCategory: string) {
    navigate(Routes[capitalize(taskCategory)], { state: { openTasks: true } });
  }
</script>

<Card>
  <svelte:fragment slot="header">Estate Readiness</svelte:fragment>

  <div slot="body" class="flex flex-wrap justify-evenly gap-10 p-5 pb-4">
    <Meter bind:percent={overallPercentage} />
    <div
      class="grid max-w-[260px] flex-grow grid-cols-[1fr_auto] gap-x-[10px] gap-y-1"
    >
      {#each categories as taskCategory}
        {@const categoryTasks = $taskList.filter(
          ({ category }) => category === taskCategory
        )}
        {@const percent = calculateCompletionPercentage(categoryTasks)}
        {@const canClick = $showTasks[taskCategory]}
        <button
          class="contents py-1"
          class:cursor-text={!canClick}
          class:hover:opacity-70={canClick}
          on:click={canClick ? () => handleClick(taskCategory) : () => null}
        >
          <span class="min-w-[70px]">
            <ReadinessMeter {percent} thickness={10} />
          </span>
          <span class="text-left text-body/x-small capitalize">
            {capitalize(taskCategory)}
          </span>
        </button>
      {/each}
    </div>
  </div>
</Card>
