<script lang="ts">
  import { indexOf } from "lodash";
  import { createEventDispatcher, setContext, tick } from "svelte";
  import { type Writable, writable } from "svelte/store";

  const dispatch = createEventDispatcher();

  let state: Writable<{
    step: number;
    steps: number[];
    direction: "forward" | "back";
    height?: number;
  }> = writable({
    step: 0,
    steps: [],
    direction: "forward",
  });

  function sortSteps() {
    return $state.steps.sort((a, b) => a - b);
  }

  export function goForward() {
    tick().then(() => {
      sortSteps();
      $state = {
        ...$state,
        step: Math.min(
          $state.steps[indexOf($state.steps, $state.step) + 1],
          Math.max(...$state.steps)
        ),
        direction: "forward",
      };
      dispatch("stepChange", $state.step);
    });
  }

  export function goBack() {
    tick().then(() => {
      sortSteps();
      $state = {
        ...$state,
        step: Math.max($state.steps[indexOf($state.steps, $state.step) - 1], 0),
        direction: "back",
      };
      dispatch("stepChange", $state.step);
    });
  }

  setContext("stepperState", state);
  setContext("forward", goForward);
  setContext("back", goBack);

  $: dispatch("change", $state.step);
</script>

<div
  style={`height: ${$state.height}px`}
  class="transition-height relative duration-100"
>
  <slot />
</div>
