<script lang="ts">
  import BlockIcon from "../icons/Block.svelte";
  import EyeIcon from "../icons/Eye.svelte";
  import EyeSlashIcon from "../icons/EyeSlash.svelte";
  import TooltipIcon from "../icons/Tooltip.svelte";
  import type { UIPermissions } from "./collaborators";
  import { popup } from "@skeletonlabs/skeleton";
  import { isEqual } from "lodash";

  /**
   * Only beneficiaries will have access to the "beneficiary" preset
   * and the assets: "read_designated" option
   *
   * Other user types should always pass in this prop as true
   */
  export let isCollaborator: boolean = false;

  const beneficiaryPermissions: UIPermissions = {
    dashboard: "none",
    assets: "read_designated",
    debts: "none",
    services: "none",
    beneficiaries: "none",
    showDollarValues: false,
    vault: "none",
  };

  const advisorPermissions: UIPermissions = {
    dashboard: "read",
    assets: "read",
    debts: "read",
    services: "read",
    beneficiaries: "read",
    showDollarValues: true,
    vault: "read",
  };

  const noPermissions: UIPermissions = {
    dashboard: "none",
    assets: "none",
    debts: "none",
    services: "none",
    beneficiaries: "none",
    showDollarValues: false,
    vault: "none",
  };

  export let permissions: UIPermissions = isCollaborator
    ? advisorPermissions
    : beneficiaryPermissions;

  let selectedTemplate: Preset;

  //set initial selected template by props
  if (isEqual(permissions, beneficiaryPermissions))
    selectedTemplate = "beneficiary";
  else if (isEqual(permissions, advisorPermissions))
    selectedTemplate = "advisor";
  else selectedTemplate = "custom";

  function setPreset(preset: Preset): void {
    selectedTemplate = preset;
    switch (preset) {
      case "beneficiary":
        permissions = beneficiaryPermissions;
        break;
      case "advisor":
        permissions = advisorPermissions;
        break;
      case "custom":
        permissions = noPermissions;
        break;
    }
  }

  type Preset = "beneficiary" | "advisor" | "custom";
</script>

<div
  class="border-b border-t border-ui/border-light md:py-[50px] md:pl-[50px] md:shadow-forms"
>
  <div class="flex flex-col">
    <div class="md:pr-[50px]">
      <label for="template">
        Template <span class="text-state/negative">*</span>
      </label>

      <div class="buttonbar bg-white">
        {#if !isCollaborator}
          <button
            type="button"
            class="buttonbar-item {selectedTemplate === 'beneficiary'
              ? 'active'
              : ''}"
            on:click={() => setPreset("beneficiary")}
          >
            View Designated
          </button>
        {/if}
        <button
          type="button"
          class="buttonbar-item {selectedTemplate === 'advisor'
            ? 'active'
            : ''}"
          class:max-w-[50%]={isCollaborator}
          on:click={() => setPreset("advisor")}
        >
          View All
        </button>
        <button
          type="button"
          class="buttonbar-item {selectedTemplate === 'custom' ? 'active' : ''}"
          on:click={() => setPreset("custom")}
        >
          Custom...
        </button>
      </div>
    </div>
  </div>

  <div class="mt-[50px]">
    <span class="form-label block">
      Permissions <span class="text-state/negative">*</span>
    </span>
    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <label
        for="readiness"
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
      >
        Dashboard
      </label>
      {#if selectedTemplate === "custom"}
        <div>
          <select
            id="readiness"
            class="w-[300px] max-w-[100%] py-[12px]"
            bind:value={permissions.dashboard}
          >
            <option value="read">Can View</option>
            <option value="none">No Access</option>
          </select>
        </div>
      {/if}
      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/negative">
          <BlockIcon /> No Access
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View
        </div>
      {/if}
    </div>

    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <label
        for="assets"
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
        >Asset Section</label
      >
      <div class:hidden={selectedTemplate !== "custom"}>
        <select
          id="assets"
          class="w-[300px] max-w-[100%] py-[12px]"
          bind:value={permissions.assets}
        >
          <option value="read">Can View</option>
          {#if !isCollaborator}
            <option value="read_designated">Can View Designated</option>
          {/if}
          <option value="write">Can Manage</option>
          <option value="none">No Access</option>
        </select>
      </div>

      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View Designated
          <span
            class="ml-[5px] inline-block cursor-help text-state/link transition hover:opacity-70 [&>*]:pointer-events-none"
            use:popup={{
              event: "hover",
              target: `perm-tooltip`,
              placement: "left-start",
              middleware: {
                offset: {
                  mainAxis: -25,
                  crossAxis: 35,
                },
              },
            }}
          >
            <TooltipIcon />
          </span>
          <div
            class="rounded-[6px] border border-black bg-text/dark px-[14px] py-[12px] text-white shadow-heavy"
            data-popup="perm-tooltip"
          >
            <p class="max-w-[300px] text-body/small leading-[24px]">
              This permission allows beneficiaries to only view assets which are
              designated to them.
            </p>
          </div>
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View
        </div>
      {/if}
    </div>

    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <label
        for="debts"
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
        >Debts Section</label
      >
      <div class:hidden={selectedTemplate !== "custom"}>
        <select
          id="debts"
          class="w-[300px] max-w-[100%] py-[12px]"
          bind:value={permissions.debts}
        >
          <option value="read">Can View</option>
          <option value="write">Can Manage</option>
          <option value="none">No Access</option>
        </select>
      </div>
      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/negative">
          <BlockIcon /> No Access
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View
        </div>
      {/if}
    </div>

    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <label
        for="services"
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
        >Services Section</label
      >
      <div class:hidden={selectedTemplate !== "custom"}>
        <select
          id="services"
          class="w-[300px] max-w-[100%] py-[12px]"
          bind:value={permissions.services}
        >
          <option value="read">Can View</option>
          <option value="write">Can Manage</option>
          <option value="none">No Access</option>
        </select>
      </div>
      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/negative">
          <BlockIcon /> No Access
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View
        </div>
      {/if}
    </div>

    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <label
        for="beneficiaries"
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
        >Beneficiaries Section</label
      >
      <div class:hidden={selectedTemplate !== "custom"}>
        <select
          id="beneficiaries"
          class="w-[300px] max-w-[100%] py-[12px]"
          bind:value={permissions.beneficiaries}
        >
          <option value="read">Can View</option>
          <option value="write">Can Manage</option>
          <option value="none">No Access</option>
        </select>
      </div>
      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/negative">
          <BlockIcon /> No Access
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View
        </div>
      {/if}
    </div>

    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <label
        for="beneficiaries"
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
        >Vault Section</label
      >
      <div class:hidden={selectedTemplate !== "custom"}>
        <select
          id="vault"
          class="w-[300px] max-w-[100%] py-[12px]"
          bind:value={permissions.vault}
        >
          <option value="read">Can View</option>
          <option value="write">Can Manage</option>
          <option value="none">No Access</option>
        </select>
      </div>
      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/negative">
          <BlockIcon /> No Access
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive">
          <EyeIcon /> Can View
        </div>
      {/if}
    </div>

    <div
      class="items-center justify-between border-t border-ui/border-light py-[25px] pr-[50px] max-md:border-t max-md:border-ui/border-dark md:flex md:py-[15px]"
    >
      <div
        class="m-0 text-body/large font-normal text-text/dark max-md:mb-[12px] max-md:text-body/small"
      >
        Dollar Amounts
        <span class="mt-[12px] text-body/small text-text/medium md:block">
          <span class="md:hidden">&nbsp;(</span>Platform-wide<span
            class="md:hidden">)</span
          >
        </span>
      </div>
      <div class="flex gap-[30px]" class:hidden={selectedTemplate !== "custom"}>
        <label class="input-option">
          <input
            type="radio"
            bind:group={permissions.showDollarValues}
            value={true}
          />
          Show
        </label>
        <label class="input-option">
          <input
            type="radio"
            bind:group={permissions.showDollarValues}
            value={false}
          />
          Hide
        </label>
      </div>
      {#if selectedTemplate === "beneficiary"}
        <div class="perm-value text-state/negative md:!items-start">
          <EyeSlashIcon />
          <span class="relative top-[2px]">Hide Amounts</span>
        </div>
      {:else if selectedTemplate === "advisor"}
        <div class="perm-value text-state/positive md:!items-start">
          <EyeIcon /> <span class="relative top-[2px]">Show Amounts</span>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="postcss">
  .buttonbar {
    box-shadow: 0 0 0 1px #c4cdda inset, 0 2px 4px rgba(0, 0, 0, 0.05);
    @apply flex flex-auto overflow-hidden rounded-[6px] text-body/large;
  }
  .buttonbar-item {
    @apply flex-grow py-[13px] text-center text-text/dark;
  }

  .buttonbar-item.active {
    @apply bg-state/link font-semibold text-white;
  }

  .buttonbar-item:nth-of-type(2) {
    @apply border-l border-r border-state/link;
  }

  .buttonbar-item:nth-of-type(2):not(.active) {
    @apply border-ui/border-dark;
  }

  .perm-value {
    @apply flex items-center gap-[5px] text-[20px] font-semibold leading-[20px] max-md:pt-[3px] max-md:text-[18px] max-md:leading-[22px] md:min-h-[54px] md:justify-end;
  }

  .perm-value :global(svg) {
    @apply h-[24px] w-[24px] max-md:h-[18px] max-md:w-[18px];
  }
</style>
