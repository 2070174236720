<script lang="ts">
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { popSuccessToast, popErrorToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import AccessFormField from "./AccessFormField.svelte";
  import {
    convertDBPermissionsToUIPermissions,
    convertUIPermissionsToDBPermissions,
  } from "./collaborators";
  import type { CollaboratorProfile } from "./collaborators";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { get } from "svelte/store";

  export let collaborator: CollaboratorProfile;

  let { user_data, ...dbPermissions } = collaborator;
  let uiPermissions = convertDBPermissionsToUIPermissions(dbPermissions);
  let fullName = `${user_data.first_name} ${user_data.last_name}`;

  let processing = false;

  async function updatePermissions() {
    try {
      processing = true;

      const permissions = convertUIPermissionsToDBPermissions(uiPermissions);

      const { error } = await supabase
        .from("permission")
        .update(permissions)
        .eq("collaborator_id", user_data.user_id)
        .eq("estate_id", get(currentEstate).id);

      if (error) throw error;

      popSuccessToast(`Success!\n Updated permissions for ${fullName}`);
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast(error.message);
      $modalStore[0].response(false);
    } finally {
      modalStore.close();
    }
  }
</script>

<Modal mobileExit="back">
  <form on:submit|preventDefault={updatePermissions}>
    <div class="mobile-content w-[750px] max-w-[100%] max-md:!top-[85px]">
      <div
        class="mobile-header mb-[30px] px-[50px] pt-[30px] max-md:block max-md:!h-[85px]"
      >
        <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Access</h1>
        <p
          class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
        >
          {fullName}
        </p>
      </div>

      <div class="mobile-content-body">
        <AccessFormField
          bind:permissions={uiPermissions}
          isCollaborator={collaborator.user_data.user_type !== "beneficiary"}
        />
      </div>

      <div
        class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
      >
        <button
          type="button"
          class="btn-main mobile-back-button"
          on:click|preventDefault={modalStore.close}
        >
          <span class="md:hidden">
            <ArrowLeftIcon />
          </span>
          <span>Cancel</span>
        </button>
        <button
          type="submit"
          disabled={processing}
          class="btn-lg btn variant-filled bg-state/positive"
        >
          <CheckIcon />
          <span>{processing ? "Updating..." : "Save Changes"}</span>
        </button>
      </div>
    </div>
  </form>
</Modal>
