<script lang="ts">
  import DateInput from "../lib/DateInput.svelte";
  import CalendarIcon from "../lib/icons/Calendar.svelte";
  import CheckIcon from "../lib/icons/Check.svelte";
  import WarnIcon from "../lib/icons/WarnFilled.svelte";
  import { userStore } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import { popErrorToast, popSuccessToast } from "../util/toasts";
  import type { AuthSession } from "@supabase/supabase-js";
  import { createForm } from "svelte-forms-lib";
  import { object, string, date } from "yup";

  export let session: AuthSession;

  let submitClicked = false;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        firstName: $userStore?.first_name ?? null,
        lastName: $userStore?.last_name ?? null,
        dateOfBirth: $userStore?.date_of_birth ?? null,
      },
      validationSchema: object().shape({
        firstName: string().required("Required"),
        lastName: string().required("Required"),
        dateOfBirth: date()
          .typeError("Must be a valid date")
          .required("Required"),
      }),
      onSubmit: async (formData) => {
        await updateProfile(
          formData.firstName,
          formData.lastName,
          formData.dateOfBirth
        );

        popSuccessToast("Profile updated successfully");
      },
    }
  );

  async function updateProfile(
    first_name: string,
    last_name: string,
    date_of_birth: string
  ) {
    try {
      const { user } = session;

      const updates = {
        first_name,
        last_name,
        date_of_birth,
      };

      let { data, error } = await supabase
        .from("user")
        .update(updates)
        .eq("user_id", user.id)
        .select()
        .single();

      if (error) {
        throw error;
      }

      //? update client store
      $userStore = {
        ...$userStore,
        ...data,
      };
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    }
  }
</script>

<h1 class="mobile-header-title-fixed">Your Profile</h1>

<hr class="mb-[50px] mt-[30px] max-md:hidden" />

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body">
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:max-w-[775px]"
    >
      <div class="input-wrap">
        <label for="firstName">
          First Name <span class="text-state/negative">*</span>
        </label>

        <input
          id="firstName"
          name="firstName"
          type="text"
          bind:value={$form.firstName}
          on:change={handleChange}
        />

        {#if $errors.firstName && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.firstName}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="lastName">
          Last Name <span class="text-state/negative">*</span>
        </label>

        <input
          id="lastName"
          name="lastName"
          type="text"
          bind:value={$form.lastName}
          on:change={handleChange}
        />

        {#if $errors.lastName && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.lastName}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="dateOfBirth">
          Date of Birth <span class="text-state/negative">*</span>
        </label>
        <DateInput bind:value={$form.dateOfBirth} />
        {#if $errors.dateOfBirth && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.dateOfBirth}</small>
        {/if}
      </div>
    </div>
  </div>

  <div class="mobile-content-footer">
    <button
      type="submit"
      class="btn-filled btn-lg btn-success flex gap-[10px] md:mt-[60px]"
      disabled={$isSubmitting}
      on:click={() => (submitClicked = true)}
    >
      <CheckIcon />

      {$isSubmitting ? "Saving ..." : "Save Changes"}
    </button>
  </div>
</form>
