<script lang="ts">
  import ModalStep from "../lib/ModalStep.svelte";
  import ModalStepper from "../lib/ModalStepper.svelte";
  import BeneficiaryCharity from "../lib/onboarding/BeneficiaryCharity.svelte";
  import BeneficiaryPerson from "../lib/onboarding/BeneficiaryPerson.svelte";
  import BeneficiaryPrompt from "../lib/onboarding/BeneficiaryPrompt.svelte";
  import BeneficiarySelectType from "../lib/onboarding/BeneficiarySelectType.svelte";
  import Credentials from "../lib/onboarding/Credentials.svelte";
  import PersonalInfo from "../lib/onboarding/PersonalInfo.svelte";
  import StepIndicator from "../lib/onboarding/StepIndicator.svelte";
  import TaxReturn from "../lib/onboarding/TaxReturn.svelte";
  import Trust from "../lib/onboarding/Trust.svelte";
  import TrustPrompt from "../lib/onboarding/TrustPrompt.svelte";
  import Welcome from "../lib/onboarding/Welcome.svelte";
  import Will from "../lib/onboarding/Will.svelte";
  import WillPrompt from "../lib/onboarding/WillPrompt.svelte";
  import { userStore } from "../stores/user";
  import type { OnboardingData } from "../types/Onboarding";

  /**
   * Onboarding data is empty for organic signups, but invites are pre-populated
   * with email, name from the user store
   */
  let data: OnboardingData = {
    // Credentials,
    email: $userStore?.email ?? null,
    password: null,

    // PersonalInfo,
    firstName: $userStore?.first_name ?? null,
    lastName: $userStore?.last_name ?? null,
    dateOfBirth: null,
    ssn: null,

    // TaxReturn,
    taxReturn: null,

    // Will,
    hasWill: false,
    willDocument: null,

    // Trusts,
    trusts: [],

    // Beneficiaries,
    beneficiaries: [],
  };

  let step: number;
  let started = false;
</script>

<section
  class="flex min-h-full items-center justify-center bg-gradient-to-br from-[#3A86FF] to-[#06BA63]"
>
  <div
    class="h-fit min-h-[300px] w-[835px] max-w-[100%] rounded-lg bg-white shadow-onboarding max-[880px]:rounded-none"
  >
    {#if step !== undefined}
      <StepIndicator {step} />
    {/if}

    {#if started}
      <ModalStepper on:change={({ detail }) => (step = detail)}>
        <ModalStep step={0} let:goForward>
          <Credentials {goForward} bind:data />
        </ModalStep>

        <ModalStep step={1} let:goBack let:goForward>
          <PersonalInfo {goBack} {goForward} bind:data />
        </ModalStep>

        <ModalStep step={2} let:goBack let:goForward>
          <TaxReturn {goBack} {goForward} bind:data />
        </ModalStep>

        <ModalStep step={3} let:goBack let:goForward>
          <WillPrompt {goBack} {goForward} bind:data />
        </ModalStep>

        {#if data.hasWill}
          <ModalStep step={4} let:goBack let:goForward>
            <Will {goBack} {goForward} bind:data />
          </ModalStep>
        {/if}

        <ModalStep step={5} let:goBack let:goForward>
          <TrustPrompt {goBack} {goForward} bind:data />
        </ModalStep>

        {#each data.trusts as trust, i}
          <ModalStep step={5 + (i * 2 + 1)} let:goBack let:goForward>
            <Trust {goBack} {goForward} bind:trust index={i} />
          </ModalStep>

          <ModalStep step={5 + (i * 2 + 2)} let:goBack let:goForward>
            <TrustPrompt {goBack} {goForward} bind:data index={i} />
          </ModalStep>
        {/each}

        <ModalStep step={100} let:goBack let:goForward>
          <BeneficiaryPrompt {goBack} {goForward} bind:data />
        </ModalStep>

        {#each data.beneficiaries as beneficiary, i}
          <ModalStep step={100 + (i * 3 + 1)} let:goBack let:goForward>
            <BeneficiarySelectType
              {goBack}
              {goForward}
              bind:beneficiary
              index={i}
            />
          </ModalStep>

          <ModalStep step={100 + (i * 3 + 2)} let:goBack let:goForward>
            {#if beneficiary.type === "person"}
              <BeneficiaryPerson {goBack} {goForward} bind:beneficiary />
            {:else}
              <BeneficiaryCharity {goBack} {goForward} bind:beneficiary />
            {/if}
          </ModalStep>

          <ModalStep step={100 + (i * 3 + 3)} let:goBack let:goForward>
            <BeneficiaryPrompt {goBack} {goForward} bind:data index={i} />
          </ModalStep>
        {/each}
      </ModalStepper>
    {:else}
      <Welcome on:start={() => (started = true)} />
    {/if}
  </div>
</section>
