<script lang="ts">
  import type { Charity } from "../../pages/Beneficiaries.svelte";
  import Modal from "../Modal.svelte";
  import DeleteBeneficiaryCharityForm from "./DeleteBeneficiaryCharityForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let charity: Charity;

  function handleDone() {
    $modalStore[0].response(charity.id);
    modalStore.close();
  }
</script>

<Modal mobileExit="back">
  <DeleteBeneficiaryCharityForm
    {charity}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  />
</Modal>
