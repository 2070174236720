<script lang="ts">
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { addOrUpdateTrustDocument } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import FileUpload from "../FileUpload.svelte";
  import Modal from "../Modal.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial, modalStore } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  let submitClicked = false;

  let file: File;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        name: "",
      },
      validationSchema: object().shape({
        name: string().required("Required"),
      }),
      onSubmit: async (formData) => {
        try {
          const { data, error } = await supabase
            .from("trust")
            .insert({ estate_id: $currentEstate.id, name: formData.name })
            .select("id");

          if (error) throw error;

          if (file) {
            const { error } = await addOrUpdateTrustDocument(
              file,
              formData.name
            );

            // Delete trust record if file upload fails
            if (error) {
              await supabase.from("trust").delete().eq("id", data[0].id);

              throw error;
            }
          }

          $modalStore[0].response(true);
          modalStore.close();
          popSuccessToast("Your trust has been saved successfully.");
        } catch (error) {
          if (error.message.includes("unique_trust_name_in_estate")) {
            popErrorToast("A trust with that name already exists");
            return;
          } else {
            console.error(error);
            popErrorToast("An error occurred while adding the trust");
          }
        }
      },
    }
  );
</script>

<Modal mobileExit="back">
  <div class="mobile-content w-[750px] max-w-[100%]">
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title-fixed">Add New Trust</h1>
    </div>

    <form on:submit={handleSubmit} novalidate>
      <div
        class="mobile-content-body border-b border-t border-ui/border-light px-[50px] py-[50px] shadow-forms"
      >
        <div class="grid gap-y-[40px]">
          <div class="input-wrap">
            <label for="name">
              Trust Name <span class="text-state/negative">*</span>
            </label>

            <input
              type="text"
              id="name"
              bind:value={$form.name}
              on:change={handleChange}
            />

            {#if $errors.name && submitClicked}
              <small class="input-error"><WarnIcon /> {$errors.name}</small>
            {/if}
          </div>

          <div class="input-wrap">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label>Legal Document</label>

            <FileUpload bind:file />
          </div>
        </div>
      </div>

      <div
        class="mobile-content-footer flex items-center justify-between border-t border-ui/border-light px-[50px] py-[40px]"
      >
        <button
          type="button"
          class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
          on:click={() => modalStore.close()}
        >
          Cancel
        </button>

        <button
          type="submit"
          class="btn-filled btn-lg btn-success flex gap-[10px]"
          disabled={$isSubmitting}
          on:click={() => (submitClicked = true)}
        >
          {#if $isSubmitting}
            Adding Trust

            <ProgressRadial
              value={undefined}
              width="w-6"
              meter="stroke-white"
            />
          {:else}
            Add Trust

            <ArrowRightIcon />
          {/if}
        </button>
      </div>
    </form>
  </div>
</Modal>
