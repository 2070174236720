<script lang="ts">
  import AppRouter from "./AppRouter.svelte";
  import Confettery from "./lib/Confettery.svelte";
  import JiraIssueCollector from "./lib/JiraIssueCollector.svelte";
  import Tour from "./lib/welcome-tour/Tour.svelte";
  import { userStore } from "./stores/user";
  import {
    computePosition,
    autoUpdate,
    offset,
    shift,
    flip,
    arrow,
  } from "@floating-ui/dom";
  import { Modal, Toast } from "@skeletonlabs/skeleton";
  import { storePopup } from "@skeletonlabs/skeleton";

  storePopup.set({ computePosition, autoUpdate, offset, shift, flip, arrow });
</script>

{#if $userStore?.user_type === "owner"}
  <Tour />
{/if}
<Modal />
<Confettery />

<!--
  The modal has a higher z-index than the toast (999 > 888), so we need to set the toast's to at least 1000.
-->
<Toast
  shadow="shadow-heavy"
  rounded="rounded-[6px]"
  position="tr"
  background="bg-[#222324]"
  buttonDismiss="btn-icon btn-icon-sm variant-filled-surface"
  zIndex="z-[1000]"
/>

<JiraIssueCollector />

<AppRouter />
