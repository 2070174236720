<script lang="ts">
  import {
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money.js";
  import { handleDollarInput } from "../../util/validation.ts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { number, object, string } from "yup";

  export let multipartFormData;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        description: $multipartFormData.description,
        estimatedValue: convertCentsToInputDollars(
          $multipartFormData.estimated_value
        ),
        uniqueIdentifier: $multipartFormData.unique_id,
        physicalLocation: $multipartFormData.physical_location,
      },
      validationSchema: object().shape({
        description: string().required("Required"),
        estimatedValue: string().required("Required"),
        uniqueIdentifier: string().nullable(),
        physicalLocation: string().nullable(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.description = formData.description;
        $multipartFormData.estimated_value = convertDollarsStringToCents(
          formData.estimatedValue
        );
        $multipartFormData.unique_id = formData.uniqueIdentifier;
        $multipartFormData.physical_location = formData.physicalLocation;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );
</script>

<form
  class="mobile-content {!!onSubmitted ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if !!onSubmitted}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Asset</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {$multipartFormData.description}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">Add Other Property</h1>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <label for="description">
          Description <span class="text-state/negative">*</span>
        </label>

        <input
          name="description"
          id="description"
          type="text"
          bind:value={$form.description}
          on:change={handleChange}
        />

        {#if $errors.description}
          <small class="input-error"><WarnIcon /> {$errors.description}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="estimatedValue">
          Estimated Value <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="estimatedValue"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            id="estimatedValue"
            name="estimatedValue"
            type="text"
            class="!pl-0"
            bind:value={$form.estimatedValue}
            on:input={(e) => handleDollarInput(e, form, "estimatedValue")}
          />
        </div>

        {#if $errors.estimatedValue}
          <small class="input-error"
            ><WarnIcon /> {$errors.estimatedValue}</small
          >
        {/if}
      </div>
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] border-ui/border-light pt-[35px] max-md:gap-y-[35px] md:gap-y-[45px] md:border-t md:pr-[50px] md:pt-[45px]"
    >
      <div class="input-wrap">
        <label for="uniqueIdentifier"> Unique Identifier </label>

        <input
          name="uniqueIdentifier"
          id="uniqueIdentifier"
          type="text"
          bind:value={$form.uniqueIdentifier}
          on:change={handleChange}
        />
      </div>

      <div class="input-wrap">
        <label for="physicalLocation"> Physical Location </label>

        <input
          name="physicalLocation"
          id="physicalLocation"
          type="text"
          bind:value={$form.physicalLocation}
          on:change={handleChange}
        />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button btn-main"
      on:click|preventDefault={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
