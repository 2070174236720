<script lang="ts">
  import type { Charity } from "../../pages/Beneficiaries.svelte";
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { states } from "../../util/place";
  import { popErrorToast } from "../../util/toasts";
  import { createTaskToSpecifyBeneficiaries } from "../assets/tasks";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { charityLegalStructures } from "./beneficiaries";
  import { createTaskToDesignateBeneficiaryAssets } from "./tasks";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let charity: Charity = null;

  const dispatch = createEventDispatcher();

  type FormData = typeof $form;

  let submitClicked = false;

  async function addCharity(formData: FormData) {
    return supabase
      .from("beneficiary_charity")
      .insert({
        ...formData,
        estate_id: $currentEstate.id,
      })
      .select("id")
      .single();
  }

  async function updateCharity(formData: FormData) {
    return supabase
      .from("beneficiary_charity")
      .update({
        ...formData,
        estate_id: $currentEstate.id,
      })
      .eq("id", charity.id)
      .select("id")
      .single();
  }

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        name: charity?.name || "",
        state_of_formation: charity?.state_of_formation || "",
        legal_structure: charity?.legal_structure || "",
        contact_email: charity?.contact_email || "",
        ein: charity?.ein || null,
      },
      validationSchema: object().shape({
        name: string().required("Required"),
        state_of_formation: string().nullable(),
        legal_structure: string().nullable(),
        ein: string().length(9, "Must be a valid EIN").nullable(),
        contact_email: string().email("Must be a valid email").nullable(),
      }),
      onSubmit: async (formData) => {
        try {
          const {
            data: { id },
            error,
          } = isUpdate
            ? await updateCharity(formData)
            : await addCharity(formData);

          if (error) throw error;

          if (!isUpdate) {
            createTaskToDesignateBeneficiaryAssets(
              id,
              "charity",
              formData.name
            );
          }

          dispatch("done", {
            ...formData,
            id,
          });
        } catch (error) {
          popErrorToast(error.message);
        }
      },
    }
  );

  $: isUpdate = !!charity;
</script>

<form
  class="mobile-content {isUpdate ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  <div
    class="mobile-header mb-[30px] px-[50px] pt-[30px] {isUpdate
      ? 'max-md:block max-md:!h-[85px]'
      : ''}"
  >
    <h1 class="mobile-header-title-fixed{isUpdate ? ' max-md:!text-left' : ''}">
      {#if isUpdate}
        Edit Beneficiary Details
      {:else}
        Add <ins class="max-md:hidden">a </ins>New Beneficiary
      {/if}
    </h1>

    {#if isUpdate}
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {charity.name}
      </p>
    {/if}
  </div>

  <div
    class="mobile-content-body border-ui/border-light py-[50px] pb-0 pl-[50px] md:border-b md:border-t md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pb-[45px] max-md:gap-y-[35px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <label for="name">
          Charity Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="name"
          bind:value={$form.name}
          on:change={handleChange}
        />

        {#if $errors.name && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.name}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="state_of_formation"> State of Formation </label>

        <select
          id="state_of_formation"
          bind:value={$form.state_of_formation}
          on:change={handleChange}
          required
        >
          <option value="">Choose...</option>

          {#each Object.entries(states) as [abbreviation, name]}
            <option value={abbreviation}>{name}</option>
          {/each}
        </select>

        {#if $errors.state_of_formation && submitClicked}
          <small class="input-error"
            ><WarnIcon /> {$errors.state_of_formation}</small
          >
        {/if}
      </div>

      <div class="input-wrap">
        <label for="legal_structure"> Legal Structure </label>

        <select
          id="legal_structure"
          bind:value={$form.legal_structure}
          on:change={handleChange}
          required
        >
          <option value="">Choose...</option>

          {#each charityLegalStructures as legalStructure}
            <option value={legalStructure}>{legalStructure}</option>
          {/each}
        </select>

        {#if $errors.legal_structure && submitClicked}
          <small class="input-error"
            ><WarnIcon /> {$errors.legal_structure}</small
          >
        {/if}
      </div>

      <div class="input-wrap">
        <label for="ein"> EIN </label>

        <input
          type="text"
          id="ein"
          bind:value={$form.ein}
          on:change={handleChange}
        />

        {#if $errors.ein && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.ein}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="contact_email"> Contact Email Address</label>

        <input
          type="email"
          id="contact_email"
          bind:value={$form.contact_email}
          on:change={handleChange}
        />

        {#if $errors.contact_email && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.contact_email}</small
          >
        {/if}
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
      disabled={$isSubmitting}
      on:click={() => dispatch("cancel")}
    >
      {#if isUpdate}
        <span class="md:hidden">
          <ArrowLeftIcon />
        </span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>

    <button
      type="submit"
      class="btn-filled btn-success btn-lg flex gap-[10px]"
      disabled={$isSubmitting}
      on:click={() => (submitClicked = true)}
    >
      {#if isUpdate}
        {#if $isSubmitting}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />

          Saving
        {:else}
          <CheckIcon />

          Save Changes
        {/if}
      {:else if $isSubmitting}
        Adding Beneficiary

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        Add Beneficiary

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
