<script lang="ts">
  import type { Service } from "../../pages/Services.svelte";
  import { supabase } from "../../supabaseClient";
  import { popErrorToast } from "../../util/toasts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import TrashIcon from "../icons/TrashClip.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";

  export let service: Service;

  const dispatch = createEventDispatcher();

  let confirmed = false;
  let loading = false;

  async function handleDelete() {
    try {
      loading = true;

      const { error } = await supabase
        .from("service")
        .delete()
        .eq("id", service.id);

      if (error) throw error;

      dispatch("done", service.id);
    } catch (error) {
      popErrorToast(error?.message);
    } finally {
      loading = false;
    }
  }
</script>

<div class="mobile-content w-[620px] max-w-[100%]">
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title max-md:!pl-[34px] max-md:!text-left">
      Delete {service.name}
    </h1>
  </div>

  <div class="mobile-content-body px-[50px] pb-[20px] shadow">
    <p
      class="mb-[30px] text-[20px] font-semibold leading-[25px] text-state/negative max-md:text-[18px] max-md:font-bold max-md:leading-[28px]"
    >
      By continuing, you understand that:
    </p>

    <label class="input-option !mb-[30px] flex items-start gap-[8px]">
      <input
        type="checkbox"
        class="relative !top-[2px]"
        bind:value={confirmed}
      />

      <span class="relative !mt-0 block leading-[30px]">
        This service will be completely removed from LegaSEE, and will no longer
        appear for you or any of your account users.
      </span>
    </label>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      disabled={loading}
      on:click={() => dispatch("cancel")}
    >
      <span class="md:hidden">
        <ArrowLeftIcon />
      </span>

      Cancel
    </button>

    <button
      type="button"
      class="btn-filled btn-lg flex gap-[10px] !bg-state/negative"
      disabled={!confirmed || loading}
      on:click={handleDelete}
    >
      {#if loading}
        Deleting

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        <TrashIcon />

        Delete Forever
      {/if}
    </button>
  </div>
</div>
