<script lang="ts">
  import {
    convertCentsToDollarString,
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money.js";
  import { splitAddressFields } from "../../util/place.ts";
  import { handleDollarInput } from "../../util/validation.ts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import SearchIcon from "../icons/Search.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { Loader } from "@googlemaps/js-api-loader";
  import { getContext, onMount } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { number, object, string } from "yup";

  export let multipartFormData;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        address: $multipartFormData.address,
        estimatedValue: convertCentsToInputDollars(
          $multipartFormData.estimated_value
        ),
        locationOfDeed: $multipartFormData.location_of_deed,
      },
      validationSchema: object().shape({
        address: string().required("Required"),
        estimatedValue: string().required("Required"),
        locationOfDeed: string().nullable(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.address = formData.address;
        $multipartFormData.estimated_value = convertDollarsStringToCents(
          formData.estimatedValue
        );
        $multipartFormData.location_of_deed = formData.locationOfDeed;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );

  function initGoogleAutoComplete() {
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });
    loader.importLibrary("places").then(() => {
      const input = document.getElementById("address");
      const autocomplete = new google.maps.places.Autocomplete(input, {
        types: ["address"],
        componentRestrictions: { country: "us" },
      });
      autocomplete.setFields(["address_components", "formatted_address"]);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        $form.address = place.formatted_address;
      });
    });
  }

  onMount(() => {
    initGoogleAutoComplete();
  });

  const streetAddress = !!$multipartFormData.address
    ? splitAddressFields($multipartFormData.address).streetAddress
    : "";
</script>

<form
  class="mobile-content {!!onSubmitted ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if !!onSubmitted}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Asset</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {streetAddress}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">Add Real Estate</h1>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pb-[35px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap col-span-full">
        <label for="address">
          Property Address <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[auto_42px]">
          <input
            name="address"
            type="text"
            id="address"
            class="!pr-0"
            bind:value={$form.address}
            on:change={handleChange}
          />
          <label
            for="address"
            class="m-0 flex items-center pr-[18px] text-text/medium"
          >
            <SearchIcon />
          </label>
        </div>

        {#if $errors.address}
          <small class="input-error"><WarnIcon /> {$errors.address}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="estimatedValue">
          Estimated Value <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="estimatedValue"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>
          <input
            name="estimatedValue"
            id="estimatedValue"
            type="text"
            class="!pl-0"
            bind:value={$form.estimatedValue}
            on:input={(e) => handleDollarInput(e, form, "estimatedValue")}
          />
        </div>

        {#if $errors.estimatedValue}
          <small class="input-error"
            ><WarnIcon /> {$errors.estimatedValue}</small
          >
        {/if}
      </div>
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] border-ui/border-light max-md:gap-y-[35px] md:gap-y-[45px] md:border-t md:pr-[50px] md:pt-[45px]"
    >
      <div class="input-wrap col-span-full">
        <label for="locationOfDeed"> Location of Deed </label>

        <input
          name="locationOfDeed"
          id="locationOfDeed"
          type="text"
          bind:value={$form.locationOfDeed}
          on:change={handleChange}
        />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="btn-main mobile-back-button"
      on:click|preventDefault={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
