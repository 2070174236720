<script lang="ts">
  import img from "../../assets/images/onboarding/credentials.png";
  import type { OnboardingData } from "../../types/Onboarding";
  import { passwordValidationSchema } from "../../util/validation";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import EyeIcon from "../icons/Eye.svelte";
  import EyeSlashIcon from "../icons/EyeSlash.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let data: OnboardingData;

  // ? block changing email address when onboarding as an invitee
  const lockInviteeDetails = !!data.email;

  export let goForward: () => void;

  let submitClicked = false;
  let passwordVisible = false;

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      email: data.email,
      password: data.password,
    },
    validationSchema: object().shape({
      email: string().required("Required").email("Must be a valid email"),
      password: passwordValidationSchema,
    }),
    onSubmit: (formData) => {
      data = {
        ...data,
        ...formData,
      };

      goForward();
    },
  });
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div
    class="mobile-content-body flex justify-between gap-[60px] px-[50px] pb-[50px] pt-[40px] shadow"
  >
    <div class="grow-1">
      <p
        class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        First, let’s setup your login credentials.
      </p>

      <div class="grid gap-y-[40px]">
        <div class="w-[395px]">
          <label for="email">
            Email Address <span class="text-state/negative">*</span>
          </label>

          <input
            type="email"
            id="email"
            bind:value={$form.email}
            on:change={handleChange}
            disabled={lockInviteeDetails}
          />

          {#if $errors.email && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.email}</small>
          {/if}
        </div>

        <div class="input-wrap">
          <label for="password">
            Desired Password <span class="text-state/negative">*</span>
          </label>

          <div class="input-group w-[300px] grid-cols-[auto_60px]">
            <!-- Can't change `type` dynamically  -->
            {#if passwordVisible}
              <input
                id="password"
                type="text"
                class="!pr-0"
                bind:value={$form.password}
                on:change={handleChange}
              />
            {:else}
              <input
                id="password"
                type="password"
                class="!pr-0"
                bind:value={$form.password}
                on:change={handleChange}
              />
            {/if}

            <button
              type="button"
              class="justify-end pr-[18px] text-text/medium"
              on:click={() => (passwordVisible = !passwordVisible)}
            >
              {#if passwordVisible}
                <EyeSlashIcon />
              {:else}
                <EyeIcon />
              {/if}
            </button>
          </div>

          {#if $errors.password && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.password}</small>
          {/if}

          <div class="input-hint">
            <ul class="ml-[18px] mt-[10px] list-disc">
              <li class="mt-[3px]">8 characters minimum</li>
              <li class="mt-[3px]">One lowercase letter</li>
              <li class="mt-[3px]">One uppercase letter</li>
              <li class="mt-[3px]">One special character</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="shrink-0 basis-[275px] max-md:hidden">
      <img src={img} alt="Credentials" />
    </div>
  </div>

  <div
    class="mobile-content-footer border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="submit"
      class="btn-filled btn-lg flex gap-[10px]"
      on:click={() => (submitClicked = true)}
    >
      Next Step

      <ArrowRightIcon />
    </button>
  </div>
</form>
