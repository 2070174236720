<script lang="ts">
  import type { OnboardingPersonBeneficiary } from "../../types/Onboarding";
  import DateInput from "../DateInput.svelte";
  import { beneficiaryRelationships } from "../beneficiaries/beneficiaries";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CalendarIcon from "../icons/Calendar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { imask } from "@imask/svelte";
  import { createForm } from "svelte-forms-lib";
  import { date, object, string } from "yup";

  export let beneficiary: OnboardingPersonBeneficiary;

  export let goBack: () => void;
  export let goForward: () => void;

  let submitClicked = false;
  let detailsVisible = false;

  let ssn = beneficiary.ssn;

  const handleAccept = ({ detail: { _unmaskedValue } }) =>
    updateValidateField("ssn", _unmaskedValue);

  const { form, errors, handleChange, handleSubmit, updateValidateField } =
    createForm({
      initialValues: {
        firstName: beneficiary.firstName,
        lastName: beneficiary.lastName,
        relationship: beneficiary.relationship,
        dateOfBirth: beneficiary.dateOfBirth,
        ssn,
      },
      validationSchema: object().shape({
        firstName: string().required("Required"),
        lastName: string().required("Required"),
        relationship: string().required("Required"),
        dateOfBirth: date().typeError("Must be a valid date").nullable(),
        ssn: string().length(9, "Must be a valid SSN").nullable(),
      }),
      onSubmit: (formData) => {
        beneficiary = {
          ...formData,
          type: "person",
        };

        goForward();
      },
    });
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-content-body px-[50px] pb-[50px] pt-[40px] shadow">
    <p
      class="mb-[60px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
    >
      Tell us about the person you would like to add as a beneficiary.
    </p>

    <div class="flex flex-col gap-x-[40px] gap-y-[40px] md:grid md:grid-cols-2">
      <div>
        <label for="firstName">
          First Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="firstName"
          bind:value={$form.firstName}
          on:change={handleChange}
        />

        {#if $errors.firstName && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.firstName}</small>
        {/if}
      </div>

      <div>
        <label for="lastName">
          Last Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="lastName"
          bind:value={$form.lastName}
          on:change={handleChange}
        />

        {#if $errors.lastName && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.lastName}</small>
        {/if}
      </div>

      <div class="input-wrap col-span-full">
        <label for="relationship"
          >Relationship <span class="text-state/negative">*</span></label
        >

        <select
          id="relationship"
          bind:value={$form.relationship}
          on:change={handleChange}
          required
        >
          <option selected disabled>Choose...</option>

          {#each beneficiaryRelationships as relationship}
            <option value={relationship}>{relationship}</option>
          {/each}
        </select>

        {#if $errors.relationship && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.relationship}</small>
        {/if}
      </div>

      {#if detailsVisible}
        <div class="input-wrap">
          <label for="dateOfBirth"> Date of Birth </label>

          <DateInput bind:value={$form.dateOfBirth} />

          {#if $errors.dateOfBirth && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.dateOfBirth}</small
            >
          {/if}
        </div>

        <div class="input-wrap">
          <label for="ssn"> Social Security Number </label>

          <input
            type="text"
            id="ssn"
            value={ssn}
            use:imask={{ mask: "000-00-0000" }}
            on:accept={handleAccept}
          />

          {#if $errors.ssn && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.ssn}</small>
          {/if}
        </div>
      {:else}
        <button
          type="button"
          class="col-span-2 text-left text-body/medium text-text/medium underline transition hover:text-state/link"
          on:click={() => (detailsVisible = !detailsVisible)}
        >
          Enter additional details...
        </button>
      {/if}
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg flex gap-[10px]"
      on:click={() => (submitClicked = true)}
    >
      Continue

      <ArrowRightIcon />
    </button>
  </div>
</form>
