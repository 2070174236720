<script lang="ts">
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { convertCentsToDollarString } from "../../util/money";
  import { Routes } from "../../util/routes";
  import { popErrorToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import ArrowRight from "../icons/ArrowRight.svelte";
  import Card from "./Card.svelte";
  import Empty from "./Empty.svelte";
  import SplitBar from "./SplitBar.svelte";
  import { sum, flatten } from "lodash";
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";

  let loading = { assets: true, debts: true };
  let empty = { assets: false, debts: false };

  $: somethingLoading = Object.values(loading).some((val) => val === true);
  let totalAssets: number;
  let totalDebt: number;
  $: netWorth = ![typeof totalAssets, typeof totalDebt].includes("undefined")
    ? totalAssets - totalDebt
    : undefined;

  // $: console.log($currentEstate.id);

  const estateId = $currentEstate.id;

  async function getTotalDebt() {
    try {
      const { data, error } = await supabase
        .from("debt")
        .select("current_balance")
        .eq("estate_id", estateId);

      if (error) throw error;

      totalDebt = sum(data.map(({ current_balance }) => current_balance));

      if (!data.length) empty.debts = true;
    } catch (error) {
      console.error(error.message);
      popErrorToast("Something went wrong. Please try again.");
    } finally {
      loading.debts = false;
    }
  }

  async function getTotalAssets() {
    try {
      const banks = supabase
          .from("financial_account")
          .select("current_value, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        realEstate = supabase
          .from("real_estate")
          .select("estimated_value, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        vehicles = supabase
          .from("vehicle")
          .select("estimated_value, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        otherProperty = supabase
          .from("property")
          .select("estimated_value, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        insurance = supabase
          .from("insurance")
          .select("policy_limit, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        inheritance = supabase
          .from("inheritance")
          .select("estimated_value, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        legalEntities = supabase
          .from("legal_entity")
          .select("value, asset!inner(id)")
          .eq("asset.estate_id", estateId),
        loans = supabase
          .from("loan_asset")
          .select("amount, asset!inner(id)")
          .eq("asset.estate_id", estateId);
      const dataSet = await Promise.all([
        banks,
        realEstate,
        vehicles,
        otherProperty,
        insurance,
        inheritance,
        legalEntities,
        loans,
      ]);

      if (dataSet.some((result) => result.error)) {
        throw dataSet.find((result) => result.error).error;
      }

      if (dataSet.every(({ data }) => data.length === 0)) {
        empty.assets = true;
      }

      totalAssets = sum(
        flatten(
          dataSet.map(({ data }) =>
            data.map(
              (node) =>
                node.value ??
                node.current_value ??
                node.estimated_value ??
                node.amount ??
                node.policy_limit ??
                0
            )
          )
        )
      );
    } catch (error) {
      console.error(error.message);
      popErrorToast("Something went wrong. Please try again.");
    } finally {
      loading.assets = false;
    }
  }

  onMount(() => {
    getTotalAssets();
    getTotalDebt();
  });
</script>

<Card>
  <svelte:fragment slot="header">Net Worth</svelte:fragment>
  <svelte:fragment slot="body">
    <!-- loading -->
    {#if somethingLoading}
      <BarLoader />
    {/if}

    <!-- empty -->
    {#if empty.assets && empty.debts}
      <Empty>
        <span slot="text">Add assets and debts to view net worth</span>
        <svelte:fragment slot="buttons">
          <Link to={Routes.Assets} class="btn-main">
            View Assets <ArrowRight />
          </Link>
          <Link to={Routes.Debts} class="btn-main">
            View Debts <ArrowRight />
          </Link>
        </svelte:fragment>
      </Empty>

      <!-- loaded -->
    {:else}
      <div class="flex flex-grow flex-col justify-evenly gap-8 p-5">
        {#if !somethingLoading && typeof netWorth !== "undefined"}
          <strong
            class="flex items-center text-[40px] leading-[1.2] tracking-[1.2px]"
          >
            <sup class="-top-2">$</sup>
            <span>
              {convertCentsToDollarString(netWorth)}
            </span>
          </strong>

          <div>
            <SplitBar
              percent={(totalAssets / (totalAssets + totalDebt)) * 100}
            />

            <div class="mt-2 flex items-center justify-between">
              <Link
                to={Routes.Assets}
                class="flex flex-col text-text/dark hover:opacity-70"
              >
                <span class="text-body/x-small leading-[26px]">Assets</span>
                <span
                  class="text-body/medium font-semibold leading-[26px] text-state/positive"
                >
                  <sup>$</sup>
                  {convertCentsToDollarString(totalAssets)}
                </span>
              </Link>
              <Link
                to={Routes.Debts}
                class="flex flex-col items-end text-text/dark hover:opacity-70"
              >
                <span class="text-body/x-small leading-[26px]">Debts</span>
                <span
                  class="text-body/medium font-semibold leading-[26px] text-state/negative"
                >
                  <sup>$</sup>
                  {convertCentsToDollarString(totalDebt)}
                </span>
              </Link>
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </svelte:fragment>
</Card>
