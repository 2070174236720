<script lang="ts">
  import type { AssetType } from "../../types/index.js";
  import {
    PaymentFrequencies,
    convertDollarsStringToCents,
    convertCentsToInputDollars,
  } from "../../util/money.js";
  import { handleDollarInput } from "../../util/validation.ts";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { accountTypes } from "./assets";
  import { getAccountDisplayName } from "./assets";
  import { startCase } from "lodash";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string, number, boolean } from "yup";

  export let multipartFormData: any;
  export let selectedAssetType: AssetType;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goBack: () => void = getContext("back");
  const goForward: () => void = getContext("forward");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        institutionName: $multipartFormData.institution_name,
        showAccountType: selectedAssetType !== "retirement",
        accountType: $multipartFormData.financial_account_subtype,
        currentValue: convertCentsToInputDollars(
          $multipartFormData.current_value
        ),
        accountNumber: $multipartFormData.account_number,
        paymentFrequency: $multipartFormData.payment_frequency ?? "",
      },
      validationSchema: object().shape({
        institutionName: string().required("Required"),
        showAccountType: boolean(),
        accountType: string().when("showAccountType", {
          is: true,
          then: (schema) =>
            schema.oneOf(accountTypes[selectedAssetType]).required("Required"),
          otherwise: (schema) => schema.nullable(),
        }),
        currentValue: string().required("Required"),
        accountNumber: string(),
        paymentFrequency: string(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.financial_account_subtype = formData.accountType;
        $multipartFormData.current_value = convertDollarsStringToCents(
          formData.currentValue
        );
        $multipartFormData.account_number = formData.accountNumber;
        $multipartFormData.institution_name = formData.institutionName;
        $multipartFormData.payment_frequency = formData.paymentFrequency;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );
</script>

<form
  class="mobile-content {!!onSubmitted ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if !!onSubmitted}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Asset</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {getAccountDisplayName(
          $multipartFormData.institution_name,
          $multipartFormData.account_number,
          $multipartFormData.label
        )}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">
        Add {startCase(selectedAssetType)} Account
      </h1>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="institutionName">
          Institution Name <span class="text-state/negative">*</span>
        </label>

        <input
          name="institutionName"
          type="text"
          id="institutionName"
          bind:value={$form.institutionName}
          on:change={handleChange}
        />

        {#if $errors.institutionName}
          <small class="input-error"
            ><WarnIcon /> {$errors.institutionName}</small
          >
        {/if}
      </div>

      {#if selectedAssetType !== "retirement"}
        <div class="input-wrap">
          <label for="accountType">
            Account Type <span class="text-state/negative">*</span>
          </label>

          <select
            name="accountType"
            id="accountType"
            bind:value={$form.accountType}
            on:change={handleChange}
            required
          >
            <option value="" disabled selected>Choose...</option>
            {#each accountTypes[selectedAssetType] as accountType}
              <option value={accountType}>{startCase(accountType)}</option>
            {/each}
          </select>

          {#if $errors.accountType}
            <small class="input-error"><WarnIcon /> {$errors.accountType}</small
            >
          {/if}
        </div>
      {/if}

      <div class="input-wrap">
        <label for="currentValue">
          Current Value <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="currentValue"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            name="currentValue"
            type="text"
            id="currentValue"
            class="!pl-0"
            disabled={!!$multipartFormData.plaid_account_id}
            bind:value={$form.currentValue}
            on:input={(e) => handleDollarInput(e, form, "currentValue")}
          />
        </div>

        {#if $errors.currentValue}
          <small class="input-error"><WarnIcon /> {$errors.currentValue}</small>
        {/if}
      </div>
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pt-[45px] max-md:gap-y-[35px] max-md:pt-[35px] md:border-t md:border-ui/border-light md:pr-[50px]"
    >
      {#if selectedAssetType === "investment"}
        <div class="input-wrap">
          <label for="paymentFrequency"> Payment Frequency </label>

          <select
            name="paymentFrequency"
            id="paymentFrequency"
            bind:value={$form.paymentFrequency}
            on:change={handleChange}
            required
          >
            <option value="" disabled selected>Choose...</option>
            {#each PaymentFrequencies as paymentFrequency}
              <option value={paymentFrequency}>
                {startCase(paymentFrequency)}
              </option>
            {/each}
          </select>

          {#if $errors.paymentFrequency}
            <small class="input-error"
              ><WarnIcon /> {$errors.paymentFrequency}</small
            >
          {/if}
        </div>
      {/if}

      <div
        class={selectedAssetType !== "investment"
          ? "input-wrap col-span-full"
          : "input-wrap"}
      >
        <label for="accountNumber"> Account Number </label>

        <input
          name="accountNumber"
          id="accountNumber"
          type="text"
          disabled={!!$multipartFormData.plaid_account_id}
          bind:value={$form.accountNumber}
          on:change={handleChange}
        />

        {#if $errors.accountNumber}
          <small class="input-error"><WarnIcon /> {$errors.accountNumber}</small
          >
        {/if}
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button btn-main"
      on:click|preventDefault={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
