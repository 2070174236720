<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import type { Trust } from "../../types";
  import { deleteTrustDocuments } from "../../util/storage";
  import { popSuccessToast } from "../../util/toasts";
  import DeleteForm from "../DeleteForm.svelte";
  import Modal from "../Modal.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let trust: Trust;
  export let hasDoc: boolean;
  export let hasAssetDesignations: boolean;

  async function deleteAction() {
    // Update designations first since after the trust is removed the trust id will
    // be set to null
    if (hasAssetDesignations) {
      const { error: dbError } = await supabase
        .from("asset")
        .update({ distribution_method: "tbd" })
        .eq("distribution_trust_id", trust.id);

      if (dbError) throw dbError;
    }

    const { error: dbError } = await supabase
      .from("trust")
      .delete()
      .eq("id", trust.id);

    if (dbError) throw dbError;

    if (hasDoc) {
      const { error: storageError } = await deleteTrustDocuments(trust.name);

      if (storageError) throw storageError;
    }
  }

  function handleDone() {
    $modalStore[0].response(true);
    modalStore.close();
    popSuccessToast("Your trust has been deleted.");
  }

  $: requirements = [
    "This trust will be completely removed from the LegaSEE application.",
    ...(hasDoc
      ? ["The legal document you uploaded for this trust will be deleted."]
      : []),
    ...(hasAssetDesignations
      ? [
          "All asset designations using this trust as the distribution method will have their method updated as “to be determined”.",
        ]
      : []),
  ];
</script>

<Modal mobileExit="back">
  <DeleteForm
    {requirements}
    {deleteAction}
    on:done={handleDone}
    on:cancel={() => modalStore.close()}
  >
    Delete {trust?.name}
  </DeleteForm>
</Modal>
