<script lang="ts">
  export let noPadding = false;
  export let mobileSubtitle = false;
</script>

<ul
  class:!px-0={noPadding}
  class="mobile-tabs {mobileSubtitle
    ? 'max-md:!top-[85px]'
    : ''} mb-[50px] flex border-b border-ui/border px-[50px]"
  style="background: linear-gradient(0deg, rgba(0,0,0,0.03) 0%, rgba(0,0,0,0) 20%);"
>
  <slot />
</ul>

{#if $$slots.panel}
  <section class:!px-0={noPadding} class="px-[50px] pb-[50px]">
    <slot name="panel" />
  </section>
{/if}
