<script lang="ts">
  import { tour } from "../welcome-tour/tour";
  import Needle from "./Needle.svelte";

  export let percent: number = 0;
</script>

<div class="relative w-min max-w-full">
  <Needle bind:percent />

  <div
    class="absolute bottom-2 left-1/2 flex w-min -translate-x-1/2 items-center justify-center gap-1 rounded-[3px] border border-ui/border bg-white px-3 py-4 shadow"
    use:tour={0}
  >
    <strong class="inline-flex text-[40px]">{percent.toFixed(0)}</strong>
    <strong class="inline-flex text-[25px]">%</strong>
  </div>

  <svg
    width="289"
    height="180"
    viewBox="0 0 289 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6450_43939)">
      <g filter="url(#filter0_d_6450_43939)">
        <path
          d="M212.551 17.3311L187.68 61.2422C174.369 54.2874 159.625 50.6602 144.666 50.6602C129.708 50.6602 114.964 54.2874 101.652 61.2422L76.7812 17.3311C97.6672 5.95279 120.984 0 144.666 0C168.349 0 191.665 5.95279 212.551 17.3311Z"
          fill="url(#paint0_linear_6450_43939)"
        />
        <path
          d="M144.666 0.5C168.096 0.5 191.167 6.34714 211.865 17.5277L187.483 60.5763C174.213 53.7296 159.545 50.1602 144.666 50.1602C129.787 50.1602 115.12 53.7296 101.85 60.5763L77.4673 17.5277C98.1656 6.34714 121.237 0.5 144.666 0.5Z"
          stroke="#FFBE0B"
        />
      </g>
      <g filter="url(#filter2_d_6450_43939)">
        <path
          d="M50.4016 138.475C50.2773 140.071 48.9636 141.328 47.3628 141.328H3.09584C1.40203 141.328 0.041028 139.926 0.129802 138.235C1.33738 115.226 7.92114 92.814 19.3626 72.833C30.7973 52.8639 46.745 35.9147 65.8919 23.3533C67.3155 22.4193 69.2222 22.8994 70.0685 24.3768L92.2241 63.0529C93.0154 64.4343 92.5868 66.1891 91.2782 67.0956C79.628 75.1656 69.9024 85.7638 62.824 98.125C55.7467 110.484 51.5074 124.272 50.4016 138.475Z"
          fill="url(#paint1_linear_6450_43939)"
        />
        <path
          d="M49.9031 138.436C49.7984 139.781 48.6932 140.828 47.3628 140.828H3.09584C1.6815 140.828 0.555716 139.66 0.629115 138.261C1.8326 115.33 8.39404 92.9943 19.7965 73.0815C31.1922 53.1804 47.0853 36.2895 66.1661 23.7713C67.343 22.9993 68.9279 23.3917 69.6347 24.6254L91.7902 63.3015C92.4479 64.4495 92.0965 65.9205 90.9934 66.6846C79.2819 74.7971 69.5054 85.4509 62.3901 97.8766C55.2759 110.3 51.0146 124.16 49.9031 138.436Z"
          stroke="#E5063E"
        />
      </g>
      <g filter="url(#filter3_d_6450_43939)">
        <path
          d="M289.338 141.328H239.118C238.385 126.122 234.064 111.311 226.514 98.126C218.963 84.9406 208.4 73.7609 195.703 65.515L220.816 21.6704C241.135 34.3809 258.022 51.9555 269.977 72.8312C281.932 93.707 288.582 117.237 289.338 141.328Z"
          fill="url(#paint2_linear_6450_43939)"
        />
        <path
          d="M196.371 65.3542L220.991 22.3708C241.043 35.0152 257.716 52.4261 269.543 73.0797C281.372 93.7353 287.99 116.998 288.821 140.828H239.593C238.791 125.71 234.459 110.994 226.948 97.8776C219.436 84.7607 208.959 73.618 196.371 65.3542Z"
          stroke="#07AE5E"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_6450_43939"
        x="72.7812"
        y="-2"
        width="143.77"
        height="69.2422"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6450_43939"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6450_43939"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_6450_43939"
        x="129.778"
        y="26.2729"
        width="35.7003"
        height="120.765"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0539063 0 0 0 0 0.0594062 0 0 0 0 0.0625 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6450_43939"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6450_43939"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_6450_43939"
        x="-3.87427"
        y="20.8745"
        width="100.496"
        height="126.454"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6450_43939"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6450_43939"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_6450_43939"
        x="191.703"
        y="19.6704"
        width="101.634"
        height="127.658"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6450_43939"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6450_43939"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_6450_43939"
        x="96"
        y="101"
        width="98"
        height="64"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6450_43939"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6450_43939"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_6450_43939"
        x1="144.666"
        y1="0"
        x2="144.666"
        y2="61.2422"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFDB76" />
        <stop offset="1" stop-color="#FFBE0B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6450_43939"
        x1="46.8172"
        y1="21.6704"
        x2="46.8172"
        y2="141.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EE6084" />
        <stop offset="1" stop-color="#E5063E" />
        <stop offset="1" stop-color="#E5063E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6450_43939"
        x1="242.52"
        y1="21.6704"
        x2="242.52"
        y2="141.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#53CE93" />
        <stop offset="1" stop-color="#06BA63" />
      </linearGradient>
      <clipPath id="clip0_6450_43939">
        <rect width="289" height="180" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
