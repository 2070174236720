<script lang="ts">
  import ContextMenu from "../lib/ContextMenu.svelte";
  import TasksToggleButton from "../lib/TasksToggleButton.svelte";
  import AccessList from "../lib/collaborators/AccessList.svelte";
  import InviteCollaboratorModal from "../lib/collaborators/InviteCollaboratorModal.svelte";
  import RemovePermissionsModal from "../lib/collaborators/RemovePermissionsModal.svelte";
  import UpdatePermissionsModal from "../lib/collaborators/UpdatePermissionsModal.svelte";
  import {
    accessType,
    fetchCollaboratorPermissions,
    type CollaboratorProfile,
  } from "../lib/collaborators/collaborators";
  import BlockIcon from "../lib/icons/Block.svelte";
  import BlockSmallIcon from "../lib/icons/BlockSmall.svelte";
  import CheckIcon from "../lib/icons/CheckCircle.svelte";
  import KeysIcon from "../lib/icons/Keys.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import ProfileIcon from "../lib/icons/Profile.svelte";
  import TablePlaceholder from "../lib/placeholders/CollaboratorsTable.svelte";
  import { markComplete } from "../lib/readiness/readiness";
  import { timeAgo } from "../util/format";
  import { Routes, onNavigate } from "../util/routes";
  import { popErrorToast } from "../util/toasts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { popup } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";
  import { navigate, useLocation } from "svelte-navigator";

  const location = useLocation();

  let loading = false;
  let collaborators: CollaboratorProfile[] = [];

  onMount(getCollaborators);

  onNavigate(({ state }) => {
    if (state?.openModal === "addCollaborator") {
      openInviteFlow(state.taskId);
    }
  });

  async function getCollaborators() {
    try {
      loading = true;

      const { data, error } = await fetchCollaboratorPermissions();

      if (error) throw error;

      collaborators = data;
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    } finally {
      loading = false;
    }
  }

  function openInviteFlow(willSatisfyTaskId: number = null) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: InviteCollaboratorModal,
      },
      response: (success: boolean) => {
        if (success) {
          getCollaborators();
          if (willSatisfyTaskId) {
            markComplete(
              willSatisfyTaskId,
              `Task complete: Invite your ${$location.state.satisfies} to the platform!`
            );
          }
        }
      },
    });
  }

  function openEditFlow(collaborator: CollaboratorProfile) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: UpdatePermissionsModal,
        props: {
          collaborator,
        },
      },
      response: (success: boolean) => {
        if (success) getCollaborators();
      },
    });
  }

  function openRemoveFlow(collaborator: CollaboratorProfile["user_data"]) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: RemovePermissionsModal,
        props: {
          collaborator,
        },
      },
      response: (success: boolean) => {
        if (success) getCollaborators();
      },
    });
  }

  function goToBeneficiary(userId: string) {
    navigate(Routes.Beneficiaries, {
      state: {
        openModal: "showProfile",
        returnTo: $location.pathname,
        userId,
      },
    });
  }
</script>

<header class="flex items-start justify-between">
  <div>
    <h1 class="mobile-header-title-fixed">Collaborators</h1>
    <p
      class="max-w-[650px] pr-[50px] pt-[15px] text-[18px] leading-[26px] max-md:hidden"
    >
      Share full or limited access to your account with others. Remove or modify
      access at any time.
    </p>
  </div>

  <div class="flex items-center">
    <button class="btn-main mobile-fab" on:click={() => openInviteFlow()}>
      <PlusIcon />
      <span>Invite Someone</span>
    </button>

    <TasksToggleButton class="ml-[15px]" />
  </div>
</header>

<main class="mobile-content">
  <div class="mobile-content-body">
    {#if loading}
      <TablePlaceholder />
    {:else if !collaborators.length}
      <div
        class="mt-[150px] flex flex-col items-center justify-center text-body/large"
      >
        <h2 class="mb-[50px] font-normal text-text/medium">
          You don't have any collaborators yet.
        </h2>

        <button class="btn-main btn-lg" on:click={() => openInviteFlow()}>
          <span><PlusIcon /></span>
          <span>Invite First Collaborator</span>
        </button>
      </div>
    {:else}
      <div class="table-container mt-[52px] max-md:mt-[8px]">
        <table
          class="table max-md:border-t max-md:border-ui/border-dark md:rounded-tl-[5px] md:rounded-tr-[5px]"
        >
          <thead class="!bg-bg/light">
            <th class="md:rounded-tl-[5px]">Name</th>
            <th class="max-md:hidden">Access</th>
            <th class="max-md:hidden">$ Amounts</th>
            <th class="max-md:hidden">Last Login</th>
            <th class="md:rounded-tr-[5px]" />
          </thead>
          <tbody>
            {#each collaborators as { user_data, ...access }, i}
              <tr>
                <td>
                  <div class="max-md:text-[16px] max-md:leading-[24px]">
                    <span>{user_data.first_name} {user_data.last_name}</span>
                    <div class="mt-[15px]">
                      {#if user_data.user_type === "advisor"}
                        <span class="orange chip max-md:!text-[14px]">
                          Your Advisor
                        </span>
                      {/if}
                      {#if user_data.user_type === "beneficiary"}
                        <span class="chip max-md:!text-[14px]">
                          Beneficiary
                        </span>
                      {/if}
                    </div>
                  </div>
                </td>
                <td class="max-md:hidden">
                  <button
                    class="cursor-help text-state/link transition hover:opacity-70"
                    use:popup={{
                      event: "hover",
                      target: `popup-${i}`,
                      placement: "bottom",
                    }}
                  >
                    {accessType(access)}
                  </button>
                  <div
                    class="rounded-[6px] border border-ui/border bg-white text-body/small shadow-heavy"
                    data-popup="popup-{i}"
                  >
                    <AccessList permissions={access} />
                  </div>
                </td>
                <td class="max-md:hidden">
                  {#if access.show_dollar_values}
                    <span class="text-state/positive"><CheckIcon /></span>
                  {:else}
                    <span class="text-state/negative"><BlockSmallIcon /></span>
                  {/if}
                </td>
                <td class="max-md:hidden">
                  {user_data.last_sign_in_at
                    ? timeAgo(user_data.last_sign_in_at)
                    : "Never"}
                </td>
                <td class="relative w-[1%] max-md:!pr-[10px] max-md:text-right">
                  <ContextMenu
                    items={[
                      ...(user_data.user_type === "beneficiary"
                        ? [
                            {
                              text: "View Profile",
                              icon: ProfileIcon,
                              color: "text-text/dark",
                              onClick: () => goToBeneficiary(user_data.user_id),
                            },
                          ]
                        : []),
                      {
                        text: "Edit Permissions",
                        icon: KeysIcon,
                        color: "text-text/dark",
                        onClick: () => openEditFlow({ ...access, user_data }),
                      },
                      ...(user_data.user_type !== "advisor"
                        ? [
                            {
                              text: "Remove Access",
                              icon: BlockIcon,
                              color: "text-state/negative",
                              onClick: () => openRemoveFlow(user_data),
                            },
                          ]
                        : []),
                    ]}
                  />
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {/if}
  </div>
</main>
