<script lang="ts">
  import { slide } from "svelte/transition";

  let className = "";
  export { className as class };

  export let percent: number;
</script>

<figure
  style="max-width: {percent}%"
  class="{className} h-[23px] w-full min-w-[4px] rounded-[3px] border border-state/link bg-gradient-to-b from-[#67A2FF] to-state/link shadow-heavy transition-all duration-500"
  in:slide|local={{ axis: "x" }}
/>

<style lang="postcss">
  .full {
    @apply rounded-[3px];
  }
</style>
