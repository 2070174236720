<script lang="ts" context="module">
  import {
    getDebtDisplayName,
    getDebtCategory,
    type DebtCategory,
    type CreditAccountType,
    type LoanAccountType,
    type DebtType,
  } from "../lib/debts/debts";
  import CarMoneyIcon from "../lib/icons/CarMoney.svelte";
  import CreditCardIcon from "../lib/icons/CreditCard.svelte";
  import HandBanknoteIcon from "../lib/icons/HandBanknote.svelte";
  import HandshakeBanknoteIcon from "../lib/icons/HandshakeBanknote.svelte";
  import HouseMoneyIcon from "../lib/icons/HouseMoney.svelte";
  import MortgageIcon from "../lib/icons/Mortgage.svelte";
  import RecurringMoneyIcon from "../lib/icons/RecurringMoney.svelte";
  import StudentCapMoneyIcon from "../lib/icons/StudentCapMoney.svelte";
  import SuitcaseMoneyIcon from "../lib/icons/SuitcaseMoney.svelte";
  import TablePlaceholder from "../lib/placeholders/DebtsTable.svelte";
  import { currentEstate } from "../stores/user";

  export type Debt = Awaited<ReturnType<typeof fetchDebts>>[number];

  export const creditAccountTypesMap: {
    [key in CreditAccountType]: DetailsType;
  } = {
    credit_card: {
      name: "Credit Card",
      icon: CreditCardIcon,
    },
    home_equity: {
      name: "Home Equity Line",
      icon: HouseMoneyIcon,
    },
    personal_credit: {
      name: "Personal Line of Credit",
      icon: RecurringMoneyIcon,
    },
    retail_credit: {
      name: "Retail Credit Account",
      icon: SuitcaseMoneyIcon,
      note: "i.e. Macy’s, Home Depot",
    },
  } as const;

  export const loanAccountTypesMap: { [key in LoanAccountType]: DetailsType } =
    {
      auto_loan: {
        name: "Auto Loan",
        icon: CarMoneyIcon,
      },
      mortgage: {
        name: "Home Mortgage",
        icon: MortgageIcon,
      },
      personal_loan: {
        name: "Personal Loan",
        icon: HandBanknoteIcon,
      },
      student_loan: {
        name: "Student Loan",
        icon: StudentCapMoneyIcon,
      },
      vendor_financing: {
        name: "Vendor Financing",
        icon: HandshakeBanknoteIcon,
      },
    } as const;

  const debtTypesMap: { [key in DebtType]: DetailsType } = {
    ...creditAccountTypesMap,
    ...loanAccountTypesMap,
  } as const;

  type DetailsType = {
    name: string;
    icon: any;
    note?: string;
  };

  export const getDebtTypeName = (type: DebtType | string) =>
    debtTypesMap[type].name;
  export const getDebtTypeIcon = (type: DebtType) => debtTypesMap[type].icon;

  function fetchDebts() {
    return supabase
      .from("debt")
      .select(
        "id, institution_name, current_balance, credit_limit, original_loan_amount, payment_frequency, maturity_date, account_number, label, debt_type, balance_updated_at, plaid_account_id, connected_account(needs_auth)"
      )
      .eq("estate_id", get(currentEstate).id)
      .then(({ data, error }) => {
        if (error) throw error;

        return data;
      });
  }
</script>

<script lang="ts">
  import CollapsibleTable from "../lib/CollapsibleTable.svelte";
  import ContextMenu from "../lib/ContextMenu.svelte";
  import TasksToggleButton from "../lib/TasksToggleButton.svelte";
  import AddDebtModal from "../lib/debts/AddDebtModal.svelte";
  import DeleteDebtModal from "../lib/debts/DeleteDebtModal.svelte";
  import ViewDebtModal from "../lib/debts/ViewDebtModal.svelte";
  import HandCoinIcon from "../lib/icons/HandCoin.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import TransactionsIcon from "../lib/icons/Transactions.svelte";
  import TrashIcon from "../lib/icons/Trash.svelte";
  import ViewIcon from "../lib/icons/View.svelte";
  import WarningCircleIcon from "../lib/icons/WarningCircle.svelte";
  import { userCan } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import { timeAgo } from "../util/format";
  import { convertCentsToDollarString } from "../util/money";
  import { openPlaidUpdateModal } from "../util/plaid.ts";
  import { onNavigate } from "../util/routes.ts";
  import { popErrorToast } from "../util/toasts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { differenceInCalendarDays } from "date-fns";
  import { flatten } from "lodash";
  import { onMount } from "svelte";
  import { get } from "svelte/store";

  let debtsMap: { [key in DebtCategory]: Debt[] } = {
    credit_account: [],
    loan_account: [],
  };

  let loading = false;

  function updateDebts(
    type: Debt["debt_type"],
    callback: (services: Debt[]) => Debt[]
  ) {
    const category = getDebtCategory(type as any);

    debtsMap = {
      ...debtsMap,
      [category]: callback(debtsMap[category]),
    };
  }

  async function getDebts() {
    try {
      loading = true;

      const debts = await fetchDebts();

      debtsMap = debts.reduce(
        (map, debt) => {
          map[getDebtCategory(debt.debt_type as any)].push(debt);
          return map;
        },
        {
          credit_account: [],
          loan_account: [],
        }
      );
    } catch (error) {
      popErrorToast(error.message || "Error fetching debts");
    } finally {
      loading = false;
    }
  }

  function showAddModal(debtType: DebtType = null) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: AddDebtModal,
        ...(debtType
          ? {
              props: {
                debtType,
              },
            }
          : {}),
      },
      response: (debt: Debt) => {
        if (debt) {
          updateDebts(debt.debt_type, (debts) => [...debts, debt]);
        }
      },
    });
  }

  function showViewModal(
    debt: Debt,
    tab: string = "Details",
    transactionId: string = null
  ) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: ViewDebtModal,
        props: {
          debt,
          tabView: tab,
          transactionId,
        },
      },
      response: (data: Debt | number) => {
        if (data) {
          const isDeleted = typeof data === "number";

          if (isDeleted) {
            updateDebts(debt.debt_type, (debts) =>
              debts.filter((d) => d.id !== data)
            );
          } else {
            updateDebts(debt.debt_type, (debts) =>
              debts.map((d) => (d.id === debt.id ? data : d))
            );
          }
        }
      },
    });
  }

  function showDeleteModal(debt: Debt) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: DeleteDebtModal,
        props: {
          debt,
        },
      },
      response: (id: number) => {
        if (id) {
          updateDebts(debt.debt_type, (debts) =>
            debts.filter((d) => d.id !== id)
          );
        }
      },
    });
  }

  onMount(getDebts);

  onNavigate(({ state }) => {
    if (state?.openModal === "addDebt") {
      showAddModal(state.debtType);
    }

    if (state?.openModal === "viewDebt") {
      if (!state?.debtId) return;
      const poll = setInterval(() => {
        if (loading === false) {
          clearInterval(poll);
          showViewModal(
            flatten(Object.values(debtsMap)).find(
              (debt) => debt.id === state.debtId
            ),
            ...(state?.tabView ? [state.tabView] : ["Details"]),
            ...(state?.transactionId ? [state.transactionId] : [])
          );
        }
      }, 100);
    }

    if (state?.openModal === "reAuthPlaid" && state?.plaidAccountId) {
      openPlaidUpdateModal(state.plaidAccountId, getDebts);
    }
  });

  $: debtsTotal = Object.values(debtsMap).reduce(
    (total, debts) =>
      total +
      debts.reduce((total, debt) => total + (debt.current_balance || 0), 0),
    0
  );
</script>

<svelte:head>
  <script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js">
  </script>
</svelte:head>

<header
  class="flex justify-between {!loading && debtsTotal === 0
    ? 'items-center'
    : 'items-start'}"
>
  <div>
    <h1 class="mobile-header-title-fixed">Estate Debts</h1>
    {#if userCan("show_dollar_values")}
      {#if loading}
        <p
          class="mt-[-2px] pt-[15px] text-[18px] text-text/medium max-md:hidden"
        >
          <sup>$</sup><span
            class="placeholder relative left-[2px] top-[6px] inline-block w-[60px] animate-pulse bg-text/light"
          />
        </p>
      {:else if debtsTotal !== 0}
        <p class="pt-[15px] text-[18px] text-text/medium max-md:hidden">
          <sup>$</sup>{convertCentsToDollarString(debtsTotal)} total
        </p>
      {/if}
    {/if}
  </div>

  <div class="flex items-center">
    {#if userCan("debts_write")}
      <button
        type="button"
        class="btn-main mobile-fab"
        on:click={() => showAddModal()}
      >
        <PlusIcon />
        <span>New Debt</span>
      </button>
    {/if}

    {#if userCan("debts_write") && userCan("dashboard_read")}
      <TasksToggleButton class="ml-[15px]" />
    {/if}
  </div>
</header>

<main class="mobile-content">
  <div class="mobile-content-body">
    {#if loading}
      <TablePlaceholder />
    {:else if !debtsMap.credit_account.length && !debtsMap.loan_account.length}
      <div
        class="mt-[30px] flex flex-col items-center justify-center border-t border-ui/border pt-[150px] text-body/large"
      >
        {#if userCan("debts_write")}
          <h2 class="mb-[50px] font-normal text-text/medium">
            You don't have any debts yet.
          </h2>

          <button class="btn-main btn-lg" on:click={() => showAddModal()}>
            <span><PlusIcon /></span>
            <span>Add First Debt</span>
          </button>
        {:else}
          <h2 class="font-normal text-text/medium">
            No debts have been added yet.
          </h2>
        {/if}
      </div>
    {:else}
      {#each Object.entries(debtsMap) as [category, debts]}
        {#if debts.length}
          <CollapsibleTable
            class="mt-[50px] max-md:mt-[32px] first:max-md:mt-[0]"
          >
            <svelte:fragment slot="titleBar">
              {#if category === "credit_account"}
                <span class="max-md:hidden">
                  <CreditCardIcon />
                </span>
                Credit Accounts
              {/if}

              {#if category === "loan_account"}
                <span class="max-md:hidden">
                  <HandCoinIcon />
                </span>
                Loans & Mortgages
              {/if}
            </svelte:fragment>
            <svelte:fragment slot="thead">
              <tr>
                <th>Account</th>
                <th class="max-md:hidden">Liability</th>
                <th />
              </tr>
            </svelte:fragment>
            <svelte:fragment slot="tbody">
              {#each debts as debt}
                {@const isOutdated =
                  differenceInCalendarDays(
                    new Date(),
                    new Date(debt.balance_updated_at)
                  ) > 180}
                <tr>
                  <td class="w-[50%]">
                    <div class="max-md:text-[16px] max-md:leading-[24px]">
                      {getDebtDisplayName(debt)}
                      <span class="table-meta">
                        {getDebtTypeName(debt.debt_type)}
                      </span>
                    </div>
                  </td>
                  <td class="w-[49%] max-md:hidden">
                    <sup>$</sup>
                    {convertCentsToDollarString(debt.current_balance)}
                    <div class="mt-[15px] text-sm text-[#858F95]">
                      {timeAgo(debt.balance_updated_at)}
                    </div>
                    {#if isOutdated || debt.connected_account?.needs_auth}
                      <div class="mt-[15px] flex items-center gap-4 text-sm">
                        <span
                          class="flex items-center gap-[5px] text-state/negative"
                        >
                          <WarningCircleIcon />
                        </span>
                        {#if debt.connected_account?.needs_auth && debt.plaid_account_id}
                          <button
                            class="cursor-pointer font-semibold text-state/negative underline"
                            on:click={() =>
                              openPlaidUpdateModal(debt.plaid_account_id, () =>
                                getDebts()
                              )}
                            >Re-Authenticate
                          </button>
                        {:else}
                          <span
                            class="cursor-pointer font-semibold text-state/negative underline"
                            >Update Balance</span
                          >
                        {/if}
                      </div>
                    {/if}
                  </td>
                  <td
                    class="relative w-[1%] max-md:!pr-[10px] max-md:text-right"
                  >
                    <ContextMenu
                      items={[
                        {
                          text: "View Details",
                          icon: ViewIcon,
                          color: "text-text/dark",
                          onClick: () => showViewModal(debt),
                        },
                        ...(debt.plaid_account_id
                          ? [
                              {
                                text: "Transactions",
                                icon: TransactionsIcon,
                                color: "text-text/dark",
                                onClick: () =>
                                  showViewModal(debt, "Transactions"),
                              },
                            ]
                          : []),
                        {
                          text: "Delete Debt",
                          icon: TrashIcon,
                          color: "text-state/negative",
                          onClick: () => showDeleteModal(debt),
                        },
                      ]}
                    />
                  </td>
                </tr>
              {/each}
            </svelte:fragment>
          </CollapsibleTable>
        {/if}
      {/each}
    {/if}
  </div>
</main>
