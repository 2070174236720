<script lang="ts">
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import type { InviteCollaboratorFields } from "./collaborators";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { createForm } from "svelte-forms-lib";
  import type { Writable } from "svelte/store";
  import { object, string } from "yup";

  export let multipartFormData: Writable<InviteCollaboratorFields>;
  export let goForward: () => void;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        firstName: $multipartFormData.firstName,
        lastName: $multipartFormData.lastName,
        email: $multipartFormData.email,
      },
      validationSchema: object().shape({
        firstName: string().required("Required"),
        lastName: string().required("Required"),
        email: string().email("Must be a valid email").required("Required"),
      }),
      onSubmit: (formData) => {
        $multipartFormData.firstName = formData.firstName;
        $multipartFormData.lastName = formData.lastName;
        $multipartFormData.email = formData.email;
        goForward();
      },
    }
  );
</script>

<form class="mobile-content-body" on:submit={handleSubmit} novalidate>
  <p class="mobile-content-description md:hidden">
    Tell us who you would like to invite to your account.<br /><br />
    You will be able to define what they have access to in the next step.
  </p>
  <div
    class="border-b border-t border-ui/border-light md:py-[50px] md:pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="firstName">
          First Name <span class="text-state/negative">*</span>
        </label>

        <input
          name="firstName"
          type="text"
          bind:value={$form.firstName}
          on:change={handleChange}
        />

        {#if $errors.firstName}
          <small class="input-error"><WarnIcon /> {$errors.firstName}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="lastName">
          Last Name <span class="text-state/negative">*</span>
        </label>

        <input
          name="lastName"
          type="text"
          bind:value={$form.lastName}
          on:change={handleChange}
        />

        {#if $errors.lastName}
          <small class="input-error"><WarnIcon /> {$errors.lastName}</small>
        {/if}
      </div>

      <div class="input-wrap col-span-full">
        <label for="email">
          Email Address <span class="text-state/negative">*</span>
        </label>

        <input
          name="email"
          type="email"
          bind:value={$form.email}
          on:change={handleChange}
        />

        {#if $errors.email}
          <small class="input-error"><WarnIcon /> {$errors.email}</small>
        {/if}
      </div>
    </div>
  </div>
  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      class="mobile-back-button btn-main"
      type="button"
      on:click|preventDefault={modalStore.close}
    >
      Cancel
    </button>
    <button class="btn-filled btn-lg" type="submit" disabled={$isSubmitting}>
      <span>Continue</span>
      <ArrowRightIcon />
    </button>
  </div>
</form>
