import type { Writable } from "svelte/store";

/**
 * Return a promise for a store value which resolves either:
 *
 * * no predicate (default)
 * as soon as the store value is truthy (default),
 *
 * * with predicate
 * if passed a predicate function as the second parameter, when the predicate
 * evaluates to true for the store value
 *
 * This is primarily useful for waiting for a store to be initialized, or match
 * a certain condition, before working with that data.
 */
export async function once<T>(
  store: Writable<T>,
  predicate: (value: T) => boolean = null
): Promise<T> {
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe((value) => {
      if (predicate) {
        if (predicate(value) === true) {
          () => unsubscribe();
          resolve(value);
        }
      } else {
        if (value) {
          () => unsubscribe();
          resolve(value);
        }
      }
    });
  });
}
