import { isDate, format as datefnsFormat, formatDistanceToNow } from "date-fns";
import { capitalize } from "lodash";

/**
 * Format float as a string with a given precision in digits
 * @param {number} float - The number to format
 * @param {number} digits - The precision to format to in digits
 * @param {boolean} [force=false] - include zeroes up to the maximum digit precision
 * @returns {string} The formatted number string
 */
export function floatString(
  float: number,
  digits: number,
  force: boolean = false
): string {
  const opts = {
    maximumFractionDigits: digits,
    ...(force ? { minimumFractionDigits: digits } : {}),
  };

  return Intl.NumberFormat("en-US", opts).format(float);
}

/**
 * Returns the ordinal word for the given index up to the fifth index. Otherwise, returns "next".
 * @param index - The index of the ordinal word to return. Starts at 0 (first).
 * @returns The ordinal word for the given index up to the fifth index. Otherwise, returns "next".
 */
export function getOrdinalWord(index: number) {
  const ordinalWords = ["first", "second", "third", "fourth", "fifth"];
  return index + 1 > ordinalWords.length ? "next" : ordinalWords[index];
}

/**
 * Returns an account number with all but the last four digits masked with asterisks
 * @param accountNumber - The account number to mask
 * @param symbol - The symbol to use for masking (default: "*")
 * @returns An account number with all but the last four digits masked with asterisks
 */

export function maskAccountNumber(accountNumber: string, symbol = "*") {
  return (accountNumber || "").replace(/\d(?=\d{4})/g, symbol);
}

/**
 * Formats dates from the database using date-fns format tokens
 * https://date-fns.org/v2.30.0/docs/format
 *
 * Dates in our database are often in a simple "yyyy-MM-dd" format without time
 * information but system fields like "created_at" are full ISO strings.
 *
 * This function will handle either case.
 *
 * When given a full ISO string, we will trust the time information.
 * When given a simple date string, we will ensure that the formatted string
 * is returned for the same day in the user's timezone, by preventing a
 * conversion to UTC.
 *
 * @param date - The date string to format
 * @param format - A string of format tokens (see: https://date-fns.org/v2.30.0/docs/format)
 * @returns The formatted date string
 */
export function formatDBDate(date: string, format: string): string {
  let dateObj: Date;
  if (date.includes("T")) {
    // trust the ISO time information
    dateObj = new Date(date);
  } else {
    // time information here without "Z" ensures date is created in the local timezone
    dateObj = new Date(`${date}T00:00:00`);
  }
  return datefnsFormat(dateObj, format);
}

/**
 * Returns a string representing the time elapsed since the given date
 *
 * Will return "Unknown" if passed an invalid date string
 *
 * @param {Date|string} date - The date to format (can be a js Date object or a date/datetime string directly from the database)
 * @returns {string} A string representing the time elapsed since the given date
 */
export function timeAgo(date: Date | string): string {
  // cast our date string as a Date object as needed
  // but only if date is not null/undefined
  if (date && !isDate(date)) {
    if ((date as string).includes("T")) {
      // trust ISO string time information
      date = new Date(date);
    } else {
      // no time information, ensure date is created in local tz rather than utc
      date = new Date(`${date}T00:00:00`);
    }
  }
  // but since our Date object could be an "Invalid Date" we need to validate it
  if (date instanceof Date && !isNaN(date as unknown as number)) {
    // we have a real Date, can format it and return
    return capitalize(formatDistanceToNow(date as Date, { addSuffix: true }));
  } else {
    // we have an invalid Date, return a suitable string
    return "Unknown";
  }
}

export const getFileNameWithoutExtension = (name: string) =>
  name?.split(".")[0];

export function formatSSN(ssn: string) {
  return (ssn || "").replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
}

export function camelToCapitalized(camel: string): string {
  return (camel || "")
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}
