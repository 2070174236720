<script lang="ts">
  import type { Document } from "../../types";
  import {
    addDocument,
    documentCategories,
    type DocumentCategory,
    updateDocument,
  } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import FileUpload from "../FileUpload.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial, modalStore } from "@skeletonlabs/skeleton";
  import { capitalize } from "lodash";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let document: Document = null;
  export let category: DocumentCategory = null;
  export let forTask: string = null;

  let submitClicked = false;
  let file: File;

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        description: document?.description ?? "",
        category: document?.category ?? category ?? "",
      },
      validationSchema: object().shape({
        description: string().required("Required"),
        category: string().required("Required").oneOf(documentCategories),
      }),
      onSubmit: async (formData) => {
        if (!isUpdate && !file) return;
        try {
          if (isUpdate) {
            await updateDocument(
              document,
              {
                description: formData.description,
                category: formData.category as DocumentCategory,
              },
              file
            );
          } else {
            if (file) {
              await addDocument(
                file,
                formData.category as DocumentCategory,
                formData.description,
                ...(forTask ? [{ forTask }] : [])
              );
            }
          }
          $modalStore[0].response(true);
          modalStore.close();
          popSuccessToast(
            `Your document has been ${
              isUpdate ? "updated" : "uploaded"
            } successfully.`
          );
        } catch (error) {
          console.error(error);
          popErrorToast("There was a problem uploading your document.");
        }
      },
    }
  );

  $: isUpdate = !!document;
</script>

<form
  class="mobile-content w-[750px] max-w-[100%]"
  on:submit={handleSubmit}
  novalidate
>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title-fixed">
      {#if isUpdate}
        Update Document
      {:else}
        Add New Document
      {/if}
    </h1>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pb-0 pl-[50px] shadow-forms"
  >
    <div class="grid grid-cols-1 gap-[45px] md:pb-[50px] md:pr-[45px]">
      <div class="input-wrap">
        <label for="name">
          Description <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="description"
          bind:value={$form.description}
          on:change={handleChange}
        />

        {#if $errors.description && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.description}</small>
        {/if}
      </div>

      <div class="input-wrap w-[50%]">
        <label for="category">
          Category <span class="text-state/negative">*</span>
        </label>

        <select
          id="category"
          bind:value={$form.category}
          on:change={handleChange}
          required
        >
          <option selected disabled>Choose...</option>

          {#each documentCategories as category (category)}
            <!-- text-transform: capitalize is not working on select option -->
            <option value={category}>{capitalize(category)}</option>
          {/each}
        </select>

        {#if $errors.category && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.category}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label
          >{isUpdate ? "Replace File" : "Document"}
          {#if !isUpdate}
            <span class="text-state/negative">*</span>
          {/if}
        </label>

        <FileUpload bind:file />

        {#if !isUpdate && !file && submitClicked}
          <small class="input-error"><WarnIcon /> Required</small>
        {/if}
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
      disabled={$isSubmitting}
      on:click={() => modalStore.close()}
    >
      Cancel
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg btn-success flex gap-[10px]"
      disabled={$isSubmitting}
      on:click={() => (submitClicked = true)}
    >
      {#if isUpdate}
        {#if $isSubmitting}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />

          Saving
        {:else}
          <CheckIcon />

          Save Changes
        {/if}
      {:else if $isSubmitting}
        Adding Document

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        Add Document

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
