<script lang="ts" context="module">
  import type { Service } from "../../pages/Services.svelte";
  import BulbIcon from "../icons/Bulb.svelte";
  import CalendarTimeIcon from "../icons/CalendarTime.svelte";
  import RecurringIcon from "../icons/Recurring.svelte";
  import { onMount } from "svelte";

  type ServiceType = Service["type"];

  const serviceTypeDetailsMap = {
    expense: {
      name: "Recurring Expense",
      type: "expense",
      icon: RecurringIcon,
      examples: ["Pest Control", "Auto Insurance", "HOA Fee"],
    },
    subscription: {
      name: "Subscription",
      type: "subscription",
      icon: CalendarTimeIcon,
      examples: ["Netflix", "Planet Fitness", "Hello Fresh"],
    },
    utility: {
      name: "Utility",
      type: "utility",
      icon: BulbIcon,
      examples: ["Electricity", "Water", "Internet"],
    },
  } as const;

  export const getServiceTypeName = (type: ServiceType) =>
    serviceTypeDetailsMap[type].name;

  export const getServiceTypeIcon = (type: ServiceType) =>
    serviceTypeDetailsMap[type].icon;
</script>

<script lang="ts">
  import Modal from "../Modal.svelte";
  import AddUpdateServiceForm from "./AddUpdateServiceForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  type Step = "Select Type" | "Form" | "Success";

  let step: Step = "Select Type";
  let serviceType: ServiceType = null;

  function selectServiceType(type: ServiceType) {
    serviceType = type;
    step = "Form";
  }

  function handleDone({ detail: service }: { detail: Service }) {
    step = "Success";

    $modalStore[0].response(service);
  }

  // service types can be pre-set via the state object on the history stack
  onMount(maybePresetServiceType);
  function maybePresetServiceType() {
    if ($modalStore[0].meta.serviceType) {
      selectServiceType($modalStore[0].meta.serviceType);
    }
  }
</script>

<Modal>
  <div class="mobile-content w-[750px] max-w-[100%]">
    {#if step === "Select Type"}
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title mb-[30px]">Add a New Service</h1>

        <p class="text-body/large text-text/dark max-md:hidden">
          What type of service would you like to add?
        </p>
      </div>

      <div
        class="mobile-content-body bg-bg/white md:iconshadow-forms border-t border-ui/border-light px-[50px] pb-[40px] pt-[50px]"
      >
        <p class="mobile-content-description md:hidden">
          What type of service would you like to add?
        </p>

        {#each Object.values(serviceTypeDetailsMap) as serviceType}
          <div
            class="mb-[30px] flex items-center max-sm:flex-col max-sm:justify-start"
          >
            <button
              type="button"
              class="variant-ringed-primary btn-lg btn flex w-[315px] justify-start gap-[5px] bg-white text-state/link max-md:!p-[16px] max-sm:w-[100%]"
              on:click={() => selectServiceType(serviceType.type)}
            >
              {#if serviceType.icon}
                <svelte:component this={serviceType.icon} />
              {/if}

              <span class="max-md:text-[20px]">{serviceType.name}</span>
            </button>

            <div
              class="mx-4 w-[70px] border-t border-ui/border-dark max-sm:hidden md:border-ui/border"
            />

            <div class="max-sm:hidden">
              {#each serviceType.examples as example}
                <div class="text-sm text-text/dark md:text-text/medium">
                  {example}
                </div>
              {/each}
            </div>
            <div
              class="input-hint !mt-[10px] ml-[40px] w-[100%] text-left sm:hidden"
            >
              e.g. {serviceType.examples.join(", ")}
            </div>
          </div>
        {/each}
      </div>
    {:else if step === "Form"}
      <AddUpdateServiceForm
        {serviceType}
        on:cancel={() => (step = "Select Type")}
        on:done={handleDone}
      />
    {:else if step === "Success"}
      <div class="text-center">
        <div class=" px-[50px] py-[30px]">
          <span class="icon mb-[30px] flex justify-center">
            <svelte:component this={getServiceTypeIcon(serviceType)} />
          </span>

          <h1>{getServiceTypeName(serviceType)} Added!</h1>
        </div>

        <div class="w-full border-t border-ui/border p-[50px] shadow">
          <p class="mb-10 text-[22px] text-text/dark">
            What would you like to do now?
          </p>

          <button
            type="button"
            class="variant-ringed-primary btn-lg btn mb-[22px] w-[350px] text-state/link"
            on:click={() => {
              step = "Select Type";
              history.replaceState({}, "");
              history.pushState({ openModal: "addService" }, "");
            }}
          >
            Add Another Service
          </button>

          <button
            type="button"
            class="btn-filled btn-lg btn w-[350px] gap-[5px]"
            on:click={modalStore.close}
          >
            Close Window
          </button>
        </div>
      </div>
    {/if}
  </div>
</Modal>

<style>
  .icon :global(svg) {
    height: 80px;
    width: 80px;
  }
</style>
