<script lang="ts">
  import { userCan } from "../../stores/user.ts";
  import { supabase } from "../../supabaseClient.ts";
  import {
    type CharityBeneficiary,
    type PersonBeneficiary,
    type Trust,
  } from "../../types";
  import ModalStep from "../ModalStep.svelte";
  import ModalStepper from "../ModalStepper.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import InfoIcon from "../icons/Info.svelte";
  import PlusIcon from "../icons/PlusSmall.svelte";
  import AddNewBeneficiary from "./AddNewBeneficiary.svelte";
  import AddNewTrust from "./AddNewTrust.svelte";
  import { onMount } from "svelte";
  import { writable, type Writable } from "svelte/store";

  export let multipartFormData: Writable<any>;
  export let selectedAssetType: string;
  export let name: string;
  export let onSubmitted: () => void;
  export let onCancelled: () => void;
  let trusts: Writable<Trust[]> = writable([]);
  let personBeneficiaries: Writable<PersonBeneficiary[]> = writable([]);
  let charityBeneficiaries: Writable<CharityBeneficiary[]> = writable([]);

  let addBeneficiary: boolean = false;
  let addTrust: boolean = false;

  let stepper: ModalStepper;

  function handleStepChange(event) {
    if (event.detail === 0) {
      addBeneficiary = false;
      addTrust = false;
    }
  }

  function getTrustsAndBeneficiaries() {
    supabase
      .from("beneficiary_person")
      .select("id, first_name, last_name")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          //@ts-ignore
          $personBeneficiaries = [...data];
        }
      });
    supabase
      .from("beneficiary_charity")
      .select("id, name")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          //@ts-ignore
          $charityBeneficiaries = [...data];
        }
      });
    supabase
      .from("trust")
      .select("id, name")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          //@ts-ignore
          $trusts = [...data];
        }
      });
  }

  // Other parts need the full objects, not just the IDs, but bind only works with IDs/strings not objects, thus we have to
  // go through the unfortunate process of converting to id's for use here and then back on submittal
  $multipartFormData.beneficiary_person =
    $multipartFormData.beneficiary_person?.map((beneficiary) => {
      return beneficiary.id;
    });
  $multipartFormData.beneficiary_charity =
    $multipartFormData.beneficiary_charity?.map((beneficiary) => {
      return beneficiary.id;
    });

  function submit() {
    $multipartFormData.beneficiary_person =
      $multipartFormData.beneficiary_person.map((id) => {
        return $personBeneficiaries.find(
          (beneficiary) => beneficiary.id === id
        );
      });
    $multipartFormData.beneficiary_charity =
      $multipartFormData.beneficiary_charity.map((id) => {
        return $charityBeneficiaries.find(
          (beneficiary) => beneficiary.id === id
        );
      });
    onSubmitted();
  }

  function cancel() {
    $multipartFormData.beneficiary_person =
      $multipartFormData.beneficiary_person.map((id) => {
        return $personBeneficiaries.find(
          (beneficiary) => beneficiary.id === id
        );
      });
    $multipartFormData.beneficiary_charity =
      $multipartFormData.beneficiary_charity.map((id) => {
        return $charityBeneficiaries.find(
          (beneficiary) => beneficiary.id === id
        );
      });
    onCancelled();
  }

  onMount(() => {
    getTrustsAndBeneficiaries();
  });
</script>

<ModalStepper bind:this={stepper} on:stepChange={handleStepChange}>
  <ModalStep step={0}>
    <form
      class="mobile-content max-md:!top-[85px]"
      on:submit|preventDefault={submit}
      novalidate
    >
      <div
        class="mobile-header mb-[30px] px-[50px] pt-[30px] max-md:block max-md:!h-[85px]"
      >
        <h1 class="mobile-header-title-fixed max-md:!text-left">
          Edit Asset Distribution
        </h1>
        <p
          class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
        >
          {name}
        </p>
      </div>

      <div
        class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
      >
        <div
          class="grid gap-x-[40px] gap-y-[45px] md:grid-cols-2 md:border-b md:border-ui/border-light md:pb-[45px] md:pr-[50px]"
        >
          <div class="input-wrap">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label> Distribution Method </label>

            <div class="flex flex-col items-start gap-[12px]">
              {#if selectedAssetType === "banking" || selectedAssetType === "investment" || selectedAssetType === "retirement" || selectedAssetType === "real_estate"}
                <label class="input-option">
                  <input
                    type="radio"
                    id="will"
                    name="distributionMethod"
                    value="designation"
                    bind:group={$multipartFormData.distribution_method}
                  />
                  <span class="underline">POD / TOD</span> Designation
                </label>
              {/if}
              <label class="input-option">
                <input
                  type="radio"
                  id="trust"
                  name="distributionMethod"
                  value="will"
                  bind:group={$multipartFormData.distribution_method}
                />
                Through the <span class="underline">Will</span>
              </label>
              <label class="input-option">
                <input
                  type="radio"
                  id="joint"
                  name="distributionMethod"
                  value="trust"
                  bind:group={$multipartFormData.distribution_method}
                />
                Through a <span class="underline">Trust</span>
              </label>
              <label class="input-option">
                <input
                  type="radio"
                  id="other"
                  name="distributionMethod"
                  value="tbd"
                  bind:group={$multipartFormData.distribution_method}
                />
                To be determined
              </label>
            </div>
          </div>

          {#if $multipartFormData.distribution_method === "trust"}
            <div class="input-wrap">
              <label> Select Trust </label>

              <div class="flex flex-col items-start gap-[12px]">
                {#each $trusts as trust}
                  <label class="input-option">
                    <input
                      type="radio"
                      name="selectTrust"
                      value={trust.id}
                      bind:group={$multipartFormData.distribution_trust_id}
                    />
                    {trust.name}
                  </label>
                {/each}
                {#if userCan("vault_write")}
                  <button
                    class="btn-main btn-sm mt-[10px]"
                    on:click|preventDefault={() => {
                      addTrust = true;
                      stepper.goForward();
                    }}
                  >
                    <span><PlusIcon /></span>
                    Add New Trust
                  </button>
                {/if}
              </div>
            </div>
          {/if}
          {#if $multipartFormData.distribution_method === "tbd"}
            <div class="col-span-full">
              <p
                class="flex items-center justify-start gap-[10px] text-[18px] font-semibold leading-[22px] text-[#FB5607]"
              >
                <InfoIcon /> A new task will be added for you to define this method
                later
              </p>
            </div>
          {/if}
        </div>

        <div
          class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pr-[50px] pt-[45px] max-md:gap-y-[35px] md:gap-y-[45px]"
        >
          <div class="input-wrap col-span-full">
            <div class="form-label">Beneficiaries</div>

            <div class="check-cols gap-[12px]">
              {#each $personBeneficiaries as person}
                <label class="input-option pb-[12px]">
                  <input
                    type="checkbox"
                    id={person.id.toString()}
                    name={person.id.toString()}
                    value={person.id}
                    bind:group={$multipartFormData.beneficiary_person}
                  />
                  {person.first_name + " " + person.last_name}
                </label>
              {/each}
              {#each $charityBeneficiaries as charity}
                <label class="input-option pb-[12px]">
                  <input
                    type="checkbox"
                    id={charity.id.toString()}
                    name={charity.id.toString()}
                    value={charity.id}
                    bind:group={$multipartFormData.beneficiary_charity}
                  />
                  {charity.name}
                </label>
              {/each}
            </div>

            <button
              class="btn-main btn-sm mt-[30px]"
              on:click|preventDefault={() => {
                addBeneficiary = true;
                stepper.goForward();
              }}><PlusIcon /> Add New Beneficiary</button
            >
          </div>
        </div>
      </div>

      <div
        class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
      >
        <button
          class="btn-main mobile-back-button"
          on:click|preventDefault={cancel}
        >
          <span class="md:hidden"><ArrowLeftIcon /></span>

          Cancel
        </button>
        <button class="btn-filled btn-lg btn-success" type="submit">
          <CheckIcon />

          Save Changes
        </button>
      </div>
    </form>
  </ModalStep>
  {#if addTrust}
    <ModalStep step={2}>
      <AddNewTrust {multipartFormData} {trusts} />
    </ModalStep>
  {/if}
  {#if addBeneficiary}
    <ModalStep step={3}>
      <AddNewBeneficiary {personBeneficiaries} {charityBeneficiaries} />
    </ModalStep>
  {/if}
</ModalStepper>
