<script lang="ts">
  import { currentEstate, isOwner, userCan } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import type {
    AssetType,
    CharityBeneficiary,
    PersonBeneficiary,
    Trust,
  } from "../../types";
  import {
    openPlaidLinkModal,
    type OnExitMetaData,
    type OnSuccessMetaData,
    type PlaidError,
    type LinkHandler,
  } from "../../util/plaid";
  import { getWillDocuments } from "../../util/storage.ts";
  import { popErrorToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import Modal from "../Modal.svelte";
  import ModalStep from "../ModalStep.svelte";
  import ModalStepConnectedChooser from "../ModalStepConnectedChooser.svelte";
  import ModalStepper from "../ModalStepper.svelte";
  import BankIcon from "../icons/Bank.svelte";
  import CarIcon from "../icons/Car.svelte";
  import ChartIcon from "../icons/Chart.svelte";
  import GavelIcon from "../icons/Gavel.svelte";
  import HandCoinIcon from "../icons/HandCoin.svelte";
  import HierarchyTreeIcon from "../icons/HierarchyTree.svelte";
  import HouseIcon from "../icons/House.svelte";
  import PiggyBankIcon from "../icons/PiggyBank.svelte";
  import RingIcon from "../icons/Ring.svelte";
  import UmbrellaIcon from "../icons/Umbrella.svelte";
  import AddConfirmation from "./AddConfirmation.svelte";
  import AddConnectedAccount from "./AddConnectedAccount.svelte";
  import AddInheritance from "./AddInheritance.svelte";
  import AddInsurance from "./AddInsurance.svelte";
  import AddLegalEntity from "./AddLegalEntity.svelte";
  import AddLoanAsset from "./AddLoanAsset.svelte";
  import AddManualAccount from "./AddManualAccount.svelte";
  import AddManualAccountLabel from "./AddManualAccountLabel.svelte";
  import AddNewBeneficiary from "./AddNewBeneficiary.svelte";
  import AddNewTrust from "./AddNewTrust.svelte";
  import AddOtherProperty from "./AddOtherProperty.svelte";
  import AddRealEstate from "./AddRealEstate.svelte";
  import AddVehicle from "./AddVehicle.svelte";
  import AddWill from "./AddWill.svelte";
  import AssetBeneficiaries from "./AssetBeneficiaries.svelte";
  import AssetDistribution from "./AssetDistribution.svelte";
  import {
    createTaskToSpecifyBeneficiaries,
    createTaskToSpecifyDistribution,
  } from "./tasks.ts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { type ComponentType, onMount, tick, onDestroy } from "svelte";
  import { writable } from "svelte/store";

  let modalStepper: ModalStepper;

  let loading = false;

  // This can take the shape of any of the various asset types depending
  // on the selections
  const multipartFormData = writable<{ [key: string]: any }>({
    estate_id: $currentEstate.id,
  });

  let selectedAssetType: AssetType;

  let plaidHandler: LinkHandler;

  function setAssetType(type: AssetType) {
    // Reset form data if there was some before as at this point we're starting over
    $multipartFormData = { estate_id: $currentEstate.id };
    selectedAssetType = type;
    // If it's a financial account type the next step is deciding online or offline, which then dictates the flow. Otherwise
    // we can just jump to the manual flow
    if (type === "banking" || type === "investment" || type === "retirement") {
      $multipartFormData.financial_account_type = type;
    } else {
      selectedFlow = type;
    }
    modalStepper.goForward();
  }

  /** Flow control
   *
   * There are different asset types with different forms associated, as well as a connected flow for
   * plaid connected accounts. The following variables and methods control the flow on different selections.
   */
  const addAssetForms: { [key in AssetType & "connected"]: ComponentType } = {
    connected: AddConnectedAccount,
    financial_account: AddManualAccount,
    inheritance: AddInheritance,
    insurance: AddInsurance,
    real_estate: AddRealEstate,
    property: AddOtherProperty,
    legal_entity: AddLegalEntity,
    vehicle: AddVehicle,
    loan_asset: AddLoanAsset,
  };

  let selectedFlow: AssetType | "connected" = "financial_account";

  // Different conditional components display depending on the flow and different states. Child components update stores as appropriate,
  // or they react of other selections
  $: showConnectedChooser =
    selectedAssetType === "banking" ||
    selectedAssetType === "investment" ||
    selectedAssetType === "retirement";
  $: showAccountLabel =
    selectedFlow === "financial_account" && !$multipartFormData.account_number;
  const showAddNewTrust = writable(false);
  const showAddNewBeneficiary = writable(false);
  const willExists = writable(false);

  function onConnectSelected(connected: boolean) {
    if (connected) {
      selectedFlow = "connected";
      loading = true;
      openPlaidLink();
    } else {
      selectedFlow = "financial_account";
      modalStepper.goForward();
    }
  }

  /**
   * Plaid control
   *
   * The following control interaction with plaid if the user chooses a connected flow
   */
  function openPlaidLink() {
    $multipartFormData.current_value = 0;
    try {
      openPlaidLinkModal({
        onSuccess: (public_token: string, metadata: OnSuccessMetaData) => {
          // There's only a single account possible for now
          const account = metadata.accounts[0];
          $multipartFormData.account_number = account.mask;
          $multipartFormData.financial_account_subtype = account.subtype;
          $multipartFormData.plaid_account_id = account.id;
          $multipartFormData.institution_name = metadata.institution.name;
          $multipartFormData.institution_id =
            metadata.institution.institution_id;
          $multipartFormData.public_token = public_token;
          loading = false;
          modalStepper.goForward();
        },
        onExit: (error: PlaidError, metadata: OnExitMetaData) => {
          loading = false;
          if (error) {
            console.error(
              `Plaid error: ${error.error_type} ${error.display_message}`
            );
            popErrorToast("Error connecting asset. Please try again later");
          }
        },
      }).then((handler) => {
        plaidHandler = handler;
      });
    } catch (e) {
      popErrorToast("Error connecting asset. Please try again later");
      console.error(e);
    }
  }

  // Asset types can be pre-set via the state object on the history stack
  function maybePresetAssetType() {
    if ($modalStore[0].meta.assetType) {
      setAssetType($modalStore[0].meta.assetType);
    }
  }

  // Beneficiaries and trusts are used in the last steps of the add asset flow for all asset types
  const personBeneficiaries = writable<PersonBeneficiary[]>([]);
  const charityBeneficiaries = writable<CharityBeneficiary[]>([]);
  const trusts = writable<Trust[]>([]);

  async function getBeneficiariesTrustsWills() {
    supabase
      .from("beneficiary_person")
      .select("id, first_name, last_name")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          //@ts-ignore - This is worried we're not getting all the properties of this object, not a useful worry
          $personBeneficiaries = [...data];
        }
      });
    supabase
      .from("beneficiary_charity")
      .select("id, name")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          //@ts-ignore - Same as above
          $charityBeneficiaries = [...data];
        }
      });
    supabase
      .from("trust")
      .select("id, name")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          //@ts-ignore - Same as above
          $trusts = [...data];
        }
      });
    supabase
      .from("document")
      .select("type")
      .eq("type", "will")
      .eq("estate_id", $currentEstate.id)
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          $willExists = !!data.length;
        }
      });
  }

  function createAsset() {
    loading = true;
    modalStepper.goForward();
    let assetType = selectedAssetType;
    if (
      selectedAssetType === "banking" ||
      selectedAssetType === "investment" ||
      selectedAssetType === "retirement"
    ) {
      assetType = "financial_account";
    }
    supabase.functions
      .invoke("create-asset", {
        body: { assetToAdd: $multipartFormData, assetType },
      })
      .then(({ data, error }) => {
        if (error) {
          popErrorToast(error.message);
          modalStepper.goBack();
        } else {
          if ($multipartFormData.distribution_method === "tbd") {
            createTaskToSpecifyDistribution(data.asset_id);
          }
          if (
            !Object.keys($multipartFormData).some((k) =>
              ["personBeneficiaries", "charityBeneficiaries"].includes(k)
            )
          ) {
            createTaskToSpecifyBeneficiaries(data.asset_id);
          }

          $modalStore[0].response({ data, assetType });
          modalStepper.goForward();
        }
      })
      .finally(() => {
        loading = false;
      });
  }

  // This is a svelte technique for resetting component state if a user decides to start over. This is used as a key around
  // the entire tree, and {} is not equal to {} in JS, so simply resetting the object starts it over.
  let unique = {};
  function restartAddAsset() {
    unique = {};
    $multipartFormData = { estate_id: $currentEstate.id };
  }

  onMount(() => {
    maybePresetAssetType();
    getBeneficiariesTrustsWills();
  });

  onDestroy(() => {
    if (plaidHandler) {
      plaidHandler.exit();
    }
  });
</script>

{#key unique}
  <Modal>
    <div class="w-[750px] max-w-[100%]">
      <ModalStepper bind:this={modalStepper}>
        <ModalStep step={0}>
          <svelte:fragment>
            <div class="mobile-content">
              <div class="mobile-header px-[50px] py-[30px]">
                <h1 class="mobile-header-title mb-[30px]">
                  Add <ins class="max-md:hidden">a </ins>New Asset
                </h1>
                <p class="m-0 p-0 text-body/large max-md:hidden">
                  What type of asset would you like to add?
                </p>
              </div>
              <div
                class="mobile-content-body border-t border-ui/border-light p-[50px] md:shadow-forms"
              >
                <p class="mobile-content-description md:hidden">
                  What type of asset would you like to add?
                </p>

                <h3
                  class="pb-[15px] text-[20px] leading-[30px] max-md:text-[16px] max-md:leading-[24px]"
                >
                  Financial Accounts
                </h3>
                <div
                  class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-[20px] max-md:gap-[15px]"
                >
                  <button
                    on:click|preventDefault={() => setAssetType("banking")}
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                  >
                    <span><BankIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">Banking</span>
                  </button>
                  <button
                    on:click|preventDefault={() => setAssetType("investment")}
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                  >
                    <span><ChartIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]"
                      >Investment</span
                    >
                  </button>
                  <button
                    on:click|preventDefault={() => setAssetType("retirement")}
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                  >
                    <span><PiggyBankIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]"
                      >Retirement</span
                    >
                  </button>
                </div>

                <h3
                  class="mt-[45px] pb-[15px] text-[20px] leading-[30px] max-md:text-[16px] max-md:leading-[24px]"
                >
                  Property
                </h3>
                <div
                  class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-[20px] max-md:gap-[15px]"
                >
                  <button
                    on:click|preventDefault={() => setAssetType("real_estate")}
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                  >
                    <span><HouseIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]"
                      >Real Estate</span
                    >
                  </button>
                  <button
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                    on:click|preventDefault={() => setAssetType("vehicle")}
                  >
                    <span><CarIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">Vehicle</span>
                  </button>
                  <button
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                    on:click|preventDefault={() => setAssetType("property")}
                  >
                    <span><RingIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">Other</span>
                  </button>
                </div>

                <h3
                  class="mt-[45px] pb-[15px] text-[20px] leading-[30px] max-md:text-[16px] max-md:leading-[24px]"
                >
                  Other
                </h3>
                <div
                  class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-[20px] max-md:gap-[15px] md:grid-cols-[repeat(auto-fit,minmax(250px,1fr))]"
                >
                  <button
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                    on:click|preventDefault={() => setAssetType("insurance")}
                  >
                    <span><UmbrellaIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">
                      Insurance
                    </span>
                  </button>
                  <button
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                    on:click|preventDefault={() => setAssetType("inheritance")}
                  >
                    <span><HierarchyTreeIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">
                      Inheritance
                    </span>
                  </button>
                  <button
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                    on:click|preventDefault={() => setAssetType("legal_entity")}
                  >
                    <span><GavelIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">
                      Legal Entity
                    </span>
                  </button>
                  <button
                    class="btn-main flex-1 !justify-start !p-[20px] max-md:gap-[8px] max-md:!p-[16px]"
                    on:click|preventDefault={() => setAssetType("loan_asset")}
                  >
                    <span><HandCoinIcon /></span>
                    <span class="text-[22px] max-md:text-[20px]">Loan</span>
                  </button>
                </div>
              </div>
            </div>
          </svelte:fragment>
        </ModalStep>

        {#if showConnectedChooser}
          <ModalStep step={1}>
            <svelte:fragment>
              <ModalStepConnectedChooser
                type={selectedAssetType}
                {onConnectSelected}
                createConnectedLoading={loading}
              />
            </svelte:fragment>
          </ModalStep>
        {/if}

        <ModalStep step={2}>
          <svelte:component
            this={addAssetForms[selectedFlow]}
            {multipartFormData}
            {selectedAssetType}
          />
        </ModalStep>

        {#if showAccountLabel}
          <ModalStep step={3}>
            <AddManualAccountLabel {multipartFormData} {selectedAssetType} />
          </ModalStep>
        {/if}

        <ModalStep step={4}>
          <AssetDistribution
            {multipartFormData}
            {selectedAssetType}
            {trusts}
            {showAddNewTrust}
            on:submitted={() =>
              userCan("beneficiaries_read")
                ? modalStepper.goForward()
                : createAsset()}
          />
        </ModalStep>

        {#if $showAddNewTrust}
          <ModalStep step={5}>
            <AddNewTrust
              {multipartFormData}
              {trusts}
              on:trustAdded={() =>
                tick().then(() => ($showAddNewTrust = false))}
            />
          </ModalStep>
        {/if}

        {#if userCan("vault_write") && $multipartFormData.distribution_method === "will" && !$willExists}
          <ModalStep step={6}>
            <AddWill />
          </ModalStep>
        {/if}

        {#if userCan("beneficiaries_read")}
          <ModalStep step={7}>
            <AssetBeneficiaries
              {multipartFormData}
              {personBeneficiaries}
              {charityBeneficiaries}
              {showAddNewBeneficiary}
              on:submitted={() => createAsset()}
            />
          </ModalStep>
        {/if}

        {#if $showAddNewBeneficiary && userCan("beneficiaries_write")}
          <ModalStep step={8}>
            <AddNewBeneficiary
              {personBeneficiaries}
              {charityBeneficiaries}
              on:beneficiaryAdded={() => {
                tick().then(() => ($showAddNewBeneficiary = false));
              }}
            />
          </ModalStep>
        {/if}

        {#if loading}
          <ModalStep step={9}>
            <div class="p-[100px]">
              <h2 class="mb-[50px] text-center">Creating Asset...</h2>
              <BarLoader />
            </div>
          </ModalStep>
        {/if}

        <ModalStep step={10}>
          <AddConfirmation {restartAddAsset} {selectedAssetType} />
        </ModalStep>
      </ModalStepper>
    </div>
  </Modal>
{/key}
