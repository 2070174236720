import { supabase } from "../../supabaseClient";

export async function getTrustName(id: number): Promise<string | null> {
  const { data, error } = await supabase
    .from("trust")
    .select("name")
    .eq("id", id)
    .single();

  if (error) {
    console.error(error);
    return null;
  }

  return data.name;
}

export async function getTrustNames(
  id: number[]
): Promise<{ id: number; name: string }[]> {
  const { data, error } = await supabase
    .from("trust")
    .select("id, name")
    .in("id", id);

  if (error) {
    console.error(error);
    return [];
  }

  return data;
}
