<script lang="ts">
  import type { OnboardingData } from "../../types/Onboarding";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import CheckIcon from "../icons/CheckFramed.svelte";
  import CrossIcon from "../icons/Cross.svelte";

  export let data: OnboardingData;

  export let goBack: () => void;
  export let goForward: () => void;

  function setHasWill(hasWill: boolean) {
    data = {
      ...data,
      hasWill,
      willDocument: hasWill ? data.willDocument : null,
    };

    goForward();
  }
</script>

<div class="mobile-content">
  <div
    class="mobile-content-body max-md:flex max-md:items-center max-md:justify-center"
  >
    <div
      class="flex flex-col items-center justify-center md:px-[50px] md:py-[100px] md:shadow"
    >
      <p
        class="mb-[40px] text-center text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        Do you have a will already in place?
      </p>

      <div class="flex w-[350px] flex-col gap-[25px]">
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          on:click={() => setHasWill(true)}
        >
          <span class="button-icon"><CheckIcon /></span>

          Yes
        </button>
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          on:click={() => setHasWill(false)}
        >
          <span class="button-icon"><CrossIcon /></span>

          No
        </button>
      </div>
    </div>

    <div
      class="flex items-center border-t border-ui/border md:px-[50px] md:py-[40px] md:pt-[40px]"
    >
      <button
        class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
        on:click={goBack}
      >
        <ArrowLeftIcon />

        Back
      </button>
    </div>
  </div>
</div>

<style type="text/css">
  .button-icon :global(svg) {
    width: 28px;
    height: 28px;
  }
</style>
