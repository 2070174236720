<script lang="ts">
  import BarLoader from "../lib/BarLoader.svelte";
  import ArrowRightIcon from "../lib/icons/ArrowRight.svelte";
  import EyeIcon from "../lib/icons/Eye.svelte";
  import EyeSlashIcon from "../lib/icons/EyeSlash.svelte";
  import WarnIcon from "../lib/icons/WarnFilled.svelte";
  import { supabase } from "../supabaseClient";
  import { navigateHome } from "../util/navigateHome";
  import { popErrorToast } from "../util/toasts";
  import { passwordValidationSchema } from "../util/validation";
  import { createForm } from "svelte-forms-lib";
  import { object } from "yup";

  let loading = false;
  let authenticated = false;
  let submitClicked = false;
  let passwordVisible = false;

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      password: "",
    },
    validationSchema: object().shape({
      password: passwordValidationSchema,
    }),
    onSubmit: ({ password }) => {
      setPassword(password);
    },
  });

  async function setPassword(password: string) {
    try {
      loading = true;
      const { error } = await supabase.auth.updateUser({
        password,
        data: {
          flags: {
            inviteeAwaitingAccountSetup: false,
          },
        },
      });

      if (error) throw error;

      authenticated = true;
      navigateHome();
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    } finally {
      loading = false;
    }
  }
</script>

<div class="backdrop flex h-full flex-col items-center justify-center">
  <div class="card w-full max-w-[650px] overflow-hidden shadow-onboarding">
    {#if loading || authenticated}
      <BarLoader />
    {/if}

    <form on:submit|preventDefault={handleSubmit}>
      <div class="mobile-content md:p-[50px] md:pt-10 md:shadow-forms">
        <div class="mobile-header">
          <h1 class="mobile-header-title-fixed">Welcome to LegaSEE</h1>
        </div>

        <div class="mobile-content-body">
          <p
            class="mb-[40px] text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px] md:mt-[30px]"
          >
            Before you get started exploring the platform, please set your
            desired password below.
          </p>

          <div class="grid gap-y-[40px]">
            <div class="input-wrap">
              <label for="password">
                Desired Password <span class="text-state/negative">*</span>
              </label>

              <div class="input-group w-[300px] grid-cols-[auto_60px]">
                <!-- Can't change `type` dynamically  -->
                {#if passwordVisible}
                  <input
                    id="password"
                    type="text"
                    class="!pr-0"
                    bind:value={$form.password}
                    on:change={handleChange}
                  />
                {:else}
                  <input
                    id="password"
                    type="password"
                    class="!pr-0"
                    bind:value={$form.password}
                    on:change={handleChange}
                  />
                {/if}

                <button
                  type="button"
                  class="justify-end pr-[18px] text-text/medium"
                  on:click={() => (passwordVisible = !passwordVisible)}
                >
                  {#if passwordVisible}
                    <EyeSlashIcon />
                  {:else}
                    <EyeIcon />
                  {/if}
                </button>
              </div>

              {#if $errors.password && submitClicked}
                <small class="input-error"
                  ><WarnIcon /> {$errors.password}</small
                >
              {/if}

              <div class="input-hint">
                <ul class="ml-[18px] mt-[10px] list-disc">
                  <li class="mt-[3px]">8 characters minimum</li>
                  <li class="mt-[3px]">One lowercase letter</li>
                  <li class="mt-[3px]">One uppercase letter</li>
                  <li class="mt-[3px]">One special character</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mobile-content-footer flex justify-end border-t border-ui/border px-[50px] py-[40px]"
      >
        <button
          type="submit"
          class="btn-filled btn-lg flex gap-[10px]"
          disabled={loading}
          on:click={() => (submitClicked = true)}
        >
          Set Password <ArrowRightIcon />
        </button>
      </div>
    </form>
  </div>
</div>

<style>
  .backdrop {
    background: linear-gradient(116.82deg, #3a86ff 0%, #06ba63 100%);
  }
</style>
