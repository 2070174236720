<script lang="ts">
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { Routes } from "../../util/routes";
  import { popErrorToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import ArrowRight from "../icons/ArrowRight.svelte";
  import Plus from "../icons/Plus.svelte";
  import Card from "./Card.svelte";
  import Empty from "./Empty.svelte";
  import IconCharity from "./IconCharity.svelte";
  import IconPerson from "./IconPerson.svelte";
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";

  let loading = true;
  let people: Array<MinimalBeneficiary>;
  let charities: Array<MinimalBeneficiary>;

  $: grouped = {
    person: people,
    charity: charities,
  };

  const estateId = $currentEstate.id;

  async function getBeneficiaries() {
    try {
      const peopleQuery = supabase
          .from("beneficiary_person")
          .select("first_name, last_name, id")
          .eq("estate_id", estateId),
        charityQuery = supabase
          .from("beneficiary_charity")
          .select("name, id")
          .eq("estate_id", estateId);

      const dataSet = await Promise.all([peopleQuery, charityQuery]);

      if (dataSet.some((result) => result.error)) {
        throw dataSet.find((result) => result.error).error;
      }

      people = dataSet[0]["data"].map((person) => ({
        name: `${person.first_name} ${person.last_name}`,
        id: person.id,
      }));
      charities = dataSet[1]["data"];
    } catch (error) {
      console.error(error.message);
      popErrorToast("Something went wrong. Please try again.");
    } finally {
      loading = false;
    }
  }

  onMount(getBeneficiaries);

  type MinimalBeneficiary = {
    name: string;
    id: number;
  };
</script>

<Card linkTo={Routes.Beneficiaries}>
  <svelte:fragment slot="header">
    Beneficiaries
    <span class="text-text/medium">
      <ArrowRight />
    </span>
  </svelte:fragment>
  <svelte:fragment slot="body">
    {#if loading}
      <BarLoader />
    {/if}
    <div class="p-5">
      {#if !loading}
        {#if people.length || charities.length}
          <div class="flex gap-5">
            {#each Object.entries(grouped) as [type, beneficiaries]}
              <div class="flex basis-1/2 flex-col gap-6">
                {#each beneficiaries as { name, id } (id)}
                  <Link
                    to={Routes.Beneficiaries}
                    state={{
                      openModal: "viewAssetBeneficiary",
                      returnTo: location.pathname,
                      type,
                      id,
                    }}
                    class="flex items-center gap-[10px] text-text/dark hover:opacity-70"
                  >
                    {#if type === "person"}
                      <IconPerson />
                    {:else if type === "charity"}
                      <IconCharity />
                    {/if}
                    {name}
                  </Link>
                {/each}
              </div>
            {/each}
          </div>
        {:else}
          <Empty>
            <span slot="text">No beneficiaries added yet</span>

            <svelte:fragment slot="buttons">
              <Link
                class="btn-main"
                to={Routes.Beneficiaries}
                state={{
                  openModal: "addBeneficiary",
                  returnTo: location.pathname,
                }}
              >
                <Plus />
                Add First Beneficiary
              </Link>
            </svelte:fragment>
          </Empty>
        {/if}
      {/if}
    </div>
  </svelte:fragment>
</Card>
