<script lang="ts">
  import type { OnboardingData } from "../../types/Onboarding";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import CheckIcon from "../icons/CheckFramed.svelte";
  import CrossIcon from "../icons/Cross.svelte";

  export let data: OnboardingData;
  export let index = -1;

  export let goBack: () => void;
  export let goForward: () => void;

  function select(confirm: boolean) {
    data = {
      ...data,
      trusts: confirm
        ? [...data.trusts, { name: "" }]
        : data.trusts.slice(0, index + 1),
    };

    goForward();
  }

  $: previousTrustName = data.trusts[index]?.name;
</script>

<div class="mobile-content">
  <div
    class="mobile-content-body max-md:flex max-md:items-center max-md:justify-center"
  >
    <div
      class="flex flex-col items-center justify-center md:px-[50px] md:py-[100px] md:shadow"
    >
      {#if previousTrustName}
        <p class="mb-5 text-text/medium">You've added: {previousTrustName}</p>
      {/if}

      <p
        class="mb-[40px] w-[350px] text-center text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        {previousTrustName
          ? "Do you have another trust you would like to add?"
          : "Do you have any trusts in place?"}
      </p>

      <div class="flex w-[350px] flex-col gap-[25px]">
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          on:click={() => select(true)}
        >
          <span class="button-icon"><CheckIcon /></span>

          Yes
        </button>
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          on:click={() => select(false)}
        >
          <span class="button-icon"><CrossIcon /></span>

          No
        </button>
      </div>
    </div>
  </div>

  <div
    class="flex items-center border-t border-ui/border md:px-[50px] md:py-[40px]"
  >
    <button
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>
  </div>
</div>

<style type="text/css">
  .button-icon :global(svg) {
    width: 28px;
    height: 28px;
  }
</style>
