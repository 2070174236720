<script lang="ts">
  import type { DocumentCategory } from "../../util/storage";
  import Modal from "../Modal.svelte";
  import AddUpdateDocumentForm from "./AddUpdateDocumentForm.svelte";

  export let category: DocumentCategory = null;
  export let forTask: string = null;
</script>

<Modal mobileExit="back">
  <AddUpdateDocumentForm {category} {forTask} />
</Modal>
