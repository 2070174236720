<script lang="ts">
  import { addOrUpdateWillDocument } from "../../util/storage.ts";
  import FileUpload from "../FileUpload.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import { getContext } from "svelte";

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  let willDocument: File = null;
  $: uploaded = willDocument !== null;
  $: if (uploaded) {
    addOrUpdateWillDocument(willDocument);
  }
</script>

<div class="mobile-content">
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title pb-[30px]">Upload Your Will</h1>
    <p class="m-0 p-0 text-body/large max-md:hidden">
      Looks like you haven’t added your will to LegaSEE yet. Upload the file
      below, or click “Do This Later”.
    </p>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      Looks like you haven’t added your will to LegaSEE yet. Upload the file
      below, or click “Do This Later”.
    </p>

    <div class="grid grid-cols-2 gap-x-[40px] gap-y-[45px] md:pr-[50px]">
      <div class="input-wrap col-span-full">
        <label for="willDocument"> Legal Document </label>

        <FileUpload bind:file={willDocument} />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>

    {#if uploaded}
      <button class="btn-filled btn-lg flex gap-[10px]" on:click={goForward}>
        Continue

        <ArrowRightIcon />
      </button>
    {:else}
      <button
        class="variant-ringed-primary btn-lg btn flex gap-[10px] text-state/link"
        on:click={goForward}
      >
        Do This Later

        <ArrowRightIcon />
      </button>
    {/if}
  </div>
</div>
