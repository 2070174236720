<script lang="ts">
  export let percent: number;

  $: convertToDegrees = (percent * 180) / 100;
  $: adjustedRotation = convertToDegrees - 90;
  $: style = `
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform-origin: center 102px;
    transform: translateX(-50%) rotate(${adjustedRotation}deg);
    transition: transform 500ms ease-in-out;
  `;
</script>

<svg
  {style}
  width="24.410975"
  height="113.95045"
  viewBox="0 0 6.458737 30.149389"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <defs id="defs1" />
  <g id="layer1" transform="translate(-74.099981,-61.185452)">
    <g id="g4">
      <g id="g9" transform="translate(-0.00294454,0.04602541)">
        <path
          d="M 33.7211,91.7274 12.7784,2.27275 19.7726,93.9206 c -1.8567,1.8196 -3.0914,4.1869 -3.5257,6.7594 -0.4342,2.573 -0.0455,5.218 1.11,7.553 1.1556,2.335 3.018,4.238 5.318,5.435 2.3,1.197 4.9184,1.625 7.4765,1.223 2.5582,-0.402 4.9235,-1.614 6.754,-3.461 1.8305,-1.846 3.0313,-4.231 3.4287,-6.81 0.3974,-2.579 -0.0291,-5.2178 -1.2179,-7.5354 -1.1887,-2.3177 -3.0782,-4.1939 -5.3951,-5.3572 z M 28.9239,106.92 c -0.8204,0.13 -1.6605,0.011 -2.4139,-0.34 -0.7534,-0.352 -1.3863,-0.921 -1.8186,-1.636 -0.4323,-0.714 -0.6445,-1.542 -0.6098,-2.378 0.0347,-0.836 0.3147,-1.644 0.8047,-2.32 0.49,-0.6758 1.1679,-1.1902 1.9478,-1.4778 0.78,-0.2877 1.627,-0.3357 2.4338,-0.138 0.8068,0.1978 1.5371,0.6324 2.0986,1.2488 0.5614,0.616 0.9287,1.387 1.0554,2.214 0.0853,0.549 0.0625,1.109 -0.0672,1.649 -0.1297,0.54 -0.3636,1.048 -0.6884,1.497 -0.3248,0.449 -0.7341,0.828 -1.2046,1.118 -0.4704,0.289 -0.9927,0.482 -1.5371,0.567 z"
          fill="#222324"
          id="path1"
          transform="matrix(0.26150112,0.04026793,-0.04026793,0.26150112,74.075019,60.03054)"
        />
        <path
          d="m 77.511936,89.147386 c 0.604917,-0.09525 1.019043,-0.667279 0.924984,-1.277673 -0.09406,-0.61042 -0.660718,-1.028065 -1.265635,-0.932842 -0.604917,0.09522 -1.019042,0.6672 -0.924983,1.277594 0.09409,0.610394 0.660718,1.027906 1.265634,0.932921 z"
          fill="#ffffff"
          id="path4"
          style="stroke-width:0.264583"
        />
      </g>
    </g>
  </g>
</svg>
