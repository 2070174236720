<script lang="ts" context="module">
  import { currentEstate } from "../stores/user";
  import { userStore } from "../stores/user";
  import { splitAddressFields } from "../util/place.js";
  import { get, writable, type Writable } from "svelte/store";

  const getCurrentEstateId = () => get(currentEstate).id;

  async function fetchPeople() {
    const { data, error } = await supabase
      .from("beneficiary_person")
      .select(
        `
          id, first_name, last_name, relationship, email, dob, ssn, user_id,
          asset (
            id,
            distribution_method,
            distribution_trust_id,
            beneficiary_person(id, first_name, last_name),
            beneficiary_charity(id, name),
            financial_account (institution_name, account_number, label, current_value),
            real_estate (address, estimated_value),
            property (description, unique_id, estimated_value),
            loan_asset (debtor, account_number, current_balance),
            insurance (insurer, insured, policy_limit),
            inheritance (description, asset_type, estimated_value),
            legal_entity (name, role, value),
            vehicle (year, model, estimated_value)
          ),
          user (
            user_id,
            user_type,
            permission (
              assets_read,
              assets_read_designated,
              assets_write,
              beneficiaries_read,
              beneficiaries_write,
              dashboard_read,
              debts_read,
              debts_write,
              vault_read,
              vault_write,
              services_read,
              services_write,
              show_dollar_values
            )
          )
        `
      )
      .eq("estate_id", getCurrentEstateId())
      .eq("user.permission.estate_id", getCurrentEstateId());

    if (error) throw error;
    return data;
  }

  async function fetchCharities() {
    const { data, error } = await supabase
      .from("beneficiary_charity")
      .select(
        `
          id, name, state_of_formation, legal_structure, contact_email, ein,
          asset (
            id,
            distribution_method,
            distribution_trust_id,
            beneficiary_person(id, first_name, last_name),
            beneficiary_charity(id, name),
            financial_account (institution_name, account_number, label, current_value),
            real_estate (address, estimated_value),
            property (description, unique_id, estimated_value),
            loan_asset (debtor, account_number, current_balance),
            insurance (insurer, insured, policy_limit),
            inheritance (description, asset_type, estimated_value),
            legal_entity (name, role, value),
            vehicle (year, model, estimated_value)
          )
        `
      )
      .eq("estate_id", getCurrentEstateId());

    if (error) throw error;

    return data;
  }

  export function getNormalizedAsset(asset: Asset) {
    switch (true) {
      case !!asset.financial_account?.[0]: {
        const account = asset.financial_account[0];

        return {
          id: asset.id,
          assetType: "financial_account",
          title: "Financial Account",
          description: getAccountDisplayName(
            account.institution_name,
            account.account_number,
            account.label
          ),
          descriptionSubtext: account.account_number,
          value: account.current_value,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.real_estate?.[0]: {
        const realEstate = asset.real_estate[0];
        const addressSplit = splitAddressFields(realEstate.address);
        return {
          id: asset.id,
          assetType: "real_estate",
          title: "Real Estate",
          description: addressSplit.streetAddress,
          descriptionSubtext: addressSplit.city + ", " + addressSplit.state,
          value: realEstate.estimated_value,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.property?.[0]: {
        const property = asset.property[0];

        return {
          id: asset.id,
          assetType: "property",
          title: "Other Property",
          description: property.description,
          descriptionSubtext: property.unique_id,
          value: property.estimated_value,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.loan_asset?.[0]: {
        const loan = asset.loan_asset[0];

        return {
          id: asset.id,
          assetType: "loan_asset",
          title: "Loan",
          description: loan.debtor,
          descriptionSubtext: loan.account_number,
          value: loan.current_balance,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.insurance?.[0]: {
        const insurance = asset.insurance[0];

        return {
          id: asset.id,
          assetType: "insurance",
          title: "Insurance",
          description: insurance.insurer,
          descriptionSubtext: insurance.insured,
          value: insurance.policy_limit,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.inheritance?.[0]: {
        const inheritance = asset.inheritance[0];

        return {
          id: asset.id,
          assetType: "inheritance",
          title: "Inheritance",
          description: inheritance.description,
          descriptionSubtext: inheritance.asset_type,
          value: inheritance.estimated_value,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.legal_entity?.[0]: {
        const entity = asset.legal_entity[0];

        return {
          id: asset.id,
          assetType: "legal_entity",
          title: "Legal Entities",
          description: entity.name,
          descriptionSubtext: entity.role,
          value: entity.value,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
      case !!asset.vehicle?.[0]: {
        const vehicle = asset.vehicle[0];

        return {
          id: asset.id,
          assetType: "vehicle",
          title: "Vehicles",
          description: `${vehicle.year ?? ""} ${vehicle.model}`,
          descriptionSubtext: vehicle.model,
          value: vehicle.estimated_value,
          distributionMethod: asset.distribution_method,
          distributionTrustId: asset.distribution_trust_id,
          personBeneficiaries: asset.beneficiary_person,
          charityBeneficiaries: asset.beneficiary_charity,
        };
      }
    }
  }

  export const selectedAssetDistributionData: Writable<{
    id: number;
    assetType: AssetType;
    distribution_method: string;
    distribution_trust_id: number | null;
    beneficiary_person: { id: number; first_name: string; last_name: string }[];
    beneficiary_charity: { id: number; name: string }[];
    name: string;
  }> = writable(null);

  export function assetSelectedForEdit(selectedAsset: NormalizedAsset) {
    selectedAssetDistributionData.set({
      id: selectedAsset.id,
      assetType: selectedAsset.assetType as AssetType,
      distribution_method: selectedAsset.distributionMethod,
      distribution_trust_id: selectedAsset.distributionTrustId,
      beneficiary_person: selectedAsset.personBeneficiaries,
      beneficiary_charity: selectedAsset.charityBeneficiaries,
      name: selectedAsset.description,
    });
  }

  export type Person = Awaited<ReturnType<typeof fetchPeople>>[number];
  export type Charity = Awaited<ReturnType<typeof fetchCharities>>[number];
  type Asset = Charity["asset"][number] | Person["asset"][number];
  export type NormalizedAsset = ReturnType<typeof getNormalizedAsset>;
</script>

<script lang="ts">
  import CollapsibleTable from "../lib/CollapsibleTable.svelte";
  import ContextMenu from "../lib/ContextMenu.svelte";
  import TasksToggleButton from "../lib/TasksToggleButton.svelte";
  import {
    getAccountDisplayName,
    getAssetDisplayName,
  } from "../lib/assets/assets";
  import AddBeneficiaryModal from "../lib/beneficiaries/AddBeneficiaryModal.svelte";
  import DeleteBeneficiaryCharityModal from "../lib/beneficiaries/DeleteBeneficiaryCharityModal.svelte";
  import DeleteBeneficiaryPersonModal from "../lib/beneficiaries/DeleteBeneficiaryPersonModal.svelte";
  import type { PersonToInvite } from "../lib/beneficiaries/InviteToAccessForm.svelte";
  import InviteToAccessModal from "../lib/beneficiaries/InviteToAccessModal.svelte";
  import ViewBeneficiaryCharityModal from "../lib/beneficiaries/ViewBeneficiaryCharityModal.svelte";
  import ViewBeneficiaryPersonModal, {
    type ViewBeneficiaryPersonModalTabView,
  } from "../lib/beneficiaries/ViewBeneficiaryPersonModal.svelte";
  import CheckCircleIcon from "../lib/icons/CheckCircle.svelte";
  import GiftIcon from "../lib/icons/Gift.svelte";
  import KeysIcon from "../lib/icons/Keys.svelte";
  import PeopleIcon from "../lib/icons/People.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import PlusCircle from "../lib/icons/PlusCircle.svelte";
  import ProfileIcon from "../lib/icons/Profile.svelte";
  import TrashIcon from "../lib/icons/Trash.svelte";
  import TablePlaceholder from "../lib/placeholders/ServicesTable.svelte";
  import { userCan } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import type { AssetType } from "../types/index.js";
  import { onNavigate } from "../util/routes";
  import { Routes } from "../util/routes";
  import { popErrorToast, popSuccessToast } from "../util/toasts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";

  let charities: Charity[] = [];
  let people: Person[] = [];

  let loading = false;

  async function getBeneficiaries() {
    try {
      loading = true;

      [people, charities] = await Promise.all([
        fetchPeople(),
        fetchCharities(),
      ]);
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      loading = false;
    }
  }

  // Modal
  function openModal(
    component: any,
    props = {},
    response = (res: any) => null
  ) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: component,
        props,
      },
      response,
    });
  }

  function showDeletePersonModal(person: Person) {
    openModal(DeleteBeneficiaryPersonModal, { person }, (id: number) => {
      if (id) {
        people = people.filter((p) => p.id !== id);
      }
    });
  }

  function showDeleteCharityModal(charity: Charity) {
    openModal(DeleteBeneficiaryCharityModal, { charity }, (id: number) => {
      if (id) {
        charities = charities.filter((c) => c.id !== id);
      }
    });
  }

  function showViewCharityModal(charity: Charity) {
    openModal(ViewBeneficiaryCharityModal, { charity }, (data) => {
      if (data) {
        const isDeleted = typeof data === "number";

        if (isDeleted) {
          charities = charities.filter((c) => c.id !== data);
        } else {
          charities = charities.map((c) =>
            c.id === charity.id ? { ...c, ...data } : c
          );
        }
      }
    });
  }

  function showViewPersonModal(
    person: Person = null,
    tabView: ViewBeneficiaryPersonModalTabView = "Details"
  ) {
    openModal(
      ViewBeneficiaryPersonModal,
      {
        person,
        tabView,
      },
      (data) => {
        if (data) {
          // TODO calling getBeneficiaries here is a catch-all to update state from db
          // we can remove the "atomic" state changes below this line
          // since this should handle everything
          // (and we can do this everywhere to keep things simple)
          getBeneficiaries();

          const isDeleted = typeof data === "number";

          if (isDeleted) {
            people = people.filter((p) => p.id !== data);
          } else {
            people = people.map((p) =>
              p.id === person.id ? { ...p, ...data } : p
            );
          }
        }
      }
    );
  }

  function showAddBeneficiaryModal(type: "person" | "charity" = null) {
    openModal(
      AddBeneficiaryModal,
      type ? { beneficiaryType: type } : {},
      ({ charity, person, fullRefresh } = {}) => {
        // TODO calling getBeneficiaries here is a catch-all to update state from db
        // we can replace the charity and people conditions below
        // by using this in all cases
        if (fullRefresh) {
          getBeneficiaries();
          return;
        }
        if (charity) {
          charities = [...charities, charity];
        } else if (person) {
          people = [...people, person];
        }
      }
    );
  }

  function showInviteToAccessModal(person: PersonToInvite) {
    openModal(InviteToAccessModal, { person }, (person) => {
      if (person) {
        getBeneficiaries();
        popSuccessToast(
          `Success! ${person.first_name} ${person.last_name} has been invited to access your account.`
        );
      }
    });
  }

  onMount(() => {
    getBeneficiaries();
  });

  onNavigate(({ state }) => {
    if (state?.openModal === "addBeneficiary") {
      showAddBeneficiaryModal(state?.beneficiaryType);
    }

    if (state?.openModal === "viewAssetBeneficiary") {
      //? we may have to wait for loading, so we will poll if necessary
      const ready = () =>
        state.type === "person" ? people.length : charities.length;
      const person = () => people.find((p) => p.id === state.id);
      const charity = () => charities.find((c) => c.id === state.id);
      if (ready()) {
        if (state.type === "person") {
          showViewPersonModal(person());
        } else {
          showViewCharityModal(charity());
        }
      } else {
        const interval = setInterval(() => {
          if (ready()) {
            clearInterval(interval);
            if (state.type === "person") {
              showViewPersonModal(person());
            } else {
              showViewCharityModal(charity());
            }
          }
        }, 200);
      }
    }

    if (state?.openModal === "showProfile") {
      //? we may have to wait for loading, so we will poll if necessary
      const ready = () => people.length;
      const person = () =>
        people.find((person) => person.user_id === state.userId);
      if (ready()) {
        showViewPersonModal(person());
      } else {
        const interval = setInterval(() => {
          if (ready()) {
            clearInterval(interval);
            showViewPersonModal(person());
          }
        }, 200);
      }
    }

    if (state?.openModal === "inviteToAccess") {
      showInviteToAccessModal(state.person);
    }
  });
</script>

<header class="flex items-start justify-between">
  <div>
    <h1 class="mobile-header-title-fixed">Beneficiaries</h1>
    <p
      class="max-w-[650px] pr-[50px] pt-[15px] text-[18px] leading-[26px] max-md:hidden"
    >
      People or charities designated to receive assets. Grant beneficiaries
      account access by inviting them as collaborators.
    </p>
  </div>
  <div class="flex items-center">
    {#if userCan("beneficiaries_write")}
      <button
        type="button"
        class="btn-main mobile-fab"
        on:click={() => showAddBeneficiaryModal(null)}
      >
        <PlusIcon />
        <span>New Beneficiary</span>
      </button>
    {/if}

    {#if userCan("beneficiaries_write") && userCan("dashboard_read")}
      <TasksToggleButton class="ml-[15px]" />
    {/if}
  </div>
</header>

<main class="mobile-content">
  <div class="mobile-content-body">
    {#if loading}
      <TablePlaceholder />
    {:else if !people.length && !charities.length}
      <div
        class="mt-[30px] flex flex-col items-center justify-center border-t border-ui/border pt-[150px] text-body/large"
      >
        {#if userCan("beneficiaries_write")}
          <h2 class="mb-[50px] font-normal text-text/medium">
            You don't have any beneficiaries yet.
          </h2>

          <button
            class="btn-main btn-lg"
            on:click={() => showAddBeneficiaryModal(null)}
          >
            <span><PlusIcon /></span>
            <span>Add First Beneficiary</span>
          </button>
        {:else}
          <h2 class="font-normal text-text/medium">
            No beneficiaries have been added yet.
          </h2>
        {/if}
      </div>
    {:else}
      {#if people.length}
        <CollapsibleTable class="mt-[50px] max-md:mt-[0]">
          <svelte:fragment slot="titleBar">
            <span class="max-md:hidden">
              <PeopleIcon />
            </span>
            <span>People</span>
          </svelte:fragment>
          <svelte:fragment slot="thead">
            <tr>
              <th>Name</th>
              <th class="max-md:hidden">Distributions</th>
              <th>Collaborator Access</th>
              <th />
            </tr>
          </svelte:fragment>
          <svelte:fragment slot="tbody">
            {#each people as person (person.id)}
              {@const canAccess = !!person.user_id}
              <tr>
                <td class="w-[33%]">
                  <div class="max-md:text-[16px] max-md:leading-[24px]">
                    {person.first_name}
                    {person.last_name}
                    <span class="table-meta capitalize">
                      {person.relationship}
                    </span>
                  </div>
                </td>
                <td class="w-[33%] max-md:hidden">
                  {#if person.asset?.length}
                    <span class="flex flex-wrap gap-2">
                      {#each person.asset as asset (asset.id)}
                        <Link
                          class="chip text-text/dark"
                          to={Routes.Assets}
                          state={{
                            openModal: "viewAsset",
                            returnTo: location.pathname,
                            assetId: asset.id,
                          }}
                        >
                          {getAssetDisplayName(asset)}
                        </Link>
                      {/each}
                    </span>
                  {:else}
                    <span class="text-text/light">&mdash;</span>
                  {/if}
                </td>
                <td class="w-[33%] text-state/positive max-md:hidden">
                  {#if canAccess}
                    <CheckCircleIcon />
                  {/if}
                </td>
                <td class="relative w-[1%] max-md:!pr-[10px] max-md:text-right">
                  <ContextMenu
                    items={[
                      {
                        text: "View Profile",
                        icon: ProfileIcon,
                        color: "text-text/dark",
                        onClick: () => showViewPersonModal(person),
                      },
                      ...($userStore.user_type === "owner"
                        ? [
                            canAccess
                              ? {
                                  text: "View Permissions",
                                  icon: KeysIcon,
                                  color: "text-text/dark",
                                  onClick: () =>
                                    showViewPersonModal(person, "Access"),
                                }
                              : {
                                  text: "Invite as Collaborator",
                                  icon: PlusCircle,
                                  color: "text-state/positive",
                                  onClick: () =>
                                    showInviteToAccessModal(person),
                                },
                          ]
                        : []),
                      {
                        text: "Delete Beneficiary",
                        icon: TrashIcon,
                        color: "text-state/negative",
                        onClick: () => showDeletePersonModal(person),
                      },
                    ]}
                  />
                </td>
              </tr>
            {/each}
          </svelte:fragment>
        </CollapsibleTable>
      {/if}

      {#if charities.length}
        <CollapsibleTable class="mt-[50px] max-md:mt-[32px]">
          <svelte:fragment slot="titleBar">
            <span class="max-md:hidden">
              <GiftIcon />
            </span>
            <span>Charities</span>
          </svelte:fragment>
          <svelte:fragment slot="thead">
            <tr>
              <th>Name</th>
              <th>Distributions</th>
              <th />
            </tr>
          </svelte:fragment>
          <svelte:fragment slot="tbody">
            {#each charities as charity (charity.id)}
              <tr>
                <td class="w-[33%]">
                  <div class="max-md:text-[16px] max-md:leading-[24px]">
                    {charity.name}

                    <span class="table-meta capitalize">
                      {charity.legal_structure}
                    </span>
                  </div>
                </td>
                <td class="w-[66%] max-md:hidden">
                  {#if charity.asset?.length}
                    <span class="flex flex-wrap gap-2">
                      {#each charity.asset as asset (asset.id)}
                        <Link
                          class="chip text-text/dark"
                          to={Routes.Assets}
                          state={{
                            openModal: "viewAsset",
                            returnTo: location.pathname,
                            assetId: asset.id,
                          }}
                        >
                          {getAssetDisplayName(asset)}
                        </Link>
                      {/each}
                    </span>
                  {/if}
                </td>
                <td class="relative w-[1%] max-md:!pr-[10px] max-md:text-right">
                  <ContextMenu
                    items={[
                      {
                        text: "View Profile",
                        icon: ProfileIcon,
                        color: "text-text/dark",
                        onClick: () => showViewCharityModal(charity),
                      },
                      {
                        text: "Delete Beneficiary",
                        icon: TrashIcon,
                        color: "text-state/negative",
                        onClick: () => showDeleteCharityModal(charity),
                      },
                    ]}
                  />
                </td>
              </tr>
            {/each}
          </svelte:fragment>
        </CollapsibleTable>
      {/if}
    {/if}
  </div>
</main>
