<script lang="ts">
  import EyeIcon from "../icons/Eye.svelte";
  import EyeSlashIcon from "../icons/EyeSlash.svelte";
  import PencilIcon from "../icons/Pen.svelte";
  import type { DBPermissions } from "./collaborators";

  export let permissions: DBPermissions;
</script>

<ul>
  <li class="perm">
    <span class="perm-label">Dashboard:</span>
    {#if permissions.dashboard_read}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View
      </span>
    {:else}
      <span class="perm-value text-state/negative">
        <EyeSlashIcon /> No Access
      </span>
    {/if}
  </li>
  <li class="perm">
    <span class="perm-label">Assets:</span>
    {#if permissions.assets_write}
      <span class="perm-value text-state/positive">
        <PencilIcon /> Manage
      </span>
    {:else if permissions.assets_read_designated}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View Designated
      </span>
    {:else if permissions.assets_read}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View
      </span>
    {:else}
      <span class="perm-value text-state/negative">
        <EyeSlashIcon /> No Access
      </span>
    {/if}
  </li>
  <li class="perm">
    <span class="perm-label">Debts:</span>
    {#if permissions.debts_write}
      <span class="perm-value text-state/positive">
        <PencilIcon /> Manage
      </span>
    {:else if permissions.debts_read}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View
      </span>
    {:else}
      <span class="perm-value text-state/negative">
        <EyeSlashIcon /> No Access
      </span>
    {/if}
  </li>
  <li class="perm">
    <span class="perm-label">Services:</span>
    {#if permissions.services_write}
      <span class="perm-value text-state/positive">
        <PencilIcon /> Manage
      </span>
    {:else if permissions.services_read}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View
      </span>
    {:else}
      <span class="perm-value text-state/negative">
        <EyeSlashIcon /> No Access
      </span>
    {/if}
  </li>
  <li class="perm">
    <span class="perm-label">Beneficiaries:</span>
    {#if permissions.beneficiaries_write}
      <span class="perm-value text-state/positive">
        <PencilIcon /> Manage
      </span>
    {:else if permissions.beneficiaries_read}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View
      </span>
    {:else}
      <span class="perm-value text-state/negative">
        <EyeSlashIcon /> No Access
      </span>
    {/if}
  </li>
  <li class="perm">
    <span class="perm-label">Vault:</span>
    {#if permissions.vault_write}
      <span class="perm-value text-state/positive">
        <PencilIcon /> Manage
      </span>
    {:else if permissions.vault_read}
      <span class="perm-value text-state/positive">
        <EyeIcon /> View
      </span>
    {:else}
      <span class="perm-value text-state/negative">
        <EyeSlashIcon /> No Access
      </span>
    {/if}
  </li>
</ul>

<style lang="postcss">
  .perm {
    @apply flex items-center justify-between border-t border-ui/border-light p-[12px];
  }

  .perm:first-of-type {
    @apply border-t-0;
  }

  .perm-label {
    @apply w-[105px];
  }
  .perm-value {
    @apply flex items-center gap-[5px] font-semibold;
  }

  .perm-value :global(svg) {
    width: 18px;
    height: 18px;
  }
</style>
