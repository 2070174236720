import { supabase } from "../supabaseClient.ts";
import type { Writable } from "svelte/store";

/**
 * Opens a plaid link modal. This automatically destroys the handler after the modal exits
 * @param updateAccountId - If this is supplied the plaid link will be opened in update mode,
 * used to update an existing account that needs to be reconnected.
 * @param onSuccess - This will be called when the user successfully connects an account.
 * Plaid is configured to only allow one account to be connected at a time
 * @param onExit - This will be called when the plaid link modal is closed for some reason
 * @param onEvent - This store will be called when plaid link emits an event, which is especially useful
 * for debugging.
 */
export async function openPlaidLinkModal({
  updateAccountId = null,
  onSuccess,
  onExit = null,
  onEvent = null,
}: LinkHandlerOptions): Promise<LinkHandler> {
  const { data, error } = await supabase.functions.invoke("plaid-link-token", {
    body: {
      updateAccountId,
    },
  });
  if (error) {
    console.error(`Error getting link token: ${error.message}`);
    throw error;
  }
  const token = data.token;
  //@ts-ignore - This will be on the window so long as its called where the script is in the header
  const handler = window.Plaid.create({
    token,
    onSuccess: (public_token: string, metadata: OnSuccessMetaData) => {
      handler.destroy();
      onSuccess(public_token, metadata);
    },
    onExit: (error: PlaidError, metadata: OnExitMetaData) => {
      handler.destroy();
      if (onExit) {
        onExit(error, metadata);
      }
    },
    onEvent,
  });
  handler.open();
  return handler;
}

export function openPlaidUpdateModal(
  plaidAccountId: string,
  onSuccess: () => void
): void {
  openPlaidLinkModal({
    updateAccountId: plaidAccountId,
    onSuccess: () => {
      supabase
        .from("connected_account")
        .update({ needs_auth: false })
        .eq("plaid_account_id", plaidAccountId)
        .then(() => {
          supabase.functions.invoke("update-connected-balances").then(() => {
            onSuccess();
          });
        });
    },
  });
}

export type LinkHandlerOptions = {
  updateAccountId?: string;
  onSuccess: (public_token: string, metadata: OnSuccessMetaData) => void;
  onExit?: (error: PlaidError | null, metadata: OnExitMetaData) => void;
  onEvent?: (eventName: string, metadata: OnEventMetaData) => void;
};

export type PlaidError = {
  error_type: string;
  error_code: string;
  error_message: string;
  display_message: string;
  request_id: string;
  causes: string[];
  status: number;
  documentation_url: string;
  suggested_action: string;
};

// The @types/plaid-link package and vite are not getting along, so just copy and pasting
// the types here for now.

export interface Account {
  class_type: string | null;
  id: string;
  mask: string | null;
  name: string;
  subtype: string;
  type: string;
  verification_status: VerificationStatus | null;
}
interface CreateConfig {
  clientName?: string;
  countryCodes?: Country[];
  env?: Environment;
  isWebView?: boolean;
  key?: string;
  language?: Language;
  linkCustomizationName?: string;
  oauthNonce?: string;
  oauthRedirectUri?: string;
  oauthStateId?: string;
  onLoad?: OnLoad;
  onEvent?: OnEvent;
  onExit?: OnExit;
  onSuccess: OnSuccess;
  product?: Product[];
  receivedRedirectUri?: string | null;
  token?: string;
  userEmailAddress?: string;
  userLegalName?: string;
  webhook?: string;
}
interface Error {
  display_message: string | null;
  error_code: string;
  error_message: string;
  error_type: string;
}
interface ExitOptions {
  force: boolean;
}
interface Institution {
  institution_id: string;
  name: string;
}
export interface LinkHandler {
  open: (institution_id?: string) => void; // the connect flow skips the 'Select your bank' step if `institution_id` is provided
  exit: (options?: ExitOptions) => void;
  destroy: () => void;
}
export interface OnEventMetaData {
  account_number_mask: string | null;
  error_code: string | null;
  error_message: string | null;
  error_type: string | null;
  exit_status: ExitStatus | null;
  institution_id: string | null;
  institution_name: string | null;
  institution_search_query: string | null;
  link_session_id: string;
  match_reason: string | null;
  mfa_type: string | null;
  request_id: string;
  routing_number: string | null;
  selection: AuthTypeSelectFlow | VerificationMethod | null;
  timestamp: string;
  view_name: ViewName;
}
export interface OnExitMetaData {
  institution: Institution | null;
  link_session_id: string;
  request_id: string;
  status: ExitStatus;
}

export interface OnSuccessMetaData {
  account: Account;
  accounts: Account[];
  institution: Institution | null;
  link_session_id: string;
  transfer_status: TransferStatus | null;
  wallet: {
    name: string;
  } | null;
}

type AuthTypeSelectFlow = "flow_type_instant" | "flow_type_manual";

type Country =
  | "CA"
  | "DE"
  | "DK"
  | "EE"
  | "ES"
  | "FR"
  | "GB"
  | "IE"
  | "IT"
  | "LT"
  | "LV"
  | "NL"
  | "NO"
  | "PL"
  | "SE"
  | "US";

type Environment = "development" | "sandbox" | "production";

type EventName =
  | "BANK_INCOME_INSIGHTS_COMPLETED"
  | "CLOSE_OAUTH"
  | "ERROR"
  | "EXIT"
  | "FAIL_OAUTH"
  | "HANDOFF"
  | "IDENTITY_VERIFICATION_CLOSE_UI"
  | "IDENTITY_VERIFICATION_CREATE_SESSION"
  | "IDENTITY_VERIFICATION_FAIL_SESSION"
  | "IDENTITY_VERIFICATION_FAIL_STEP"
  | "IDENTITY_VERIFICATION_OPEN_UI"
  | "IDENTITY_VERIFICATION_PASS_SESSION"
  | "IDENTITY_VERIFICATION_PASS_STEP"
  | "IDENTITY_VERIFICATION_PENDING_REVIEW_STEP"
  | "IDENTITY_VERIFICATION_RESUME_SESSION"
  | "IDENTITY_VERIFICATION_RESUME_UI"
  | "IDENTITY_VERIFICATION_START_STEP"
  | "MATCHED_SELECT_INSTITUTION"
  | "MATCHED_SELECT_VERIFY_METHOD"
  | "OPEN"
  | "OPEN_MY_PLAID"
  | "OPEN_OAUTH"
  | "SEARCH_INSTITUTION"
  | "SELECT_AUTH_TYPE"
  | "SELECT_BRAND"
  | "SELECT_DEGRADED_INSTITUTION"
  | "SELECT_DOWN_INSTITUTION"
  | "SELECT_INSTITUTION"
  | "SUBMIT_ACCOUNT_NUMBER"
  | "SUBMIT_CREDENTIALS"
  | "SUBMIT_DOCUMENTS"
  | "SUBMIT_DOCUMENTS_ERROR"
  | "SUBMIT_DOCUMENTS_SUCCESS"
  | "SUBMIT_MFA"
  | "SUBMIT_ROUTING_NUMBER"
  | "TRANSITION_VIEW"
  | "VIEW_DATA_TYPES";

type ExitStatus =
  | "choose_device"
  | "institution_not_found"
  | "requires_account_selection"
  | "requires_code"
  | "requires_credentials"
  | "requires_oauth"
  | "requires_questions"
  | "requires_selections";

type Language =
  | "da"
  | "de"
  | "en"
  | "es"
  | "et"
  | "fr"
  | "it"
  | "lt"
  | "lv"
  | "nl"
  | "no"
  | "po"
  | "ro"
  | "se";

type OnSuccess = (public_token: string, metadata: OnSuccessMetaData) => void;
type OnExit = (error: Error | null, metadata: OnExitMetaData) => void;
type OnEvent = (eventName: EventName, metadata: OnEventMetaData) => void;
type OnLoad = () => void;

type Product =
  | "assets"
  | "auth"
  | "employment"
  | "identity"
  | "identity_verification"
  | "income"
  | "income_verification"
  | "investments"
  | "payment_initiation"
  | "liabilities"
  | "standing_orders"
  | "transactions"
  | "transfer";

type TransferStatus = "COMPLETE" | "INCOMPLETE";

type VerificationMethod = "password" | "phoneotp";

type VerificationStatus =
  | "automatically_verified"
  | "manually_verified"
  | "pending_automatic_verification"
  | "pending_manual_verification"
  | "verification_expired"
  | "verification_failed";

type ViewName =
  | "ACCEPT_TOS"
  | "CONNECTED"
  | "CONSENT"
  | "CREDENTIAL"
  | "DATA_TRANSPARENCY"
  | "DATA_TRANSPARENCY_CONSENT"
  | "DOCUMENTARY_VERIFICATION"
  | "ERROR"
  | "EXIT"
  | "KYC_CHECK"
  | "LOADING"
  | "MATCHED_CONSENT"
  | "MATCHED_CREDENTIAL"
  | "MATCHED_MFA"
  | "MFA"
  | "NUMBERS"
  | "OAUTH"
  | "RECAPTCHA"
  | "RISK_CHECK"
  | "SCREENING"
  | "SELECT_ACCOUNT"
  | "SELECT_AUTH_TYPE"
  | "SELECT_BRAND"
  | "SELECT_INSTITUTION"
  | "SELFIE_CHECK"
  | "UPLOAD_DOCUMENTS"
  | "VERIFY_SMS";

type PaymentMeta = {
  by_order_of: string | null;
  payee: string | null;
  payer: string | null;
  payment_method: string | null;
  payment_processor: string | null;
  ppd_id: string | null;
  reason: string | null;
  reference_number: string | null;
};

type Transaction = {
  account_id: string;
  account_owner: string | null;
  amount: number;
  authorized_date: string | null;
  authorized_datetime: string | null;
  category: string[];
  category_id: string | null;
  date: string;
  datetime: string;
  iso_currency_code: string | null;
  location: Location | null;
  merchant_name: string | null;
  name: string;
  payment_channel: string | null;
  payment_meta: PaymentMeta | null;
  pending: boolean;
  pending_transaction_id: string | null;
  personal_finance_category: string | null;
  transaction_code: string | null;
  transaction_id: string;
  transaction_type: string | null;
  unofficial_currency_code: string | null;
};
