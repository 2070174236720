<script lang="ts">
  import { supabase } from "../../supabaseClient.ts";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import Modal from "../Modal.svelte";
  import ModalStep from "../ModalStep.svelte";
  import ModalStepper from "../ModalStepper.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import SelectAllIcon from "../icons/SelectAll.svelte";
  import SelectNoneIcon from "../icons/SelectNone.svelte";
  import SendIcon from "../icons/Send.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  let REDTAIL_API_URL = import.meta.env.VITE_REDTAIL_BASE_URL;
  let REDTAIL_API_KEY = import.meta.env.VITE_REDTAIL_API_KEY;

  let redtailEmail: string;
  let redtailPassword: string;

  let encoded: string;

  let redtailContacts: any[] = [];

  let selectedContactIds: any[] = [];

  async function loadContacts() {
    loading = true;
    stepper.goForward();
    encoded = btoa(`${REDTAIL_API_KEY}:${redtailEmail}:${redtailPassword}`);
    let page = 1;
    let totalPages = 1;
    do {
      try {
        const response = await fetch(
          `${REDTAIL_API_URL}/contacts?page=${page}`,
          {
            headers: {
              Authorization: `Basic ${encoded}`,
              include: "emails",
            },
          }
        );
        const {
          contacts,
          meta: { total_pages },
        } = await response.json();
        redtailContacts = [...redtailContacts, ...contacts];
        totalPages = total_pages;
      } catch (e) {
        console.error(e);
        popErrorToast("Error retrieving contacts");
      }
      page++;
    } while (page <= totalPages);
    loading = false;
  }

  let loading = false;

  let stepper: ModalStepper;

  async function sendInvites() {
    loading = true;
    const inviteResponses = await Promise.all(
      selectedContactIds.map((contactId) => {
        const contact = redtailContacts.find((c) => c.id == contactId);
        return supabase.functions.invoke("invite-client", {
          body: {
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.emails[0].address,
          },
        });
      })
    );
    loading = false;
    const failedInvites = inviteResponses.filter(
      (response) => response.error || response.data?.error
    );
    if (failedInvites.length > 0) {
      popErrorToast(`Failed to send ${failedInvites.length} invites.`);
      modalStore[0].response(false);
    } else {
      popSuccessToast("Invites sent successfully.");
      modalStore[0].response(true);
      modalStore.close();
    }
  }
</script>

<Modal>
  <div class="w-screen max-w-[750px]">
    <ModalStepper bind:this={stepper}>
      <ModalStep step={0}>
        <div class="mobile-content">
          <div
            class="mobile-header px-[50px] py-[30px] md:border-b md:border-ui/border-light md:shadow-forms"
          >
            <h1 class="mobile-header-title pb-[30px]">Import from Redtail</h1>
            <p class="m-0 p-0 text-body/large max-md:hidden">
              The next screen will ask for your Redtail credentials so we can
              use them to retrieve contacts.<br /><br />Once this is complete,
              you’ll be able to finish inviting your clients to the LegaSEE
              platform.
            </p>
          </div>

          <div class="mobile-content-body md:hidden">
            <p class="mobile-content-description max-md:!border-0">
              The next screen will ask for your Redtail credentials so we can
              use them to retrieve contacts.<br /><br />Once this is complete,
              you’ll be able to finish inviting your clients to the LegaSEE
              platform.
            </p>
          </div>

          <div
            class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
          >
            <button
              type="button"
              class="mobile-back-button btn-main"
              on:click={modalStore.close}
            >
              <span>Cancel</span>
            </button>
            <button class="btn-filled btn-lg" on:click={stepper.goForward}>
              <span>Continue</span>
              <ArrowRightIcon />
            </button>
          </div>
        </div>
      </ModalStep>
      <ModalStep step={1}>
        <div class="mobile-content">
          <div class="mobile-header px-[50px] py-[30px]">
            <h1 class="mobile-header-title pb-[30px]">Import from Redtail</h1>
            <p class="m-0 p-0 text-body/large max-md:hidden">
              Please enter your Redtail credentials.
            </p>
          </div>

          <div
            class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
          >
            <p class="mobile-content-description md:hidden">
              Please enter your Redtail credentials.
            </p>

            <div
              class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pr-[50px]"
            >
              <div class="input-wrap col-span-full">
                <label for="email">
                  Username <span class="text-state/negative">*</span>
                </label>

                <input type="email" id="email" bind:value={redtailEmail} />
              </div>

              <div class="input-wrap col-span-full">
                <label for="email">
                  Password <span class="text-state/negative">*</span>
                </label>

                <input
                  id="password"
                  type="password"
                  class="max-w-[350px]"
                  bind:value={redtailPassword}
                />
              </div>
            </div>
          </div>

          <div
            class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
          >
            <button
              type="button"
              class="mobile-back-button btn-main"
              on:click={modalStore.close}
            >
              <span>Cancel</span>
            </button>
            <button class="btn-filled btn-lg" on:click={loadContacts}>
              <span>Get Contacts</span>
              <ArrowRightIcon />
            </button>
          </div>
        </div>
      </ModalStep>
      <ModalStep step={2}>
        {#if loading}
          <div class="p-[100px]">
            <p class="mb-[60px] text-center text-[28px]">Loading...</p>
            <BarLoader />
          </div>
        {:else if !redtailContacts.length}
          <div class="flex flex-col gap-y-[45px] p-[35px]">
            <div class="my-[60px] flex items-center justify-center gap-[8px]">
              <span class="text-state/negative"><WarnIcon /></span>
              <span class="text-[28px] font-bold">No Contacts Found</span>
            </div>
            <button class="btn-filled btn-lg" on:click={modalStore.close}>
              <span>Close Window</span>
            </button>
          </div>
        {:else}
          <div class="mobile-content">
            <div
              class="mobile-header px-[50px] py-[30px] md:border-ui/border-light md:shadow-forms"
            >
              <h1 class="mobile-header-title pb-[30px]">Import from Redtail</h1>
              <p class="m-0 p-0 text-body/large max-md:hidden">
                Please select the Redtail clients you would like to send LegaSEE
                invitations to below.
              </p>
            </div>

            <div
              class="mobile-content-body border-b border-t border-ui/border-light"
            >
              <p class="mobile-content-description md:hidden">
                Please select the Redtail clients you would like to send LegaSEE
                invitations to below.
              </p>

              <div
                class="contacts-window relative overflow-scroll py-[5px] pl-[86px]"
              >
                {#each redtailContacts as contact}
                  {#if contact.emails[0]}
                    <label
                      class="input-option relative border-b border-ui/border-light py-[20px] pr-[40px] last:border-b-0"
                    >
                      <input
                        type="checkbox"
                        id={contact.id}
                        name={contact.id}
                        value={contact.id}
                        bind:group={selectedContactIds}
                        class="absolute left-[-40px] top-[15px]"
                      />
                      <span class="ml-[-36px]"
                        >{contact.first_name} {contact.last_name}</span
                      >
                      <span
                        class="mt-[8px] block text-body/small text-text/medium"
                        >{contact.emails[0].address}</span
                      >
                    </label>
                  {/if}
                {/each}
              </div>
            </div>
            <div
              class="flex gap-[20px] border-b border-ui/border bg-bg/lighter px-[50px] py-[20px] shadow-forms"
            >
              <button
                type="button"
                class="btn-main"
                on:click|preventDefault={() =>
                  (selectedContactIds = redtailContacts
                    .filter((contact) => contact.emails[0])
                    .map((contact) => contact.emails[0].address))}
              >
                <SelectAllIcon /> <span>Select All</span>
              </button>
              <button
                type="button"
                class="btn-main"
                on:click|preventDefault={() => (selectedContactIds = [])}
              >
                <SelectNoneIcon /> <span>Select None</span>
              </button>
            </div>
            <div
              class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
            >
              <button
                type="button"
                class="mobile-back-button btn-main"
                on:click={modalStore.close}
              >
                <span>Cancel</span>
              </button>
              <button
                class="btn-filled btn-lg btn-success"
                on:click|preventDefault={sendInvites}
              >
                <SendIcon />
                <span>Send {selectedContactIds.length} Invites</span>
              </button>
            </div>
          </div>
        {/if}
      </ModalStep>
    </ModalStepper>
  </div>
</Modal>

<style type="text/css">
  .contacts-window {
    max-height: calc(100vh - 535px);
  }
</style>
