import { onDestroy } from "svelte";
import { useLocation, type NavigatorLocation } from "svelte-navigator";

export const Routes = {
  Dashboard: "/dashboard",
  Assets: "/assets",
  Debts: "/debts",
  Services: "/services",
  Beneficiaries: "/beneficiaries",
  Collaborators: "/collaborators",
  Vault: "/vault",
  Profile: "/profile",
  LoginDetails: "/login-details",
  Help: "/help",
  Clients: "/clients",
  Onboarding: "/onboarding",
  UpdatePassword: "/update-password",
} as const;

/**
 * Call a reaction/side effect on navigation (including history actions)
 * ! Can only be used within the context of a Svelte Component script tag
 * @param {function} reaction The side effect/reaction function to fire
 * @return {void}
 */
export function onNavigate(
  reaction: (location: NavigatorLocation) => void
): void {
  const location = useLocation();
  const unsubscribe = location.subscribe((val) => {
    reaction(val);
  });
  onDestroy(unsubscribe);
}
