import { popConfettiAt } from "../lib/Confettery.svelte";
import { refreshTasks } from "../lib/readiness/readiness";
import { userCan } from "../stores/user";
import { supabase } from "../supabaseClient";
import { popSuccessToast } from "./toasts";

/**
 * Realtime Database Subscription: listen for messages from db
 */
supabase
  .channel("changes")
  .on(
    "postgres_changes",
    {
      event: "UPDATE",
      schema: "public",
      table: "realtime_message",
    },
    ({ new: { message } }) => processMessage(message)
  )
  .subscribe();

/**
 * Add further conditions to process here as needed
 */

async function processMessage(message: any) {
  // Claims changed -- refresh the session to get new claims, and re-fetch permissions
  if (message?.claimsChanged) {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    const { error } = await supabase.auth.refreshSession(session);

    if (error) console.warn(error);
  }

  // Task completed -- pop success toast and re-fetch the tasklist
  // Only if user is the owner of that task, or has permissions for tasks of that category
  if (message?.taskCompleted) {
    if (
      userCan("dashboard_read") &&
      //@ts-ignore: ts can't handle string concatenation
      userCan(`${message.taskCompleted.category}_write`)
    ) {
      refreshTasks().then(() => {
        popSuccessToast(`Task complete: ${message.taskCompleted.name}!`);
        popConfettiAt(window.innerWidth - 100, 100);
      });
    }
  }

  // "Trigger"-type Task added or removed -- quietly re-fetch the tasklist
  // Only if user is the owner of that task, or has permissions for tasks of that category
  if (message?.taskAddedRemoved) {
    if (
      userCan("dashboard_read") &&
      //@ts-ignore: ts can't handle string concatenation
      userCan(`${message.taskAddedRemoved.category}_write`)
    ) {
      refreshTasks();
    }
  }
}
