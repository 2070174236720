<script lang="ts">
  import type { Debt } from "../../pages/Debts.svelte";
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { popErrorToast } from "../../util/toasts";
  import AddUpdateDebtStepOne, {
    type StepOneFormData,
  } from "./AddUpdateDebtStepOne.svelte";
  import AddUpdateDebtStepTwo from "./AddUpdateDebtStepTwo.svelte";
  import type { DebtType } from "./debts";
  import { createEventDispatcher } from "svelte";

  export let debt: Debt = null;
  export let debtType: DebtType;

  const dispatch = createEventDispatcher();

  type Step = "Required Form" | "Label Form";
  type SubmitData = {
    institution_name: string;
    current_balance: number;
    credit_limit: number;
    original_loan_amount: number;
    payment_frequency: string;
    maturity_date: string;
    account_number: string;
    label: string;
    debt_type: DebtType;
    estate_id: number;
  };

  let step: Step = "Required Form";

  let formData: any = {};
  let loading = false;

  function addDebt(formData: SubmitData) {
    return supabase
      .from("debt")
      .insert({
        ...formData,
        estate_id: $currentEstate.id,
        debt_type: debtType,
      })
      .select(
        "id, institution_name, current_balance, credit_limit, original_loan_amount, payment_frequency, maturity_date, account_number, label, plaid_account_id, debt_type, balance_updated_at"
      );
  }

  function updateDebt(formData: SubmitData) {
    return supabase
      .from("debt")
      .update({
        ...formData,
        debt_type: debtType,
      })
      .eq("id", debt.id)
      .select(
        "id, institution_name, current_balance, credit_limit, original_loan_amount, payment_frequency, maturity_date, account_number, label, plaid_account_id, debt_type, balance_updated_at"
      );
  }

  async function save() {
    try {
      loading = true;

      const { data, error } = isUpdate
        ? await updateDebt(formData)
        : await addDebt(formData);

      if (error) throw error;

      dispatch("done", data[0]);
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      loading = false;
    }
  }

  function handleStepOneSubmit({ detail }) {
    formData = detail;

    if (!detail.account_number && !detail.label) {
      step = "Label Form";
      return;
    }

    save();
  }

  function handleStepTwoSubmit({ detail: label }) {
    formData = {
      ...formData,
      label,
    };

    save();
  }

  $: isUpdate = !!debt;
</script>

{#if step === "Required Form"}
  <AddUpdateDebtStepOne
    {debt}
    {debtType}
    {loading}
    on:submit={handleStepOneSubmit}
    on:cancel
  />
{:else if step === "Label Form"}
  <AddUpdateDebtStepTwo
    {debtType}
    {loading}
    {isUpdate}
    on:submit={handleStepTwoSubmit}
    on:cancel={() => (step = "Required Form")}
  />
{/if}
