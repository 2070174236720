<script lang="ts">
  import type { Charity } from "../../pages/Beneficiaries.svelte";
  import { supabase } from "../../supabaseClient";
  import DeleteForm from "../DeleteForm.svelte";

  export let charity: Charity;

  async function deleteAction() {
    const { error } = await supabase
      .from("beneficiary_charity")
      .delete()
      .eq("id", charity.id);

    if (error) throw error;
  }

  $: hasDesignations = !!charity.asset?.length;

  $: requirements = [
    "This beneficiary will be completely removed from the LegaSEE application.",
    ...(hasDesignations
      ? [
          "Any asset designations associated with this beneficiary will be removed.",
        ]
      : []),
  ];
</script>

<DeleteForm {requirements} {deleteAction} on:done on:cancel>
  Delete {charity?.name}
</DeleteForm>
