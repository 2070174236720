<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import DeleteForm from "../DeleteForm.svelte";

  export let asset: { raw: { asset: { id: number } } };
  export let assetGroupTitle: string;

  const requirements = [
    "This asset will be completely removed from LegaSEE, and will no longer appear for you or any of your account users.",
  ];

  async function deleteAction() {
    const { error } = await supabase
      .from("asset")
      .delete()
      .eq("id", asset.raw.asset.id);

    if (error) throw error;
  }
</script>

<DeleteForm {requirements} {deleteAction} on:done on:cancel>
  Delete {assetGroupTitle}
</DeleteForm>
