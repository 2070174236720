<script lang="ts">
  import { currentEstate, userCan } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { Routes } from "../../util/routes";
  import {
    documentCategories,
    type DocumentCategory,
  } from "../../util/storage";
  import { popErrorToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import ArrowRight from "../icons/ArrowRight.svelte";
  import { taskList } from "../readiness/readiness";
  import Card from "./Card.svelte";
  import IconCompleted from "./IconCompleted.svelte";
  import IconInProgress from "./IconInProgress.svelte";
  import IconNotStarted from "./IconNotStarted.svelte";
  import { zipObject } from "lodash";
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";

  const estateId = $currentEstate.id;

  let loading = true;
  let counts: Record<DocumentCategory, number>;
  let categoriesComplete: Record<string, boolean | "partial">;

  $: willTask = $taskList.find(
    (task) => (task.action_hook as ActionHook).openModal === "uploadWillDoc"
  );
  $: trustTasks = $taskList.filter(
    (task) => !!(task.action_hook as ActionHook).trustId
  );
  $: docTasks = $taskList.filter(
    (task) =>
      task.category === "vault" && !!(task.action_hook as ActionHook).category
  );

  $: categoriesComplete = {
    will: willTask?.complete ?? false,
    // prettier-ignore
    trusts: trustTasks?.every((task) => task.complete)
      ? true
      : trustTasks?.some((task) => task.complete)
        ? "partial"
        : false,
    ...documentCategories.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]:
          // prettier-ignore
          docTasks?.some(
            (task) =>
              (task.action_hook as ActionHook).category === curr &&
              task.complete === true
          )
            ? true
            : counts?.[curr] > 0
              ? "partial"
              : false,
      }),
      {}
    ),
  };

  async function init() {
    try {
      const catQueries = documentCategories.map((category) =>
        supabase
          .from("document")
          .select("*", { count: "exact", head: true })
          .eq("estate_id", estateId)
          .eq("category", category)
      );
      const dataSet = await Promise.all(catQueries);

      if (dataSet.some((result) => result.error)) {
        throw dataSet.find((result) => result.error).error;
      }

      // @ts-expect-error - lodash doesn't, but we know that our keys here exactly match our read-only "documentCategories"
      counts = zipObject(
        documentCategories,
        dataSet.map(({ count }) => count)
      );
    } catch (error) {
      console.error(error.message);
      popErrorToast("Something went wrong. Please try again.");
    } finally {
      loading = false;
    }
  }

  onMount(init);

  /**
   * action hooks are json blobs which contain various hooks for behaviors in
   * the UI such as opening modoals etc.
   *
   * here, we're just typing the ones we will be using to match up tasks with
   * the desired categories, which will have to be cast later on
   *
   * because Supabase gives a type Json & Object which can't be worked with very
   * easily
   */
  type ActionHook = {
    category?: DocumentCategory;
    trustId?: number;
    openModal?: string;
  };
</script>

<Card linkTo={Routes.Vault}>
  <svelte:fragment slot="header">
    Digital Vault
    <span class="text-text/medium">
      <ArrowRight />
    </span>
  </svelte:fragment>
  <svelte:fragment slot="body">
    {#if loading}
      <BarLoader />
    {:else}
      <div
        class="grid grid-flow-col gap-6 p-5"
        style="grid-template-rows: repeat({Math.ceil(
          Object.keys(categoriesComplete).length / 2
        )}, 1fr)"
      >
        {#each Object.entries(categoriesComplete) as [category, status]}
          <Link
            to={Routes.Vault}
            class="flex items-center gap-[10px] text-text/dark hover:opacity-70"
            state={{
              openTasks: status !== true && userCan("vault_write"),
              tabView: ["will", "trusts"].includes(category)
                ? "Will & Trusts"
                : "Documents",
            }}
          >
            {#if status === true}
              <IconCompleted />
            {:else if status === "partial"}
              <IconInProgress />
            {:else if status === false}
              <IconNotStarted />
            {/if}
            <span class="capitalize">{category}</span>
          </Link>
        {/each}
      </div>
    {/if}
  </svelte:fragment>
</Card>
