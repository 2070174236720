<script lang="ts">
  import { currentEstate } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import { formatDBDate } from "../../util/format";
  import {
    convertCentsToDollarString,
    convertDollarsToCents,
  } from "../../util/money";
  import { Routes } from "../../util/routes";
  import { popErrorToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import ArrowRight from "../icons/ArrowRight.svelte";
  import Card from "./Card.svelte";
  import Empty from "./Empty.svelte";
  import { format, sub } from "date-fns";
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";

  let expensesState: "loading" | "loaded" | "error" = "loading";
  let topExpenses = [];

  async function getTopExpenses() {
    try {
      const { data, error } = await supabase
        .from("connected_account")
        .select("plaid_account_id, financial_account(asset_id), debt(id)")
        .eq("estate_id", $currentEstate.id);

      if (error) throw error;
      const plaidAccountIds = data.map((account) => account.plaid_account_id);

      if (plaidAccountIds) {
        const transactions = await getTransactions(
          data.map((account) => account.plaid_account_id)
        );
        const sliceTo = transactions.length < 4 ? transactions.length : 4;
        topExpenses = transactions
          .sort((a, b) => b.amount - a.amount)
          .slice(0, sliceTo)
          .map((transaction) => ({
            ...transaction,
            legasee_asset_id:
              data.find(
                (node) => node.plaid_account_id === transaction.account_id
              )?.financial_account?.[0]?.asset_id ?? null,
            legasee_debt_id:
              data.find(
                (node) => node.plaid_account_id === transaction.account_id
              )?.debt?.[0]?.id ?? null,
          }));
      }
      expensesState = "loaded";
    } catch (error) {
      expensesState = "error";
      console.error(error.message);
      popErrorToast("Something went wrong. Please try again.");
    }
  }

  async function getTransactions(plaidAccountIds: string[]) {
    let offset = 0;
    let latestFetched = 0;
    const transactions = [];

    do {
      const { data, error } = await supabase.functions.invoke(
        "fetch-transactions",
        {
          body: {
            plaidAccountIds,
            offset,
            count: 500,
            startDate: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
          },
        }
      );

      if (error) throw error;
      latestFetched = transactions.length;
      offset += latestFetched;
      transactions.push(...data);
    } while (latestFetched === 500);
    return transactions;
  }

  onMount(getTopExpenses);
</script>

<Card linkTo={Routes.Assets}>
  <svelte:fragment slot="header">
    Top Expenditures
    <span class="text-body/x-small text-text/medium">Last 30 Days</span>
  </svelte:fragment>
  <svelte:fragment slot="body">
    {#if expensesState === "loading"}
      <BarLoader />
    {:else if expensesState === "error"}
      <div
        class="flex flex-grow flex-col items-center justify-center gap-5 p-5 text-state/negative"
      >
        <span> Error fetching transactions from your connected accounts. </span>
        <span> Please refresh the page to try again. </span>
      </div>
    {:else if expensesState === "loaded"}
      {#if topExpenses.length}
        <div class="max-h-80 overflow-auto pl-4">
          {#each topExpenses as { amount, date, name, legasee_asset_id, legasee_debt_id, transaction_id }}
            {@const route = legasee_asset_id ? Routes.Assets : Routes.Debts}
            {@const state = {
              ...(legasee_asset_id
                ? {
                    openModal: "viewAsset",
                    assetId: legasee_asset_id,
                  }
                : {
                    openModal: "viewDebt",
                    debtId: legasee_debt_id,
                  }),
              tabView: "Transactions",
              transactionId: transaction_id,
              returnTo: location.href,
            }}
            <Link
              to={route}
              {state}
              class="group grid grid-cols-[78px_1fr_auto] items-center gap-3 border-b border-ui/border py-4 text-body/medium text-text/dark last:border-none"
            >
              <span class="basis-[100px] group-hover:opacity-70">
                {formatDBDate(date, "M/dd/yy")}
              </span>
              <span class=" group-hover:opacity-70">{name}</span>
              <span class="pr-4 group-hover:opacity-70">
                {@html "&ndash;"}
                <sup class="-mr-1">$</sup>
                {convertCentsToDollarString(
                  convertDollarsToCents(amount),
                  true
                )}
              </span>
            </Link>
          {/each}
        </div>
      {:else}
        <Empty>
          <span slot="text">Add connected accounts to view expenses</span>
          <svelte:fragment slot="buttons">
            <Link to={Routes.Assets} class="btn-main">
              View Assets <ArrowRight />
            </Link>
            <Link to={Routes.Debts} class="btn-main">
              View Debts <ArrowRight />
            </Link>
          </svelte:fragment>
        </Empty>
      {/if}
    {/if}
  </svelte:fragment>
</Card>
