<script lang="ts">
  import { floatString } from "../../util/format";
  import CheckIcon from "../icons/CheckSmall.svelte";
  import ChevronDownIcon from "../icons/ChevronDown.svelte";
  import ExpandLeftIcon from "../icons/ExpandLeft.svelte";
  import ReadinessMeter from "./ReadinessMeter.svelte";
  import TaskList from "./TaskList.svelte";
  import { calculateCompletionPercentage } from "./readiness";
  import { taskList } from "./readiness";
  import { capitalize } from "lodash";
  import { createEventDispatcher } from "svelte";
  import { useLocation } from "svelte-navigator";
  import { slide } from "svelte/transition";

  const dispatch = createEventDispatcher();
  const location = useLocation();

  export let collapsed: boolean;
  export let noslide: boolean = false;

  $: route = $location.pathname.slice(1);
  $: routeTasks = $taskList.filter(({ category }) => category === route);
  $: percentComplete = calculateCompletionPercentage(routeTasks);
  $: completeTasks = routeTasks.filter(({ complete }) => complete);
  $: incompleteTasks = routeTasks.filter(({ complete }) => !complete);

  let incompleteCollapsed = false;
  let completeCollapsed = true;

  function toggleIncomplete() {
    incompleteCollapsed = !incompleteCollapsed;
  }

  function toggleComplete() {
    completeCollapsed = !completeCollapsed;
  }
</script>

<div
  style="width: {collapsed ? 64 : 300}px"
  class="tasks-sidebar-content flex h-full flex-col transition-[width]"
  transition:slide={{ axis: "x", duration: noslide ? 0 : 200 }}
>
  {#if collapsed}
    <div class="max-md:hidden">
      {#if incompleteTasks.length}
        <div class="flex justify-center pt-5" transition:slide|local>
          <figure
            class="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-state/negative text-[14px] font-bold leading-[14px] text-text/lightest"
          >
            <span>{incompleteTasks.length}</span>
          </figure>
        </div>
      {/if}
      <div class="flex justify-center border-b border-ui/border py-5">
        <button
          class="anchor hover:opacity-70 active:scale-95"
          on:click={() => dispatch("toggle")}
        >
          <ExpandLeftIcon />
        </button>
      </div>
      <div class="flex flex-col items-center gap-3 py-5">
        <span>
          {floatString(percentComplete, 0)}%
        </span>
        <span class="h-[400px]">
          <ReadinessMeter
            thickness={18}
            orientation="vertical"
            percent={percentComplete}
            class="h-[400px]"
          />
        </span>
      </div>
    </div>
  {/if}

  {#if !collapsed}
    <div
      class="px-[25px] pb-[30px] pt-[20px] max-md:mb-[25px] max-md:bg-white max-md:pl-[50px] max-md:shadow-mobileheader"
    >
      <span class="text-body/large font-bold leading-[26px]">
        {capitalize(route)} Readiness
      </span>
      <div class="mt-[15px] flex items-center gap-3">
        <ReadinessMeter thickness={15} percent={percentComplete} />
        <span class="text-body/x-small leading-none">
          {floatString(percentComplete, 0)}%
        </span>
      </div>
    </div>
    <div class="mb-[20px] flex flex-col">
      <div
        class="mobile-list-header task-list-header flex cursor-pointer items-center justify-between bg-text/medium px-[15px] py-[12px] text-white md:shadow-heavy"
        on:click={toggleIncomplete}
        on:keydown={toggleIncomplete}
      >
        <span class="flex items-center justify-start max-md:flex-row-reverse">
          <span
            class="mobile-list-header-gem mr-[8px] flex h-[20px] w-[20px] items-center justify-center rounded-full bg-white text-center text-[14px] font-bold leading-[14px] text-text/medium shadow"
          >
            {incompleteTasks.length}
          </span>
          <span
            class="mobile-list-header-text font-bold uppercase tracking-wide"
            >Incomplete Tasks</span
          >
        </span>
        <button
          class="task-list-toggle mobile-list-header-toggle transition"
          class:-scale-y-100={incompleteCollapsed}
        >
          <ChevronDownIcon />
        </button>
      </div>
      {#if !incompleteCollapsed}
        <TaskList
          tasks={incompleteTasks}
          completed={false}
          autoOpen={true}
          showCaret={false}
        />
      {/if}
    </div>
    {#if completeTasks.length > 0}
      <div class="flex flex-col">
        <div
          class="mobile-list-header task-list-header flex cursor-pointer items-center justify-between bg-text/medium px-[15px] py-[12px] text-white max-md:font-bold md:shadow-heavy"
          on:click={toggleComplete}
          on:keydown={toggleComplete}
        >
          <span class="flex items-center justify-start max-md:flex-row-reverse">
            <span
              class="mobile-list-header-gem mr-[8px] flex h-[20px] w-[20px] items-center justify-center rounded-full border border-white text-center text-[14px] leading-[14px] text-white shadow"
            >
              {completeTasks.length}
            </span>
            <span
              class="mobile-list-header-text font-bold uppercase tracking-wide"
              >Complete Tasks</span
            >
          </span>
          <button
            class="task-list-toggle mobile-list-header-toggle transition"
            class:-scale-y-100={completeCollapsed}
          >
            <ChevronDownIcon />
          </button>
        </div>
        {#if !completeCollapsed}
          {#each completeTasks as task}
            <div
              class="flex items-center justify-start border-b border-ui/border bg-[#F9FBFE] px-[15px] py-[18px] text-text/medium transition"
            >
              <span class="task-complete-icon"><CheckIcon /></span>
              <span class="ml-[8px] text-body/small">{task.name}</span>
            </div>
          {/each}
        {/if}
      </div>
    {/if}
  {/if}
</div>

<style>
  .task-list-header {
    position: relative;
  }
  .task-list-header:before {
    content: " ";
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    height: 100%;
    width: 1px;
    background: #848b9f;
  }
  .task-list-toggle :global(svg) {
    width: 16px;
  }

  .task-complete-icon :global(svg) {
    color: #06ba63;
  }
</style>
