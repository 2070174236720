<script lang="ts">
  import ArrowLeftIcon from "../lib/icons/ArrowLeft.svelte";
  import WifiIcon from "../lib/icons/Wifi.svelte";
  import BarLoader from "./BarLoader.svelte";
  import NoWifiIcon from "./icons/WifiNo.svelte";
  import { capitalize } from "lodash";
  import { getContext } from "svelte";

  export let type: string;
  export let onConnectSelected: (connected: boolean) => void;
  export let createConnectedLoading: boolean;
  const goBack = getContext("back");
</script>

<div class="mobile-content">
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title mb-[15px]">
      Add <ins class="max-md:hidden">a </ins>New
      <ins class="max-sm:hidden">{capitalize(type)} </ins>Account
    </h1>
    <p class="m-0 p-0 text-body/large max-md:hidden">
      How would you like to add this account to LegaSEE?
    </p>
  </div>
  <div
    class="mobile-content-body relative border-b border-t border-ui/border-light md:p-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      How would you like to add this account to LegaSEE?
    </p>

    <div class="flex gap-[30px] max-sm:flex-col max-sm:items-stretch">
      <button
        class="btn-wifi btn bg-white"
        disabled={createConnectedLoading}
        on:click={() => onConnectSelected(true)}
      >
        <span class="btn-wifi-svg"><WifiIcon /></span>
        <span
          class="mt-[10px] block text-[22px] leading-[28px] max-md:mt-[20px] max-md:text-[20px]"
          >Connected Account</span
        >
        <span
          class="mt-[20px] block whitespace-normal text-[16px] font-normal leading-[24px] text-text/dark max-md:mt-[10px]"
          >Find your financial institution and link your account for automatic
          updates.</span
        >
      </button>
      <button
        class="btn-wifi btn bg-white"
        disabled={createConnectedLoading}
        on:click={() => onConnectSelected(false)}
      >
        <span class="btn-wifi-svg"><NoWifiIcon /></span>
        <span
          class="mt-[10px] block text-[22px] leading-[28px] max-md:mt-[20px] max-md:text-[20px]"
          >Offline Asset</span
        >
        <span
          class="mt-[20px] block whitespace-normal text-[16px] font-normal leading-[24px] text-text/dark max-md:mt-[10px]"
          >If you can't link to an account, give us the best details you have.</span
        >
      </button>
    </div>
    {#if createConnectedLoading}
      <span class="absolute bottom-0 left-0 right-0">
        <BarLoader />
      </span>
    {/if}
  </div>
  <div class="md:px-[50px] md:py-[40px]">
    <button
      class="mobile-back-button btn-main btn"
      disabled={createConnectedLoading}
      on:click={goBack}
    >
      <span><ArrowLeftIcon /></span>
      <span>Back</span>
    </button>
  </div>
</div>

<style type="text/css">
  .btn-wifi {
    @apply flex flex-col items-center justify-center gap-0 rounded rounded border border-state/link px-[25px] py-[30px] text-state/link sm:w-[50%];
  }
  .btn-wifi-svg :global svg {
    width: 60px;
    height: 60px;
  }
</style>
