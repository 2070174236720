<script lang="ts">
  import { userStore, userCan } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import { Routes } from "../util/routes";
  import { popErrorToast } from "../util/toasts";
  import CoinsIcon from "./icons/Coins.svelte";
  import DataTreeIcon from "./icons/DataTree.svelte";
  import GearIcon from "./icons/Gear.svelte";
  import HelpIcon from "./icons/Help.svelte";
  import LogoutIcon from "./icons/Logout.svelte";
  import SpeedometerIcon from "./icons/Speedometer.svelte";
  import UserGroupIcon from "./icons/UserGroup.svelte";
  import VaultIcon from "./icons/Vault.svelte";
  import { tour } from "./welcome-tour/tour";
  import { navigate, link, useLocation } from "svelte-navigator";
  import { slide } from "svelte/transition";

  export let collapsed: boolean;

  const location = useLocation();
  const financesChildren = [
    Routes.Assets,
    Routes.Debts,
    Routes.Services,
  ] as const;

  let accountCollapsed = true;

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) popErrorToast(error.message);
    else navigate("/");
  }

  $: isActive = (href: string) => $location.pathname === href;

  $: financesFallback = financesChildren.find((route) => {
    const item = route.split("/").pop();
    // @ts-ignore: string manipulation in line above clobbers type information
    return userCan(`${item}_read`) || userCan(`${item}_read_designated`);
  });
  $: financesActive =
    collapsed && financesChildren.includes($location.pathname as any);

  $: accountActive =
    (collapsed || accountCollapsed) &&
    [Routes.Profile, Routes.LoginDetails].includes($location.pathname as any);
</script>

<nav>
  <div class="flex flex-col border-ui/border px-[10px] py-4 md:border-t">
    {#if userCan("dashboard_read")}
      <a
        use:link
        href={Routes.Dashboard}
        class:active={isActive(Routes.Dashboard)}
        class="link"
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <SpeedometerIcon />
        </span>

        {#if !collapsed}
          Dashboard
        {/if}
      </a>
    {/if}

    {#if financesFallback}
      <a
        use:link
        use:tour={1}
        href={financesFallback}
        class:active={financesActive}
        class="link"
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <CoinsIcon />
        </span>

        {#if !collapsed}
          Finances
        {/if}
      </a>
    {/if}

    {#if !collapsed}
      {#if userCan("assets_read") || userCan("assets_read_designated")}
        <a
          use:link
          href={Routes.Assets}
          class:active={isActive(Routes.Assets)}
          class="link small"
        >
          Assets
        </a>
      {/if}

      {#if userCan("debts_read")}
        <a
          use:link
          href={Routes.Debts}
          class:active={isActive(Routes.Debts)}
          class="link small"
        >
          Debts
        </a>
      {/if}

      {#if userCan("services_read")}
        <a
          use:link
          href={Routes.Services}
          class:active={isActive(Routes.Services)}
          class="link small"
        >
          Services
        </a>
      {/if}
    {/if}

    {#if userCan("beneficiaries_read")}
      <a
        use:link
        use:tour={2}
        href={Routes.Beneficiaries}
        class:active={isActive(Routes.Beneficiaries)}
        class="link"
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <DataTreeIcon />
        </span>

        {#if !collapsed}
          Beneficiaries
        {/if}
      </a>
    {/if}

    {#if $userStore?.user_type === "owner"}
      <a
        use:link
        use:tour={3}
        href={Routes.Collaborators}
        class:active={isActive(Routes.Collaborators)}
        class="link"
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <UserGroupIcon />
        </span>

        {#if !collapsed}
          Collaborators
        {/if}
      </a>
    {/if}

    {#if userCan("vault_read")}
      <a
        use:link
        use:tour={4}
        href={Routes.Vault}
        class:active={isActive(Routes.Vault)}
        class="link"
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <VaultIcon />
        </span>

        {#if !collapsed}
          Digital Vault
        {/if}
      </a>
    {/if}
  </div>

  <div class="flex flex-col border-t border-ui/border px-[10px] py-4">
    {#if $userStore?.user_type !== "advisor"}
      <button
        class:active={accountActive}
        class="link"
        on:click={() => (accountCollapsed = !accountCollapsed)}
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <GearIcon />
        </span>

        {#if !collapsed}
          Account
        {/if}
      </button>

      {#if !collapsed && !accountCollapsed}
        <div transition:slide|local={{ duration: 100 }}>
          <a
            use:link
            href={Routes.Profile}
            class:active={isActive(Routes.Profile)}
            class="link small"
          >
            Your Profile
          </a>

          <a
            use:link
            href={Routes.LoginDetails}
            class:active={isActive(Routes.LoginDetails)}
            class="link small"
          >
            Login Details
          </a>
        </div>
      {/if}

      <a
        use:link
        use:tour={5}
        href={Routes.Help}
        class:active={isActive(Routes.Help)}
        class="link"
      >
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <HelpIcon />
        </span>

        {#if !collapsed}
          Get Help
        {/if}
      </a>

      <button type="button" class="link" on:click={signOut}>
        <span class="{collapsed ? 'mx-auto' : 'mx-[14px]'} flex h-7 w-7">
          <LogoutIcon />
        </span>

        {#if !collapsed}
          Log Out
        {/if}
      </button>
    {/if}
  </div>
</nav>

<style lang="postcss">
  .link {
    @apply my-2 flex cursor-pointer items-center rounded border border-transparent py-2 text-lg text-text/dark no-underline transition duration-200 hover:opacity-70 md:text-text/medium;
  }

  .active {
    @apply border-ui/border bg-white font-bold text-text/dark max-md:border-state/link max-md:bg-[rgba(220,235,255,.5)];
  }

  .small {
    @apply my-0 py-[5px] pl-14 text-base;
  }
</style>
