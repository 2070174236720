<script lang="ts">
  import type { OnboardingData } from "../../types/Onboarding";
  import FileUpload from "../FileUpload.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";

  export let data: OnboardingData;

  export let goBack: () => void;
  export let goForward: () => void;

  $: hasFile = !!data.taxReturn;
</script>

<div class="mobile-content">
  <div
    class="mobile-content-body flex flex-col items-center justify-center px-[50px] py-[130px] shadow"
  >
    <p
      class="mb-[40px] w-[280px] text-center text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
    >
      Please upload a copy of your prior year’s tax return.
    </p>

    <FileUpload bind:file={data.taxReturn} />
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between border-t border-ui/border px-[50px] py-[40px]"
  >
    <button
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      on:click={goBack}
    >
      <ArrowLeftIcon />

      Back
    </button>

    {#if hasFile}
      <button class="btn-filled btn-lg flex gap-[10px]" on:click={goForward}>
        Next Step

        <ArrowRightIcon />
      </button>
    {:else}
      <button
        class="variant-ringed-primary btn-lg btn flex gap-[10px] text-state/link"
        on:click={goForward}
      >
        Do This Later

        <ArrowRightIcon />
      </button>
    {/if}
  </div>
</div>
