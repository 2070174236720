import {
  currentEstate,
  userStore,
  userPermissions,
  userCan,
} from "../../stores/user";
import { supabase } from "../../supabaseClient";
import type { Database } from "../../types/database";
import { once } from "../../util/once";
import { Routes } from "../../util/routes";
import { popErrorToast, popSuccessToast } from "../../util/toasts";
import { isEqual, pick } from "lodash";
import { get, writable, type Writable } from "svelte/store";

/**
 * We have three stores for tracking tasks throughout the app
 *
 * * showTasks
 *   tracks which types of tasks are visible due to granular permissions
 * * taskList
 *   tracks the individual task items
 * * taskListOpen
 *   provided as a convenience to detect whether or not the tasklist sidebar is
 *   currently open or closed.
 */
const initialShowTasks = {
  assets: false,
  debts: false,
  services: false,
  beneficiaries: false,
  collaborators: false,
  vault: false,
};
export const showTasks = writable(initialShowTasks);
export const taskList: Writable<Task[]> = writable([]);
export const taskListOpen: Writable<boolean> = writable(false);

/**
 * "taskList" and "showTasks" stores are dependent on "userPermissions" and
 * "currentEstate", respectively, so we'll need subscriptions to update when
 * those change
 */
currentEstate.subscribe(async () => {
  await once(userStore);
  refreshTasks();
});
userPermissions.subscribe(() => {
  if (!userCan("dashboard_read")) {
    showTasks.set(initialShowTasks);
  } else {
    const user = get(userStore);
    showTasks.set({
      assets: userCan("assets_write"),
      debts: userCan("debts_write"),
      services: userCan("services_write"),
      beneficiaries: userCan("beneficiaries_write"),
      collaborators: user?.user_type === "owner",
      vault: userCan("vault_write"),
    });
  }
});

/**
 * Refresh the task list from database
 */
export async function refreshTasks(): Promise<void> {
  try {
    const { data, error } = await fetchTasks();
    if (error) throw error;
    taskList.set(data);
  } catch (error) {
    popErrorToast(error.message);
  }
}

// UTILS

export const tasksSidebarRoutes: string[] = Object.values(
  pick(
    Routes,
    "Assets",
    "Debts",
    "Services",
    "Beneficiaries",
    "Collaborators",
    "Vault"
  )
);

export function calculateCompletionPercentage(tasks: Task[]): number {
  return tasks.length
    ? (tasks.filter((task) => task.complete).length / tasks.length) * 100
    : 0;
}

function fetchTasks() {
  /**
   * If 'currentEstate' is not determined, we will select all tasks the user is
   * authorized to view. Otherwise, we will specify tasks on the currentEstate.
   *
   * In most cases, these are identical, but in the case of advisors who are
   * viewing their list of clients, they'll get all tasks for all clients for
   * which they have permission to view dashboard/readiness, and the subset
   * which belong to the current estate when an estate is selected.
   */

  const estate_id = get(currentEstate)?.id;

  const columns =
    "category, name, description, complete, repeatable, repetitions, action_label, action_hook, dismiss_label, id, estate_id";

  return estate_id
    ? supabase
        .from("task")
        .select(columns)
        .eq("estate_id", get(currentEstate)?.id)
    : supabase.from("task").select(columns);
}

export type Task = Awaited<ReturnType<typeof fetchTasks>>["data"][number] & {
  action_hook: Object;
};

/**
 * Mark a task as complete
 */
export async function markComplete(
  taskId: number,
  toastMessage: string = null
): Promise<void> {
  try {
    const { error } = await supabase
      .from("task")
      .update({ complete: true })
      .eq("id", taskId);
    if (error) throw error;

    if (toastMessage) {
      popSuccessToast(toastMessage);
    }
  } catch (error) {
    popErrorToast(error.message);
  } finally {
    refreshTasks();
  }
}

/**
 * Generate a dynamic task
 */
export async function createDynamicTask(task: DBTask) {
  const estate_id = get(currentEstate)?.id;

  if (!estate_id) return;

  //? We can't upsert, because we don't know the primary key (task id)
  //? Instead, we'll have to diff the task list to ensure we don't duplicate
  const currentTasks = get(taskList).filter(({ complete }) => !complete),
    alreadyHaveTask = currentTasks.some(
      ({ name, action_hook }) =>
        name === task.name && isEqual(action_hook, task.action_hook)
    );
  if (alreadyHaveTask) return;

  const { data } = await supabase
    .from("task")
    .upsert({ estate_id, ...task })
    .select("id");

  if (data) refreshTasks();
  //? we're allowing failures on dynamic task creation to just fail silently
}

type DBTask = Omit<
  Database["public"]["Tables"]["task"]["Row"],
  "id" | "estate_id"
>;
