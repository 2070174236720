<script lang="ts">
  import { currentEstate, userStore } from "../../stores/user.ts";
  import { supabase } from "../../supabaseClient.ts";
  import type { CharityBeneficiary } from "../../types";
  import type {
    OnboardingCharityBeneficiary,
    OnboardingData,
    OnboardingPersonBeneficiary,
  } from "../../types/Onboarding";
  import { Routes } from "../../util/routes";
  import {
    addDocument,
    addOrUpdateTrustDocument,
    addOrUpdateWillDocument,
  } from "../../util/storage.ts";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import BarLoader from "../BarLoader.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import CheckIcon from "../icons/CheckFramed.svelte";
  import CrossIcon from "../icons/Cross.svelte";
  import { refreshTasks } from "../readiness/readiness.ts";
  import { navigate } from "svelte-navigator";

  export let data: OnboardingData;
  export let index = -1;

  export let goBack: () => void;
  export let goForward: () => void;

  let loading = false;

  function select(confirm: boolean) {
    data = {
      ...data,
      beneficiaries: confirm
        ? [...data.beneficiaries, { type: null, name: "" }]
        : data.beneficiaries.slice(0, index + 1),
    };

    if (confirm) {
      goForward();
    } else {
      saveData();
    }
  }

  async function saveData() {
    try {
      loading = true;
      // Create beneficiaries, trusts, will, and tax returns
      const personBeneficiaries = data.beneficiaries
        .filter((beneficiary) => beneficiary.type === "person")
        .map((beneficiary: OnboardingPersonBeneficiary) => {
          return {
            first_name: beneficiary.firstName,
            last_name: beneficiary.lastName,
            estate_id: $currentEstate.id,
            relationship: beneficiary.relationship,
            dob: beneficiary.dateOfBirth,
            ssn: beneficiary.ssn,
          };
        });
      const charityBeneficiaries = data.beneficiaries
        .filter((beneficiary) => beneficiary.type === "charity")
        .map((beneficiary: OnboardingCharityBeneficiary) => {
          return {
            name: beneficiary.name,
            estate_id: $currentEstate.id,
            state_of_formation: beneficiary.state,
            legal_structure: beneficiary.legalStructure,
            ein: beneficiary.ein,
            contact_email: beneficiary.email,
          };
        });
      const inserts: any = [
        supabase.auth.updateUser({
          password: data.password,
          data: {
            flags: {
              inviteeAwaitingAccountSetup: false,
            },
          },
        }),
        supabase
          .from("user")
          .update({
            first_name: data.firstName,
            last_name: data.lastName,
            date_of_birth: data.dateOfBirth,
            ssn: data.ssn,
          })
          .eq("user_id", $userStore.user_id),
      ];
      if (personBeneficiaries.length > 0) {
        inserts.push(
          //@ts-ignore
          supabase.from("beneficiary_person").insert(personBeneficiaries)
        );
      }
      if (charityBeneficiaries.length > 0) {
        inserts.push(
          //@ts-ignore
          supabase.from("beneficiary_charity").insert(charityBeneficiaries)
        );
      }
      if (!!data.willDocument) {
        inserts.push(addOrUpdateWillDocument(data.willDocument));
      }
      if (!!data.taxReturn) {
        inserts.push(addDocument(data.taxReturn, "financial", "tax_return"));
      }
      if (data.trusts.length > 0) {
        inserts.push(
          supabase.from("trust").insert(
            //@ts-ignore
            data.trusts.map((trust) => {
              return { name: trust.name, estate_id: $currentEstate.id };
            })
          ),
          ...data.trusts
            .filter((trust) => !!trust.legalDocument)
            .map((trust) =>
              addOrUpdateTrustDocument(trust.legalDocument, trust.name)
            )
        );
      }
      const responses = await Promise.all(inserts);
      if (responses.some((response) => response.error)) {
        throw new Error("Failed to onboard user");
      }
      popSuccessToast("Successfully set up your account!");
      navigate(Routes.Dashboard, { state: { tour: true } });
      location.reload(); // ? hack/shortcut to prevent issues with welcome tour tooltip positioning
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    } finally {
      loading = false;
    }
  }

  $: previousBeneficiaryName = data.beneficiaries[index]
    ? data.beneficiaries[index]?.type === "person"
      ? `${
          (data.beneficiaries[index] as OnboardingPersonBeneficiary)?.firstName
        } ${
          (data.beneficiaries[index] as OnboardingPersonBeneficiary)?.lastName
        }`
      : (data.beneficiaries[index] as OnboardingCharityBeneficiary)?.name
    : null;
</script>

{#if loading}
  <BarLoader />
{/if}

<div class="mobile-content">
  <div
    class="mobile-content-body max-md:flex max-md:items-center max-md:justify-center"
  >
    <div
      class="flex flex-col items-center justify-center md:px-[50px] md:py-[100px] md:shadow"
    >
      {#if previousBeneficiaryName}
        <p class="mb-5 text-text/medium">
          You've added: {previousBeneficiaryName}
        </p>
      {/if}

      <p
        class="w-[350px] text-center text-body/large text-text/dark max-md:text-body/medium max-md:leading-[28px]"
      >
        {previousBeneficiaryName
          ? "Would you like to add any more beneficiaries?"
          : "Would you like to add some of your planned beneficiaries now?"}
      </p>

      {#if !previousBeneficiaryName}
        <p class="mt-[15px] text-text/medium">(Recommended)</p>
      {/if}

      <div class="mt-[40px] flex w-[350px] flex-col gap-[25px]">
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          disabled={loading}
          on:click={() => select(true)}
        >
          <span class="button-icon"><CheckIcon /></span>

          Yes
        </button>
        <button
          class="align-center btn-main btn flex gap-[10px] !py-[18px] !text-[22px] text-state/link"
          type="button"
          disabled={loading}
          on:click={() => select(false)}
        >
          <span class="button-icon"><CrossIcon /></span>

          No
        </button>
      </div>
    </div>

    <div
      class="flex items-center border-t border-ui/border md:px-[50px] md:py-[40px]"
    >
      <button
        class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
        disabled={loading}
        on:click={goBack}
      >
        <ArrowLeftIcon />

        Back
      </button>
    </div>
  </div>
</div>

<style type="text/css">
  .button-icon :global(svg) {
    width: 28px;
    height: 28px;
  }
</style>
