import { maskAccountNumber } from "../../util/format";

const creditAccountTypes = ["credit_card", "home_equity", "personal_credit", "retail_credit"] as const;
const loanAccountTypes = ["auto_loan", "mortgage", "personal_loan", "student_loan", "vendor_financing"] as const;

export type CreditAccountType = typeof creditAccountTypes[number];
export type LoanAccountType = typeof loanAccountTypes[number];

export type DebtType = CreditAccountType | LoanAccountType;

export type DebtCategory = "credit_account" | "loan_account";

// Helpers
export function getDebtDisplayName({ institution_name, account_number }: { institution_name: string, account_number: string }) {
    return `${institution_name} ${maskAccountNumber(account_number, ".")}`;
}

export function getDebtCategory(debtType: DebtType): DebtCategory {
    return creditAccountTypes.includes(debtType as any) ? "credit_account" : "loan_account";
}
