<script lang="ts" context="module">
  export type PersonToInvite = {
    first_name: string;
    last_name: string;
    id: number;
    email?: string;
  };
</script>

<script lang="ts">
  import { supabase } from "../../supabaseClient";
  import { popErrorToast } from "../../util/toasts";
  import AccessFormField from "../collaborators/AccessFormField.svelte";
  import {
    convertUIPermissionsToDBPermissions,
    type UIPermissions,
  } from "../collaborators/collaborators";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import SendIcon from "../icons/Send.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let person: PersonToInvite;

  const dispatch = createEventDispatcher();

  type Step = "Permissions" | "Email";
  let step: Step = "Permissions";

  let permissions: UIPermissions;
  let loading = false;
  let submitClicked = false;

  async function inviteToAccess(email: string) {
    try {
      loading = true;

      // 1. invite user
      const { first_name, last_name, id } = person;
      const dbPermissions = convertUIPermissionsToDBPermissions(permissions);
      const { data, error } = await supabase.functions.invoke(
        "invite-collaborator",
        {
          body: {
            first_name,
            last_name,
            email,
            invited_as: "beneficiary",
            permissions: dbPermissions,
          },
        }
      );
      if (error ?? data.error) throw new Error(error ?? data.error);

      // 2. link beneficiary to user
      const { error: linkErr } = await supabase
        .from("beneficiary_person")
        .update({ user_id: data.user.id, email })
        .eq("id", id)
        .select("user_id");
      if (linkErr) throw linkErr;

      const updatedPerson = {
        ...person,
        user_id: data.user.id,
        email,
        user: {
          permission: [dbPermissions],
        },
      };

      dispatch("done", { updatedPerson });
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      loading = false;
    }
  }

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      email: "",
    },
    validationSchema: object().shape({
      email: string().required("Required").email("Invalid email"),
    }),
    onSubmit: ({ email }) => {
      inviteToAccess(email);
    },
  });

  function handlePermissionsStepSubmit() {
    if (requiresEmailStep) {
      step = "Email";
      return;
    }

    inviteToAccess(person.email);
  }

  $: requiresEmailStep = !person.email;
</script>

<div class="w-[750px] max-w-[100%]">
  {#if step === "Permissions"}
    <div class="mobile-content">
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title-fixed mb-[30px]">
          Invite to Collaborate
        </h1>

        <p class="m-0 p-0 text-body/large text-text/dark max-md:hidden">
          This will grant {person.first_name} limited access to your account based
          on the permissions below, and add them to your collaborators list.
        </p>
      </div>

      <div class="mobile-content-body">
        <p class="mobile-content-description md:hidden">
          This will grant {person.first_name} limited access to your account based
          on the permissions below, and add them to your collaborators list.
        </p>
        <AccessFormField bind:permissions />
      </div>

      <div
        class="mobile-content-footer flex items-baseline justify-between px-[50px] py-[40px]"
      >
        <button
          type="button"
          class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link max-md:!gap-0"
          disabled={loading}
          on:click={() => dispatch("cancel")}
        >
          Cancel<span class="md:hidden"><ArrowLeftIcon /></span>
        </button>

        <button
          type="button"
          class="btn-filled btn-lg flex gap-[10px]"
          class:btn-success={!requiresEmailStep}
          disabled={loading}
          on:click={handlePermissionsStepSubmit}
        >
          {#if requiresEmailStep}
            Continue

            <ArrowRightIcon />
          {:else if loading}
            <ProgressRadial
              value={undefined}
              width="w-6"
              meter="stroke-white"
            />

            Sending Invite
          {:else}
            <SendIcon />

            Send Invite
          {/if}
        </button>
      </div>
    </div>
  {:else if step === "Email"}
    <div class="mobile-content">
      <div class="mobile-header px-[50px] py-[30px]">
        <h1 class="mobile-header-title-fixed mb-[30px]">Invite to Access</h1>

        <p class="text-body/large text-text/dark max-md:hidden">
          Please enter {person.first_name}’s email address before sending your
          invite.
        </p>
      </div>

      <form class="mobile-content-body" on:submit={handleSubmit} novalidate>
        <p class="mobile-content-description md:hidden">
          Please enter {person.first_name}’s email address before sending your
          invite.
        </p>

        <div
          class="border-b border-t border-ui/border-light md:px-[50px] md:py-[50px] md:shadow-forms"
        >
          <div class="input-wrap">
            <label for="email">
              Email Address <span class="text-state/negative">*</span>
            </label>

            <input
              type="email"
              id="email"
              bind:value={$form.email}
              on:change={handleChange}
            />

            {#if $errors.email && submitClicked}
              <small class="input-error"><WarnIcon /> {$errors.email}</small>
            {/if}
          </div>
        </div>

        <div
          class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
        >
          <button
            type="button"
            class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link max-md:!gap-0"
            disabled={loading}
            on:click={() => (step = "Permissions")}
          >
            <ArrowLeftIcon />

            Back
          </button>

          <button
            type="submit"
            class="btn-filled btn-success btn-lg flex gap-[10px]"
            disabled={loading}
            on:click={() => (submitClicked = true)}
          >
            {#if loading}
              <ProgressRadial
                value={undefined}
                width="w-6"
                meter="stroke-white"
              />

              Sending Invite
            {:else}
              <SendIcon />

              Send Invite
            {/if}
          </button>
        </div>
      </form>
    </div>
  {/if}
</div>
