<script lang="ts">
  import CollapsibleTable from "../lib/CollapsibleTable.svelte";
  import ContextMenu from "../lib/ContextMenu.svelte";
  import TasksToggleButton from "../lib/TasksToggleButton.svelte";
  import AddAssetModal from "../lib/assets/AddAssetModal.svelte";
  import DeleteAssetModal from "../lib/assets/DeleteAssetModal.svelte";
  import type { View, TabView } from "../lib/assets/ViewAssetModal.svelte";
  import ViewAssetModal from "../lib/assets/ViewAssetModal.svelte";
  import { getAccountDisplayName } from "../lib/assets/assets";
  import { getAssetBeneficiaries } from "../lib/assets/assets";
  import { getInitials } from "../lib/beneficiaries/beneficiaries.ts";
  import BankIcon from "../lib/icons/Bank.svelte";
  import CarIcon from "../lib/icons/Car.svelte";
  import ChartIcon from "../lib/icons/Chart.svelte";
  import FileIcon from "../lib/icons/File.svelte";
  import GavelIcon from "../lib/icons/Gavel.svelte";
  import HandCoinIcon from "../lib/icons/HandCoin.svelte";
  import HierarchyTreeIcon from "../lib/icons/HierarchyTree.svelte";
  import HouseIcon from "../lib/icons/House.svelte";
  import PiggyBankIcon from "../lib/icons/PiggyBank.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import RingIcon from "../lib/icons/Ring.svelte";
  import TransactionsIcon from "../lib/icons/Transactions.svelte";
  import TrashIcon from "../lib/icons/Trash.svelte";
  import UmbrellaIcon from "../lib/icons/Umbrella.svelte";
  import ViewIcon from "../lib/icons/View.svelte";
  import WarningIcon from "../lib/icons/WarningCircle.svelte";
  import TablePlaceholder from "../lib/placeholders/AssetsTable.svelte";
  import { currentEstate, userCan } from "../stores/user";
  import { supabase } from "../supabaseClient.js";
  import type {
    FinancialAccount,
    Inheritance,
    Insurance,
    LegalEntity,
    LoanAsset,
    Property,
    RealEstate,
    Vehicle,
    Trust,
  } from "../types";
  import { camelToCapitalized, maskAccountNumber } from "../util/format.js";
  import { timeAgo } from "../util/format.ts";
  import { convertCentsToDollarString } from "../util/money.js";
  import { splitAddressFields } from "../util/place.ts";
  import { openPlaidUpdateModal } from "../util/plaid.ts";
  import { Routes, onNavigate } from "../util/routes.js";
  import { popErrorToast } from "../util/toasts.ts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { differenceInCalendarDays } from "date-fns";
  import { capitalize, groupBy, startCase, flatten } from "lodash";
  import { type ComponentType, onMount } from "svelte";
  import { Link, navigate } from "svelte-navigator";

  let financialAccounts: FinancialAccount[] = [];
  let realEstate: RealEstate[] = [];
  let properties: Property[] = [];
  let loanAssets: LoanAsset[] = [];
  let insurance: Insurance[] = [];
  let inheritances: Inheritance[] = [];
  let legalEntities: LegalEntity[] = [];
  let vehicles: Vehicle[] = [];

  let loading = true;

  async function getAssets() {
    try {
      const estateId = $currentEstate.id;
      const financialAccountsQuery = supabase
        .from("financial_account")
        .select(
          `
          *,
          connected_account(
            needs_auth
          ),
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const realEstateQuery = supabase
        .from("real_estate")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const propertyQuery = supabase
        .from("property")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const loanAssetQuery = supabase
        .from("loan_asset")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const insuranceQuery = supabase
        .from("insurance")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const inheritanceQuery = supabase
        .from("inheritance")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const legalEntityQuery = supabase
        .from("legal_entity")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const vehicleQuery = supabase
        .from("vehicle")
        .select(
          `
          *,
          asset!inner (
            *,
            beneficiary_charity (id, name),
            beneficiary_person (id, first_name, last_name),
            trust (id, name)
          )`
        )
        .eq("asset.estate_id", estateId);

      const [
        financialAccountsResult,
        realEstateResult,
        propertyResult,
        loanAssetResult,
        insuranceResult,
        inheritanceResult,
        legalEntityResult,
        vehicleResult,
      ] = await Promise.all([
        financialAccountsQuery,
        realEstateQuery,
        propertyQuery,
        loanAssetQuery,
        insuranceQuery,
        inheritanceQuery,
        legalEntityQuery,
        vehicleQuery,
      ]);

      financialAccounts = financialAccountsResult.data as FinancialAccount[];
      realEstate = realEstateResult.data as RealEstate[];
      properties = propertyResult.data as Property[];
      loanAssets = loanAssetResult.data as LoanAsset[];
      insurance = insuranceResult.data as Insurance[];
      inheritances = inheritanceResult.data as Inheritance[];
      legalEntities = legalEntityResult.data as LegalEntity[];
      vehicles = vehicleResult.data as Vehicle[];
    } catch (error) {
      console.error(error);
      popErrorToast(`Error getting assets ${error.message}`);
    } finally {
      loading = false;
    }
  }

  let tableData: TableData[];
  $: tableData = [
    ...Object.entries(groupBy(financialAccounts, "financial_account_type")).map(
      ([financialAccountType, accounts]) => ({
        descriptionLabel: "Account",
        title: capitalize(financialAccountType),
        svg:
          financialAccountType === "banking"
            ? BankIcon
            : financialAccountType === "investment"
            ? ChartIcon
            : PiggyBankIcon,
        rows: accounts.map((account) => ({
          description: getAccountDisplayName(
            account.institution_name,
            account.account_number,
            account.label
          ),
          descriptionSubtext: camelToCapitalized(
            account.financial_account_subtype
          ),
          value: account.current_value,
          beneficiaries: getAssetBeneficiaries(account.asset),
          distributionMethod: account.asset.distribution_method,
          raw: account,
          type: "financial_account",
          valueUpdatedAt: account.value_updated_at,
          needsAuth: account.connected_account?.needs_auth,
          plaidAccountId: account.plaid_account_id,
          trust: account.asset.trust,
        })),
      })
    ),
    {
      descriptionLabel: "Address",
      title: "Real Estate",
      svg: HouseIcon,
      rows: realEstate.map((realEstate) => {
        const splitAddress = splitAddressFields(realEstate.address);
        return {
          description: splitAddress.streetAddress,
          descriptionSubtext: `${splitAddress.city}${
            splitAddress.city && splitAddress.state ? "," : ""
          } ${splitAddress.state}`,
          value: realEstate.estimated_value,
          beneficiaries: getAssetBeneficiaries(realEstate.asset),
          distributionMethod: realEstate.asset.distribution_method,
          raw: realEstate,
          type: "real_estate",
          valueUpdatedAt: realEstate.value_updated_at,
          trust: realEstate.asset.trust,
        };
      }),
    },
    {
      descriptionLabel: "Description",
      title: "Other Property",
      svg: RingIcon,
      rows: properties.map((property) => ({
        description: property.description,
        descriptionSubtext: property.unique_id,
        value: property.estimated_value,
        beneficiaries: getAssetBeneficiaries(property.asset),
        distributionMethod: property.asset.distribution_method,
        raw: property,
        type: "property",
        valueUpdatedAt: property.value_updated_at,
        trust: property.asset.trust,
      })),
    },
    {
      descriptionLabel: "Debtor",
      title: "Loan",
      svg: HandCoinIcon,
      rows: loanAssets.map((loan) => ({
        description: loan.debtor,
        descriptionSubtext: loan.account_number,
        value: loan.current_balance,
        beneficiaries: getAssetBeneficiaries(loan.asset),
        distributionMethod: loan.asset.distribution_method,
        raw: loan,
        type: "loan_asset",
        valueUpdatedAt: loan.value_updated_at,
        trust: loan.asset.trust,
      })),
    },
    {
      descriptionLabel: "Plan",
      title: "Insurance",
      svg: UmbrellaIcon,
      rows: insurance.map((insurance) => ({
        description: insurance.insurer,
        descriptionSubtext: insurance.insured,
        value: insurance.policy_limit,
        beneficiaries: getAssetBeneficiaries(insurance.asset),
        distributionMethod: insurance.asset.distribution_method,
        raw: insurance,
        type: "insurance",
        valueUpdatedAt: insurance.value_updated_at,
        trust: insurance.asset.trust,
      })),
    },
    {
      descriptionLabel: "Description",
      title: "Inheritance",
      svg: HierarchyTreeIcon,
      rows: inheritances.map((inheritance) => ({
        description: inheritance.description,
        descriptionSubtext: inheritance.asset_type,
        value: inheritance.estimated_value,
        beneficiaries: getAssetBeneficiaries(inheritance.asset),
        distributionMethod: inheritance.asset.distribution_method,
        raw: inheritance,
        type: "inheritance",
        valueUpdatedAt: inheritance.value_updated_at,
        trust: inheritance.asset.trust,
      })),
    },
    {
      descriptionLabel: "Name",
      title: "Legal Entities",
      svg: GavelIcon,
      rows: legalEntities.map((entity) => ({
        description: entity.name,
        descriptionSubtext: entity.role,
        value: entity.value,
        beneficiaries: getAssetBeneficiaries(entity.asset),
        distributionMethod: entity.asset.distribution_method,
        raw: entity,
        type: "legal_entity",
        valueUpdatedAt: entity.value_updated_at,
        trust: entity.asset.trust,
      })),
    },
    {
      descriptionLabel: "Make",
      title: "Vehicles",
      svg: CarIcon,
      rows: vehicles.map((vehicle) => ({
        description: `${!!vehicle.year ? vehicle.year : ""} ${vehicle.model}`,
        descriptionSubtext: vehicle.make,
        value: vehicle.estimated_value,
        beneficiaries: getAssetBeneficiaries(vehicle.asset),
        distributionMethod: vehicle.asset.distribution_method,
        raw: vehicle,
        type: "vehicle",
        valueUpdatedAt: vehicle.value_updated_at,
        trust: vehicle.asset.trust,
      })),
    },
  ].sort((a, b) => a.title.localeCompare(b.title));

  $: totalAssetsValue = tableData.reduce(
    (total, assetGroup) =>
      total + assetGroup.rows.reduce((total, row) => total + row.value, 0),
    0
  );

  type TableData = {
    descriptionLabel: string;
    title: string;
    svg: ComponentType;
    rows: {
      description: string;
      descriptionSubtext?: string;
      value: number;
      beneficiaries: ReturnType<typeof getAssetBeneficiaries>;
      distributionMethod: string;
      trust?: Trust;
      valueUpdatedAt: string;
      needsAuth?: boolean;
      plaidAccountId?: string;
      raw?: any;
    }[];
  };

  function openDeleteAssetModal(asset: any, assetGroupTitle: string) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: DeleteAssetModal,
        props: {
          asset,
          assetGroupTitle,
        },
      },
      response: (deleted) => {
        if (deleted) {
          getAssets();
        }
      },
    });
  }

  function openAddAssetModal(preSelectedType: string = null) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: AddAssetModal,
      },
      meta: {
        ...(preSelectedType ? { assetType: preSelectedType } : {}),
      },
      response: () => {
        // Can patch in the specific asset if needed
        getAssets();
      },
    });
  }

  function openViewAssetModal(
    asset: any,
    assetGroupTitle: string = "Asset",
    tabView: TabView = "Details",
    view: View = "Main",
    transactionId: string = null
  ) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: ViewAssetModal,
        props: {
          asset,
          assetGroupTitle,
          tabView,
          view,
          transactionId,
        },
      },
      response: (updated) => {
        if (updated) {
          getAssets();
        }
      },
    });
  }

  onMount(getAssets);

  function pluckViewAssetModalPropsByAssetId(
    assetId: number,
    tableData: TableData[]
  ): [any, string] {
    const { assetGroupTitle, ...asset } =
      flatten(
        tableData.map(({ title, rows }) =>
          rows.map((row) => ({ ...row, assetGroupTitle: title }))
        )
      ).find((row) => (row as any)?.raw?.asset_id === assetId) ?? {};
    return [asset, assetGroupTitle];
  }

  onNavigate(({ state }) => {
    if (state?.openModal === "addAsset") {
      openAddAssetModal(state?.assetType ?? null);
    }

    if (state?.openModal === "editDistribution") {
      if (!state?.assetId) return;
      const poll = setInterval(() => {
        if (loading === false) {
          clearInterval(poll);
          openViewAssetModal(
            ...pluckViewAssetModalPropsByAssetId(state?.assetId, tableData),
            "Details",
            "Edit Distribution"
          );
        }
      }, 100);
    }

    if (state?.openModal === "viewAsset") {
      if (!state?.assetId) return;
      const poll = setInterval(() => {
        if (loading === false) {
          clearInterval(poll);
          openViewAssetModal(
            ...pluckViewAssetModalPropsByAssetId(state?.assetId, tableData),
            ...(state?.tabView ? [state.tabView, "Main"] : []),
            ...(state?.transactionId ? [state.transactionId] : [])
          );
        }
      }, 100);
    }

    if (state?.openModal === "reAuthPlaid" && state?.plaidAccountId) {
      openPlaidUpdateModal(state.plaidAccountId, getAssets);
    }
  });
</script>

<svelte:head>
  <script
    src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
  ></script>
</svelte:head>

<header
  class="flex justify-between {!loading && totalAssetsValue === 0
    ? 'items-center'
    : 'items-start'}"
>
  <div>
    <h1 class="mobile-header-title-fixed">Estate Assets</h1>
    {#if userCan("show_dollar_values")}
      {#if loading}
        <p
          class="mt-[-2px] pt-[15px] text-[18px] text-text/medium max-md:hidden"
        >
          <sup>$</sup><span
            class="placeholder relative left-[2px] top-[6px] inline-block w-[60px] animate-pulse bg-text/light"
          />
        </p>
      {:else if totalAssetsValue !== 0}
        <p class="pt-[15px] text-[18px] text-text/medium max-md:hidden">
          <sup>$</sup>{convertCentsToDollarString(totalAssetsValue)} total
        </p>
      {/if}
    {/if}
  </div>
  <div class="flex items-center">
    {#if userCan("assets_write")}
      <button class="btn-main mobile-fab" on:click={() => openAddAssetModal()}>
        <PlusIcon />
        <span>New Asset</span>
      </button>
    {/if}
    {#if userCan("assets_write") && userCan("dashboard_read")}
      <TasksToggleButton class="ml-[15px]" />
    {/if}
  </div>
</header>

<main class="mobile-content">
  <div class="mobile-content-body">
    {#if loading}
      <TablePlaceholder />
    {:else if totalAssetsValue === 0}
      <div
        class="mt-[30px] flex flex-col items-center justify-center border-t border-ui/border pt-[150px] text-body/large"
      >
        {#if userCan("assets_write")}
          <h2 class="mb-[50px] font-normal text-text/medium">
            You don't have any assets yet.
          </h2>

          <button class="btn-main btn-lg" on:click={() => openAddAssetModal()}>
            <span><PlusIcon /></span>
            <span>Add First Asset</span>
          </button>
        {:else if userCan("assets_read_designated")}
          <h2 class="mb-[50px] font-normal text-text/medium">
            You don't have any assets designated to you yet.
          </h2>
        {:else}
          <h2 class="mb-[50px] font-normal text-text/medium">
            No assets have been added yet.
          </h2>
        {/if}
      </div>
    {:else}
      {#each tableData as assetGroup}
        {#if assetGroup.rows.length}
          <CollapsibleTable
            class="mt-[50px] max-md:mt-[32px] first:max-md:mt-[0]"
          >
            <svelte:fragment slot="titleBar">
              <span class="max-md:hidden"
                ><svelte:component this={assetGroup.svg} /></span
              >
              <span>{assetGroup.title}</span>
            </svelte:fragment>
            <svelte:fragment slot="thead">
              <tr>
                <th>{assetGroup.descriptionLabel}</th>
                <th class="max-md:hidden">Value</th>
                <th class="max-md:hidden">Distribution</th>
                <th />
              </tr>
            </svelte:fragment>
            <svelte:fragment slot="tbody">
              {#each assetGroup.rows.sort( (a, b) => a.description.localeCompare(b.description) ) as account}
                <tr>
                  <td class="w-[33%]">
                    <div class="max-md:text-[16px] max-md:leading-[24px]">
                      {account.description}
                      {#if account.descriptionSubtext}
                        <span class="table-meta">
                          {account.descriptionSubtext}
                        </span>
                      {/if}
                    </div>
                  </td>
                  <td class="w-[33%] max-md:hidden">
                    {#if userCan("show_dollar_values")}
                      <sup>$</sup>{convertCentsToDollarString(account.value)}
                      <div class="mt-[15px] text-sm text-[#858F95]">
                        {timeAgo(account.valueUpdatedAt)}
                      </div>

                      {@const isOutdated =
                        differenceInCalendarDays(
                          new Date(),
                          new Date(account.valueUpdatedAt)
                        ) > 180}
                      {#if (isOutdated || account.needsAuth) && userCan("assets_write")}
                        <div class="mt-[15px] flex items-center gap-4 text-sm">
                          <span
                            class="flex items-center gap-[5px] text-state/negative"
                          >
                            <WarningIcon />
                          </span>

                          {#if account.needsAuth && account.plaidAccountId}
                            <button
                              class="cursor-pointer font-semibold text-state/negative underline"
                              on:click={() =>
                                openPlaidUpdateModal(
                                  account.plaidAccountId,
                                  () => getAssets()
                                )}
                            >
                              Re-Authenticate
                            </button>
                          {:else}
                            <button
                              class="cursor-pointer font-semibold text-state/negative underline"
                            >
                              Update Balance
                            </button>
                          {/if}
                        </div>
                      {/if}
                    {:else}
                      &mdash;
                    {/if}
                  </td>
                  <td class="w-[33%] max-md:hidden">
                    <div>
                      {#if userCan("beneficiaries_read")}
                        {#if !account.beneficiaries.length}
                          <span
                            class="flex gap-[4px] text-[18px] leading-[20px] text-state/negative"
                          >
                            <WarningIcon />
                            <span>TBD</span>
                          </span>
                          <span class="table-meta">
                            <Link
                              class="font-semibold"
                              to={location.pathname}
                              state={{
                                openModal: "editDistribution",
                                assetId: account.raw.asset_id,
                              }}
                            >
                              Specify
                            </Link>
                          </span>
                        {:else}
                          <div class="flex flex-wrap gap-[7px]">
                            {#each account.beneficiaries as beneficiary}
                              {@const fullName =
                                beneficiary.name ??
                                `${beneficiary.first_name} ${beneficiary.last_name}`}
                              <Link
                                to={Routes.Beneficiaries}
                                state={{
                                  openModal: "viewAssetBeneficiary",
                                  returnTo: location.pathname,
                                  type: beneficiary.type,
                                  id: beneficiary.id,
                                }}
                                class="chip !px-[5px] !pb-[3px] !pt-[4px] uppercase text-text/dark"
                              >
                                {getInitials(fullName, beneficiary.type)}
                              </Link>
                            {/each}
                          </div>
                          {#if account.distributionMethod}
                            <span class="table-meta">
                              <FileIcon />
                              {#if account.distributionMethod === "tbd"}
                                TBD
                              {:else if account.trust}
                                {account.trust.name}
                              {:else}
                                {startCase(account.distributionMethod)}
                              {/if}
                            </span>
                          {/if}
                        {/if}
                      {:else}
                        &mdash;
                      {/if}
                    </div>
                  </td>

                  <td
                    class="relative w-[1%] max-md:!pr-[10px] max-md:text-right"
                  >
                    <ContextMenu
                      items={[
                        {
                          text: "View Details",
                          icon: ViewIcon,
                          color: "text-text/dark",
                          onClick: () =>
                            navigate(location.pathname, {
                              state: {
                                openModal: "viewAsset",
                                returnTo: location.pathname,
                                assetId: account.raw.asset_id,
                              },
                            }),
                        },
                        ...(account.plaidAccountId
                          ? [
                              {
                                text: "Transactions",
                                icon: TransactionsIcon,
                                color: "text-text/dark",
                                onClick: () =>
                                  navigate(location.pathname, {
                                    state: {
                                      openModal: "viewAsset",
                                      returnTo: location.pathname,
                                      assetId: account.raw.asset_id,
                                      tabView: "Transactions",
                                    },
                                  }),
                              },
                            ]
                          : []),
                        {
                          text: "Delete Asset",
                          icon: TrashIcon,
                          color: "text-state/negative",
                          onClick: () =>
                            openDeleteAssetModal(account, assetGroup.title),
                        },
                      ]}
                    />
                  </td>
                </tr>
              {/each}
            </svelte:fragment>
          </CollapsibleTable>
        {/if}
      {/each}
    {/if}
  </div>
</main>
