<script lang="ts">
  import ClientsIcon from "../lib/icons/Clients.svelte";
  import HelpIcon from "../lib/icons/Help.svelte";
  import KeyIcon from "../lib/icons/Key.svelte";
  import LogoutIcon from "../lib/icons/Logout.svelte";
  import ProfileIcon from "../lib/icons/Profile.svelte";
  import { supabase } from "../supabaseClient";
  import { Routes } from "../util/routes";
  import { popErrorToast } from "../util/toasts";
  import { navigate, link, useLocation } from "svelte-navigator";

  const location = useLocation();

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) popErrorToast(error.message);
    else navigate("/");
  }

  $: isActive = (href: string) => $location.pathname === href;
</script>

<nav>
  <div class="flex flex-col border-ui/border px-[10px] py-4 md:border-t">
    <a
      use:link
      href={Routes.Clients}
      class:active={isActive(Routes.Clients)}
      class="link"
    >
      <span class="mx-[14px] flex h-7 w-7">
        <ClientsIcon />
      </span>
      Clients
    </a>

    <a
      use:link
      href={Routes.Help}
      class:active={isActive(Routes.Help)}
      class="link"
    >
      <span class="mx-[14px] flex h-7 w-7">
        <HelpIcon />
      </span>
      Help
    </a>
  </div>

  <div class="flex flex-col border-t border-ui/border px-[10px] py-4">
    <a
      use:link
      href={Routes.Profile}
      class:active={isActive(Routes.Profile)}
      class="link"
    >
      <span class="mx-[14px] flex h-7 w-7">
        <ProfileIcon />
      </span>
      Your Profile
    </a>

    <a
      use:link
      href={Routes.LoginDetails}
      class:active={isActive(Routes.LoginDetails)}
      class="link"
    >
      <span class="mx-[14px] flex h-7 w-7">
        <KeyIcon />
      </span>
      Login Details
    </a>

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-missing-attribute -->
    <a use:link class="link" on:click={signOut}>
      <span class="mx-[14px] flex h-7 w-7">
        <LogoutIcon />
      </span>
      Log Out
    </a>
  </div>
</nav>

<style lang="postcss">
  .link {
    @apply my-2 flex cursor-pointer items-center rounded border border-transparent py-2 text-lg text-text/dark no-underline transition duration-200 hover:opacity-70 md:text-text/medium;
  }

  .active {
    @apply border-ui/border bg-white font-bold text-text/dark max-md:border-state/link max-md:bg-[rgba(220,235,255,.5)];
  }
</style>
