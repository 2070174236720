<script lang="ts">
  import type { Document } from "../../types/index.ts";
  import {
    addDocument,
    addOrUpdateWillDocument,
    type DocumentCategory,
  } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts.ts";
  import FileUpload from "../FileUpload.svelte";
  import Modal from "../Modal.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { capitalize } from "lodash";

  export let category: DocumentCategory;
  export let taskId: number;
  export let existingDocs: Document[];

  let loading = false;

  const documentsMap: {
    [key in Exclude<DocumentCategory, "legal">]: string[];
  } = {
    identity: [
      "Birth Certificate",
      "Certificate of Citizenship",
      "Death Certificate",
      "Driver's License",
      "Naturalization Certificate",
      "Passport",
      "Social Security Card",
    ],
    family: [
      "Adoption Papers",
      "Divorce Decree or Settlement",
      "Marriage Certificate",
      "Prenuptial Agreement",
    ],
    estate: [
      "Cemetery Plot",
      "Estate Digital Asset Plan",
      "Living Power of Attorney",
      "Will",
    ],
    financial: [
      "Credit Agency Authorization",
      "Deferred Compensation Plans",
      "IRS Tax Return Authorization",
      "Life Insurance Policy",
      "Military Benefits",
      "Prenuptial Agreement",
    ],
    health: [
      "Advanced Directive",
      "HIPAA Authorization Form",
      "Organ Donation Form",
    ],
  };

  function handleAdd() {
    loading = true;
    Promise.all(
      remainingDocs
        .filter((doc) => doc.file)
        .map((doc) => {
          if (doc.name === "Will") {
            return addOrUpdateWillDocument(doc.file);
          } else {
            return addDocument(doc.file, category, doc.name);
          }
        })
    )
      .then((results) => {
        if (results.some((result) => result.error)) {
          popErrorToast("Error adding documents");
        } else {
          popSuccessToast("Documents added");
        }
      })
      .finally(() => {
        loading = false;
        $modalStore[0].response({
          done: true,
          ...(willCompleteList ? { taskId } : {}),
        });
        modalStore.close();
      });
  }

  let remainingDocs = documentsMap[category]
    .filter((name) => !existingDocs.some((doc) => doc.description === name))
    .map((name) => ({
      name,
      file: undefined,
    }));

  $: selectedCount = remainingDocs.filter((doc) => doc.file).length;
  $: willCompleteList = selectedCount === remainingDocs.length;
</script>

<Modal mobileExit="back">
  <div class="mobile-content w-[750px] max-w-[100%]">
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title-fixed pb-[30px]">
        Upload {capitalize(category)} Documents
      </h1>
      <p class="m-0 p-0 text-body/large max-md:hidden">
        You may upload as many of the items below as you’d like at once.
      </p>
    </div>

    <div
      class="mobile-content-body border-t border-ui/border-light py-[20px] pl-[50px] shadow-forms"
    >
      <p class="mobile-content-description max-md:!mb-[16px] md:hidden">
        You may upload as many of the items below as you’d like at once.
      </p>

      {#each remainingDocs as document (document.name)}
        <div
          class="flex items-center justify-between border-b border-b-ui/border-light py-[15px] last:border-b-0 max-md:border-0 max-sm:flex-col max-sm:items-start max-sm:gap-y-[15px] md:pr-[50px]"
        >
          <label
            for="file"
            class="mb-0 text-[20px] font-normal leading-[25px] text-text/dark max-md:text-body/medium"
          >
            {document?.name === "Will" ? "Will & Testament" : document.name}
          </label>

          <FileUpload
            id="file"
            bind:file={document.file}
            removable
            labelVisible={false}
          />
        </div>
      {/each}
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between border-t border-ui/border-light px-[50px] py-[40px]"
    >
      <button
        type="button"
        class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
        disabled={loading}
        on:click={modalStore.close}
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn-filled btn-lg btn-success flex gap-[10px]"
        disabled={loading || selectedCount < 1}
        on:click={handleAdd}
      >
        {#if loading}
          Adding Documents

          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
        {:else}
          Add {selectedCount ? selectedCount : ""} Document{selectedCount > 1
            ? "s"
            : ""}

          <ArrowRightIcon />
        {/if}
      </button>
    </div>
  </div>
</Modal>
