<script lang="ts">
    import type { Trust } from "../../types";
  import { addOrUpdateTrustDocument } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import FileUpload from "../FileUpload.svelte";
  import Modal from "../Modal.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { ProgressRadial, modalStore } from "@skeletonlabs/skeleton";

  export let trust: Trust;
  export let hasDoc: boolean;

  let submitClicked = false;
  let loading = false;

  let file: File;

  async function uploadFile() {
    try {
      loading = true;

      const { error } = await addOrUpdateTrustDocument(file, trust.name);

      if (error) throw error;

      $modalStore[0].response(true);
      modalStore.close();
      popSuccessToast(
        `Your trust has been ${
          hasDoc ? "updated" : "uploaded"
        } successfully.`
      );
    } catch (error) {
      popErrorToast(
        error.message || "An error occurred while uploading your trust."
      );
    } finally {
      loading = false;
    }
  }

  function handleSave() {
    submitClicked = true;

    if (!file) return;

    uploadFile();
  }
</script>

<Modal mobileExit="back">
  <div class="mobile-content w-[630px] max-w-[100%] max-md:!top-[85px]">
    <div
      class="mobile-header border-b border-ui/border-light px-[50px] py-[30px] max-md:!block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed !text-left">
        {hasDoc ? "Update" : "Upload"} Trust Document
      </h1>

      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {trust?.name}
      </p>
    </div>

    <div class="mobile-content-body px-[50px] py-[50px] shadow-forms">
      <div class="input-wrap">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label>Legal Document <span class="text-state/negative">*</span></label>

        <FileUpload bind:file />

        {#if !file && submitClicked}
          <small class="input-error"><WarnIcon /> Required</small>
        {/if}
      </div>
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between border-t border-ui/border-light px-[50px] py-[40px]"
    >
      <button
        type="button"
        class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
        on:click={() => modalStore.close()}
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn-filled btn-lg btn-success flex gap-[10px]"
        disabled={!file || loading}
        on:click={handleSave}
      >
        {#if loading}
          Saving

          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
        {:else}
          <CheckIcon />

          Save
        {/if}
      </button>
    </div>
  </div>
</Modal>
