import { supabase } from "../../supabaseClient";
import { isEqual } from "lodash";

const advisorPermissions = {
  dashboard_read: true,
  assets_read: true,
  assets_read_designated: false,
  assets_write: false,
  debts_read: true,
  debts_write: false,
  services_read: true,
  services_write: false,
  beneficiaries_read: true,
  beneficiaries_write: false,
  show_dollar_values: true,
  vault_read: true,
  vault_write: false,
};

const beneficiaryPermissions = {
  dashboard_read: false,
  assets_read: false,
  assets_read_designated: true,
  assets_write: false,
  debts_read: false,
  debts_write: false,
  services_read: false,
  services_write: false,
  beneficiaries_read: false,
  beneficiaries_write: false,
  show_dollar_values: false,
  vault_read: false,
  vault_write: false,
};

export function fetchCollaboratorPermissions() {
  return supabase.from("permission").select(`
      dashboard_read,
      assets_read,
      assets_read_designated,
      assets_write,
      debts_read,
      debts_write,
      services_read,
      services_write,
      beneficiaries_read,
      beneficiaries_write,
      vault_read,
      vault_write,
      show_dollar_values,
      user_data: user!permission_collaborator_id_fkey (first_name, last_name, last_sign_in_at, user_type, user_id)
    `);
}

export async function myAdvisor() {
  const { data } = await supabase
    .from("permission")
    .select(
      `user!permission_collaborator_id_fkey (first_name, last_name, user_type)`
    )
    .eq("user.user_type", "advisor");
  const filtered = data.filter(({ user }) => user);
  return filtered.length ? filtered[0].user : null;
}

export function accessType(access: DBPermissions): AccessType {
  let accessType: AccessType = "Custom";

  if (isEqual(access, advisorPermissions)) accessType = "View All";

  if (isEqual(access, beneficiaryPermissions)) accessType = "View Designated";

  return accessType;
}

export function convertUIPermissionsToDBPermissions(
  uiPermissions: UIPermissions
): DBPermissions {
  return {
    dashboard_read: uiPermissions.dashboard === "read",
    assets_read: ["read", "write"].includes(uiPermissions.assets),
    assets_read_designated: uiPermissions.assets === "read_designated",
    assets_write: uiPermissions.assets === "write",
    debts_read: ["read", "write"].includes(uiPermissions.debts),
    debts_write: uiPermissions.debts === "write",
    services_read: ["read", "write"].includes(uiPermissions.services),
    services_write: uiPermissions.services === "write",
    beneficiaries_read: ["read", "write"].includes(uiPermissions.beneficiaries),
    beneficiaries_write: uiPermissions.beneficiaries === "write",
    vault_read: ["read", "write"].includes(uiPermissions.vault),
    vault_write: uiPermissions.vault === "write",
    show_dollar_values: uiPermissions.showDollarValues,
  };
}

export function convertDBPermissionsToUIPermissions(
  dbPermissions: DBPermissions
): UIPermissions {
  let dashboard: UIPermissions["dashboard"],
    assets: UIPermissions["assets"],
    debts: UIPermissions["debts"],
    services: UIPermissions["services"],
    beneficiaries: UIPermissions["beneficiaries"],
    vault: UIPermissions["vault"];

  // prettier-ignore
  {
    dashboard = dbPermissions.dashboard_read
      ? "read"
      : "none";
    assets = dbPermissions.assets_write
      ? "write"
      : dbPermissions.assets_read_designated
        ? "read_designated"
        : dbPermissions.assets_read
          ? "read"
          : "none";
    debts = dbPermissions.debts_write
      ? "write"
      : dbPermissions.debts_read
        ? "read"
        : "none";
    services = dbPermissions.services_write
      ? "write"
      : dbPermissions.services_read
        ? "read"
        : "none";
    beneficiaries = dbPermissions.beneficiaries_write
      ? "write"
      : dbPermissions.beneficiaries_read
        ? "read"
        : "none";
    vault = dbPermissions.vault_write
      ? "write"
      : dbPermissions.vault_read
        ? "read"
        : "none";   
  }

  return {
    dashboard,
    assets,
    debts,
    services,
    beneficiaries,
    showDollarValues: dbPermissions.show_dollar_values,
    vault,
  };
}

export type CollaboratorProfile = Awaited<
  ReturnType<typeof fetchCollaboratorPermissions>
>["data"][number];

export type DBPermissions = Pick<
  CollaboratorProfile,
  | "dashboard_read"
  | "assets_read"
  | "assets_read_designated"
  | "assets_write"
  | "debts_read"
  | "debts_write"
  | "services_read"
  | "services_write"
  | "beneficiaries_read"
  | "beneficiaries_write"
  | "show_dollar_values"
  | "vault_read"
  | "vault_write"
>;

export type UIPermissions = {
  dashboard: "none" | "read";
  assets: "none" | "read_designated" | "read" | "write";
  debts: "none" | "read" | "write";
  services: "none" | "read" | "write";
  beneficiaries: "none" | "read" | "write";
  vault: "none" | "read" | "write";
  showDollarValues: boolean;
};

export type InviteCollaboratorFields = {
  firstName: string;
  lastName: string;
  email: string;
} & UIPermissions;

type AccessType = "View All" | "View Designated" | "Custom";
