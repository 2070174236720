import { supabase } from "../../supabaseClient.ts";
import { splitAddressFields } from "../../util/place.ts";

export const accountTypes = {
  banking: ["checking", "savings", "moneyMarket", "certificateOfDeposit"],
  investment: [
    "stocks",
    "mutualFunds",
    "realEstate",
    "investmentTrusts",
    "nonQualifiedAnnuities",
    "stockOptions",
  ],
  retirement: ["401k", "403b", "ira", "rothIra", "sepIra", "simpleIra"],
};

export function getAccountDisplayName(
  institutionName: string,
  accountNumber?: string,
  label?: string
) {
  if (accountNumber) {
    return `${institutionName} ...${accountNumber.slice(-4)}`;
  }
  if (label) {
    return `${institutionName} ${label}`;
  }
  return institutionName;
}

type Asset = {
  id: number;
  financial_account: {
    institution_name: string;
    account_number: string;
    label: string;
  }[];
  real_estate: {
    address: string;
  }[];
  property: {
    description: string;
  }[];
  loan_asset: {
    debtor: string;
  }[];
  insurance: {
    insurer: string;
  }[];
  inheritance: {
    description: string;
  }[];
  legal_entity: {
    name: string;
  }[];
  vehicle: {
    year: number;
    model: string;
  }[];
};

export function getAssetDisplayName(asset: Asset) {
  switch (true) {
    case !!asset?.financial_account?.[0]:
      const account = asset.financial_account[0];
      return getAccountDisplayName(
        account.institution_name,
        account.account_number,
        account.label
      );
    case !!asset?.real_estate?.[0]:
      const realEstate = asset.real_estate[0];
      return splitAddressFields(realEstate.address).streetAddress;
    case !!asset?.property?.[0]:
      const property = asset.property[0];
      return property.description;
    case !!asset?.loan_asset?.[0]:
      const loan = asset.loan_asset[0];
      return loan.debtor;
    case !!asset?.insurance?.[0]:
      const insurance = asset.insurance[0];
      return insurance.insurer;
    case !!asset?.inheritance?.[0]:
      const inheritance = asset.inheritance[0];
      return inheritance.description;
    case !!asset?.legal_entity?.[0]:
      const entity = asset.legal_entity[0];
      return entity.name;
    case !!asset?.vehicle?.[0]:
      const vehicle = asset.vehicle[0];
      return `${vehicle.year ?? ""} ${vehicle.model}`;
    default:
      return "";
  }
}

export function getAssetBeneficiaries(asset: Record<string, any>): Array<{
  id: number;
  name?: string;
  first_name?: string;
  last_name?: string;
  type: "person" | "charity";
}> {
  return [
    ...(asset.beneficiary_charity.map((c) => ({
      ...c,
      type: "charity",
    })) ?? []),
    ...(asset.beneficiary_person.map((p) => ({
      ...p,
      type: "person",
    })) ?? []),
  ];
}

// Adds new rows to the join table and removes ones that aren't in the updated list
export async function updateAssetBeneficiaries({
  assetId,
  personBeneficiaryIds,
  charityBeneficiaryIds,
}: {
  assetId: number;
  personBeneficiaryIds?: number[];
  charityBeneficiaryIds?: number[];
}): Promise<void> {
  // First add new ones
  const insertPeople = supabase.from("asset_person_beneficiary").upsert(
    personBeneficiaryIds.map((beneficiary_id) => ({
      asset_id: assetId,
      beneficiary_person_id: beneficiary_id,
    }))
  );
  const insertCharities = supabase.from("asset_charity_beneficiary").upsert(
    charityBeneficiaryIds.map((beneficiary_id) => ({
      asset_id: assetId,
      beneficiary_charity_id: beneficiary_id,
    }))
  );
  const insert = await Promise.all([insertPeople, insertCharities]);
  if (insert.some((i) => i.error)) {
    throw new Error(
      `Error updating beneficiaries: ${JSON.stringify(
        insert.map((i) => i.error)
      )}`
    );
  }

  // Then delete old ones
  const deletePeople = supabase
    .from("asset_person_beneficiary")
    .delete()
    .eq("asset_id", assetId)
    .not(
      "beneficiary_person_id",
      "in",
      `(${(personBeneficiaryIds ?? []).join(",")})`
    );
  const deleteCharities = supabase
    .from("asset_charity_beneficiary")
    .delete()
    .eq("asset_id", assetId)
    .not(
      "beneficiary_charity_id",
      "in",
      `(${(charityBeneficiaryIds ?? []).join(",")})`
    );
  const del = await Promise.all([deletePeople, deleteCharities]);
  if (del.some((d) => d.error)) {
    throw new Error(
      `Error updating beneficiaries: ${JSON.stringify(del.map((d) => d.error))}`
    );
  }
}

export async function updateAssetDistribution({
  id,
  distribution_method,
  distribution_trust_id,
  beneficiary_charity,
  beneficiary_person,
}): Promise<void> {
  const updateDistribution = await Promise.all([
    supabase
      .from("asset")
      .update({ distribution_method, distribution_trust_id })
      .eq("id", id),
    updateAssetBeneficiaries({
      assetId: id,
      personBeneficiaryIds: beneficiary_person.map((b) => b.id),
      charityBeneficiaryIds: beneficiary_charity.map((b) => b.id),
    }),
  ]);

  if (updateDistribution[0].error)
    throw new Error(updateDistribution[0].error.message);
}
