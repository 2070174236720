<script lang="ts">
  import { currentEstate } from "../../stores/user.js";
  import { supabase } from "../../supabaseClient";
  import type { Trust } from "../../types";
  import { addOrUpdateTrustDocument } from "../../util/storage";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import FileUpload from "../FileUpload.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { FileButton } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import type { Writable } from "svelte/store";
  import { mixed, object, string } from "yup";

  export let multipartFormData;
  export let trusts: Writable<Trust[]>;
  const goBack: () => void = getContext("back");

  const dispatch = createEventDispatcher();

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      // This forgoes initial values, which means if a user continues and then goes back they will come back to this point
      // with nothing filled out. That is likely fine since this is to add a new trust. If in the future we want values
      // to be preserved will need to save them to the multipartFormData object and then pass them in as initial values,
      // then update the createAsset function to get the id to link to from there and discard the rest
      initialValues: {
        name: "",
        legalDocument: null,
      },
      validationSchema: object().shape({
        name: string().required("Required"),
        legalDocument: mixed().nullable(),
      }),
      onSubmit: (formData) => {
        supabase
          .from("trust")
          .insert({ estate_id: $currentEstate.id, name: formData.name })
          .select("*")
          .then((response) => {
            if (formData.legalDocument) {
              addOrUpdateTrustDocument(formData.legalDocument, formData.name);
            }
            if (response.error) {
              if (response.error.message.includes("unique_trust_name_in_estate")) {
                popErrorToast("A trust with that name already exists");
                return;
              } else {
                console.error(response.error);
                popErrorToast("An error occurred while adding the trust");
              }
              return;
            }
            $trusts = [...$trusts, response.data[0]];
            $multipartFormData.distribution_trust_id = response.data[0].id;

            goBack();
            dispatch("trustAdded");
          });
      },
    }
  );
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title pb-[30px]">Add New Trust</h1>
    <p class="m-0 p-0 text-body/large max-md:hidden">
      Tell us about the trust you’d like to add.
    </p>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      Tell us about the trust you’d like to add.
    </p>

    <div class="grid grid-cols-2 gap-x-[40px] gap-y-[45px] md:pr-[50px]">
      <div class="input-wrap col-span-full">
        <label for="name">
          Trust Name <span class="text-state/negative">*</span>
        </label>

        <input
          name="name"
          id="name"
          type="text"
          bind:value={$form.name}
          on:change={handleChange}
        />

        {#if $errors.name}
          <small class="input-error"><WarnIcon /> {$errors.name}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label>Legal Document</label>

        <FileUpload bind:file={$form.legalDocument} />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button btn-main"
      on:click|preventDefault={goBack}
    >
      <ArrowLeftIcon />
      <span>Back</span>
    </button>
    <button class="btn-filled btn-lg" type="submit" disabled={$isSubmitting}>
      <span>Continue</span>
      <ArrowRightIcon />
    </button>
  </div>
</form>
