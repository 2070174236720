<script lang="ts">
  import { userCan } from "../../stores/user";
  import type { CharityBeneficiary, PersonBeneficiary } from "../../types";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import PlusIcon from "../icons/PlusSmall.svelte";
  import { createEventDispatcher, getContext } from "svelte";
  import type { Writable } from "svelte/store";

  export let multipartFormData;
  export let personBeneficiaries: Writable<PersonBeneficiary[]>;
  export let charityBeneficiaries: Writable<CharityBeneficiary[]>;
  export let showAddNewBeneficiary: Writable<boolean>;

  const goBack: () => void = getContext("back");
  const goForward: () => void = getContext("forward");

  const dispatch = createEventDispatcher();

  $: hasBeneficairies =
    $personBeneficiaries.length > 0 || $charityBeneficiaries.length > 0;

  function addNewBeneficiarySelected() {
    $showAddNewBeneficiary = true;
    goForward();
  }
</script>

{#if hasBeneficairies}
  <form
    class="mobile-content"
    on:submit|preventDefault={() => dispatch("submitted")}
  >
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title pb-[30px]">Asset Beneficiaries</h1>
      <p class="m-0 p-0 text-body/large max-md:hidden">
        Who should receive this asset?
      </p>
    </div>

    <div
      class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
    >
      <p class="mobile-content-description md:hidden">
        Who should receive this asset?
      </p>

      <div
        class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] pr-[50px] max-md:gap-y-[35px] md:gap-y-[45px]"
      >
        <div class="input-wrap col-span-full">
          <div class="form-label">Beneficiaries</div>

          <div class="check-cols gap-[12px]">
            {#each $personBeneficiaries as person}
              <label class="input-option pb-[12px]">
                <input
                  type="checkbox"
                  id={person.id.toString()}
                  name={person.id.toString()}
                  value={person.id}
                  bind:group={$multipartFormData.personBeneficiaries}
                />
                {person.first_name + " " + person.last_name}
              </label>
            {/each}
            {#each $charityBeneficiaries as charity}
              <label class="input-option pb-[12px]">
                <input
                  type="checkbox"
                  id={charity.id.toString()}
                  name={charity.id.toString()}
                  value={charity.id}
                  bind:group={$multipartFormData.charityBeneficiaries}
                />
                {charity.name}
              </label>
            {/each}
          </div>

          {#if userCan("beneficiaries_write")}
            <button
              class="btn-main btn-sm mt-[30px]"
              on:click|preventDefault={addNewBeneficiarySelected}
              ><PlusIcon /> Add New Beneficiary</button
            >
          {/if}
        </div>
      </div>
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
    >
      <button
        class="mobile-back-button btn-main"
        on:click|preventDefault={goBack}
      >
        <ArrowLeftIcon />
        Back
      </button>
      <button
        class="btn-filled btn-success btn-lg bg-state/positive"
        type="submit"
      >
        Add Asset <ArrowRightIcon /></button
      >
    </div>
  </form>
{:else}
  <div class="mobile-content">
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title pb-[30px]">Asset Beneficiaries</h1>
      <p class="m-0 p-0 text-body/large max-md:hidden">
        We want to record who should receive this asset, but you haven't added
        any beneficiaries to LegaSEE yet.
      </p>
    </div>

    <div
      class="mobile-content-body border-b border-t border-ui/border-light py-[75px] pl-[50px] md:shadow-forms"
    >
      <p class="m-0 mb-[30px] p-0 text-body/medium md:hidden">
        We want to record who should receive this asset, but you haven't added
        any beneficiaries to LegaSEE yet.
      </p>

      {#if userCan("beneficiaries_write")}
        <div
          class="flex items-center justify-center gap-x-[40px] gap-y-[45px] pr-[50px] max-md:hidden"
        >
          <button
            class="btn-main btn-filled btn-lg"
            on:click={addNewBeneficiarySelected}
          >
            <span><PlusIcon /></span>
            <span>Add First Beneficiary</span>
          </button>
        </div>
      {/if}
    </div>

    <div
      class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
    >
      <button
        class="mobile-back-button btn-main"
        on:click|preventDefault={goBack}
      >
        <ArrowLeftIcon />
        Back
      </button>
      <button class="btn-main btn-lg" on:click={() => dispatch("submitted")}>
        Skip For Now <ArrowRightIcon /></button
      >
      {#if userCan("beneficiaries_write")}
        <button
          class="btn-main btn-filled btn-lg md:!hidden"
          on:click={addNewBeneficiarySelected}
        >
          <span><PlusIcon /></span>
          <span>Add First Beneficiary</span>
        </button>
      {/if}
    </div>
  </div>
{/if}

<style lang="postcss">
  @media (min-width: 767px) {
    .check-cols {
      column-count: 2;
    }
  }
</style>
