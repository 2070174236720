<script lang="ts">
  import { slide } from "svelte/transition";

  let className = "";
  export { className as class };

  export let percent: number;
  export let thickness: number = 38;
  export let orientation: "horizontal" | "vertical" = "horizontal";

  $: dimension = orientation === "horizontal" ? "width" : "height";
</script>

<figure
  style="{orientation === 'horizontal' ? 'height' : 'width'}: {thickness}px"
  class="{className} relative"
  class:w-full={orientation === "horizontal"}
  class:h-full={orientation === "vertical"}
>
  <figure
    class="absolute bottom-0 left-0 h-full w-full rounded-[3px] border border-ui/border bg-white"
    style="box-shadow: 0 2px 4px rgba(0,0,0,0.05)"
  />
  <figure
    style="max-{dimension}: {percent}%"
    class="{orientation} absolute bottom-0 left-0 h-full w-full min-w-[4px] border transition-all duration-500"
    class:red={percent < 25}
    class:orange={percent >= 25 && percent < 50}
    class:yellow={percent >= 50 && percent < 75}
    class:green={percent >= 75}
    class:full={percent === 100}
    in:slide|local={{ axis: "x" }}
  />
</figure>

<style lang="postcss">
  .full {
    @apply rounded-[3px];
  }
  .horizontal {
    @apply origin-left rounded-bl-[3px] rounded-tl-[3px] bg-gradient-to-b;
  }
  .vertical {
    @apply origin-bottom rounded-bl-[3px] rounded-br-[3px] bg-gradient-to-r;
  }
  .red {
    @apply border-[#E5063E] from-[#EE6084] to-[#E5063E];
  }
  .orange {
    @apply border-[#FB5607] from-[#FF9867] to-[#FB5607];
  }
  .yellow {
    @apply border-[#FFBE0B] from-[#FFDB76] to-[#FFBE0B];
  }
  .green {
    @apply border-[#07AE5E] from-[#53CE93] to-[#06BA63];
  }
</style>
