<script lang="ts">
  import CheckIcon from "../lib/icons/Check.svelte";
  import EyeIcon from "../lib/icons/Eye.svelte";
  import PenIcon from "../lib/icons/Pencil.svelte";
  import WarnIcon from "../lib/icons/WarnFilled.svelte";
  import { supabase } from "../supabaseClient";
  import { popErrorToast, popSuccessToast } from "../util/toasts";
  import { passwordValidationSchema } from "../util/validation";
  import type { AuthSession } from "@supabase/supabase-js";
  import { createForm } from "svelte-forms-lib";
  import { slide } from "svelte/transition";
  import { object } from "yup";

  export let session: AuthSession;

  let passwordVisible = false;
  let canUpdatePassword = false;
  let submitClicked = false;

  async function updatePassword(password: string) {
    try {
      await supabase.auth.updateUser({ password });
      canUpdatePassword = false;
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    }
  }

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        password: null,
      },
      validationSchema: object().shape({
        password: passwordValidationSchema,
      }),
      onSubmit: async (formData) => {
        await updatePassword(formData.password);

        popSuccessToast("Password updated successfully");
      },
    }
  );
</script>

<h1 class="border-bg/dark mobile-header-title-fixed">Login Details</h1>

<hr class="mb-[50px] mt-[30px] max-md:hidden" />

<div class="mobile-content">
  <div class="mobile-content-body">
    <div
      class="border-bg/dark mb-12 flex flex-col items-baseline border-b pb-12 max-md:border-ui/border-dark"
    >
      <label for="email">
        Email Address <span class="text-state/negative">*</span>
      </label>

      <input
        id="email"
        type="email"
        value={session.user.email}
        class="max-w-[500px]"
        placeholder="email@example.com"
        disabled
      />
    </div>

    <form class="flex flex-col items-baseline" on:submit={handleSubmit}>
      <div class="mb-[30px]">
        <label for="password">
          Password <span class="text-state/negative">*</span>
        </label>

        <div class="input-group grid-cols-[auto_1fr_auto]">
          <!-- Can't change `type` dynamically  -->
          {#if passwordVisible && canUpdatePassword}
            <input
              id="password"
              type="text"
              placeholder="******"
              class="max-w-[350px]"
              disabled={!canUpdatePassword}
              bind:value={$form.password}
              on:change={handleChange}
            />
          {:else}
            <input
              id="password"
              type="password"
              placeholder={!canUpdatePassword ? "******" : ""}
              class="max-w-[350px]"
              disabled={!canUpdatePassword}
              bind:value={$form.password}
              on:change={handleChange}
            />
          {/if}

          <button
            type="button"
            class="text-text/medium"
            disabled={!canUpdatePassword}
            on:click={() => (passwordVisible = !passwordVisible)}
          >
            <EyeIcon />
          </button>
        </div>

        {#if canUpdatePassword}
          {#if $errors.password && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.password}</small>
          {/if}

          <div class="input-hint" transition:slide|local={{ duration: 100 }}>
            <ul class="ml-[18px] mt-[10px] list-disc">
              <li class="mt-[3px]">8 characters minimum</li>
              <li class="mt-[3px]">One lowercase letter</li>
              <li class="mt-[3px]">One uppercase letter</li>
              <li class="mt-[3px]">One special character</li>
            </ul>
          </div>
        {/if}
      </div>

      {#if canUpdatePassword}
        <div class="flex gap-6">
          <button
            type="button"
            class="variant-ringed-primary btn bg-white text-state/link"
            disabled={false}
            on:click={() => (canUpdatePassword = false)}
          >
            Cancel
          </button>

          <button
            type="submit"
            class="btn-filled btn-success btn flex gap-[10px]"
            disabled={$isSubmitting}
            on:click={() => (submitClicked = true)}
          >
            <CheckIcon />

            {$isSubmitting ? "Saving ..." : "Save Changes"}
          </button>
        </div>
      {:else}
        <button
          type="button"
          class="variant-ringed-primary btn flex gap-[5px] bg-white text-state/link"
          on:click={() => (canUpdatePassword = true)}
        >
          <PenIcon />

          Update Password
        </button>
      {/if}
    </form>
  </div>
</div>

<style lang="postcss">
  /* Global styles are overwritting inline styles */
  input {
    @apply w-[500px];
  }
</style>
