<script lang="ts">
  import {
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money.js";
  import { handleDollarInput } from "../../util/validation.ts";
  import DateInput from "../DateInput.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { date, object, string } from "yup";

  export let multipartFormData;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        description: $multipartFormData.description,
        estimated_value: convertCentsToInputDollars(
          $multipartFormData.estimated_value
        ),
        asset_type: $multipartFormData.asset_type,
        expected_inheritance_date: $multipartFormData.expected_inheritance_date,
      },
      validationSchema: object().shape({
        description: string().required("Required"),
        estimated_value: string().required("Required"),
        asset_type: string().nullable(),
        expected_inheritance_date: date().nullable(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.description = formData.description;
        $multipartFormData.estimated_value = convertDollarsStringToCents(
          formData.estimated_value
        );
        $multipartFormData.asset_type = formData.asset_type;
        $multipartFormData.expected_inheritance_date =
          formData.expected_inheritance_date;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );
</script>

<form
  class="mobile-content {!!onSubmitted ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if !!onSubmitted}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Asset</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {$multipartFormData.description}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">Add Inheritance</h1>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="description">
          Description <span class="text-state/negative">*</span>
        </label>

        <input
          name="description"
          id="description"
          type="text"
          bind:value={$form.description}
          on:change={handleChange}
        />

        {#if $errors.description}
          <small class="input-error"><WarnIcon /> {$errors.description}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="estimated_value">
          Estimated Value <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="estimated_value"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            name="estimated_value"
            id="estimated_value"
            class="!pl-0"
            bind:value={$form.estimated_value}
            on:input={(e) => handleDollarInput(e, form, "estimated_value")}
          />
        </div>

        {#if $errors.estimated_value}
          <small class="input-error"
            ><WarnIcon /> {$errors.estimated_value}</small
          >
        {/if}
      </div>
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] border-ui/border-light pt-[35px] max-md:gap-y-[35px] md:border-t md:pr-[50px] md:pt-[45px]"
    >
      <div class="input-wrap">
        <label for="asset_type"> Asset Type </label>

        <select
          name="asset_type"
          id="asset_type"
          bind:value={$form.asset_type}
          on:change={handleChange}
          required
        >
          <option value="" disabled selected>Choose...</option>
          <optgroup label="Financial Accounts">
            <option>Banking</option>
            <option>Investment</option>
            <option>Retirement</option>
          </optgroup>
          <optgroup label="Property">
            <option>Automobile</option>
            <option>Real Estate</option>
            <option>Specialty</option>
          </optgroup>
          <optgroup label="Other">
            <option>Insurance</option>
            <option>Legal Entity</option>
            <option>Loan</option>
          </optgroup>
        </select>
      </div>

      <div class="input-wrap">
        <label for="expected_inheritance_date">
          Expected Inheritance Date
        </label>

        <DateInput bind:value={$form.expected_inheritance_date} />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="btn-main mobile-back-button"
      on:click={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
