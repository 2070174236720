<script context="module" lang="ts">
  export type View = "login" | "forgot-password";
</script>

<script lang="ts">
  import ExpiredInvite from "../lib/login/ExpiredInvite.svelte";
  import ForgotPassword from "../lib/login/ForgotPassword.svelte";
  import Login from "../lib/login/Login.svelte";
  import { userCan } from "../stores/user.ts";
  import { supabase } from "../supabaseClient";
  import { navigateHome } from "../util/navigateHome";
  import { onDestroy } from "svelte";
  import { useLocation } from "svelte-navigator";

  let view: View = "login";

  const { data } = supabase.auth.onAuthStateChange(async (event) => {
    if (event === "SIGNED_IN") {
      navigateHome();
    }
  });

  onDestroy(() => {
    data.subscription.unsubscribe();
  });

  const location = useLocation();
</script>

<div
  class="flex h-full flex-col items-center justify-center bg-gradient-to-br from-[#3A86FF] to-[#06BA63]"
>
  <div
    class="card w-full shadow-onboarding max-md:rounded-none md:max-w-[650px]"
  >
    {#if $location.search.includes("type=invite")}
      <ExpiredInvite />
    {:else if view === "login"}
      <Login bind:view />
    {:else if view === "forgot-password"}
      <ForgotPassword bind:view />
    {/if}
  </div>
</div>
