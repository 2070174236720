<script lang="ts">
  import { getContext } from "svelte";
  import { onMount, onDestroy } from "svelte";
  import { type Writable } from "svelte/store";
  import { fly } from "svelte/transition";

  export let step: number;

  let stepperState: Writable<{
    step: number;
    steps: number[];
    direction: "forward" | "back";
    height?: number;
  }> = getContext("stepperState");

  onMount(() => {
    $stepperState.steps = [...$stepperState.steps, step];
  });
  onDestroy(() => {
    $stepperState.steps = $stepperState.steps.filter((s) => s !== step);
  });

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  $: inDirective =
    $stepperState.direction === "forward"
      ? { x: 100, duration: 250 }
      : { x: -100, duration: 250 };
  $: outDirective = { ...inDirective, x: inDirective.x * -1 };
</script>

{#if $stepperState.step === step}
  <div
    in:fly|local={inDirective}
    out:fly|local={outDirective}
    bind:clientHeight={$stepperState.height}
    class="absolute top-0 w-full max-md:fixed max-md:bottom-0 max-md:left-0 max-md:right-0 max-md:top-0"
  >
    <slot {goForward} {goBack} />
  </div>
{/if}
