<script lang="ts" context="module">
  export type StepOneFormData = {
    institution_name: string;
    current_balance: string;
    credit_limit: string;
    original_loan_amount: string;
    payment_frequency: string;
    maturity_date: string;
    account_number: string;

    label: string;
  };
</script>

<script lang="ts">
  import { getDebtTypeName, type Debt } from "../../pages/Debts.svelte";
  import {
    PaymentFrequencies,
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money";
  import { handleDollarInput } from "../../util/validation.ts";
  import DateInput from "../DateInput.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CalendarIcon from "../icons/Calendar.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getDebtDisplayName, type DebtType, getDebtCategory } from "./debts";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string, number } from "yup";

  export let debt: Debt = null;
  export let debtType: DebtType;
  export let loading: boolean;

  const dispatch = createEventDispatcher();

  let submitClicked = false;

  const {
    institution_name,
    current_balance,
    credit_limit,
    original_loan_amount,
    payment_frequency,
    maturity_date,
    account_number,
    label,
  } = debt || {};

  const { form, errors, handleChange, handleSubmit } =
    createForm<StepOneFormData>({
      initialValues: {
        institution_name,
        current_balance: convertCentsToInputDollars(current_balance),
        credit_limit: convertCentsToInputDollars(credit_limit),
        original_loan_amount: convertCentsToInputDollars(original_loan_amount),
        payment_frequency,
        maturity_date,
        account_number,
        label,
      },
      validationSchema: object()
        .shape({
          institution_name: string().required("Required"),
          current_balance: string().required("Required"),
          credit_limit: string().nullable(),
          original_loan_amount: string().nullable(),
          payment_frequency: string().nullable(),
          maturity_date: string().nullable(),
          account_number: string().nullable(),
        })
        .when((_, schema) => {
          if (label) {
            return schema
              .shape({
                label: string().nullable(),
              })
              .test({
                // There's a bug either in `yup` or in `svelte-forms-lib` where the name of the custom error provided in test() is always "undefined".
                // So $errors.label_or_account_number_required will not be present but $errors.undefined.
                name: "label_or_account_number_required",
                message: "You must provide either a label or an account number",
                exclusive: true,
                test: (formData) =>
                  !!formData.label || !!formData.account_number,
              });
          }
        }),
      onSubmit: ({
        current_balance,
        original_loan_amount,
        credit_limit,
        ...formData
      }) => {
        dispatch("submit", {
          ...formData,
          current_balance: convertDollarsStringToCents(current_balance),
          original_loan_amount:
            convertDollarsStringToCents(original_loan_amount),
          credit_limit: convertDollarsStringToCents(credit_limit),
        });
      },
    });

  $: isUpdate = !!debt;
  $: debtCategory = getDebtCategory(debtType);
</script>

<form
  class="mobile-content {isUpdate ? 'max-md:!top-[85px]' : ''}"
  on:submit={handleSubmit}
  novalidate
>
  {#if isUpdate}
    <div
      class="mobile-header px-[50px] py-[30px] max-md:block max-md:!h-[85px]"
    >
      <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Debt</h1>
      <p
        class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
      >
        {getDebtDisplayName(debt)}
      </p>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title">Add {getDebtTypeName(debtType)}</h1>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[35px] pb-[35px] md:border-b md:border-ui/border-light md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="institution_name">
          Institution Name <span class="text-state/negative">*</span>
        </label>

        <input
          type="text"
          id="institution_name"
          bind:value={$form.institution_name}
          on:change={handleChange}
        />

        {#if $errors.institution_name && submitClicked}
          <small class="input-error"
            ><WarnIcon /> {$errors.institution_name}</small
          >
        {/if}
      </div>

      <div class="input-wrap">
        <label for="current_balance">
          Current Balance <span class="text-state/negative">*</span>
        </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="current_balance"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>
          <input
            name="current_balance"
            id="current_balance"
            type="text"
            class="!pl-0"
            bind:value={$form.current_balance}
            on:input={(e) => handleDollarInput(e, form, "current_balance")}
          />
        </div>

        {#if $errors.current_balance && submitClicked}
          <small class="input-error"
            ><WarnIcon /> {$errors.current_balance}</small
          >
        {/if}
      </div>
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:pr-[50px] md:pt-[45px]"
    >
      {#if debtCategory === "credit_account"}
        <div class="input-wrap">
          <label for="credit_limit">Credit Limit</label>

          <div class="input-group w-full grid-cols-[42px_auto]">
            <label
              for="credit_limit"
              class="m-0 flex items-center pl-[18px] text-text/medium"
            >
              <DollarIcon />
            </label>
            <input
              name="credit_limit"
              id="credit_limit"
              type="text"
              class="!pl-0"
              bind:value={$form.credit_limit}
              on:input={(e) => handleDollarInput(e, form, "credit_limit")}
            />
          </div>

          {#if $errors.credit_limit && submitClicked}
            <small class="input-error"
              ><WarnIcon /> {$errors.credit_limit}</small
            >
          {/if}
        </div>
      {/if}

      {#if debtCategory === "loan_account"}
        <div class="input-wrap">
          <label for="original_loan_amount">Original Loan Amount</label>

          <div class="input-group w-full grid-cols-[42px_auto]">
            <label
              for="original_loan_amount"
              class="m-0 flex items-center pl-[18px] text-text/medium"
            >
              <DollarIcon />
            </label>
            <input
              name="original_loan_amount"
              id="original_loan_amount"
              type="text"
              class="!pl-0"
              bind:value={$form.original_loan_amount}
              on:input={(e) =>
                handleDollarInput(e, form, "original_loan_amount")}
            />
          </div>

          {#if $errors.original_loan_amount && submitClicked}
            <small class="input-error"
              ><WarnIcon /> {$errors.original_loan_amount}</small
            >
          {/if}
        </div>
      {/if}

      <div class="input-wrap">
        <label for="payment_frequency">Payment Frequency</label>

        <select
          id="payment_frequency"
          bind:value={$form.payment_frequency}
          on:change={handleChange}
          required
        >
          <option value="" selected disabled>Choose...</option>
          {#each PaymentFrequencies as paymentFrequency}
            <option value={paymentFrequency}>
              {paymentFrequency}
            </option>
          {/each}
        </select>

        {#if $errors.payment_frequency && submitClicked}
          <small class="input-error"
            ><WarnIcon /> {$errors.payment_frequency}</small
          >
        {/if}
      </div>

      <div class="input-wrap">
        <label for="maturity_date">Maturity Date</label>
        <DateInput bind:value={$form.maturity_date} />

        {#if $errors.maturity_date && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors.maturity_date}</small
          >
        {/if}
      </div>

      <div class="input-wrap">
        <label for="account_number"> Account Number </label>

        <input
          type="text"
          id="account_number"
          bind:value={$form.account_number}
          on:change={handleChange}
        />

        {#if $errors.account_number && submitClicked}
          <small class="input-error"
            ><WarnIcon /> {$errors.account_number}</small
          >
        {/if}

        <!--
            There's a bug either in `yup` or in `svelte-forms-lib` where the name of the custom error provided in test() is always "undefined".
          -->
        {#if $errors["undefined"] && submitClicked}
          <small class="input-error"><WarnIcon /> {$errors["undefined"]}</small>
        {/if}
      </div>

      {#if label}
        <div class="input-wrap md:col-span-2">
          <label for="label"> Descriptive Label</label>

          <input
            type="text"
            id="label"
            bind:value={$form.label}
            on:change={handleChange}
          />

          {#if $errors.label && submitClicked}
            <small class="input-error"><WarnIcon /> {$errors.label}</small>
          {/if}

          <!--
            There's a bug either in `yup` or in `svelte-forms-lib` where the name of the custom error provided in test() is always "undefined".
          -->
          {#if $errors["undefined"] && submitClicked}
            <small class="input-error"
              ><WarnIcon /> {$errors["undefined"]}</small
            >
          {/if}
        </div>
      {/if}
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="variant-ringed-primary mobile-back-button btn flex gap-[5px] text-state/link"
      disabled={loading}
      on:click={() => dispatch("cancel")}
    >
      {#if isUpdate}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg btn-success flex gap-[10px]"
      disabled={loading}
      on:click={() => (submitClicked = true)}
    >
      {#if isUpdate}
        {#if loading}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />

          Saving
        {:else}
          <CheckIcon />

          Save Changes
        {/if}
      {:else if loading}
        Adding Debt

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        Add Debt

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
