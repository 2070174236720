<script lang="ts">
  import type { Service } from "../../pages/Services.svelte";
  import { convertCentsToDollarString } from "../../util/money.js";
  import Modal from "../Modal.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import PenIcon from "../icons/Pencil.svelte";
  import TrashIcon from "../icons/TrashClip.svelte";
  import AddUpdateServiceForm from "./AddUpdateServiceForm.svelte";
  import DeleteServiceForm from "./DeleteServiceForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { startCase } from "lodash";

  export let service: Service;

  type View = "Details" | "Edit" | "Delete";
  let view: View = "Details";

  function handleDoneUpdating({ detail }: { detail: Service }) {
    view = "Details";

    service = { ...detail };
    $modalStore[0].response(service);
  }

  function handleDoneDeleting({ detail }: { detail: number }) {
    $modalStore[0].response(detail);
    modalStore.close();
  }
</script>

<Modal mobileExit="hide">
  {#if view === "Details"}
    <button class="mobile-back-button md:hidden" on:click={modalStore.close}>
      <ArrowLeftIcon />
    </button>

    <div class="mobile-content w-[750px] max-w-[100%] max-md:!top-[85px]">
      <div
        class="mobile-header mb-[40px] px-[50px] pt-[30px] max-md:block max-md:!h-[85px]"
      >
        <h1 class="mobile-header-title-fixed max-md:!text-left">
          {service.name}
        </h1>

        <p
          class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
        >
          {service.description}
        </p>
      </div>

      <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
        <div
          class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
        >
          <div
            class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] bg-bg/lighter p-[6px] pl-[15px]"
          >
            <div
              class="mobile-list-header-text text-body/large font-semibold text-text/dark"
            >
              Service Details
            </div>

            <button
              type="button"
              class="btn-main mobile-list-header-link btn-sm shadow-heavy"
              on:click={() => (view = "Edit")}
            >
              <PenIcon />
              Edit
            </button>
          </div>

          <div class="flex flex-wrap rounded-bl-[5px] rounded-br-[5px]">
            <div
              class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
            >
              <div class="mobile-list-item-label mb-[8px] text-text/medium">
                Provider
              </div>
              <div
                class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
              >
                {service.name}
              </div>
            </div>
            <div
              class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
            >
              <div class="mobile-list-item-label mb-[8px] text-text/medium">
                Description
              </div>
              <div
                class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
              >
                {service.description}
              </div>
            </div>
            <div
              class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
            >
              <div class="mobile-list-item-label mb-[8px] text-text/medium">
                Payment Cost
              </div>
              <div
                class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
              >
                <sup>$</sup>{convertCentsToDollarString(service.cost, true)}
              </div>
            </div>
            <div
              class="mobile-list-item flex-1 basis-1/2 p-[20px] max-md:basis-full md:border-t"
            >
              <div class="mobile-list-item-label mb-[8px] text-text/medium">
                Payment Frequency
              </div>
              <div
                class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
              >
                {startCase(service.frequency)}
              </div>
            </div>
          </div>
        </div>

        <button
          class="mobile-delete-btn variant-ringed-error btn border-state/negative bg-white text-state/negative"
          on:click={() => (view = "Delete")}
        >
          <TrashIcon />

          Delete Service
        </button>
      </div>
    </div>
  {:else if view === "Edit"}
    <div class="mobile-content w-[750px] max-w-[100%] max-md:!top-[85px]">
      <AddUpdateServiceForm
        {service}
        serviceType={service.type}
        on:cancel={() => (view = "Details")}
        on:done={handleDoneUpdating}
      />
    </div>
  {:else}
    <DeleteServiceForm
      {service}
      on:cancel={() => (view = "Details")}
      on:done={handleDoneDeleting}
    />
  {/if}
</Modal>
