<script lang="ts">
  import type { Service } from "../../pages/Services.svelte";
  import { popSuccessToast } from "../../util/toasts.ts";
  import Modal from "../Modal.svelte";
  import DeleteServiceForm from "./DeleteServiceForm.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";

  export let service: Service;

  function handleDone() {
    $modalStore[0].response(service.id);
    modalStore.close();
    popSuccessToast(`${service.name} service deleted`);
  }
</script>

<Modal mobileExit="close">
  <DeleteServiceForm
    {service}
    on:cancel={() => modalStore.close()}
    on:done={handleDone}
  />
</Modal>
