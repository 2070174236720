<script lang="ts">
  import AdvisorLayout from "./layouts/AdvisorLayout.svelte";
  import StandardLayout from "./layouts/StandardLayout.svelte";
  import UpdatePassword from "./lib/login/UpdatePassword.svelte";
  import E404 from "./pages/404.svelte";
  import AdvisorClients from "./pages/AdvisorClients.svelte";
  import Assets from "./pages/Assets.svelte";
  import Auth from "./pages/Auth.svelte";
  import Beneficiaries from "./pages/Beneficiaries.svelte";
  import CollaboratorInitialPassword from "./pages/CollaboratorInitialPassword.svelte";
  import Collaborators from "./pages/Collaborators.svelte";
  import Dashboard from "./pages/Dashboard.svelte";
  import Debts from "./pages/Debts.svelte";
  import Help from "./pages/Help.svelte";
  import LoginDetails from "./pages/LoginDetails.svelte";
  import Onboarding from "./pages/Onboarding.svelte";
  import Profile from "./pages/Profile.svelte";
  import Services from "./pages/Services.svelte";
  import Vault from "./pages/Vault.svelte";
  import { userStore, userPermissions, userCan } from "./stores/user";
  import { supabase } from "./supabaseClient";
  import { navigateHome } from "./util/navigateHome";
  import { Routes } from "./util/routes";
  import type { AuthSession } from "@supabase/supabase-js";
  import { navigate, Route, Router } from "svelte-navigator";

  let session: AuthSession;

  supabase.auth.getSession().then(({ data }) => {
    session = data.session;

    if (
      session &&
      !awaitingAccountSetup(session) &&
      location.pathname === "/"
    ) {
      navigateHome();
    }
  });

  supabase.auth.onAuthStateChange((_event, newSession) => {
    session = newSession;

    if (
      (!session || awaitingAccountSetup(session)) &&
      location.pathname !== "/"
    ) {
      navigate(`/${location.search}`);
    }
  });

  $: layout =
    $userStore?.user_type === "advisor" ? AdvisorLayout : StandardLayout;

  function awaitingAccountSetup(session: AuthSession) {
    return session?.user?.user_metadata?.flags?.inviteeAwaitingAccountSetup;
  }
</script>

<Router>
  <!-- Logged Out Routes -->
  {#if session === null}
    <Route path="/">
      <Auth />
    </Route>
  {/if}

  <!-- Purgatory Routes -->
  {#if session?.user?.user_metadata?.flags?.inviteeAwaitingAccountSetup}
    <Route path="/">
      {#if $userStore?.user_type === "owner"}
        <Onboarding />
      {:else}
        <CollaboratorInitialPassword />
      {/if}
    </Route>
  {:else}
    <!-- Logged In Routes -->
    {#if $userStore && $userPermissions}
      <Route path={Routes.UpdatePassword}>
        <UpdatePassword />
      </Route>

      <Route path="/*" primary={false}>
        <svelte:component this={layout}>
          <!-- Advisor only -->
          {#if $userStore?.user_type === "advisor"}
            <Route path={Routes.Clients}>
              <AdvisorClients />
            </Route>
          {/if}

          {#if userCan("dashboard_read")}
            <Route path={Routes.Dashboard}>
              <Dashboard />
            </Route>
          {/if}

          {#if userCan("assets_read") || userCan("assets_read_designated")}
            <Route path={Routes.Assets}>
              <Assets />
            </Route>
          {/if}

          {#if userCan("debts_read")}
            <Route path={Routes.Debts}>
              <Debts />
            </Route>
          {/if}

          {#if userCan("services_read")}
            <Route path={Routes.Services}>
              <Services />
            </Route>
          {/if}

          {#if userCan("beneficiaries_read")}
            <Route path={Routes.Beneficiaries}>
              <Beneficiaries />
            </Route>
          {/if}

          {#if $userStore?.user_type === "owner"}
            <Route path={Routes.Collaborators}>
              <Collaborators />
            </Route>
          {/if}

          {#if userCan("vault_read")}
            <Route path={Routes.Vault}>
              <Vault />
            </Route>
          {/if}

          <Route path={Routes.Profile}>
            <Profile {session} />
          </Route>

          <Route path={Routes.LoginDetails}>
            <LoginDetails {session} />
          </Route>

          <Route path={Routes.Help}>
            <Help />
          </Route>

          <Route path="*">
            <E404 />
          </Route>
        </svelte:component>
      </Route>
    {/if}
  {/if}
</Router>
