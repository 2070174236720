<script lang="ts">
  import {
    PaymentFrequencies,
    convertCentsToInputDollars,
    convertDollarsStringToCents,
  } from "../../util/money.js";
  import { handleDollarInput } from "../../util/validation.ts";
  import DateInput from "../DateInput.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CalendarIcon from "../icons/Calendar.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DollarIcon from "../icons/Dollar.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import { getContext } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { date, number, object, string } from "yup";

  export let multipartFormData;
  export let onSubmitted: () => void | null;
  export let onCancelled: () => void | null;

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  const { form, errors, isSubmitting, handleChange, handleSubmit } = createForm(
    {
      initialValues: {
        insured: $multipartFormData.insured,
        insurer: $multipartFormData.insurer,
        policy_number: $multipartFormData.policy_number,
        maturity: $multipartFormData.maturity,
        policy_limit: convertCentsToInputDollars(
          $multipartFormData.policy_limit
        ),
        premium: convertCentsToInputDollars($multipartFormData.premium),
        payment_frequency: $multipartFormData.payment_frequency,
        payment_source: $multipartFormData.payment_source,
      },
      validationSchema: object().shape({
        insured: string().required("Required"),
        insurer: string().required("Required"),
        policy_number: string().nullable(),
        maturity: date().nullable(),
        policy_limit: string(),
        premium: string().nullable(),
        payment_frequency: string().nullable(),
        payment_source: string().nullable(),
      }),
      onSubmit: (formData) => {
        $multipartFormData.insured = formData.insured;
        $multipartFormData.insurer = formData.insurer;
        $multipartFormData.policy_number = formData.policy_number;
        $multipartFormData.maturity = formData.maturity;
        $multipartFormData.policy_limit = convertDollarsStringToCents(
          formData.policy_limit
        );
        $multipartFormData.premium = convertDollarsStringToCents(
          formData.premium
        );
        $multipartFormData.payment_frequency = formData.payment_frequency;
        $multipartFormData.payment_source = formData.payment_source;
        if (!!onSubmitted) {
          onSubmitted();
        } else {
          goForward();
        }
      },
    }
  );
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title">
      {onSubmitted ? "Edit" : "Add"} Insurance
    </h1>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] max-md:gap-y-[35px] md:gap-y-[45px] md:pb-[45px] md:pr-[50px]"
    >
      <div class="input-wrap">
        <label for="insured">
          Insured <span class="text-state/negative">*</span>
        </label>

        <input
          id="insured"
          name="insured"
          type="text"
          bind:value={$form.insured}
          on:change={handleChange}
        />

        {#if $errors.insured}
          <small class="input-error"><WarnIcon /> {$errors.insured}</small>
        {/if}
      </div>

      <div class="input-wrap">
        <label for="insurer">
          Insurer <span class="text-state/negative">*</span>
        </label>

        <input
          id="insurer"
          name="insurer"
          type="text"
          bind:value={$form.insurer}
          on:change={handleChange}
        />

        {#if $errors.insurer}
          <small class="input-error"><WarnIcon /> {$errors.insurer}</small>
        {/if}
      </div>
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-x-[40px] gap-y-[45px] border-ui/border-light pt-[35px] max-md:gap-y-[35px] md:gap-y-[45px] md:border-t md:pr-[50px] md:pt-[45px]"
    >
      <div class="input-wrap">
        <label for="policy_number"> Policy Number </label>

        <input
          id="policy_number"
          name="policy_number"
          type="text"
          bind:value={$form.policy_number}
          on:change={handleChange}
        />
      </div>

      <div class="input-wrap">
        <label for="maturity"> Maturity </label>

        <DateInput bind:value={$form.maturity} />
      </div>

      <div class="input-wrap">
        <label for="policy_limit">
          Policy Limit <span class="text-state/negative">*</span></label
        >

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="policy_limit"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            name="policy_limit"
            id="policy_limit"
            class="!pl-0"
            bind:value={$form.policy_limit}
            on:input={(e) => handleDollarInput(e, form, "policy_limit")}
          />
        </div>
      </div>

      <div class="input-wrap">
        <label for="premium"> Premium </label>

        <div class="input-group w-full grid-cols-[42px_auto]">
          <label
            for="premium"
            class="m-0 flex items-center pl-[18px] text-text/medium"
          >
            <DollarIcon />
          </label>

          <input
            type="text"
            name="premium"
            id="premium"
            class="!pl-0"
            bind:value={$form.premium}
            on:input={(e) => handleDollarInput(e, form, "premium")}
          />
        </div>
      </div>

      <div class="input-wrap">
        <label for="payment_frequency"> Payment Frequency </label>

        <select
          name="payment_frequency"
          id="payment_frequency"
          bind:value={$form.payment_frequency}
          on:change={handleChange}
          required
        >
          <option value="" disabled selected>Choose...</option>
          {#each PaymentFrequencies as paymentFrequency}
            <option value={paymentFrequency}>
              {paymentFrequency}
            </option>
          {/each}
        </select>
      </div>

      <div class="input-wrap">
        <label for="payment_source"> Payment Source </label>

        <input
          id="payment_source"
          name="payment_source"
          type="text"
          bind:value={$form.payment_source}
          on:change={handleChange}
        />
      </div>
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="btn-main mobile-back-button"
      on:click|preventDefault={!!onCancelled ? onCancelled : goBack}
    >
      {#if !!onCancelled}
        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={!!onSubmitted}
      type="submit"
      disabled={$isSubmitting}
    >
      {#if !!onSubmitted}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
