<script lang="ts">
  import { userCan } from "../../stores/user";
  import type { AssetType, Trust } from "../../types";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import InfoIcon from "../icons/Info.svelte";
  import PlusIcon from "../icons/PlusSmall.svelte";
  import { createEventDispatcher, getContext, onDestroy } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import type { Writable } from "svelte/store";
  import { number, object, string } from "yup";

  export let multipartFormData;
  export let selectedAssetType: AssetType;
  export let trusts: Writable<Trust[]>;
  export let showAddNewTrust: Writable<boolean>;
  export let isUpdate = false;

  const dispatch = createEventDispatcher();

  const goForward: () => void = getContext("forward");
  const goBack: () => void = getContext("back");

  const { form, handleChange, handleSubmit } = createForm({
    initialValues: {
      distributionMethod: $multipartFormData.distribution_method,
      selectTrust: $multipartFormData.distribution_trust_id,
    },
    validationSchema: object().shape({
      distributionMethod: string()
        .required("Required")
        .oneOf(["designation", "will", "trust", "tbd"]),
      selectTrust: number().when("distributionMethod", {
        is: "trust",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    onSubmit: (formData) => {
      $multipartFormData.distribution_method = formData.distributionMethod;
      $multipartFormData.distribution_trust_id = formData.selectTrust;
      dispatch("submitted");
    },
  });

  // The user may elect to add a new trust, which means they won't submit on this step, so need to capture and set distribution method as soon as it changes
  function handleDistributionMethodChange(event) {
    $multipartFormData.distribution_method = event.target.value;
    handleChange(event);
  }

  function addNewTrustSelected() {
    $showAddNewTrust = true;
    goForward();
  }
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  <div class="mobile-header px-[50px] py-[30px]">
    <h1 class="mobile-header-title pb-[30px]">Asset Distribution</h1>
    <p class="m-0 p-0 text-body/large max-md:hidden">
      How will this asset be passed on to beneficiaries?
    </p>
  </div>

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      How will this asset be passed on to beneficiaries?
    </p>

    <div class="grid gap-x-[40px] gap-y-[45px] md:grid-cols-2 md:pr-[50px]">
      <div class="input-wrap">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label>
          Distribution Method <span class="text-state/negative">*</span>
        </label>

        <div class="flex flex-col items-start gap-[12px]">
          {#if selectedAssetType === "financial_account" || selectedAssetType === "banking" || selectedAssetType === "investment" || selectedAssetType === "retirement" || selectedAssetType === "real_estate"}
            <label class="input-option">
              <input
                type="radio"
                id="will"
                name="distributionMethod"
                value="designation"
                bind:group={$form.distributionMethod}
                on:change={handleDistributionMethodChange}
              />
              <span class="underline">POD / TOD</span> Designation
            </label>
          {/if}
          <label class="input-option">
            <input
              type="radio"
              id="trust"
              name="distributionMethod"
              value="will"
              bind:group={$form.distributionMethod}
              on:change={handleDistributionMethodChange}
            />
            Through the <span class="underline">Will</span>
          </label>
          <label class="input-option">
            <input
              type="radio"
              id="joint"
              name="distributionMethod"
              value="trust"
              bind:group={$form.distributionMethod}
              on:change={handleDistributionMethodChange}
            />
            Through a <span class="underline">Trust</span>
          </label>
          <label class="input-option">
            <input
              type="radio"
              id="other"
              name="distributionMethod"
              value="tbd"
              bind:group={$form.distributionMethod}
              on:change={handleDistributionMethodChange}
            />
            To be determined
          </label>
        </div>
      </div>

      {#if $form.distributionMethod === "trust"}
        <div class="input-wrap">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label>
            Select Trust <span class="text-state/negative">*</span>
          </label>

          <div class="flex flex-col items-start gap-[12px]">
            {#each $trusts as trust}
              <label class="input-option">
                <input
                  type="radio"
                  name="selectTrust"
                  value={trust.id}
                  bind:group={$form.selectTrust}
                />
                {trust.name}
              </label>
            {/each}
            {#if userCan("vault_write")}
              <button
                class="btn-main btn-sm mt-[10px]"
                on:click|preventDefault={addNewTrustSelected}
              >
                <span><PlusIcon /></span>
                Add New Trust
              </button>
            {/if}
          </div>
        </div>
      {/if}
      {#if $form.distributionMethod === "tbd"}
        <div class="col-span-full">
          <p
            class="flex items-center justify-start gap-[10px] text-[18px] font-semibold leading-[22px] text-[#FB5607]"
          >
            <InfoIcon /> A new task will be added for you to define this method later
          </p>
        </div>
      {/if}
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      class="btn-main mobile-back-button"
      on:click|preventDefault={goBack}
    >
      {#if isUpdate}
        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>
    <button
      class="btn-filled btn-lg"
      class:btn-success={isUpdate}
      type="submit"
    >
      {#if isUpdate}
        <CheckIcon />

        Save Changes
      {:else}
        Continue

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
