<script lang="ts" context="module">
  export type ViewBeneficiaryPersonModalTabView = "Details" | "Access";
</script>

<script lang="ts">
  import {
    getNormalizedAsset,
    selectedAssetDistributionData,
    type Person,
    assetSelectedForEdit,
  } from "../../pages/Beneficiaries.svelte";
  import { currentEstate, userCan, userStore } from "../../stores/user";
  import { supabase } from "../../supabaseClient";
  import {
    formatDBDate,
    formatSSN,
    maskAccountNumber,
  } from "../../util/format";
  import { convertCentsToDollarString } from "../../util/money";
  import { Routes } from "../../util/routes";
  import { popErrorToast, popSuccessToast } from "../../util/toasts";
  import Modal from "../Modal.svelte";
  import Tab from "../Tab.svelte";
  import Tabs from "../Tabs.svelte";
  import EditDistribution from "../assets/EditDistribution.svelte";
  import { updateAssetDistribution } from "../assets/assets";
  import AccessFormField from "../collaborators/AccessFormField.svelte";
  import {
    convertDBPermissionsToUIPermissions,
    convertUIPermissionsToDBPermissions,
    type UIPermissions,
  } from "../collaborators/collaborators";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import BlockIcon from "../icons/BlockClip.svelte";
  import CancelIcon from "../icons/Cancel.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import DocumentIcon from "../icons/Document.svelte";
  import EyeIcon from "../icons/EyeClip.svelte";
  import EyeSlashIcon from "../icons/EyeSlashClip.svelte";
  import KeysIcon from "../icons/Keys.svelte";
  import PenIcon from "../icons/Pencil.svelte";
  import PlusPersonIcon from "../icons/PlusPersonClip.svelte";
  import TooltipIcon from "../icons/Tooltip.svelte";
  import TrashIcon from "../icons/TrashClip.svelte";
  import ViewIcon from "../icons/View.svelte";
  import { getTrustNames } from "../vault/trusts";
  import AddUpdateBeneficiaryPersonForm, {
    type PersonFormData,
  } from "./AddUpdateBeneficiaryPersonForm.svelte";
  import DeleteBeneficiaryPersonForm from "./DeleteBeneficiaryPersonForm.svelte";
  import InviteToAccessForm from "./InviteToAccessForm.svelte";
  import { ProgressRadial, modalStore } from "@skeletonlabs/skeleton";
  import { popup } from "@skeletonlabs/skeleton";
  import { startCase } from "lodash";
  import { navigate } from "svelte-navigator";

  export let person: Person;
  export let tabView: ViewBeneficiaryPersonModalTabView = "Details";

  let assetTrusts = [];

  type View =
    | "Details"
    | "Edit Person"
    | "Edit Access"
    | "Invite"
    | "Delete"
    | "Distribution";
  let view: View = "Details";

  let ssnVisible = false;
  let loading = false;

  let uiPermissions: UIPermissions = null;

  async function handleUpdate({
    detail: personFormData,
  }: {
    detail: PersonFormData;
  }) {
    try {
      loading = true;

      const { inviteUser, ...personRest } = personFormData;

      const { error } = await supabase
        .from("beneficiary_person")
        .update(personRest)
        .eq("id", person.id);

      if (error) throw error;

      person = { ...person, ...personRest };
      $modalStore[0].response(person);

      view = "Details";
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      loading = false;
    }
  }

  function showEditAccessView() {
    uiPermissions = convertDBPermissionsToUIPermissions(permissions);
    view = "Edit Access";
  }

  function showInviteView() {
    uiPermissions = undefined;
    view = "Invite";
  }

  async function handleUpdatePermissions() {
    try {
      loading = true;

      const dbPermissions = convertUIPermissionsToDBPermissions(uiPermissions);

      const { error } = await supabase
        .from("permission")
        .update(dbPermissions)
        .eq("collaborator_id", person.user_id)
        .eq("estate_id", $currentEstate.id);

      if (error) throw error;

      person = {
        ...person,
        user: {
          ...person.user,
          permission: [dbPermissions],
        },
      };

      popSuccessToast("Success! Access updated.");

      $modalStore[0].response(person);
      view = "Details";
    } catch (error) {
      popErrorToast(error.message);
    } finally {
      loading = false;
    }
  }

  function handleDoneDeleting() {
    $modalStore[0].response(person.id);
    modalStore.close();
  }

  function handleDoneInviting(event) {
    person = event.detail.updatedPerson;
    view = "Details";
    tabView = "Details";
    beneficiaryCanAccess = true;
    popSuccessToast(
      `Success! ${person.first_name} ${person.last_name} has been invited to access your account.`
    );
    $modalStore[0].response({
      fullRefresh: true,
    });
  }

  async function removeAccess() {
    try {
      const { data, error } = await supabase.functions.invoke(
        "delete-collaborator",
        {
          body: {
            collaborator_id: person.user_id,
          },
        }
      );

      if (error ?? data.error) throw new Error(error ?? data.error);

      // update local state
      view = "Details";
      tabView = "Details";
      beneficiaryCanAccess = false;
      $modalStore[0].response({
        ...person,
        user_id: null,
      });

      popSuccessToast(
        `Success! Access for ${person.first_name} ${person.last_name} has been removed.`
      );
    } catch (error) {
      popErrorToast(error?.message);
    }
  }

  async function handleDoneEditingAssetDistribution() {
    try {
      const {
        id,
        distribution_method,
        distribution_trust_id,
        beneficiary_person,
        beneficiary_charity,
      } = $selectedAssetDistributionData;

      await updateAssetDistribution({
        id,
        distribution_method,
        distribution_trust_id,
        beneficiary_person,
        beneficiary_charity,
      });

      // Update the view modal w/ the updated asset
      person = {
        ...person,
        asset: person.asset
          .map(
            (asset) =>
              asset.id === id
                ? {
                    ...asset,
                    distribution_method,
                    distribution_trust_id,
                    beneficiary_person,
                    beneficiary_charity,
                  }
                : asset
            // The currently viewed beneficiary may be unselected from the asset during
            // this process, and if so we need to filter it out
          )
          .filter((asset) =>
            asset.beneficiary_person.find((b) => b.id === person.id)
          ),
      };

      view = "Details";
      popSuccessToast("Asset distribution updated successfully");

      // Notify the parent page that it needs to reload the data
      $modalStore[0].response(true);
    } catch (error) {
      popErrorToast("Failed to update asset distribution");
    } finally {
      selectedAssetDistributionData.set(null);
    }
  }

  $: beneficiaryCanAccess = !!person.user_id;
  $: viewingAsOwner = $userStore.user_type === "owner";
  $: permissions = person?.user?.permission?.[0];
  $: getTrustNames(
    person?.asset
      ?.filter((asset) => !!asset.distribution_trust_id)
      ?.map((asset) => asset.distribution_trust_id)
  ).then((trusts) => (assetTrusts = trusts));
</script>

<Modal mobileExit="hide">
  {#if view === "Details"}
    <button class="mobile-back-button md:hidden" on:click={modalStore.close}>
      <ArrowLeftIcon />
    </button>

    <div
      class="mobile-content w-[996px] max-w-[100%] {viewingAsOwner &&
      beneficiaryCanAccess
        ? 'max-md:!top-[141px]'
        : 'max-md:!top-[64px]'}"
    >
      <div
        class="mobile-header mb-[40px] px-[50px] pt-[30px] {viewingAsOwner &&
        beneficiaryCanAccess
          ? '!shadow-none'
          : ''}"
      >
        <div class="flex items-center justify-between">
          <h1 class="mobile-header-title-fixed">
            {person.first_name}
            {person.last_name}
          </h1>

          {#if viewingAsOwner && !beneficiaryCanAccess}
            <button
              type="button"
              class="variant-ringed-success btn bg-white text-state/positive max-md:!hidden"
              on:click={showInviteView}
            >
              <PlusPersonIcon />

              Invite to Access
            </button>
          {/if}
        </div>
      </div>

      {#if viewingAsOwner && beneficiaryCanAccess}
        <Tabs>
          <Tab bind:group={tabView} value="Details">
            <ViewIcon /> <span class="mobile-tab-text">Details</span>
          </Tab>
          <Tab bind:group={tabView} value="Access">
            <KeysIcon /> <span class="mobile-tab-text">Access</span>
          </Tab>
        </Tabs>
      {/if}

      {#if tabView === "Details"}
        <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
          <div
            class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
          >
            <div
              class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] bg-bg/lighter p-[6px] pl-[15px]"
            >
              <div
                class="mobile-list-header-text text-body/large font-semibold text-text/dark"
              >
                Beneficiary Details
              </div>

              <button
                type="button"
                class="btn-main mobile-list-header-link btn-sm shadow-heavy"
                on:click={() => (view = "Edit Person")}
              >
                <PenIcon />

                Edit
              </button>
            </div>

            <div class="flex flex-wrap rounded-bl-[5px] rounded-br-[5px]">
              <div
                class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
              >
                <div>
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Email Address
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    {person.email || "-"}
                  </div>
                </div>
              </div>

              <div
                class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
              >
                <div>
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Relationship
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    {person.relationship || "-"}
                  </div>
                </div>
              </div>

              <div
                class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
              >
                <div>
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Date of Birth
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    {person.dob
                      ? formatDBDate(person.dob, "MMMM dd, yyyy")
                      : "-"}
                  </div>
                </div>
              </div>

              <div
                class="mobile-list-item flex flex-1 basis-1/2 items-center gap-[20px] border-ui/border p-[20px] max-md:basis-full md:border-t"
              >
                <div>
                  <div class="mobile-list-item-label mb-[8px] text-text/medium">
                    Social Security #
                  </div>
                  <div
                    class="mobile-list-item-value flex items-center text-body/medium text-text/dark"
                  >
                    {#if person.ssn}
                      {ssnVisible
                        ? formatSSN(person.ssn)
                        : maskAccountNumber(person.ssn)}
                    {:else}
                      -
                    {/if}
                  </div>
                </div>

                {#if person.ssn}
                  <button
                    type="button"
                    class="btn-main btn-sm"
                    on:click={() => (ssnVisible = !ssnVisible)}
                  >
                    {#if ssnVisible}
                      <EyeSlashIcon />
                      Hide
                    {:else}
                      <EyeIcon />
                      View
                    {/if}
                  </button>
                {/if}
              </div>
            </div>
          </div>

          {#if (userCan("assets_read") || userCan("assets_read_designated")) && person.asset?.length}
            <div
              class="mobile-list-container mb-[36px] rounded-[5px] border border-ui/border shadow md:mb-[50px]"
            >
              <div
                class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] border-b border-ui/border bg-bg/lighter p-[6px] pl-[15px]"
              >
                <div
                  class="mobile-list-header-text text-body/large font-semibold text-text/dark"
                >
                  Asset Distribution
                </div>
              </div>

              <table class="table !rounded-t-none">
                <thead class="text-text/medium">
                  <th>Asset</th>
                  <th>Type</th>
                  <th>Method</th>
                  <th />
                </thead>
                <tbody>
                  {#each person.asset as asset (asset.id)}
                    {@const normalizedAsset = getNormalizedAsset(asset)}
                    {@const trustName = assetTrusts.find(
                      (t) => t.id === asset.distribution_trust_id
                    )?.name}

                    <tr>
                      <td>
                        {normalizedAsset.description}

                        <span class="table-meta">
                          {normalizedAsset?.descriptionSubtext ?? ""}
                        </span>
                      </td>
                      <td class="max-md:hidden">
                        {normalizedAsset.title}

                        <span class="table-meta">
                          {#if userCan("show_dollar_values")}
                            <sup>$</sup>{convertCentsToDollarString(
                              normalizedAsset.value
                            )}
                          {:else}
                            &mdash;
                          {/if}
                        </span>
                      </td>
                      <td class="max-md:hidden">
                        {#if $userStore.user_type === "owner" && ["will", "trust"].includes(normalizedAsset.distributionMethod)}
                          <button
                            class="anchor inline-flex items-center gap-2 font-semibold no-underline"
                            on:click={() => {
                              modalStore.close();
                              navigate(Routes.Vault);
                            }}
                          >
                            <DocumentIcon />
                            {!!trustName
                              ? trustName
                              : startCase(normalizedAsset.distributionMethod)}
                          </button>
                        {:else}
                          {normalizedAsset.distributionMethod === "tbd"
                            ? "TBD"
                            : startCase(normalizedAsset.distributionMethod) ||
                              "-"}
                        {/if}
                      </td>
                      <td class="text-right">
                        <button
                          type="button"
                          class="btn-main mobile-icon-button btn-sm"
                          on:click={() => {
                            assetSelectedForEdit(normalizedAsset);
                            view = "Distribution";
                          }}
                        >
                          <PenIcon />

                          Edit
                        </button>
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          {/if}
          <div
            class="flex flex-col items-start justify-start gap-[30px] md:mt-[70px]"
          >
            {#if viewingAsOwner && !beneficiaryCanAccess}
              <button
                type="button"
                class="mobile-delete-btn variant-ringed-success btn btn-sm bg-white text-state/positive md:!hidden"
                on:click={showInviteView}
              >
                <PlusPersonIcon />

                Invite to Access
              </button>
            {/if}

            <button
              class="mobile-delete-btn variant-ringed-error btn border-state/negative bg-white text-state/negative"
              on:click={() => (view = "Delete")}
            >
              <TrashIcon />

              Delete Beneficiary
            </button>
          </div>
        </div>
      {:else}
        <div class="mobile-content-body md:px-[50px] md:pb-[50px]">
          <div
            class="mobile-list-container table-container mb-[36px] md:mb-[70px]"
          >
            <div
              class="mobile-list-header flex min-h-[52px] items-center justify-between rounded-tl-[5px] rounded-tr-[5px] border-b border-ui/border bg-bg/lighter p-[6px] pl-[15px]"
            >
              <span
                class="mobile-list-header-text text-body/large font-semibold text-text/dark"
              >
                Access Permissions
              </span>

              <button
                type="button"
                class="btn-main mobile-list-header-link btn-sm shadow-heavy"
                on:click={showEditAccessView}
              >
                <PenIcon />

                Edit
              </button>
            </div>

            <div
              class="mobile-list-item items-start border-b border-ui/border bg-white px-[20px] py-[22px] md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Dashboard
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.dashboard_read
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.dashboard_read}
                    <span class="perm-icon"><EyeIcon /></span> Can View
                  {:else}
                    <span class="perm-icon"><CancelIcon /></span> No Access
                  {/if}
                </span>
              </div>
            </div>

            <div
              class="mobile-list-item items-start border-b border-ui/border bg-white px-[20px] py-[22px] md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Assets Section
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.assets_write ||
                  permissions.assets_read ||
                  permissions.assets_read_designated
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.assets_write}
                    <span class="perm-icon"><PenIcon /></span> Can Manage
                  {:else if permissions.assets_read}
                    <span class="perm-icon"><EyeIcon /></span> Can View
                  {:else if permissions.assets_read_designated}
                    <span class="perm-icon"><EyeIcon /></span> Can View Designated
                  {:else}
                    <span class="perm-icon"><CancelIcon /></span> No Access
                  {/if}

                  {#if permissions.assets_read_designated}
                    <span
                      class="tooltip ml-[7px] cursor-help text-state/link transition hover:opacity-70 [&>*]:pointer-events-none"
                      use:popup={{
                        event: "hover",
                        target: `perm-tooltip`,
                        placement: "bottom",
                      }}
                    >
                      <TooltipIcon />
                    </span>
                    <div
                      class="rounded-[6px] border border-black bg-text/dark px-[14px] py-[12px] text-white shadow-heavy"
                      data-popup="perm-tooltip"
                    >
                      <p class="max-w-[250px] text-body/small leading-[24px]">
                        This beneficiary may only view assets which are
                        designated to them.
                      </p>
                    </div>
                  {/if}
                </span>
              </div>
            </div>

            <div
              class="mobile-list-item items-start border-b border-ui/border bg-white px-[20px] py-[22px] md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Debts Section
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.debts_write ||
                  permissions.debts_read
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.debts_write}
                    <span class="perm-icon"><PenIcon /></span> Can Manage
                  {:else if permissions.debts_read}
                    <span class="perm-icon"><EyeIcon /></span> Can View
                  {:else}
                    <span class="perm-icon"><CancelIcon /></span> No Access
                  {/if}
                </span>
              </div>
            </div>

            <div
              class="mobile-list-item items-start border-b border-ui/border bg-white px-[20px] py-[22px] md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Services Section
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.services_write ||
                  permissions.services_read
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.services_write}
                    <span class="perm-icon"><PenIcon /></span> Can Manage
                  {:else if permissions.services_read}
                    <span class="perm-icon"><EyeIcon /></span> Can View
                  {:else}
                    <span class="perm-icon"><CancelIcon /></span> No Access
                  {/if}
                </span>
              </div>
            </div>

            <div
              class="mobile-list-item items-start border-b border-ui/border bg-white px-[20px] py-[22px] md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Beneficiaries Section
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.beneficiaries_write ||
                  permissions.beneficiaries_read
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.beneficiaries_write}
                    <span class="perm-icon"><PenIcon /></span> Can Manage
                  {:else if permissions.beneficiaries_read}
                    <span class="perm-icon"><EyeIcon /></span> Can View
                  {:else}
                    <span class="perm-icon"><CancelIcon /></span> No Access
                  {/if}
                </span>
              </div>
            </div>

            <div
              class="mobile-list-item items-start border-b border-ui/border bg-white px-[20px] py-[22px] md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Vault Section
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.vault_write ||
                  permissions.vault_read
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.vault_write}
                    <span class="perm-icon"><PenIcon /></span> Can Manage
                  {:else if permissions.vault_read}
                    <span class="perm-icon"><EyeIcon /></span> Can View
                  {:else}
                    <span class="perm-icon"><CancelIcon /></span> No Access
                  {/if}
                </span>
              </div>
            </div>

            <div
              class="mobile-list-item items-start bg-white px-[20px] py-[22px] max-md:border-b max-md:border-ui/border-dark md:flex"
            >
              <div class="mobile-list-item-label w-[44%] text-body/medium">
                Dollar Amounts<span
                  class="mt-[12px] text-table/meta text-text/medium md:block"
                  ><span class="md:hidden">&nbsp;(</span>Platform-wide<span
                    class="md:hidden">)</span
                  ></span
                >
              </div>
              <div class="mobile-list-item-value w-[56%] max-md:pt-[6px]">
                <span
                  class="flex items-center gap-[5px] text-lg font-semibold {permissions.show_dollar_values
                    ? 'text-state/positive'
                    : 'text-state/negative'}"
                >
                  {#if permissions.show_dollar_values}
                    <span class="perm-icon"><EyeIcon /></span> Show Amounts
                  {:else}
                    <span class="perm-icon"><EyeSlashIcon /></span> Hide Amounts
                  {/if}
                </span>
              </div>
            </div>
          </div>

          <button
            class="mobile-delete-btn variant-ringed-error btn border-state/negative bg-white text-state/negative"
            on:click={removeAccess}
          >
            <BlockIcon />

            Remove Access
          </button>
        </div>
      {/if}
    </div>
  {:else if view === "Edit Person"}
    <div class="w-[750px] max-w-[100%]">
      <AddUpdateBeneficiaryPersonForm
        {person}
        {loading}
        isUpdate
        on:submit={handleUpdate}
        on:cancel={() => (view = "Details")}
      />
    </div>
  {:else if view === "Edit Access"}
    <div class="mobile-content w-[750px] max-w-[100%] max-md:!top-[85px]">
      <div
        class="mobile-header mb-[30px] px-[50px] pt-[30px] max-md:block max-md:!h-[85px]"
      >
        <h1 class="mobile-header-title-fixed max-md:!text-left">Edit Access</h1>
        <p
          class="mobile-header-subtitle-fixed mt-[15px] text-body/medium text-text/medium"
        >
          {person.first_name}
          {person.last_name}
        </p>
      </div>

      <div class="mobile-content-body">
        <AccessFormField bind:permissions={uiPermissions} />
      </div>

      <div
        class="mobile-content-footer flex items-baseline justify-between px-[50px] py-[40px]"
      >
        <button
          type="button"
          class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
          disabled={loading}
          on:click={() => (view = "Details")}
        >
          <span class="md:hidden">
            <ArrowLeftIcon />
          </span>
          Cancel
        </button>

        <button
          type="submit"
          class="btn-filled btn-success btn-lg flex gap-[10px]"
          disabled={loading}
          on:click={handleUpdatePermissions}
        >
          {#if loading}
            <ProgressRadial
              value={undefined}
              width="w-6"
              meter="stroke-white"
            />

            Saving
          {:else}
            <CheckIcon />

            Save Changes
          {/if}
        </button>
      </div>
    </div>
  {:else if view === "Invite"}
    <InviteToAccessForm
      {person}
      on:done={handleDoneInviting}
      on:cancel={() => (view = "Details")}
    />
  {:else if view === "Delete"}
    <DeleteBeneficiaryPersonForm
      {person}
      on:done={handleDoneDeleting}
      on:cancel={() => (view = "Details")}
    />
  {:else if view === "Distribution"}
    <div class="w-[750px] max-w-[100%]">
      <EditDistribution
        multipartFormData={selectedAssetDistributionData}
        selectedAssetType={$selectedAssetDistributionData.assetType}
        name={$selectedAssetDistributionData.name}
        onSubmitted={handleDoneEditingAssetDistribution}
        onCancelled={() => (view = "Details")}
      />
    </div>
  {/if}
</Modal>

<style>
  .perm-icon :global(svg) {
    width: 20px;
    height: 20px;
  }
</style>
