import { computePosition, shift, autoUpdate, offset } from "@floating-ui/dom";
import { writable, type Writable } from "svelte/store";

/**
 * Track state in store
 */
export const activeTourStep: Writable<number> = writable(0);

/**
 * Svelte Action to let us hook into tour via any template
 *
 * Usage: `<div use:tour={n}>` where n is the zero-indexed step of the tour
 */
let seen = [];
export function tour(node: HTMLElement, i: number): void {
  // bail before we begin, if tour is not active
  if (!history.state?.tour) return;

  //? This action is firing twice on nav items for some reason. We only want the
  //? first one. We'll abort repeat fires by tracking what has already fired.
  if (seen.includes(i)) return;
  seen.push(i);

  node.dataset.tourTrigger = i.toString();
  const tourTip = document.querySelector(`[data-tour="${i}"]`) as HTMLElement;
  if (!tourTip) return;

  function updatePosition() {
    computePosition(node, tourTip, {
      placement: i === 0 ? "bottom-start" : "right-start",
      strategy: "fixed",
      middleware: [
        shift(),
        ...(i === 0
          ? [offset({ mainAxis: 15, crossAxis: -15 })]
          : [offset({ crossAxis: -10 })]),
      ],
    }).then(({ x, y }) => {
      Object.assign(tourTip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  // dispose of listener
  const cleanup = autoUpdate(node, tourTip, updatePosition);
  activeTourStep.subscribe((activeStep) => {
    if (activeStep > i || activeStep === null) cleanup();
  });
}

/**
 * Scroll triggers into view if necessary
 */
activeTourStep.subscribe((step) => {
  const nextStepTrigger = document.querySelector(
    `[data-tour-trigger="${step}"]`
  ) as HTMLElement;
  if (nextStepTrigger && !isElementVisible(nextStepTrigger)) {
    nextStepTrigger.scrollIntoView({
      behavior: "smooth",
    });
  }
});

/**
 * Helpers
 */
function isElementVisible(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
