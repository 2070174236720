<script lang="ts">
  import type { Person } from "../../pages/Beneficiaries.svelte";
  import { supabase } from "../../supabaseClient";
  import { popSuccessToast } from "../../util/toasts";
  import DeleteForm from "../DeleteForm.svelte";

  export let person: Person;

  async function deleteAction() {
    const { error } = await supabase
      .from("beneficiary_person")
      .delete()
      .eq("id", person.id);

    if (error) throw error;

    if (canAccess && person.user_id) {
      const { data, error: authDeleteErr } = await supabase.functions.invoke(
        "delete-collaborator",
        {
          body: {
            collaborator_id: person.user_id,
          },
        }
      );

      if (authDeleteErr ?? data.error) {
        // error caught in DeleteForm
        throw new Error(authDeleteErr ?? data.error);
      }
    }

    popSuccessToast(
      `Success! Beneficiary: ${person.first_name} ${person.last_name} has been removed.`
    );
  }

  $: canAccess = !!person.user_id;
  $: hasDesignations = !!person.asset?.length;

  $: requirements = [
    "This beneficiary will be completely removed from the LegaSEE application.",
    ...(hasDesignations
      ? [
          "Any asset designations associated with this beneficiary will be removed.",
        ]
      : []),
    ...(canAccess
      ? ["Account access for this beneficiary will be revoked."]
      : []),
  ];
</script>

<DeleteForm {requirements} {deleteAction} on:done on:cancel>
  Delete {person?.first_name}
  {person.last_name}
</DeleteForm>
