<script lang="ts" context="module">
  import { supabase } from "../supabaseClient";

  export function fetchClients() {
    return supabase
      .from("estate")
      .select(
        "*, user!estate_owner_id_fkey (email, first_name, last_name, invited_by, last_sign_in_at, created_at)"
      );
  }

  export type ClientEstate = Awaited<
    ReturnType<typeof fetchClients>
  >["data"][number];
</script>

<script lang="ts">
  import ContextMenu from "../lib/ContextMenu.svelte";
  import Tab from "../lib/Tab.svelte";
  import Tabs from "../lib/Tabs.svelte";
  import ActiveClientList from "../lib/advisors/ActiveClientList.svelte";
  import InviteClientModal from "../lib/advisors/InviteClientModal.svelte";
  import InviteViaRedtail from "../lib/advisors/InviteViaRedtail.svelte";
  import InvitedClientsList from "../lib/advisors/InvitedClientsList.svelte";
  import CheckIcon from "../lib/icons/CheckFramed.svelte";
  import EnvelopeIcon from "../lib/icons/Envelope.svelte";
  import ImportIcon from "../lib/icons/Import.svelte";
  import MailIcon from "../lib/icons/Mail.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import TablePlaceholder from "../lib/placeholders/ClientsTable.svelte";
  import { userStore } from "../stores/user";
  import { popErrorToast } from "../util/toasts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";

  let tabView: "Active" | "Invited" = "Active";

  let loading = false;
  let clientEstates: ClientEstate[] = [];

  $: activeClients = clientEstates.filter(
    (client) => client.user.last_sign_in_at
  );
  $: invitedClients = clientEstates.filter(
    (client) =>
      !client.user.last_sign_in_at &&
      client.user.invited_by === $userStore?.user_id
  );

  onMount(init);

  async function init() {
    try {
      loading = true;

      const { data, error } = await fetchClients();

      if (error) throw error;

      clientEstates = data;
    } catch (error) {
      if (error instanceof Error) {
        popErrorToast(error.message);
      }
    } finally {
      loading = false;
    }
  }

  function showModal(type: "manual" | "redtail" = "manual") {
    modalStore.trigger({
      type: "component",
      component: {
        ref: type === "redtail" ? InviteViaRedtail : InviteClientModal,
      },
      response: (success: boolean) => {
        // re-init (fetch) clients on success
        if (success) {
          init();
          tabView = "Invited";
        }
      },
    });
  }
</script>

<header class="mb-[40px] flex items-center justify-between">
  <h1 class="mobile-header-title-fixed">Your Clients</h1>

  <ContextMenu
    classes=""
    menuClasses="mobile-fab-context"
    items={[
      {
        text: "Invite Manually",
        icon: MailIcon,
        color: "text-text/dark",
        onClick: () => showModal("manual"),
      },
      {
        text: "Import from Redtail",
        icon: ImportIcon,
        color: "text-state/dark",
        onClick: () => showModal("redtail"),
      },
    ]}
    placement="bottom"
    offsetMain={-45}
    offsetCross={-18}
  >
    <button class="btn-main mobile-fab">
      <PlusIcon />
      <span>Invite Clients...</span>
    </button>
  </ContextMenu>
</header>

<div class="mobile-content max-md:!top-[140px]">
  <div class="mobile-content-body">
    {#if loading}
      <TablePlaceholder />
    {:else if clientEstates.length}
      <Tabs noPadding>
        <Tab bind:group={tabView} value="Active">
          <CheckIcon /> <span class="mobile-tab-text">Active</span>
        </Tab>
        <Tab bind:group={tabView} value="Invited">
          <EnvelopeIcon /> <span class="mobile-tab-text">Invited</span>
          <span
            class="ml-[-2px] flex h-[26px] w-[26px] items-center justify-center rounded-full border border-ui/border-dark text-[14px] font-semibold leading-[14px] shadow max-md:absolute max-md:left-[75%] max-md:top-[5px] max-md:ml-[15px] max-md:bg-white"
            >{invitedClients.length}</span
          >
        </Tab>
      </Tabs>

      {#if tabView === "Active"}
        <ActiveClientList clients={activeClients} />
      {/if}

      {#if tabView === "Invited"}
        <InvitedClientsList clients={invitedClients} on:cancel={init} />
      {/if}
    {:else}
      <div
        class="flex flex-col items-center justify-center border-t border-ui/border pt-[150px] text-body/large"
      >
        <h2 class="mb-[50px] font-normal text-text/medium">
          You don't have any clients yet.
        </h2>

        <button class="btn-main btn-lg" on:click={() => showModal()}>
          <span><PlusIcon /></span>
          <span>Invite Your First Client</span>
        </button>
      </div>
    {/if}
  </div>
</div>
