import { supabase } from "../supabaseClient";
import type { User, Access, Estate, Permissions } from "../types";
import { popErrorToast } from "../util/toasts.ts";
import { localStorageStore } from "@skeletonlabs/skeleton";
import { get, writable, type Writable } from "svelte/store";

/**
 * Export stores
 *
 * * userStore holds information about the user
 * * userPermission holds information about what the user can/can't access in the ui
 * * currentEstate tracks the currently-viewed estate
 *   ? currentEstate is additionally tracked in localStorage
 */
export const userStore: Writable<User | null> = writable(null);
export const userPermissions: Writable<Access | null> = writable(null);

const initialEstate = localStorage.getItem("currentEstate")
  ? JSON.parse(localStorage.getItem("currentEstate"))
  : null;

export const currentEstate: Writable<Estate> = localStorageStore(
  "currentEstate",
  initialEstate
);

/**
 * Login / Logout
 */
supabase.auth.onAuthStateChange(async (_event, session) => {
  // purge user
  if (!session) {
    userStore.set(null);
    userPermissions.set(null);
    currentEstate.set(null);
    return;
  }

  // init user
  if (!get(userStore) && session) {
    const user = await getUser(session.user.id);
    userStore.set(user);

    /**
     * Permissions are inherently tied to estates, so those will be set
     * in a subscription on currentEstate, here we need only to set up
     * the estate.
     *
     * However, in the case of advisors who do not already have an estate
     * selected in local storage, we must go ahead and provide permissions at
     * this time (a special "pending" case which will be handled by our router
     * to take them to the client (estate) selection screen.
     */
    if (user.user_type !== "advisor") {
      const estates = await getEstates();
      currentEstate.set(estates[0]);
    }
    if (user.user_type === "advisor" && !get(currentEstate)) {
      userPermissions.set("PENDING_ESTATE_SELECTION");
    }
  }

  // handle realtime updates to collaborator permissions
  if (session && get(userStore)?.user_type !== "owner") {
    const currentEstateId = get(currentEstate)?.id;

    if (currentEstateId) {
      userPermissions.set(await getPermissions(currentEstateId));
    }
  }

  /**
   * ! Edge case:
   * When logging in as a new user while signed in as another user (possible by
   * following an email invite while already logged in as another user), this
   * won't trip "purge user" routine above, because the session changes but is
   * never null.
   *
   * This scenario is only likely in UAT testing, but it can result in crashing
   * the browser when logging in as a fresh advisor, due to erroneous values
   * persisted for currentEstate (from the previous user).
   *
   * Whenever the inviteeAwaitingAccountSetup is present, we'll kill the
   * currentEstate "local storage store".
   */
  if (session?.user?.user_metadata?.flags?.inviteeAwaitingAccountSetup) {
    //? but owners should have currentEstate (its needed in onboardinng)
    if (!(get(userStore)?.user_type === "owner")) currentEstate.set(null);
  }
});

/**
 * Subscription: update permissions whenever the currently viewed estate changes
 *
 * ? Note: this also serves as the initialization of permissions on page load
 */
currentEstate.subscribe(async (estate) => {
  if (!estate?.id) return;

  const user = get(userStore);
  if (!user) return;

  userPermissions.set(
    user.user_type === "owner" ? "OWNER" : await getPermissions(estate.id)
  );
});

/**
 * Lib
 */
async function getUser(uid: string): Promise<User | null> {
  const { data, error } = await supabase
    .from("user")
    .select("*")
    .eq("user_id", uid)
    .single();

  if (error) {
    popErrorToast(error.message);
  }

  return data ?? null;
}

async function getPermissions(estate_id: number): Promise<Access | null> {
  if (!estate_id) return null;
  const { data, error } = await supabase
    .from("permission")
    .select("*")
    .eq("estate_id", estate_id)
    .limit(1);

  if (error) {
    popErrorToast(error.message);
  }

  return data[0] ?? null;
}

async function getEstates(): Promise<Estate[] | null> {
  const { data, error } = await supabase.from("estate").select("*");

  if (error) {
    popErrorToast(error.message);
  }

  return data ?? null;
}

/**
 * Check if the user can access something
 * @param {string} permission ex: "debts_write", "services_read"
 * @return {boolean}
 */
export function userCan(permission: keyof Permissions): boolean {
  return isOwner() || (get(userPermissions)?.[permission] ?? false);
}

export function isOwner(): boolean {
  return get(userStore)?.user_type === "owner";
}
