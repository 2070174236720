<script lang="ts">
  import CollapsibleTable from "../lib/CollapsibleTable.svelte";
  import ContextMenu from "../lib/ContextMenu.svelte";
  import Tab from "../lib/Tab.svelte";
  import Tabs from "../lib/Tabs.svelte";
  import TasksToggleButton from "../lib/TasksToggleButton.svelte";
  import { getAssetDisplayName } from "../lib/assets/assets";
  import { getAssetBeneficiaries } from "../lib/assets/assets";
  import { getInitials } from "../lib/beneficiaries/beneficiaries";
  import DocumentIcon from "../lib/icons/DocumentFramed.svelte";
  import DocumentsIcon from "../lib/icons/Documents.svelte";
  import DownloadIcon from "../lib/icons/Download.svelte";
  import NotebookPenIcon from "../lib/icons/NotebookPen.svelte";
  import PenClipIcon from "../lib/icons/PenClip.svelte";
  import PlusIcon from "../lib/icons/Plus.svelte";
  import PlusCircleIcon from "../lib/icons/PlusCircle.svelte";
  import TrashIcon from "../lib/icons/Trash.svelte";
  import UpdateIcon from "../lib/icons/Update.svelte";
  import CardsPlaceholder from "../lib/placeholders/VaultCards.svelte";
  import { refreshTasks } from "../lib/readiness/readiness";
  import AddDocumentModal from "../lib/vault/AddDocumentModal.svelte";
  import AddTrustModal from "../lib/vault/AddTrustModal.svelte";
  import BulkUploadModal from "../lib/vault/BulkUploadModal.svelte";
  import DeleteDocumentModal from "../lib/vault/DeleteDocumentModal.svelte";
  import DeleteTrustModal from "../lib/vault/DeleteTrustModal.svelte";
  import DeleteWillModal from "../lib/vault/DeleteWillModal.svelte";
  import UpdateDocumentModal from "../lib/vault/UpdateDocumentModal.svelte";
  import UpdateTrustModal from "../lib/vault/UpdateTrustModal.svelte";
  import UpdateWillModal from "../lib/vault/UpdateWillModal.svelte";
  import { currentEstate, userCan } from "../stores/user";
  import { supabase } from "../supabaseClient";
  import type { Document, Trust } from "../types";
  import { formatDBDate } from "../util/format";
  import { Routes, onNavigate } from "../util/routes";
  import {
    type DocumentCategory,
    documentCategories,
    getSignedUrl,
  } from "../util/storage";
  import { popErrorToast } from "../util/toasts";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";

  type TrustAssetDesignation = Awaited<
    ReturnType<typeof getTrustAssetDesignations>
  >[number];

  type WillAssetDesignations = Awaited<
    ReturnType<typeof getWillAssetDesignations>
  >[number];

  type DocumentsMap = {
    [key in DocumentCategory]: Document[];
  };

  export let tabView: "Will & Trusts" | "Documents" = "Will & Trusts";

  let trusts: Trust[] = [];
  let documentsMap: DocumentsMap;
  let trustAssetDesignations: TrustAssetDesignation[] = [];
  let willAssetDesignations: WillAssetDesignations[] = [];
  let loading: boolean = false;
  $: willDoc = documentsMap?.["estate"]?.find((doc) => doc.type === "will");

  // Fetch data
  async function fetchTrusts() {
    const { data, error } = await supabase
      .from("trust")
      .select("*")
      .eq("estate_id", $currentEstate.id);
    if (error) {
      console.error(error.message);
      popErrorToast("Error fetching trusts");
    }
    trusts = data;
  }

  async function fetchDocuments() {
    const { data, error } = await supabase
      .from("document")
      .select("*")
      .eq("estate_id", $currentEstate.id);
    if (error) {
      console.error(error.message);
      popErrorToast("Error fetching documents");
    }
    //@ts-ignore
    documentsMap = documentCategories.reduce((acc, category) => {
      acc[category] = data.filter((doc: Document) => doc.category === category);
      return acc;
    }, {});
  }

  function getTrustDoc(trust: Trust) {
    return documentsMap?.["estate"]?.find(
      (doc) => doc.type === "trust" && doc.description === trust.name
    );
  }

  async function getWillAssetDesignations() {
    const { data, error } = await supabase
      .from("asset")
      .select(
        `
            id,
            financial_account (institution_name, account_number, label),
            real_estate (address),
            property (description),
            loan_asset (debtor),
            insurance (insurer),
            inheritance (description),
            legal_entity (name),
            vehicle (year, model),
            beneficiary_person (first_name, last_name, id),
            beneficiary_charity (name, id)
          `
      )
      .eq("estate_id", $currentEstate.id)
      .eq("distribution_method", "will");

    if (error) throw error;

    return data;
  }

  async function getTrustAssetDesignations() {
    const { data, error } = await supabase
      .from("trust")
      .select(
        `id,
          asset (
            id,
            financial_account (institution_name, account_number, label),
            real_estate (address),
            property (description),
            loan_asset (debtor),
            insurance (insurer),
            inheritance (description),
            legal_entity (name),
            vehicle (year, model),
            beneficiary_person (first_name, last_name, id),
            beneficiary_charity (name, id)
          )`
      )
      .eq("estate_id", $currentEstate.id);

    if (error) throw error;

    return data;
  }

  async function fetchTrustAssetDesignations() {
    trustAssetDesignations = await getTrustAssetDesignations();
  }

  async function fetchWillAssetDesignations() {
    willAssetDesignations = await getWillAssetDesignations();
  }

  // --- MODALS ---
  function openModal(
    component: any,
    props = {},
    response = (res: any) => null
  ) {
    modalStore.trigger({
      type: "component",
      component: {
        ref: component,
        props,
      },
      response,
    });
  }

  // Will
  function showUpdateWillModal() {
    openModal(UpdateWillModal, { replace: !!willDoc }, (shouldFetch) => {
      if (shouldFetch) {
        fetchDocuments();
      }
    });
  }

  function showDeleteWillModal() {
    openModal(
      DeleteWillModal,
      { hasAssetDesignations: willAssetDesignations?.length },
      (shouldDelete) => {
        if (shouldDelete) {
          willAssetDesignations = [];
          fetchDocuments();
        }
      }
    );
  }

  // Trusts
  function showAddTrustModal() {
    openModal(AddTrustModal, null, async (shouldFetch) => {
      if (shouldFetch) {
        await fetchDocuments();
        fetchTrusts();
      }
    });
  }

  function showUpdateTrustModal(trust: Trust, hasDoc: boolean) {
    openModal(UpdateTrustModal, { trust, hasDoc }, async (shouldFetch) => {
      if (shouldFetch) {
        await fetchDocuments();
        fetchTrusts();
      }
    });
  }

  function showDeleteTrustModal(trust: Trust, hasDoc: boolean) {
    const hasAssetDesignations = trustAssetDesignations.find(
      (t) => t.id === trust.id
    );

    openModal(
      DeleteTrustModal,
      {
        trust,
        hasDoc,
        hasAssetDesignations,
      },
      (shouldDelete: boolean) => {
        if (shouldDelete) {
          fetchTrusts();
          fetchDocuments();
        }
      }
    );
  }

  // Documents
  function showAddDocumentModal(
    category: DocumentCategory = null,
    forTask: string = null
  ) {
    openModal(
      AddDocumentModal,
      {
        ...(category ? { category } : {}),
        ...(forTask ? { forTask } : {}),
      },
      (shouldFetch) => {
        if (shouldFetch) {
          fetchDocuments();
          refreshTasks();
        }
      }
    );
  }

  function showUpdateDocumentModal(document: Document) {
    openModal(UpdateDocumentModal, { document }, (shouldFetch) => {
      if (shouldFetch) {
        fetchDocuments();
      }
    });
  }

  function showDeleteDocumentModal(document: Document) {
    openModal(DeleteDocumentModal, { document }, (shouldDelete) => {
      if (shouldDelete) {
        fetchDocuments();
      }
    });
  }

  // Creates a signed URL and downloads the document on demand/lazily
  async function downloadDocument(path: string) {
    try {
      const url = await getSignedUrl(path);

      // Open in new tab
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", "");

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      popErrorToast("Could not download document");
    }
  }

  async function showBulkUploadModal(
    category: DocumentCategory,
    taskId: number
  ): Promise<void> {
    //? we may have to wait for loading, so we will poll if necessary
    const ready = () => !!documentsMap && !!Object.keys(documentsMap).length;
    const go = (existingDocs: Document[]) => {
      openModal(
        BulkUploadModal,
        {
          category,
          taskId,
          existingDocs,
        },
        async (response) => {
          if (response?.taskId) {
            await supabase
              .from("task")
              .update({ complete: true })
              .eq("id", taskId);
          }
          if (response?.done) {
            fetchDocuments();
            refreshTasks();
          }
        }
      );
    };
    if (ready()) {
      go(documentsMap[category]);
    } else {
      const interval = setInterval(() => {
        if (ready()) {
          clearInterval(interval);
          go(documentsMap[category]);
        }
      }, 200);
    }
  }

  onMount(async () => {
    try {
      loading = true;
      await Promise.all([
        fetchTrusts(),
        fetchDocuments(),
        fetchTrustAssetDesignations(),
        fetchWillAssetDesignations(),
      ]);
    } catch (error) {
      popErrorToast(error?.message || "Could not fetch data");
    } finally {
      loading = false;
    }
  });

  onNavigate(({ state }) => {
    if (state?.openModal === "bulkUpload" && state?.category) {
      showBulkUploadModal(state.category, state.taskId);
    }

    if (state?.openModal === "uploadDoc") {
      showAddDocumentModal(state.category ?? null, state.task ?? null);
    }

    if (state?.openModal === "uploadTrustDoc") {
      showUpdateTrustModal(
        {
          id: state.trustId,
          name: state.trustName,
          estate_id: $currentEstate.id,
        },
        false
      );
    }

    if (state?.openModal === "uploadWillDoc") {
      showUpdateWillModal();
    }

    if (
      state?.tabView &&
      ["Will & Trusts", "Documents"].includes(state.tabView)
    ) {
      tabView = state.tabView;
    }
  });
</script>

<header class="mb-10 flex items-center justify-between">
  <h1 class="mobile-header-title-fixed">Digital Vault</h1>
  <div class="flex items-center">
    {#if userCan("vault_write")}
      <ContextMenu
        placement="bottom"
        offsetMain={-45}
        offsetCross={-17}
        classes=""
        menuClasses="mobile-fab-context"
        items={[
          {
            text: "New Trust",
            icon: PenClipIcon,
            onClick: () => showAddTrustModal(),
          },
          {
            text: "New Document",
            icon: DocumentIcon,
            onClick: () => showAddDocumentModal(),
          },
        ]}
      >
        <span class="btn-main mobile-fab">
          <PlusIcon />
          <span>New Item...</span>
        </span>
      </ContextMenu>
    {/if}

    {#if userCan("vault_write") && userCan("dashboard_read")}
      <TasksToggleButton class="ml-[15px]" />
    {/if}
  </div>
</header>

<div class="mobile-content max-md:!top-[140px]">
  <Tabs noPadding>
    <Tab bind:group={tabView} value="Will & Trusts">
      <NotebookPenIcon />

      <span class="mobile-tab-text">Will & Trusts</span>
    </Tab>
    <Tab bind:group={tabView} value="Documents">
      <DocumentsIcon />

      <span class="mobile-tab-text">Documents</span>
    </Tab>

    <div
      class="mobile-content-body max-md:min-h-[calc(100vh-140px)] max-sm:!pl-0 max-sm:!pr-0"
      slot="panel"
    >
      {#if tabView === "Will & Trusts"}
        {#if loading}
          <CardsPlaceholder />
        {:else}
          <div
            class="grid grid-cols-2 gap-[45px] max-md:gap-[20px] max-sm:grid-cols-1"
          >
            <div
              class="border border-ui/border bg-white pl-5 shadow sm:rounded"
            >
              <div
                class="flex items-center justify-between py-[21px] pr-[10px] text-[22px] font-semibold text-text/dark max-md:text-[18px] max-md:font-bold max-md:leading-[18px]"
              >
                Your Will
                {#if userCan("vault_write")}
                  <ContextMenu
                    items={[
                      {
                        text: "Update Document",
                        icon: UpdateIcon,
                        onClick: () => showUpdateWillModal(),
                      },
                      {
                        text: "Delete Will",
                        icon: TrashIcon,
                        color: "text-state/negative",
                        onClick: () => showDeleteWillModal(),
                      },
                    ]}
                  />
                {/if}
              </div>

              <hr />

              <div class="flex items-end justify-between py-[25px] pr-5">
                <div>
                  <div class="mb-[13px] text-text/medium">Legal Document</div>

                  {#if !!willDoc}
                    <button
                      type="button"
                      class="flex items-center gap-[7px] text-body/medium text-state/link max-md:text-body/small"
                      on:click={() => downloadDocument(willDoc.path)}
                    >
                      <DocumentIcon />

                      {willDoc.filename}
                    </button>
                  {:else}
                    <div
                      class="text-body/medium text-text/dark max-md:text-body/small"
                    >
                      Not added yet
                    </div>
                  {/if}
                </div>

                {#if userCan("vault_write")}
                  {#if !!willDoc}
                    <button
                      type="button"
                      class="variant-ringed-primary btn flex gap-[5px] text-state/link shadow-heavy max-md:!px-[12px] max-md:!py-[10px] max-md:!text-[16px]"
                      on:click={() => showUpdateWillModal()}
                    >
                      Update
                    </button>
                  {:else}
                    <button
                      type="button"
                      class="variant-ringed-success btn flex gap-[5px] text-state/positive shadow-heavy max-md:!px-[12px] max-md:!py-[10px] max-md:!text-[16px]"
                      on:click={() => showUpdateWillModal()}
                    >
                      Upload
                    </button>
                  {/if}
                {/if}
              </div>

              {#if userCan("assets_read")}
                <hr />

                <div class="py-[25px] pr-5">
                  <div class="mb-[13px] text-text/medium">
                    {willAssetDesignations?.length
                      ? "Asset Designations"
                      : "No Asset Designations"}
                  </div>

                  {#each willAssetDesignations as asset (asset.id)}
                    {@const beneficiaries = getAssetBeneficiaries(asset)}

                    <div class="mb-[15px] flex items-center justify-between">
                      <Link
                        class="flex items-center gap-[7px] text-body/medium text-state/link max-md:text-body/small"
                        to={Routes.Assets}
                        state={{
                          openModal: "viewAsset",
                          returnTo: location.pathname,
                          assetId: asset.id,
                        }}
                      >
                        {getAssetDisplayName(asset)}
                      </Link>

                      <div class="flex items-end gap-[10px] max-md:gap-[6px]">
                        {#each beneficiaries as beneficiary}
                          {@const fullName =
                            beneficiary.name ??
                            `${beneficiary.first_name} ${beneficiary.last_name}`}
                          <Link
                            to={Routes.Beneficiaries}
                            state={{
                              openModal: "viewAssetBeneficiary",
                              returnTo: location.pathname,
                              type: beneficiary.type,
                              id: beneficiary.id,
                            }}
                            class="chip !px-[5px] !pb-[3px] !pt-[4px] uppercase text-text/dark max-md:!px-[5px] max-md:!py-[3px] max-md:!text-[14px]"
                          >
                            {getInitials(fullName, beneficiary.type)}
                          </Link>
                        {/each}
                      </div>
                    </div>
                  {/each}
                </div>
              {/if}
            </div>

            {#each trusts as trust}
              {@const trustDoc = getTrustDoc(trust)}
              <div
                class="border border-ui/border bg-white pl-5 shadow sm:rounded"
              >
                <div
                  class="flex items-center justify-between py-[21px] pr-[10px] text-[22px] font-semibold text-text/dark max-md:text-[18px] max-md:font-bold max-md:leading-[18px]"
                >
                  {trust.name}

                  {#if userCan("vault_write")}
                    <ContextMenu
                      items={[
                        !!trustDoc
                          ? {
                              text: "Update Document",
                              icon: UpdateIcon,
                              onClick: () =>
                                showUpdateTrustModal(trust, !!trustDoc),
                            }
                          : {
                              text: "Upload Document",
                              icon: PlusCircleIcon,
                              color: "text-state/positive",
                              onClick: () =>
                                showUpdateTrustModal(trust, !!trustDoc),
                            },
                        {
                          text: "Delete Trust",
                          icon: TrashIcon,
                          color: "text-state/negative",
                          onClick: () =>
                            showDeleteTrustModal(trust, !!trustDoc),
                        },
                      ]}
                    />
                  {/if}
                </div>

                <hr />

                <div class="flex items-end justify-between py-[25px] pr-5">
                  <div>
                    <div class="mb-[13px] text-text/medium">Legal Document</div>

                    {#if !!trustDoc}
                      <button
                        type="button"
                        class="flex items-center gap-[7px] text-body/medium text-state/link max-md:text-body/small"
                        on:click={() => downloadDocument(trustDoc.path)}
                      >
                        <DocumentIcon />

                        {trustDoc.filename}
                      </button>
                    {:else}
                      <div
                        class="text-body/medium text-text/dark max-md:text-body/small"
                      >
                        Not added yet
                      </div>
                    {/if}
                  </div>
                  {#if userCan("vault_write")}
                    {#if !!trustDoc}
                      <button
                        type="button"
                        class="variant-ringed-primary btn flex gap-[5px] text-state/link shadow-heavy max-md:!px-[12px] max-md:!py-[10px] max-md:!text-[16px]"
                        on:click={() => showUpdateTrustModal(trust, !!trustDoc)}
                      >
                        Update
                      </button>
                    {:else}
                      <button
                        type="button"
                        class="variant-ringed-success btn flex gap-[5px] text-state/positive shadow-heavy max-md:!px-[12px] max-md:!py-[10px] max-md:!text-[16px]"
                        on:click={() => showUpdateTrustModal(trust, !!trustDoc)}
                      >
                        Upload
                      </button>
                    {/if}
                  {/if}
                </div>

                <hr />

                <div class="py-[25px] pr-5">
                  {#if trustAssetDesignations && userCan("assets_read")}
                    {@const assets =
                      trustAssetDesignations.filter(
                        (t) => t.id === trust.id
                      )?.[0]?.asset ?? []}
                    <div class="mb-[13px] text-text/medium">
                      {assets?.length
                        ? "Asset Designations"
                        : "No Asset Designations"}
                    </div>

                    {#each assets as asset (asset.id)}
                      {@const beneficiaries = getAssetBeneficiaries(asset)}

                      <div class="mb-[15px] flex items-center justify-between">
                        <Link
                          class="flex items-center gap-[7px] text-body/medium text-state/link max-md:text-body/small"
                          to={Routes.Assets}
                          state={{
                            openModal: "viewAsset",
                            returnTo: location.pathname,
                            assetId: asset.id,
                          }}
                        >
                          {getAssetDisplayName(asset)}
                        </Link>

                        <div class="flex items-end gap-[10px] max-md:gap-[6px]">
                          {#each beneficiaries as beneficiary}
                            {@const fullName =
                              beneficiary.name ??
                              `${beneficiary.first_name} ${beneficiary.last_name}`}
                            <Link
                              to={Routes.Beneficiaries}
                              state={{
                                openModal: "viewAssetBeneficiary",
                                returnTo: location.pathname,
                                type: beneficiary.type,
                                id: beneficiary.id,
                              }}
                              class="chip !px-[5px] !pb-[3px] !pt-[4px] uppercase text-text/dark max-md:!px-[5px] max-md:!py-[3px] max-md:!text-[14px]"
                            >
                              {getInitials(fullName, beneficiary.type)}
                            </Link>
                          {/each}
                        </div>
                      </div>
                    {/each}
                  {/if}
                </div>
              </div>
            {/each}
          </div>
        {/if}
      {:else if tabView === "Documents"}
        <div class="max-md:px-[16px]">
          {#if !Object.values(documentsMap ?? []).some((docs) => docs.length)}
            <div
              class="mt-[150px] flex flex-col items-center justify-center text-body/large"
            >
              <h2 class="mb-[50px] font-normal text-text/medium">
                You don't have any documents yet.
              </h2>

              {#if userCan("vault_write")}
                <button
                  class="btn-main btn-lg"
                  on:click={() => showAddDocumentModal()}
                >
                  <span><PlusIcon /></span>
                  <span>Add First Document</span>
                </button>
              {/if}
            </div>
          {:else}
            {#each Object.entries(documentsMap) as [category, documents]}
              {#if documents.length}
                <CollapsibleTable class="mt-[50px]">
                  <span class="capitalize" slot="titleBar">{category}</span>

                  <svelte:fragment slot="tbody">
                    {#each documents as document}
                      {#if document.id}
                        <tr>
                          <td class="w-[66%]">
                            <span class="capitalize max-md:text-body/small">
                              {document.description}
                            </span>

                            <span class="table-meta">
                              Added {formatDBDate(
                                document.created_at,
                                "MMMM dd, y"
                              )}
                            </span>
                          </td>

                          <td
                            class="w-[33%] text-right max-md:!pr-0 max-md:!align-middle"
                          >
                            <button
                              type="button"
                              class="variant-ringed-primary mobile-icon-button btn inline-flex gap-[5px] text-state/link shadow-heavy"
                              on:click={() => downloadDocument(document.path)}
                            >
                              <DownloadIcon />

                              Download
                            </button>
                          </td>

                          {#if userCan("vault_write")}
                            <td
                              class="relative w-[1%] !align-middle max-md:!px-[10px] max-md:text-right"
                            >
                              <ContextMenu
                                items={[
                                  {
                                    text: "Update Document",
                                    icon: UpdateIcon,
                                    onClick: () =>
                                      document.type === "will"
                                        ? showUpdateWillModal()
                                        : document.type === "trust"
                                        ? showUpdateTrustModal(
                                            trusts.find(
                                              (t) =>
                                                t.name === document.description
                                            ),
                                            true
                                          )
                                        : showUpdateDocumentModal(document),
                                  },
                                  {
                                    text: "Delete Document",
                                    icon: TrashIcon,
                                    color: "text-state/negative",
                                    onClick: () =>
                                      showDeleteDocumentModal(document),
                                  },
                                ]}
                              />
                            </td>
                          {/if}
                        </tr>
                      {/if}
                    {/each}
                  </svelte:fragment>
                </CollapsibleTable>
              {/if}
            {/each}
          {/if}
        </div>
      {/if}
    </div>
  </Tabs>
</div>
