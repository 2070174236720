<script lang="ts">
  import { getDebtTypeName } from "../../pages/Debts.svelte";
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import ArrowRightIcon from "../icons/ArrowRight.svelte";
  import CheckIcon from "../icons/Check.svelte";
  import WarnIcon from "../icons/WarnFilled.svelte";
  import type { DebtType } from "./debts";
  import { ProgressRadial } from "@skeletonlabs/skeleton";
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import { object, string } from "yup";

  export let debtType: DebtType;
  export let loading: boolean;
  export let isUpdate: boolean;

  const dispatch = createEventDispatcher();

  const { form, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      label: null,
    },
    validationSchema: object().shape({
      label: string().required("Required"),
    }),
    onSubmit: (formData) => {
      dispatch("submit", formData.label);
    },
  });

  let submitClicked = false;
</script>

<form class="mobile-content" on:submit={handleSubmit} novalidate>
  {#if isUpdate}
    <div class="mobile-header px-[50px] py-[30px] max-md:block">
      <h1 class="mobile-header-title-fixed max-md:!text-left">
        Edit Debt Details
      </h1>
    </div>
  {:else}
    <div class="mobile-header px-[50px] py-[30px]">
      <h1 class="mobile-header-title pb-[30px]">
        Add {getDebtTypeName(debtType)}
      </h1>
      <p class="m-0 p-0 text-body/large max-md:hidden">
        Since you didn’t provide us with an account number, please give us a
        short, unique description that can be used to identify this account in
        lists.
      </p>
    </div>
  {/if}

  <div
    class="mobile-content-body border-b border-t border-ui/border-light py-[50px] pl-[50px] md:shadow-forms"
  >
    <p class="mobile-content-description md:hidden">
      Since you didn’t provide us with an account number, please give us a
      short, unique description that can be used to identify this account in
      lists.
    </p>

    <div class="input-wrap">
      <label for="label"
        >Descriptive Label <span class="text-state/negative">*</span></label
      >

      <input
        type="text"
        id="label"
        bind:value={$form.label}
        on:change={handleChange}
      />

      {#if $errors.label && submitClicked}
        <small class="input-error"><WarnIcon /> {$errors.label}</small>
      {/if}
    </div>
  </div>

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="mobile-back-button variant-ringed-primary btn flex gap-[5px] text-state/link"
      disabled={loading}
      on:click={() => dispatch("cancel")}
    >
      {#if isUpdate}
        <span class="md:hidden"><ArrowLeftIcon /></span>

        Cancel
      {:else}
        <ArrowLeftIcon />

        Back
      {/if}
    </button>

    <button
      type="submit"
      class="btn-filled btn-lg btn-success flex gap-[10px]"
      disabled={loading}
      on:click={() => (submitClicked = true)}
    >
      {#if isUpdate}
        {#if loading}
          <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />

          Saving
        {:else}
          <CheckIcon />

          Save Changes
        {/if}
      {:else if loading}
        Adding Debt

        <ProgressRadial value={undefined} width="w-6" meter="stroke-white" />
      {:else}
        Add Debt

        <ArrowRightIcon />
      {/if}
    </button>
  </div>
</form>
