<script lang="ts">
  import { userStore } from "../../stores/user";
  import { Routes } from "../../util/routes";
  import { popConfettiAt } from "../Confettery.svelte";
  import CheckIcon from "../icons/CheckSmall.svelte";
  import ChevronDownIcon from "../icons/ChevronDown.svelte";
  import PlusIcon from "../icons/PlusSmall.svelte";
  import type { Task } from "./readiness";
  import { markComplete } from "./readiness";
  import { Accordion, AccordionItem } from "@skeletonlabs/skeleton";
  import { capitalize } from "lodash";
  import { Link } from "svelte-navigator";

  export let completed: boolean;
  export let tasks: Task[];
  export let autoOpen: boolean;
  export let showCaret: boolean;

  let openIndex = autoOpen ? 0 : null;

  const linkTo = (task: Task): string => {
    // @ts-ignore
    return task.action_hook?.linkTo ?? Routes[capitalize(task.category)];
  };

  function handleDismiss(e: Event, task: Task) {
    markComplete(task.id);

    const { clientX, clientY } = e as PointerEvent;

    if (!["Skip", "Ignore"].includes(task.dismiss_label)) {
      popConfettiAt(clientX, clientY);
    }
  }
</script>

<Accordion
  caretOpen="-scale-y-100"
  padding="p-0"
  regionCaret="hidden"
  hover="bg-inherit"
  rounded="rounded-none"
  autocollapse
>
  {#each tasks as task, i}
    <AccordionItem
      open={i === 0 && autoOpen}
      on:toggle={() => (openIndex = i)}
      class="!m-0 border-b border-ui/border transition {openIndex === i
        ? 'relative z-10 shadow-heavy'
        : ''}"
    >
      <svelte:fragment slot="summary">
        <div
          class="task-item flex items-center justify-between px-[15px] py-[10px] transition"
          class:is-active={openIndex === i}
        >
          <div class="flex flex-col gap-2">
            <span class="text-body/small">
              {task.name}
            </span>
            {#if openIndex === i && task.description}
              <span class="text-body/x-small text-text/medium">
                {task.description}
              </span>
            {/if}
          </div>
          <button
            class="toggle-icon relative -right-2 p-2 transition hover:opacity-70 active:scale-90"
            class:invisible={openIndex === i || !showCaret}
          >
            <ChevronDownIcon />
          </button>
        </div>
      </svelte:fragment>
      <svelte:fragment slot="content">
        <div
          class="task-item flex flex-col gap-[15px] px-[15px] pb-[18px] transition"
          class:is-active={openIndex === i}
        >
          {#if !completed}
            {#if task.repeatable && task.repetitions > 0}
              <span class="text-[14px] font-semibold text-state/positive">
                {task.repetitions} added so far
              </span>
            {/if}
            {#if !task.name.includes("Invite") || $userStore.user_type === "owner"}
              <div
                class="task-actions flex w-full justify-between pt-[10px] max-md:gap-[16px]"
              >
                <Link
                  to={linkTo(task)}
                  state={{
                    // @ts-ignore
                    ...task.action_hook,
                    taskId: task.id,
                    // @ts-ignore
                    ...(task.action_hook?.openModal
                      ? { returnTo: location.pathname }
                      : {}),
                  }}
                  class="task-action flex items-center justify-start gap-[5px] text-body/small font-semibold transition hover:opacity-70 max-md:flex-grow max-md:justify-center max-md:rounded-[6px] max-md:border max-md:border-state/link max-md:px-[24px] max-md:py-[16px] max-md:text-[18px] max-md:shadow-heavy"
                >
                  <span class="max-md:hidden">
                    <PlusIcon />
                  </span>

                  {task.repeatable && task.repetitions > 0
                    ? "Add Another"
                    : task.action_label}
                </Link>

                {#if task.dismiss_label}
                  <button
                    class="anchor flex items-center justify-start gap-[5px] text-body/small font-semibold no-underline transition hover:opacity-70 max-md:flex-grow max-md:justify-center max-md:rounded-[6px] max-md:border max-md:border-state/link max-md:px-[24px] max-md:py-[16px] max-md:text-[18px] max-md:shadow-heavy"
                    on:click={(e) => handleDismiss(e, task)}
                  >
                    <span class="max-md:hidden">
                      <CheckIcon />
                    </span>
                    {task.dismiss_label}
                  </button>
                {/if}
              </div>
            {/if}
          {/if}
        </div>
      </svelte:fragment>
    </AccordionItem>
  {/each}
</Accordion>

<style lang="postcss">
  .task-item {
    @apply bg-bg/lightest;
  }

  .task-item.is-active {
    @apply bg-white;
  }

  .toggle-icon :global(svg) {
    @apply w-[16px] text-text/medium;
  }

  @media (max-width: 768px) {
    .task-item {
      @apply bg-white;
    }
    .task-item.is-active {
      @apply relative border-l-8 border-state/link pl-[16px];
    }

    .task-item.is-active:after {
      content: " ";
      @apply absolute bottom-0 left-[-9px] top-0 w-[1px] bg-state/link;
    }
  }
</style>
