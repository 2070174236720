<script lang="ts">
  import BankIcon from "../icons/Bank.svelte";
  import CarIcon from "../icons/Car.svelte";
  import ChartIcon from "../icons/Chart.svelte";
  import GavelIcon from "../icons/Gavel.svelte";
  import HandCoinIcon from "../icons/HandCoin.svelte";
  import HierarchyTreeIcon from "../icons/HierarchyTree.svelte";
  import HouseIcon from "../icons/House.svelte";
  import MoneyStackIcon from "../icons/MoneyStack.svelte";
  import PiggyBankIcon from "../icons/PiggyBank.svelte";
  import RingIcon from "../icons/Ring.svelte";
  import UmbrellaIcon from "../icons/Umbrella.svelte";
  import { modalStore } from "@skeletonlabs/skeleton";
  import { startCase } from "lodash";

  export let selectedAssetType;
  export let restartAddAsset: () => void;
</script>

<div class="px-[50px] py-[30px] text-center">
  <h1>
    {#if selectedAssetType === "banking"}
      <span class="asset-icon"><BankIcon /></span>Banking Account Added!
    {:else if selectedAssetType === "investment"}
      <span class="asset-icon"><ChartIcon /></span>Investment Account Added!
    {:else if selectedAssetType === "retirement"}
      <span class="asset-icon"><PiggyBankIcon /></span>Retirement Account Added!
    {:else if selectedAssetType === "real_estate"}
      <span class="asset-icon"><HouseIcon /></span>Real Estate Added!
    {:else if selectedAssetType === "vehicle"}
      <span class="asset-icon"><CarIcon /></span>Vehicle Added!
    {:else if selectedAssetType === "property"}
      <span class="asset-icon"><RingIcon /></span>Property Added!
    {:else if selectedAssetType === "insurance"}
      <span class="asset-icon"><UmbrellaIcon /></span>Insurance Added!
    {:else if selectedAssetType === "inheritance"}
      <span class="asset-icon"><HierarchyTreeIcon /></span>Inheritance Added!
    {:else if selectedAssetType === "legal_entity"}
      <span class="asset-icon"><GavelIcon /></span>Legal Entity Added!
    {:else if selectedAssetType === "loan_asset"}
      <span class="asset-icon"><HandCoinIcon /></span>Loan Asset Added!
    {:else}
      <MoneyStackIcon /> {startCase(selectedAssetType)} Added!
    {/if}
  </h1>
</div>
<div class="flex flex-col border-t border-ui/border-light p-[50px] text-center">
  <p class="mb-[40px] text-[22px] leading-[28px]">
    What would you like to do now?
  </p>
  <button
    class="btn-main btn-lg m-auto mb-[30px] w-[100%] max-w-[350px]"
    on:click|preventDefault={restartAddAsset}>Add Another Asset</button
  >
  <button
    class="btn-main btn-lg btn-filled m-auto w-[100%] max-w-[350px]"
    on:click|preventDefault={() => modalStore.close()}>Close Window</button
  >
</div>

<style type="text/css">
  .asset-icon :global(svg) {
    width: 80px;
    height: 80px;
    margin: 0 auto 30px;
  }
</style>
