<script lang="ts">
  import ArrowLeftIcon from "../icons/ArrowLeft.svelte";
  import SendIcon from "../icons/Send.svelte";
  import AccessFormField from "./AccessFormField.svelte";
  import type {
    InviteCollaboratorFields,
    UIPermissions,
  } from "./collaborators";
  import type { Writable } from "svelte/store";

  export let multipartFormData: Writable<InviteCollaboratorFields>;
  export let goBack: () => void;
  export let inviteCollaborator: () => Promise<void>;
  export let processing: boolean;

  let permissions: UIPermissions;

  $: {
    if (permissions)
      $multipartFormData = {
        ...$multipartFormData,
        ...permissions,
      };
  }
</script>

<form class="mobile-content-body" on:submit|preventDefault={inviteCollaborator}>
  <AccessFormField bind:permissions isCollaborator={true} />

  <div
    class="mobile-content-footer flex items-center justify-between px-[50px] py-[40px]"
  >
    <button
      type="button"
      class="btn-main mobile-back-button"
      on:click|preventDefault={goBack}
    >
      <ArrowLeftIcon />
      <span>Back</span>
    </button>
    <button
      type="submit"
      disabled={processing}
      class="btn-lg btn variant-filled bg-state/positive"
    >
      <SendIcon />
      <span>{processing ? "Inviting..." : "Send Invite"}</span>
    </button>
  </div>
</form>
